import { createSlice } from '@reduxjs/toolkit';
import {chapters} from "../data/chapters"

const chaptersSlice = createSlice({
  name: "chapters",
  initialState: chapters,
  reducers: {

  }
});

export default chaptersSlice.reducer;