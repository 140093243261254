export const mcq_questions = [
    {
        question: "What is the quantity of heat a liquid must absorb for 1g of it to be onverted from the liquid to the gaseous state?",
        options: [
            "Calorie",
            "Heat of vaporization",
            "Kinetic energy",
            "Cohesion"
        ],
        answer: "Heat of vaporization",
        chapter:1
    },
    {
        question: "question2",
        options: [
            "option1",
            "option2",
            "option3",
            "option4"
        ],
        answer: "option2",
        chapter:1
    },
    {
        question: "question3",
        options: [
            "option1",
            "option2",
            "option3",
            "option4"
        ],
        answer: "option2",
        chapter:1
    }
]