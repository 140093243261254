import React, {useState, useEffect, useRef} from 'react';
import { fetchOpenAIResponse } from '../../util/fetchOpenAIResponse';
import { fetchOpenAITopics } from '../../util/fetchOpenAITopics';
import CHAT_URL from '../../util/CHAT_URL';

//images
import search from '../../images/icons/search.svg'
import filter from "../../images/icons/filter.svg"
import loader from "../../images/loader.svg"
import botIcon from "../../images/bot-icon-mini.svg"
import arrowSmall from "../../images/icons/arrow-down.svg"
import chevronSmall from "../../images/icons/chevron-down.svg"
import arrow from "../../images/icons/chevron-right.svg"
import searchSmall from "../../images/search-16.svg"
import closeIcon from "../../images/icons/close.svg"
import thumbsUp from "../../images/icons/u_thumbs-up.svg"
import thumbsDown from "../../images/icons/u_thumbs-down.svg"

let controller;

let incompleteMatch = "";
let startReferences = false
let savedReferences = ''

function Search(props){

    const [searchTerm, setSearchTerm] = useState('')
    const [resultsShowing, setResultsShowing] = useState(false)
    const [noResults, setNoResults] = useState(false)
    const [recentSearches, setRecentSearches] = useState([])
    const [isAIResponding, setIsAIResponding] = useState(true)
    const [aIResponse, setAIResponse] = useState('')
    const [showFullResponse, setShowFullResponse] = useState(null)
    const [showFullResponseDisabled, setShowFullResponseDisabled] = useState(true)
    const [references, setReferences] = useState('')
    // const [suggestedTopics, setSuggestedTopics] = useState([])
    const [aIOverviewExpanded, setAIOverviewExpanded] = useState(true)
    const [selectedTab, setSelectedTab] = useState('book')
    

    const inputRef = useRef(null)
    const formRef = useRef(null)

    const slider = useRef();
    const tabList = useRef();

    useEffect(() => {
        if(resultsShowing === true){
            setTimeout(() => positionSlider(), 60)
            //try second timeout to re-render to right size
            setTimeout(() => positionSlider(), 400)
        }
    },[resultsShowing, selectedTab])

    const positionSlider = () => {
        let tabListBox = tabList.current.getBoundingClientRect(),
        tabListBoxLeft = tabListBox.left;

        if (tabList.current.querySelector("[aria-selected=true]")) {
            let activeTabBox = tabList.current.querySelector("[aria-selected=true]").getBoundingClientRect(),
            tabLeft = activeTabBox.left,
            tabWidth = activeTabBox.width;
            // console.log(activeTabBox)
            // console.log(tabListBox)
            slider.current.style.left = tabLeft - tabListBoxLeft + 'px';
            slider.current.style.width = tabWidth + 'px';
        }
    }

    const handleClearSearch = () => {
        setResultsShowing(false)
        setAIResponse('')
        // setSuggestedTopics([])
        // setShowFullResponse(false)
        setShowFullResponseDisabled(true)
    }


    const handleChangeSearch = (e) => {
        setSearchTerm(inputRef.current.value)
    }

    const handleSubmitSearch = (e) => {
        e.preventDefault()
        e.stopPropagation()
        handleClearSearch()
        // let inputVal = e.target.querySelector('input').value
        // console.log(e.target.querySelector('input'))
        // console.log(inputVal)
        let inputVal = inputRef.current.value
        if(inputVal != ''){
            if(inputVal === 'test'){
                setNoResults(false)
                setResultsShowing(true)
                let newArr = [...recentSearches]
                newArr.push(inputVal)
                setRecentSearches(newArr)
            }else{
                setResultsShowing(true)
                handleGetAIResponse()
            }
        } else{
            setResultsShowing(false)
            setNoResults(false)
        }
    }

    const processAndBold = (chunk, term) => {
        const regex = new RegExp(`(${term})`, 'gi');
        return chunk.replace(regex, "<b>$1</b>");
    }

    const handleStreamedResponse = (chunk) => {
        // console.log("Received chunk:", chunk);
    
        // Combine with any previous incomplete match
        let combinedChunk = incompleteMatch + chunk;
        // console.log("Combined chunk:", combinedChunk);
    
        // Try to find a match in the combined chunk
        let processedChunk = processAndBold(combinedChunk, searchTerm);
    
        // Check if the chunk ends with a potential partial match
        const splitArr = combinedChunk.split(' ') 
        const lastPart = splitArr[splitArr.length - 1]
        // console.log("lastpart:" + lastPart)
        if (searchTerm.toLowerCase().startsWith(lastPart.toLowerCase())) {
            incompleteMatch = lastPart; // Store the partial match for the next chunk
            processedChunk = processAndBold(combinedChunk.slice(0, -lastPart.length), searchTerm);
            // console.log("Partial match detected, incompleteMatch set to:", incompleteMatch);
        } else {
            incompleteMatch = ""; // Clear if no partial match at the end
            // console.log("No partial match, incompleteMatch cleared.");
        }
    
        // Append the processed chunk to the container
        setAIResponse(prev => prev + processedChunk);
    }
    

    const handleGetAIResponse = async () => {

        //https://developer.mozilla.org/en-US/docs/Web/API/AbortController
        controller = new AbortController();
        const signal = controller.signal;

        if(!searchTerm) return;
        setIsAIResponding(true)
        // console.log(searchTerm)
        // console.log(inputRef.current.value)
        let newMessage = {data:{role: "user", content: searchTerm}}
        
        // fetchOpenAIResponse(newMessage)
        // .then((responseText)=>{
        //     setIsAIResponding(false)
        //     setAIResponse(responseText)
        // })

        // fetchOpenAITopics({data:searchTerm})
        // .then((response)=>{
        //     setSuggestedTopics(response)
        // })

        const resp = await fetch(CHAT_URL + "/assistant", {
            method: "post",
            headers:{
                "Content-Type": "application/json"
            },
            body:JSON.stringify(newMessage),
            signal
        })
        //https://www.typeblock.co/blog/openai-stream
        try{
            // console.log(resp)
            const reader = resp.body
            .pipeThrough(new TextDecoderStream())
            .getReader()
            setIsAIResponding(false)
            while (true) {
            const { value, done } = await reader.read()
            if (signal.aborted) {
                break
            }
            if (done) {
                handleStreamedResponse('')
                setShowFullResponseDisabled(false)
                // console.log(savedReferences)
                // console.log(savedReferences.split('---References---')[1])
                // console.log(JSON.parse(savedReferences.split('---References---')[1]))
                setReferences(JSON.parse(savedReferences.split('---References---')[1]))
                break
            }
            if(value){
                if(value.includes('---References---')){
                    startReferences = true
                }
                if(startReferences === true){
                    // setReferences(prev => prev + value)
                    savedReferences += value
                }else{
                    handleStreamedResponse(value)
                }
            }
            }
        }catch(error){
            console.log(error)
        }
    }

    return(
        <div className='search drawer-panel'>
            <div className='search-header'>
                <div className='search-container'>
                    <img src={search} alt='search icon'/>
                    <form ref={formRef} onSubmit={handleSubmitSearch}>
                        <input 
                            type='text'
                            placeholder='Search'
                            value={searchTerm}
                            onChange={handleChangeSearch}
                            ref={inputRef}
                        />
                        {
                            resultsShowing ? 
                            <button
                                type="button"
                                onClick={()=>{
                                    setSearchTerm('')
                                    handleClearSearch()
                                }}
                            >
                                <img src={closeIcon}/>
                            </button>
                            : null
                        }
                    </form>
                </div>
                <button 
                className='filter-button'
                disabled={!resultsShowing}
                >
                        <img src={filter}/>
                </button>
            </div>

            {
                    noResults === true ? <div className='no-results'>
                        <p className='title'>No results</p>
                        <p className='p'>Give it a shot with another search term.</p>
                        <button className="primary">Clear search</button>
                    </div> : null
            }

            {
                noResults === false && resultsShowing === false ? <div className='empty'>
                    <p className='title'>Looking for something?</p>
                    <p className='p'>If it's in your eText or notes, we'll track it down.</p>
                </div> : null
            }

            {
                isAIResponding === true ? <>
                    <div className='spinner'>
                        <img src={loader}/>
                    </div>
                </>: null
            }

            {
                resultsShowing ? 
                <>
                    <div className='results-panel'>
                        {
                            aIResponse.length > 0? 
                                <>
                                    <button 
                                        onClick={()=>{setAIOverviewExpanded(!aIOverviewExpanded)}}
                                        className={`ai-overview-btn ${aIOverviewExpanded ? 'expanded' : ''}`}> 
                                        <div>
                                            <img src={botIcon}/>
                                            <span>AI Summary</span>
                                        </div>
                                        <img src={arrowSmall}/>
                                    </button>
                                    <div className='ai-response' hidden={aIOverviewExpanded ? false : true}>
                                        {
                                            showFullResponse ?
                                            <>
                                            <p>
                                                <span dangerouslySetInnerHTML={{__html: aIResponse}}></span>
                                                {
                                                    references.map((reference, index)=>{
                                                        return(
                                                            <span> [{reference.page}] </span>
                                                        )
                                                    })
                                                }
                                            </p>
                                            <h2>Sources</h2>
                                            <ul>
                                                {
                                                    references.map((reference, index)=>{
                                                        return(
                                                            <li>
                                                                <button>
                                                                    <span>
                                                                        <b>[{reference.page}] </b>
                                                                        <span>{reference.chapter}</span>
                                                                    </span>
                                                                    <img src={arrow} style={{width: '16px', height: '16px'}}/>
                                                                </button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                            </>
                                            : 
                                            <p>
                                                <span dangerouslySetInnerHTML={{__html: aIResponse.substring(0, 400)}}></span>
                                                <span>...</span>
                                            </p>    
                                        }
                                        <div className='more-btns'>
                                            <button 
                                                className='secondary'
                                                onClick={()=>{setShowFullResponse(!showFullResponse)}}
                                                disabled={showFullResponseDisabled}
                                            >
                                                {
                                                    showFullResponse ? 'Show less' : 
                                                    showFullResponseDisabled ? 'Loading more' : 'Show more'
                                                }
                                            </button>
                                            {
                                                showFullResponse ? 
                                                <button
                                                    className='secondary'
                                                >
                                                    <img src={botIcon}/>
                                                    <span>Elaborate</span>
                                                </button>
                                                : null
                                            }
                                        </div>
                                        <div
                                            className='ai-feedback'
                                        >
                                            <button
                                                className='link'
                                            >
                                                Don’t show AI summary
                                            </button>    
                                            <div className='ai-rating'>
                                                <button className='ai-thumbs-up'>
                                                    <img src={thumbsUp}/>
                                                </button>
                                                <button className='ai-thumbs-down'>
                                                    <img src={thumbsDown}/>
                                                </button>
                                            </div>
                                        </div>    
                                    </div>
                                </>
                            : null
                        }

                        <div className="tab-container main-nav">
                            <div className="tab-list-container" ref={tabList}>
                                <ul className="tab-list">
                                    <li className="tab-item active">
                                        <button onClick={()=>{setSelectedTab('book')}} 
                                            className="tab-action" role="tab" aria-selected={selectedTab === 'book' ? true : false} aria-controls="panel-book" id="tab-book">
                                            Book
                                            <span className="hidden-bold">Book</span>
                                        </button>
                                    </li>
                                    <li className="tab-item">
                                        <button onClick={()=>{setSelectedTab('custom')}} className="tab-action" role="tab" aria-selected={selectedTab === 'custom' ? true : false} aria-controls="panel-custom" id="tab-custom">
                                            Custom
                                            <span className="hidden-bold">Custom</span>
                                        </button>
                                    </li>
                                    <li className="tab-item">
                                        <button onClick={()=>{setSelectedTab('study')}} className="tab-action" role="tab" aria-selected={selectedTab === 'study' ? true : false} aria-controls="panel-study" id="tab-study">
                                            Study
                                            <span className="hidden-bold">Study</span>
                                        </button>
                                    </li>
                                </ul>
                                <div className="tab-slider" ref={slider}> </div>
                            </div>
                        </div>
                        <div className="search-panel" id="panel-book" role="tabpanel" tabindex="0" aria-labelledby="tab-book" hidden={selectedTab === 'book' ? false : true}>
                            <div className='expand-collapse'>
                                <button>Expand All</button>
                                <span>|</span>
                                <button>Collapse All</button>
                            </div>
                            <ul className='chapters'>
                                <li>
                                    <img src={chevronSmall}/>
                                    <button>
                                        <p>Glossary</p>
                                        <p><span>{Math.floor(Math.random() * (20 - 1 + 1) + 1)}</span> results</p>
                                    </button>
                                </li>
                                {
                                    props.book.toc.map((chapter, index)=>{
                                        if(chapter.type !== "unit" && chapter.type !== "frontmatter" && chapter.type !== "backmatter" && chapter.name !== "Glossary"){
                                            return (
                                                <li key={index}>
                                                    <img src={chevronSmall}/>
                                                    <button data-index={chapter.page}
                                                            id={chapter.url}
                                                    >
                                                        <p>{chapter.name}</p>
                                                        <p>{Math.floor(Math.random() * (20 - 1 + 1) + 1)} results</p>
                                                    </button>
                                                </li>
                                            )
                                        }
                                    })
                                }
                            </ul>
                        </div>

                        <div className="search-panel" id="panel-custom" role="tabpanel" tabindex="0" aria-labelledby="tab-custom" hidden={selectedTab === 'custom' ? false : true}>
                            <div className='expand-collapse'>
                                <button>Expand All</button>
                                <span>|</span>
                                <button>Collapse All</button>
                            </div>
                            <ul className='chapters'>
                                <li>
                                    <img src={chevronSmall}/>
                                    <button>
                                        <p>Chapter 5: The Structure and Function of Large Biological Molecules</p>
                                        <p><span>1</span> results</p>
                                    </button>
                                </li>
                            </ul>
                            <div className='note-card'>
                                <div>
                                    <p className='highlight'>Life’s Processes Involve the Expression and Transmission of Genetic Information</p>
                                    <p className='note'>A protist, such as a trichomonad, with modified mitochondria.</p>
                                </div>
                                <p>Note</p>
                            </div>
                        </div>

                        <div className="search-panel" id="panel-study" role="tabpanel" tabindex="0" aria-labelledby="tab-study" hidden={selectedTab === 'study' ? false : true}>
                            <div className='expand-collapse'>
                                <button>Expand All</button>
                                <span>|</span>
                                <button>Collapse All</button>
                            </div>
                            <ul className='chapters'>
                                <li>
                                    <img src={chevronSmall}/>
                                    <button>
                                        <p>Chapter 5: The Structure and Function of Large Biological Molecules</p>
                                        <p><span>4</span> results</p>
                                    </button>
                                </li>
                            </ul>
                            <div className='video-card'>
                                <div className='video-header'>
                                    <p>Video: Mitochondria</p>
                                    <span>
                                        <svg class="MuiSvgIcon-root-65670" focusable="false" viewBox="0 0 15 15" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><g clip-path="url(#clip0_2706_12420)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.56403 13.4157C9.18848 13.4157 10.7464 12.7704 11.895 11.6217C13.0437 10.4731 13.689 8.91511 13.689 7.29066C13.689 5.66621 13.0437 4.10829 11.895 2.95963C10.7464 1.81097 9.18848 1.16566 7.56403 1.16566C5.93958 1.16566 4.38166 1.81097 3.233 2.95963C2.08434 4.10829 1.43903 5.66621 1.43903 7.29066C1.43903 8.91511 2.08434 10.4731 3.233 11.6217C4.38166 12.7704 5.93958 13.4157 7.56403 13.4157ZM14.564 7.29066C14.564 9.14718 13.8265 10.9277 12.5137 12.2404C11.201 13.5532 9.42055 14.2907 7.56403 14.2907C5.70751 14.2907 3.92704 13.5532 2.61428 12.2404C1.30152 10.9277 0.564026 9.14718 0.564026 7.29066C0.564026 5.43414 1.30152 3.65367 2.61428 2.34091C3.92704 1.02816 5.70751 0.290657 7.56403 0.290657C9.42055 0.290657 11.201 1.02816 12.5137 2.34091C13.8265 3.65367 14.564 5.43414 14.564 7.29066Z" fill="#05112A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.12653 2.91566C7.24256 2.91566 7.35384 2.96175 7.43589 3.0438C7.51794 3.12585 7.56403 3.23713 7.56403 3.35316V7.91191L10.406 9.53591C10.5038 9.59492 10.5747 9.68985 10.6034 9.80041C10.6322 9.91097 10.6165 10.0284 10.5599 10.1276C10.5032 10.2268 10.41 10.2999 10.3001 10.3313C10.1903 10.3626 10.0725 10.3498 9.97203 10.2954L6.90953 8.54541C6.84257 8.50716 6.78691 8.45189 6.74818 8.3852C6.70946 8.31851 6.68905 8.24278 6.68903 8.16566V3.35316C6.68903 3.23713 6.73512 3.12585 6.81717 3.0438C6.89922 2.96175 7.0105 2.91566 7.12653 2.91566Z" fill="#05112A"></path></g><defs><clipPath id="clip0_2706_12420"><rect width="14" height="14" fill="white" transform="translate(0.564026 0.290657)"></rect></clipPath></defs></svg>
                                        <span>4m</span>
                                    </span>
                                </div>
                                <div className='video'>
                                    <img src="https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/ac1668e6"/>
                                    <svg class="MuiSvgIcon-root-62911 jss65335" focusable="false" viewBox="0 0 60 62" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="60" height="62" fill="none"><ellipse cx="30" cy="30.9187" rx="30" ry="30.5" fill="#0F0F0F" fill-opacity="0.5"></ellipse><g filter="url(#filter0_d_2706_12426)"><path d="M42.6567 32.8967L24.9229 43.378C23.4179 44.2666 21.4865 43.1907 21.4865 41.3993V20.4365C21.4865 18.648 23.4151 17.5692 24.9229 18.4606L42.6567 28.942C42.9991 29.1411 43.2836 29.4288 43.4816 29.7761C43.6795 30.1234 43.7838 30.5178 43.7838 30.9193C43.7838 31.3209 43.6795 31.7153 43.4816 32.0625C43.2836 32.4098 42.9991 32.6976 42.6567 32.8967Z" fill="white"></path></g><defs><filter id="filter0_d_2706_12426" x="16.5638" y="13.219" width="32.1426" height="35.3993" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="2.46132"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2706_12426"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2706_12426" result="shape"></feBlend></filter></defs></svg>
                                </div>
                            </div>

                            <div className='video-card'>
                                <div className='video-header'>
                                    <p>Video: Mitochondria Structure</p>
                                    <span>
                                        <svg class="MuiSvgIcon-root-65670" focusable="false" viewBox="0 0 15 15" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><g clip-path="url(#clip0_2706_12420)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.56403 13.4157C9.18848 13.4157 10.7464 12.7704 11.895 11.6217C13.0437 10.4731 13.689 8.91511 13.689 7.29066C13.689 5.66621 13.0437 4.10829 11.895 2.95963C10.7464 1.81097 9.18848 1.16566 7.56403 1.16566C5.93958 1.16566 4.38166 1.81097 3.233 2.95963C2.08434 4.10829 1.43903 5.66621 1.43903 7.29066C1.43903 8.91511 2.08434 10.4731 3.233 11.6217C4.38166 12.7704 5.93958 13.4157 7.56403 13.4157ZM14.564 7.29066C14.564 9.14718 13.8265 10.9277 12.5137 12.2404C11.201 13.5532 9.42055 14.2907 7.56403 14.2907C5.70751 14.2907 3.92704 13.5532 2.61428 12.2404C1.30152 10.9277 0.564026 9.14718 0.564026 7.29066C0.564026 5.43414 1.30152 3.65367 2.61428 2.34091C3.92704 1.02816 5.70751 0.290657 7.56403 0.290657C9.42055 0.290657 11.201 1.02816 12.5137 2.34091C13.8265 3.65367 14.564 5.43414 14.564 7.29066Z" fill="#05112A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.12653 2.91566C7.24256 2.91566 7.35384 2.96175 7.43589 3.0438C7.51794 3.12585 7.56403 3.23713 7.56403 3.35316V7.91191L10.406 9.53591C10.5038 9.59492 10.5747 9.68985 10.6034 9.80041C10.6322 9.91097 10.6165 10.0284 10.5599 10.1276C10.5032 10.2268 10.41 10.2999 10.3001 10.3313C10.1903 10.3626 10.0725 10.3498 9.97203 10.2954L6.90953 8.54541C6.84257 8.50716 6.78691 8.45189 6.74818 8.3852C6.70946 8.31851 6.68905 8.24278 6.68903 8.16566V3.35316C6.68903 3.23713 6.73512 3.12585 6.81717 3.0438C6.89922 2.96175 7.0105 2.91566 7.12653 2.91566Z" fill="#05112A"></path></g><defs><clipPath id="clip0_2706_12420"><rect width="14" height="14" fill="white" transform="translate(0.564026 0.290657)"></rect></clipPath></defs></svg>
                                        <span>4m</span>
                                    </span>
                                </div>
                                <div className='video'>
                                    <img src="https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/a64acb10"/>
                                    <svg class="MuiSvgIcon-root-62911 jss65335" focusable="false" viewBox="0 0 60 62" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="60" height="62" fill="none"><ellipse cx="30" cy="30.9187" rx="30" ry="30.5" fill="#0F0F0F" fill-opacity="0.5"></ellipse><g filter="url(#filter0_d_2706_12426)"><path d="M42.6567 32.8967L24.9229 43.378C23.4179 44.2666 21.4865 43.1907 21.4865 41.3993V20.4365C21.4865 18.648 23.4151 17.5692 24.9229 18.4606L42.6567 28.942C42.9991 29.1411 43.2836 29.4288 43.4816 29.7761C43.6795 30.1234 43.7838 30.5178 43.7838 30.9193C43.7838 31.3209 43.6795 31.7153 43.4816 32.0625C43.2836 32.4098 42.9991 32.6976 42.6567 32.8967Z" fill="white"></path></g><defs><filter id="filter0_d_2706_12426" x="16.5638" y="13.219" width="32.1426" height="35.3993" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="2.46132"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2706_12426"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2706_12426" result="shape"></feBlend></filter></defs></svg>
                                </div>
                            </div>

                            <div className='video-card'>
                                <div className='video-header'>
                                    <p>Video: Mitochondria & Chloroplasts Example 1</p>
                                    <span>
                                        <svg class="MuiSvgIcon-root-65670" focusable="false" viewBox="0 0 15 15" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><g clip-path="url(#clip0_2706_12420)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.56403 13.4157C9.18848 13.4157 10.7464 12.7704 11.895 11.6217C13.0437 10.4731 13.689 8.91511 13.689 7.29066C13.689 5.66621 13.0437 4.10829 11.895 2.95963C10.7464 1.81097 9.18848 1.16566 7.56403 1.16566C5.93958 1.16566 4.38166 1.81097 3.233 2.95963C2.08434 4.10829 1.43903 5.66621 1.43903 7.29066C1.43903 8.91511 2.08434 10.4731 3.233 11.6217C4.38166 12.7704 5.93958 13.4157 7.56403 13.4157ZM14.564 7.29066C14.564 9.14718 13.8265 10.9277 12.5137 12.2404C11.201 13.5532 9.42055 14.2907 7.56403 14.2907C5.70751 14.2907 3.92704 13.5532 2.61428 12.2404C1.30152 10.9277 0.564026 9.14718 0.564026 7.29066C0.564026 5.43414 1.30152 3.65367 2.61428 2.34091C3.92704 1.02816 5.70751 0.290657 7.56403 0.290657C9.42055 0.290657 11.201 1.02816 12.5137 2.34091C13.8265 3.65367 14.564 5.43414 14.564 7.29066Z" fill="#05112A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.12653 2.91566C7.24256 2.91566 7.35384 2.96175 7.43589 3.0438C7.51794 3.12585 7.56403 3.23713 7.56403 3.35316V7.91191L10.406 9.53591C10.5038 9.59492 10.5747 9.68985 10.6034 9.80041C10.6322 9.91097 10.6165 10.0284 10.5599 10.1276C10.5032 10.2268 10.41 10.2999 10.3001 10.3313C10.1903 10.3626 10.0725 10.3498 9.97203 10.2954L6.90953 8.54541C6.84257 8.50716 6.78691 8.45189 6.74818 8.3852C6.70946 8.31851 6.68905 8.24278 6.68903 8.16566V3.35316C6.68903 3.23713 6.73512 3.12585 6.81717 3.0438C6.89922 2.96175 7.0105 2.91566 7.12653 2.91566Z" fill="#05112A"></path></g><defs><clipPath id="clip0_2706_12420"><rect width="14" height="14" fill="white" transform="translate(0.564026 0.290657)"></rect></clipPath></defs></svg>
                                        <span>1m</span>
                                    </span>
                                </div>
                                <div className='video'>
                                    <img src="https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/a66e717e"/>
                                    <svg class="MuiSvgIcon-root-62911 jss65335" focusable="false" viewBox="0 0 60 62" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="60" height="62" fill="none"><ellipse cx="30" cy="30.9187" rx="30" ry="30.5" fill="#0F0F0F" fill-opacity="0.5"></ellipse><g filter="url(#filter0_d_2706_12426)"><path d="M42.6567 32.8967L24.9229 43.378C23.4179 44.2666 21.4865 43.1907 21.4865 41.3993V20.4365C21.4865 18.648 23.4151 17.5692 24.9229 18.4606L42.6567 28.942C42.9991 29.1411 43.2836 29.4288 43.4816 29.7761C43.6795 30.1234 43.7838 30.5178 43.7838 30.9193C43.7838 31.3209 43.6795 31.7153 43.4816 32.0625C43.2836 32.4098 42.9991 32.6976 42.6567 32.8967Z" fill="white"></path></g><defs><filter id="filter0_d_2706_12426" x="16.5638" y="13.219" width="32.1426" height="35.3993" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="2.46132"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2706_12426"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2706_12426" result="shape"></feBlend></filter></defs></svg>
                                </div>
                            </div>

                            <div className='video-card'>
                                <div className='video-header'>
                                    <p>Video: Chloroplasts</p>
                                    <span>
                                        <svg class="MuiSvgIcon-root-65670" focusable="false" viewBox="0 0 15 15" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="none"><g clip-path="url(#clip0_2706_12420)"><path fill-rule="evenodd" clip-rule="evenodd" d="M7.56403 13.4157C9.18848 13.4157 10.7464 12.7704 11.895 11.6217C13.0437 10.4731 13.689 8.91511 13.689 7.29066C13.689 5.66621 13.0437 4.10829 11.895 2.95963C10.7464 1.81097 9.18848 1.16566 7.56403 1.16566C5.93958 1.16566 4.38166 1.81097 3.233 2.95963C2.08434 4.10829 1.43903 5.66621 1.43903 7.29066C1.43903 8.91511 2.08434 10.4731 3.233 11.6217C4.38166 12.7704 5.93958 13.4157 7.56403 13.4157ZM14.564 7.29066C14.564 9.14718 13.8265 10.9277 12.5137 12.2404C11.201 13.5532 9.42055 14.2907 7.56403 14.2907C5.70751 14.2907 3.92704 13.5532 2.61428 12.2404C1.30152 10.9277 0.564026 9.14718 0.564026 7.29066C0.564026 5.43414 1.30152 3.65367 2.61428 2.34091C3.92704 1.02816 5.70751 0.290657 7.56403 0.290657C9.42055 0.290657 11.201 1.02816 12.5137 2.34091C13.8265 3.65367 14.564 5.43414 14.564 7.29066Z" fill="#05112A"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M7.12653 2.91566C7.24256 2.91566 7.35384 2.96175 7.43589 3.0438C7.51794 3.12585 7.56403 3.23713 7.56403 3.35316V7.91191L10.406 9.53591C10.5038 9.59492 10.5747 9.68985 10.6034 9.80041C10.6322 9.91097 10.6165 10.0284 10.5599 10.1276C10.5032 10.2268 10.41 10.2999 10.3001 10.3313C10.1903 10.3626 10.0725 10.3498 9.97203 10.2954L6.90953 8.54541C6.84257 8.50716 6.78691 8.45189 6.74818 8.3852C6.70946 8.31851 6.68905 8.24278 6.68903 8.16566V3.35316C6.68903 3.23713 6.73512 3.12585 6.81717 3.0438C6.89922 2.96175 7.0105 2.91566 7.12653 2.91566Z" fill="#05112A"></path></g><defs><clipPath id="clip0_2706_12420"><rect width="14" height="14" fill="white" transform="translate(0.564026 0.290657)"></rect></clipPath></defs></svg>
                                        <span>3m</span>
                                    </span>
                                </div>
                                <div className='video'>
                                    <img src="https://static.studychannel.pearsonprd.tech/courses/biology/thumbnails/606241c4"/>
                                    <svg class="MuiSvgIcon-root-62911 jss65335" focusable="false" viewBox="0 0 60 62" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="60" height="62" fill="none"><ellipse cx="30" cy="30.9187" rx="30" ry="30.5" fill="#0F0F0F" fill-opacity="0.5"></ellipse><g filter="url(#filter0_d_2706_12426)"><path d="M42.6567 32.8967L24.9229 43.378C23.4179 44.2666 21.4865 43.1907 21.4865 41.3993V20.4365C21.4865 18.648 23.4151 17.5692 24.9229 18.4606L42.6567 28.942C42.9991 29.1411 43.2836 29.4288 43.4816 29.7761C43.6795 30.1234 43.7838 30.5178 43.7838 30.9193C43.7838 31.3209 43.6795 31.7153 43.4816 32.0625C43.2836 32.4098 42.9991 32.6976 42.6567 32.8967Z" fill="white"></path></g><defs><filter id="filter0_d_2706_12426" x="16.5638" y="13.219" width="32.1426" height="35.3993" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB"><feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood><feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"></feColorMatrix><feOffset></feOffset><feGaussianBlur stdDeviation="2.46132"></feGaussianBlur><feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"></feColorMatrix><feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2706_12426"></feBlend><feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2706_12426" result="shape"></feBlend></filter></defs></svg>
                                </div>
                            </div>

                        </div>
                    </div>
                </>
                : null
            }

        </div>
    )
}

export default Search