import { createGlobalStyle } from 'styled-components';
import styled from 'styled-components';
import { colors } from "./colors"

export const PrimaryButton = styled.button`
    border-radius: 40px;
    border: 2px solid ${colors.swatchSepia900};
    background: ${colors.swatchSepia900};
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.4px;
    color:${colors.textdarkThemeDefault};
    min-width:178px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    &[disabled]{
        opacity:0.5;
    }
`

export const SecondaryButton = styled.button`
    border-radius: 40px;
    border: 2px solid ${colors.swatchSepia900};
    background: transparent;
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4px;
    color:${colors.swatchSepia900};
    min-width:178px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
`

export const GradientButton = styled.button`
    border-radius: 40px;
    background: linear-gradient(135deg, #E49D2D 0%, #EA0B7E 51.09%, #851B4A 100%);
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.4px;
    color:${colors.textdarkThemeDefault};
    min-width:178px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content:center;
    padding: 8px 24px;
    &[disabled]{
        opacity: 0.32;
        cursor:auto
    }
`

export const OutlineButton = styled.button`
    width:162px;
    height:40px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 40px;
    border: 2px solid ${colors.swatchDark800};
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.4px;
    color:${colors.textlightThemeDefault};
    &[disabled]{
        opacity: 0.32;
        cursor:auto
    }
`

export const Link = styled.a`
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.24px;
    letter-spacing: 0.4px;
    color:${colors.textdarkThemeLink};
`

export const LinkButton = styled.button`
    color: var(--Text-lightTheme--Link, #655591);
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.4px;
    height:24px;
    margin: 0 auto;
    width: 136px;
    display: block;
    &[disabled]{
        opacity: 0.32;
        cursor:auto
    }
`

