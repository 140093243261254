import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

//images
import highlight from "../images/icons/context-menu/highlight.svg"
import yellow from "../images/icons/context-menu/yellow.png"
import green from "../images/icons/context-menu/green.png"
import pink from "../images/icons/context-menu/pink.png"
import note from "../images/icons/context-menu/note.svg"
import post from "../images/icons/context-menu/post.svg"
import flashcard from "../images/icons/context-menu/flashcard.svg"
import copy from "../images/icons/context-menu/copy.svg"
import audio from "../images/toolbar/audio.svg"
import search from "../images/icons/context-menu/search.svg"
import customize from "../images/icons/context-menu/customize.svg"
import placeholder from "../images/icons/placeholder.svg"

function ContextMenu(props) {

  return (
      <div className="context-menu"
            ref={props.contextMenuRef}
            style={{
              "left":props.popUpLocation[0],
              "top":props.popUpLocation[1]
          }}
      >
          { 
            props.mini_actions.includes('highlight') ?
            <button>
                  <div>
                    <img src={highlight}/>
                    <span>Highlight</span>
                  </div>
                  <div className="highlight-colors">
                    <img src={yellow}/>
                    <img src={green}/>
                    <img src={pink}/>
                  </div>
            </button> : null
          }
           {/* <button>
                <div>
                  <img src={placeholder}/>
                  <span>Tutor</span>
                </div>
                <div className='new-tag'>
                    New!
                </div>
           </button> */}
           {
            props.mini_actions.includes('note') ?
              <button>
                    <div>
                      <img src={note}/>
                      <span>New note</span>
                    </div>
              </button>
           : null
          }
           {/* <button>
                <div>
                  <img src={post}/>
                  <span>Post a question</span>
                </div>
           </button> */}
           {
            props.mini_actions.includes('flashcard') ?
            <button>
                  <div>
                    <img src={flashcard}/>
                    <span>New flashcard</span>
                  </div>
            </button> : null
          }

          {
              props.mini_actions.includes('copy') ?
              <button>
                    <div>
                      <img src={copy}/>
                      <span>Copy</span>
                    </div>
              </button> : null
          }

           {
              props.mini_actions.includes('read') ?
              <button>
                    <div>
                      <img src={audio}/>
                      <span>Start audio here</span>
                    </div>
              </button> : null
          }

           {
              props.mini_actions.includes('search') ?
              <button>
                    <div>
                      <img src={search}/>
                      <span>Search selection</span>
                    </div>
              </button> : null
          }

           <button onClick={()=>{
              props.setCustomizeMenu(true)
              props.setShowContextMenu(false)
            }}>
                <div>
                  <img src={customize}/>
                  <span>Customize menu</span>
                </div>
           </button>
      </div>
  );
}

const mapStateToProps = ({mini_actions}) => ({
  mini_actions
});

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContextMenu);