import CHANNELS_URL from "./CHANNELS_URL"

export const getChannelDetails = async (channelId) => {
    const resp = await fetch(`${CHANNELS_URL}/channel/${channelId}`);
    // console.log(resp)
    if (resp.ok) {
        const json = await resp.json();
        return json
        // return resp
    }
}