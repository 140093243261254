import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import { useSpring, animated } from "@react-spring/web";


function AudioTimeline(props) {

    const springProps = useSpring({
        // opacity: '1',
        // zIndex:'1000',
        // pointerEvents: showAudioPlayer ? 'unset' : 'none',
        // display: showAudioPlayer ? 'flex': 'none',
    });

    return (
        <div className='audio-timeline'>
            <input
                ref={props.rangeRef}
                type="range"
                min={0}
                max={props.totalTime}
                value={props.ellapsedTime}
                // onChange={(event => {
                //     props.adjustSlider()
                //     props.handleSlider(event.target.value)
                //     // props.showAudioHover()
                // })}
                onMouseUp={(event) => {
                    // props.hideAudioHover()
                }}
            />
        </div>
    );
    }

    const mapStateToProps = ({playlist}) => ({
        playlist
    });
  
    const mapDispatchToProps = dispatch => ({
        setPlaying: (data) => dispatch({type: 'SET_PLAYING', data:data}),
        setPosition: (pos) => dispatch({type: 'SET_POSITION', pos:pos}),
        setCurrent: (current) => dispatch({type: 'SET_CURRENT', current:current}),
        setTime: (time) => dispatch({type: 'SET_TIME', time:time}),
        setRemaining: (remaining) => dispatch({type: 'SET_REMAINING', remaining:remaining}),
    });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AudioTimeline);