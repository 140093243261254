import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import bookmarkSolid from '../images/icons/bookmark-pink.svg'

function BookmarkMenu(props) {

    const handleRemove = () => {
        props.bookmarks.forEach(bookmark=>{
            props.removeBookmark(bookmark.page)
        })
    }

  return (
      <div className="bookmark-menu">
            { props.bookmarks.length > 0 ? <>
                
                <div className='bookmark-list'>
                    {
                        props.bookmarks.map((bookmark, index)=>{
                            return <div key={index}
                                        className='card bookmark-card'
                                        onClick={()=>{props.loadPage(bookmark.page)}}
                                    >
                                {/* <div className='title'>
                                    <p>{bookmark.chapter}</p>
                                   
                                </div> */}
                                <p>{bookmark.title}</p>
                                <img src={bookmarkSolid}/>
                            </div>
                        })
                    }
                    </div>
                    <button className='remove-button' onClick={handleRemove}>Remove all bookmarks</button>

              </> : <p className='empty'>No bookmarks</p>
            }
      </div>
  );
}

const mapStateToProps = ({bookmarks}) => ({
    bookmarks
  });
  
  const mapDispatchToProps = dispatch => ({
    removeBookmark: (page) => dispatch({type: 'REMOVE_BOOKMARK',page:page}),
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(BookmarkMenu);