import React, {useEffect, useState} from 'react';
import SectionSelector from './SectionSelector';
import { getChannelDetails } from "../../util/getChannelDetails";
import {getChannelTOCItem} from "../../util/getChannelTOCItem"
import { getAssetDetails } from "../../util/getAssetDetails";
import { useSelector, useDispatch } from 'react-redux';

function Channels(props) {

  const dispatch = useDispatch();

  const selectedSection = useSelector((state) => state.selectedSection);
  // const chapters = useSelector((state) => state.chapters);


  const [data, setData] = useState(null)
  const [selectedVideo, setSelectedVideo] = useState({})
  const [channelInfo, setChannelInfo] = useState(null)

  // useEffect(()=>{
  //   if(props.drawerSelectedSection.type != "chapter"){
  //         //set to chapter 1
  //         console.log('test')
  //         props.setDrawerSelectedSection(props.book.toc[4])
  //     }
  // },[props.drawerSelectedSection])

  useEffect(()=>{
      setData([])
      if(selectedSection.mappedChannelsid){
        let sid = selectedSection.mappedChannelsid
        getChannelTOCItem(process.env.REACT_APP_CHANNEL_ID, sid).then(response=>{
            let responseAssets = response.sections ? response.sections[0].assets : response.assets
            // setResponseAssets(responseAssets)
            responseAssets.forEach((asset,i)=>{
              getAssetDetails(asset.id)
                .then((response)=>{
                  // response.order = asset.order
                  if(response.type != "Problem"){
                    setData(prevState=>{
                      return [...prevState, response]
                    })
                  }
              })
          })
        })
      }
  },[selectedSection])

  useEffect(()=>{
    getChannelDetails(process.env.REACT_APP_CHANNEL_ID)
    .then(response=>{
      setChannelInfo(response)
    })
  },[])

  useEffect(()=>{
    if(data && data.length > 0 ){
        setSelectedVideo(data[0])
    }
  },[data])

  const calculateDuration = (totalSeconds) => {
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds - minutes * 60;
    if(seconds.toString().length == 1){
      seconds = '0' + seconds
    }
    return `${minutes}:${seconds} min`
  }

  return (
    <div className="right-drawer-panel channels">

      <SectionSelector
        book={props.book}
        showSections={true}
      />

      {
        data && data.length > 0 && selectedVideo ? 
        <div className='featured-video-card'>
            <video
              poster={selectedVideo.thumbnailUrl}
              controls
              src={selectedVideo.url}
            />
            <div className='title-header'>
              <img className='tutor-img' src={channelInfo ? channelInfo.tutorImg : ''}/>
              <p className='title'>{selectedVideo.title}</p>
            </div>
            <p className='meta'>By {channelInfo ? channelInfo.tutor.split(' ')[0] : ''} • 2847 views</p>
        </div>
        : null
      }

      <button className='primary'>Explore study materials</button>

      {
        data && data.length > 0 ? 
        data.map((item, i)=>{
          if(item.type != 'Problem'){
            return(
              <div className={`video-card ${item.url === selectedVideo.url ? 'active' : ''}`}
                  onClick={()=>{setSelectedVideo(item)}}
              >
                  <div className='card-header'>
                       <p>VIDEO</p>
                       <div>
                          <svg width="14" height="14" viewBox="0 0 14 14" fill="white" xmlns="http://www.w3.org/2000/svg" aria-hidden="true"><path fill-rule="evenodd" clip-rule="evenodd" d="M7 13.125C8.62445 13.125 10.1824 12.4797 11.331 11.331C12.4797 10.1824 13.125 8.62445 13.125 7C13.125 5.37555 12.4797 3.81763 11.331 2.66897C10.1824 1.52031 8.62445 0.875 7 0.875C5.37555 0.875 3.81763 1.52031 2.66897 2.66897C1.52031 3.81763 0.875 5.37555 0.875 7C0.875 8.62445 1.52031 10.1824 2.66897 11.331C3.81763 12.4797 5.37555 13.125 7 13.125ZM14 7C14 8.85652 13.2625 10.637 11.9497 11.9497C10.637 13.2625 8.85652 14 7 14C5.14348 14 3.36301 13.2625 2.05025 11.9497C0.737498 10.637 0 8.85652 0 7C0 5.14348 0.737498 3.36301 2.05025 2.05025C3.36301 0.737498 5.14348 0 7 0C8.85652 0 10.637 0.737498 11.9497 2.05025C13.2625 3.36301 14 5.14348 14 7Z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M6.5625 2.625C6.67853 2.625 6.78981 2.67109 6.87186 2.75314C6.95391 2.83519 7 2.94647 7 3.0625V7.62125L9.842 9.24525C9.93982 9.30426 10.0107 9.39919 10.0394 9.50975C10.0682 9.62031 10.0525 9.73774 9.99587 9.83692C9.93919 9.93611 9.84596 10.0092 9.73611 10.0406C9.62626 10.0719 9.5085 10.0591 9.408 10.0047L6.3455 8.25475C6.27854 8.2165 6.22288 8.16123 6.18415 8.09454C6.14543 8.02785 6.12502 7.95212 6.125 7.875V3.0625C6.125 2.94647 6.17109 2.83519 6.25314 2.75314C6.33519 2.67109 6.44647 2.625 6.5625 2.625Z"></path></svg>
                          <p>{calculateDuration(item.duration)}</p>
                       </div>
                  </div>
                  <img
                    // poster={item.thumbnailUrl}
                    // controls
                    src={item.thumbnailUrl}
                  />
              </div>
            )
          }
        }) : null
      }

    {/* <h3>Relevant videos:</h3> */}
    {/* {
      videoData.map((video, i)=>{
        return (
          <div key={i} className='video-card'>
              <div className='image-container'>
                  <img src={video.thumbnail}/>
              </div>
              <div className='card-body'> 
                  <p>{video.title}</p>
                  <p>
                    <span>{video.views} views</span>
                    <span>&#183;</span>
                    <span>5 <img src={upArrow}/> </span>
                    <span>&#183;</span>
                    <span>16 <img src={social}/> </span>
                  </p>
              </div>
          </div>
        )
      })
    } */}

        {/* <h2>Channels Miniplayer <span className='new-tag'>New!</span></h2> */}
        {/* <div className='video-card'>
            <video controls src="https://b-cdn-video.clutchprep.com/videos/Biology-5-02-20F-Prokaryotic-And-Eukaryotic-Cells-C-1-V-1-final__720p__1601575865037.mp4"></video>
            <p>Prokaryotic & Eukaryotic Cells</p>
            <p>Jason Amores Sumpter</p>
        </div> */}
        {/* <div className='channel-card'>
            <p className='title'>General Biology Channel</p>
            <p>Dive deep into the toughest concepts of General Biology with videos and practice problems tailored to your course.</p>
            <div className='images'>
                <img src={banner1}/>
                <img src={banner2}/>
                <img src={banner3}/>
            </div>
            <button className='primary'>Open Channel</button>
        </div> */}
       
    </div>
  );
}

export default Channels