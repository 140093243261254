import { createSlice, current } from '@reduxjs/toolkit';

const termsDeckSlice = createSlice({
  name: "termsDeck",
  initialState: [],
  reducers: {
    setTermsDeck: (state, action) => {
      state = action.payload
      return state;
    },
    setSavedTermsScore: (state, action) => {
      state.forEach((term, index)=>{
        if(index === action.payload.index){
            term.score = action.payload.score
        }
      })
      return state;
    },
    setSavedTermsViewed: (state, action) => {
      state.forEach((term, index)=>{
        if(index === action.payload.index){
            term.viewed = action.payload.viewed
        }
      })
      return state;
    },
  }
});

export const { setTermsDeck, setSavedTermsScore, setSavedTermsViewed } = termsDeckSlice.actions;

export default termsDeckSlice.reducer;