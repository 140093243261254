import {useEffect, useState, useRef} from 'react'
import styled from 'styled-components';
import {colors} from '../../global_styles/colors'
import {OutlineButton, PrimaryButton} from '../../global_styles/GlobalStyles'
import { useSpring, animated } from "@react-spring/web";
import useWindowResize from '../../util/useWindowResize'

//images
import thumbsUp from "../../images/icons/thumb-up.svg"
import thumbsDown from "../../images/icons/thumb-down.svg"
import correct from "../../images/icons/correct-white.svg"
import incorrect from "../../images/icons/incorrect-white.svg"
import radioChecked from "../../images/icons/radio-checked.svg"
import radioCorrect from "../../images/icons/radio-correct.svg"
import radioIncorrect from "../../images/icons/radio-incorrect.svg"

const Card = styled.div`
    max-width: 672px;
    min-height: 368px;
    padding: 16px;
    border-radius: 12px;
    background: ${colors.backgroundlightthemebglevel2};
    transition: width 0.5s ease;
    width: 100%;
    overflow: hidden scroll;
    position:relative;
    display:flex;
    flex-direction:column;
    justify-content:space-between;
    margin:0 auto;
    margin-bottom:24px;
    
`

const CardSection = styled.div`
    transition: all 0.5s ease;
`

const CardText = styled(animated.p)`
    font-family: "TT Commons";  
    transition: all 0.5s ease;  
    text-align:center;
    width:100%;
    // overflow:hidden;
`

const CardTextFront = styled(CardText)`
    font-size: 0.8em;
    font-weight: 400;
    line-height:120%;
    text-align:left;
    margin-left:20px;
    width:calc(100% - 40px);
`

const CardFooter = styled.div`
    left:0;
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
`
const CardFooterButtons = styled.div`
    display:flex;
    gap:16px;
`

const SkipButton = styled(OutlineButton)`
    width:100px;
`

const SubmitButton = styled(PrimaryButton)`
margin:0 auto;
`

const Credit = styled.div`
    display:flex;
    width:143px;
    position:absolute;
    right:16px;
    bottom:24px;
    gap:8px;
    align-items:center;
    opacity:0;
    &.answer-revealed{
        opacity:1;
    }
    p{
        font-family: "TT Commons";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.24px;
        margin:0;
    }
    button{
        width:24px;
        height:24px;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`

const CardBody = styled.div`
    
`

const QuestionOptions = styled.fieldset`
    border:none;
    margin:0;
    padding:0;
    padding-left:60px;
    margin-bottom:24px;
`

const Option = styled.div`
    margin-bottom:16px;
    position:relative;
    height: 22px;
    display: flex;
    align-items: center;
    margin-bottom:16px;
    input{
        opacity: 0;
        height: 20px;
        width: 20px;
        cursor: pointer;
        z-index: 1;
        left: 0;
        position: absolute;
        top: 0;
        &[type=radio]:checked~span img, &.show-answer~span img {
            opacity: 1;
        }
    }
    label{
        font-family: "TT Commons";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 133.333% */
        letter-spacing: 0.24px;
        margin-left:6px;
        &.correct{
            color:#01655b;
            font-weight:700;
        }
        &.incorrect{
            color:#7e0d1b;
            font-weight:700;
        }
    }
    span{
        height: 20px;
        line-height: 1.5;
        text-align: center;
        width: 20px;
        border: 2px solid #777B83;
        border-radius: 20px;
        z-index: 0;
        background: transparent;
        img{
            height: 20px;
            opacity: 0;
            width: 20px;
            top: -12px;
            position: relative;
            left: -2px;
        }
    }
`

function MCQFlashcard(props){

    const [width, height] = useWindowResize()

    const [showAnswer, setShowAnswer] = useState(false)

    const cardFooterRef = useRef(null)

    useEffect(()=>{
        if(cardFooterRef.current){
            if(window.innerWidth >= 638 || window.innerWidth <= 638 && !showAnswer){
                cardFooterRef.current.querySelector('button').style.margin = '0 auto'
                cardFooterRef.current.querySelector('div').style.position = 'absolute'
                cardFooterRef.current.style.justifyContent = 'center'
            }
            if(window.innerWidth <= 638){
                if(showAnswer){
                    cardFooterRef.current.querySelector('button').style.margin = 'unset'
                    cardFooterRef.current.querySelector('div').style.position = 'unset'
                    cardFooterRef.current.style.justifyContent = 'space-between'
                }
            }
        }   
    },[width, props.drawerOpen, showAnswer])


    // useEffect(()=>{
    //     if(cardFooterRef.current){
    //         if(window.innerWidth >= 638 || window.innerWidth <= 638 && !showAnswer){
    //             cardFooterRef.current.style.flexDirection = 'row'
    //         }
    //         if(window.innerWidth <= 638){
    //             if(showAnswer){
    //                 cardFooterRef.current.style.flexDirection = 'column'
    //             }
    //         }
    //     }   
    // },[showAnswer])

    useEffect(()=>{
        setShowAnswer(props.showAnswer)
    },[props.showAnswer])

    return(
        <>
            {
                props.currentCard === props.index ? 
                <Card>
                    <CardBody>
                        <CardTextFront>{props.data.question}</CardTextFront>
                        <QuestionOptions>
                                {
                                    props.data && props.data.options ?
                                    props.data.options.map((option, index)=>{
                                        return(
                                            <Option key={index}>
                                                <input 
                                                    type="radio"
                                                    id={`option-${index}`}
                                                    name="option" 
                                                    data-value={option}
                                                    onChange={(e)=>{props.setSelectedAnswer(e.target.getAttribute('data-value'))}}
                                                    disabled={showAnswer ? true : false}
                                                    className={showAnswer ? 'show-answer' : ''}
                                                    />
                                                <span>
                                                    {
                                                       !showAnswer ? <img src={radioChecked}/>
                                                       :
                                                       option === props.data.answer ? <img src={radioCorrect}/>
                                                       : 
                                                       props.selectedAnswer === option && props.selectedAnswer != props.data.answer ? <img src={radioIncorrect}/>
                                                       : 
                                                       null 
                                                    }
                                                    {/* <img 
                                                        src={
                                                            !showAnswer ? radioChecked
                                                            :
                                                            option === props.data.answer ? radioCorrect
                                                            : 
                                                            props.selectedAnswer === option && props.selectedAnswer != props.data.answer ? radioIncorrect
                                                            : 
                                                            null
                                                        
                                                    }/> */}
                                                </span>
                                                <label 
                                                    htmlFor="option"
                                                    className={
                                                        showAnswer && option === props.data.answer ? 'correct' 
                                                        : 
                                                        showAnswer && props.selectedAnswer === option && props.selectedAnswer != props.data.answer ? 'incorrect'
                                                        :
                                                        ''
                                                    }
                                                >
                                                    {option}
                                                </label>
                                            </Option>
                                        )
                                    })
                                    : null
                                }
                        </QuestionOptions>
                    </CardBody>
                    <CardFooter
                        ref={cardFooterRef}
                        style={{
                            justifyContent: props.showAnswer ? 'space-between' : 'center'
                        }}
                    >
                        {/* <CardFooterButtons>
                            {
                                !showAnswer ? <> */}
                                     {/* <SkipButton
                                        onClick={props.handleNext}
                                        >
                                            Skip
                                    </SkipButton> */}
                                    <SubmitButton
                                        onClick={props.handleSubmitMCQ}
                                        disabled={
                                            !props.selectedAnswer || props.selectedAnswer && showAnswer ? true : false 
                                        }
                                    >
                                        Reveal answer
                                    </SubmitButton>
                                {/* </>
                                : null
                            }
                           
                        </CardFooterButtons> */}
                        {/* <CardFooterButton
                            onClick={()=>{showAnswer ? handleHide() : handleReveal()}}
                        >
                            {showAnswer ? 'Hide answer' : 'Reveal answer'}
                        </CardFooterButton> */}
                        
                        {/* {
                            showAnswer ?  */}
                                <Credit
                                    className={showAnswer ? 'answer-revealed' : ''}
                                >
                                    <p>Created by AI</p>
                                    <button onClick={()=>{props.handleModalType('upvote')}} >
                                        <img src={thumbsUp}/>
                                    </button>
                                    <button onClick={()=>{props.handleModalType('downvote')}}>
                                        <img src={thumbsDown}/>
                                    </button>
                                </Credit>
                                {/* :null
                        } */}
                    </CardFooter>
                </Card>
                : null   
            }
            
        </>
    )
}

export default MCQFlashcard