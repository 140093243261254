import {useState, useEffect} from 'react'
import styled from 'styled-components';
import {PrimaryButton, GradientButton} from '../../global_styles/GlobalStyles'
import {colors, BAR_COLORS} from '../../global_styles/colors'
import { useSelector } from 'react-redux';
import { updateViewedState } from '../../redux/termsStateSlice';

const SelfScoreContainer = styled.div`
    max-width: 440px;
    margin: 0 auto;
    &.hidden{
       display:none;
    }
    p{
        font-family: "TT Commons";
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 120% */
        letter-spacing: 0.4px;
        text-align:center;
        margin: 0;
        margin-bottom:16px;
    }
`

const SelfScoreOptions = styled.ul`
    display:flex;
    align-items:center;
    justify-content:space-between;
    gap:30px;
    margin-bottom: 40px;
`

const SelfScoreButton = styled.li`
    position:relative;
    margin:0 4px;
    button{
        font-family: "TT Commons";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
        width: 56px;
        height: 56px;
        border-radius:56px;
        display:flex;
        align-items:center;
        justify-content:center;
        border: 1px solid transparent;
        transition: opacity 300ms ease;
        color:${colors.textlightThemeDefault};
        &[disabled]{
            opacity: 0.32;
        }
        &:hover:enabled:not(.selected){
            filter: brightness(85%);
        }
        // &.selected{
        //     box-shadow:
        //     0 0 0 4px white,
        //     0 0 0 6px #655591;
        // }
    }
    span{
        position:absolute;
        bottom:-25px;
        text-align:center;
        font-family: "TT Commons";
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.24px;
        color:${colors. textlightThemeDefault};
        left: 50%;
        transform: translate(-50%, 0);
        display:block;
        width:fit-content;
        white-space:nowrap;
    }
`

function SelfScore(props){

    return(
        <SelfScoreContainer
            className={props.firstTimeReveal ? '' : 'hidden'}
        >
            <p>How well do you know this?</p>
            <SelfScoreOptions>
                {
                    [1, 2, 3, 4, 5].map((rating, i)=>{
                        return (
                            <SelfScoreButton key={i}>
                                <button
                                    className={props.score === rating ? 'selected' : ''}
                                    onClick={props.handleScoring}
                                    style={{
                                        backgroundColor: BAR_COLORS[i + 1],
                                        boxShadow: props.score === rating && rating === 5 ? 
                                        '0 0 0 4px white, 0 0 0 6px #1E695F'
                                        :
                                        props.score === rating && rating != 5 ?
                                        '0 0 0 4px white, 0 0 0 6px #655591' 
                                        :
                                        'none'
                                    }}
                                >
                                    {rating}
                                </button>
                                {
                                    rating === 1 ?
                                    <span>No clue</span>
                                    : null
                                }
                            {
                                    rating === 5 ?
                                    <span>Learned</span>
                                    : null
                                }
                            </SelfScoreButton>
                        )
                    })
                }
            </SelfScoreOptions>
        </SelfScoreContainer>
    )
}

export default SelfScore