import React, {useEffect, useState} from 'react';

// images
import dropdown from "../../images/icons/arrow-down.svg"
import exportIcon from "../../images/icons/export.svg"
import plus from "../../images/icons/plus.svg"
import chevronBlue from "../../images/icons/chevron-blue.svg"

function Notebook(props) {

  return (
      <div className="right-drawer-panel notebook">
            {/* <h2>Notebook</h2> */}
            <div className='note-tools'>
                <button className='fake-select'>
                    <span className='label'>Chapters</span>
                    <img src={dropdown}/>
                </button>
                <button className='fake-select'>
                    <span className='label'>Colors </span>

                    <img src={dropdown}/>
                </button>
                <button className='note-tools-btn export'
                >
                    <img src={exportIcon}/>
                </button>
                <button className='note-tools-btn custom new'>
                    <img src={plus}/>
                </button>
            </div>
            <div className='notebook-content'>
                <h3>
                    <img src={chevronBlue}/>
                    <span>Chapter 1: Evolution, the Themes of Biology, and Scientific Inquiry</span>
                </h3>
                <div className='card note-card'>
                    <p>1.1: The study of life reveals unifying themes</p>
                    <div className='highlight green'>
                        <span >Even a child realizes that a dog or a plant is alive, while a rock or a car is not.</span>
                    </div>
                    
                    <p>Interesting point...should I add this to my essay?</p>
                </div>
            </div>
      </div>
  );
}

export default Notebook;