import styled from 'styled-components';
import {colors, DONUT_COLORS} from '../global_styles/colors'
import { GradientButton, OutlineButton } from '../global_styles/GlobalStyles';

import checked from '../images/icons/checked.svg'

const Modal = styled.div`
    border-radius: 14px;
    box-shadow: 0px -4px 10px 0px rgba(0, 0, 0, 0.12), 0px -2px 4px -1px rgba(0, 0, 0, 0.20);
    background: linear-gradient(117deg, rgba(5, 17, 42, 0.50) 25.6%, rgba(49, 1, 53, 0.50) 53.62%), linear-gradient(117deg, #310135 30.84%, #05112A 68.52%);
    border: 1px solid white;
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    width:400px;
    height:fit-content;
    z-index:5001;
    color:${colors.primaryTintedWhite};
    padding:24px;
    form fieldset{
        border:none;
        margin:0;
        padding:0;
        margin-top:8px;
        div{
            position:relative;
            height: 22px;
            display: flex;
            align-items: center;
            margin-bottom:8px;
        }
        input{
            opacity: 0;
            height: 20px;
            width: 20px;
            cursor: pointer;
            z-index: 1;
            left: 0;
            position: absolute;
            top: 0;
            &[type=checkbox]:checked~span img {
                opacity: 1;
            }
        }
        label{
            font-family: "TT Commons";
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px; /* 133.333% */
            letter-spacing: 0.24px;
            margin-left:6px;
        }
        span{
            height: 20px;
            line-height: 1.5;
            text-align: center;
            width: 20px;
            border: 2px solid #777B83;
            border-radius: 4px;
            z-index: 0;
            background: transparent;
            img{
                height: 20px;
                opacity: 0;
                width: 20px;
                top: -12px;
                position: relative;
                left: -2px;
            }
        }
    }
`

const Title = styled.p`
    font-family: "TT Commons";
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 116.667% */
    letter-spacing: 0.4px;
    margin:0;
    margin-bottom:8px;
`

const Subtitle = styled.p`
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.24px;
    margin:0;
`

const Input = styled.textarea`
    border-radius: 4px;
    border: 2px solid ${colors.SwatchDark700};
    background: ${colors.backgrounddarkthemebglevel0};
    padding:12px 16px;
    width:100%;
    height:100px;
    margin: 24px 0;
    font-family: "TT Commons";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
    color:${colors.textdarkThemeDefault};
    resize: none; 
    &::placeholder{
        color:${colors.textdarkThemeMuted};
    }
`

const Footer = styled.div`
    display:flex;
    align-items:center;
    justify-content:flex-end;
    width:100%;
    gap:12px;
    margin-top:16px;
`

const Cancel = styled(OutlineButton)`
    border: 1px solid ${colors.borderdarkThemeborder};
    color:${colors.textdarkThemeDefault};
    width:100px;
    min-width:unset;
`

const Submit = styled(GradientButton)`
    width:100px;
    min-width:unset;
`

const Dismiss = styled(GradientButton)`
    margin:0 auto;
`

const CommentModal = (props) => {
    return (
        <Modal>
            <Title style={{textAlign: props.type ? 'left' : 'center'}}>
            {
                props.type === 'upvote' ? 'Great!'
                :
                props.type === 'downvote' ? 'Sorry about that!'
                :
                'Thanks for the feedback!'
            }
            </Title>
            <Subtitle>
            {
                props.type === 'upvote' ? 'What did you like most about this response?'
                :
                props.type === 'downvote' ? 'What was wrong with that response?'
                :
                null
            }
            </Subtitle>
            <form
                onSubmit={(e)=>{e.preventDefault()}}
            >
            {
                props.type === 'upvote' ?
                    <Input
                        type="text"
                        placeholder="Type your reason here..."
                    />
                :
                props.type === 'downvote' ?
                <>
                    <fieldset>
                        <div>
                            <input type="checkbox" id="scales" name="scales"/>
                            <span>
                                <img src={checked}/>
                            </span>
                            <label for="scales">Not helpful</label>
                        </div>
                        <div>
                            <input type="checkbox" id="horns" name="horns" />
                            <span>
                                <img src={checked}/>
                            </span>
                            <label for="horns">Not correct</label>
                        </div>
                        <div>
                            <input type="checkbox" id="horns" name="horns" />
                            <span>
                                <img src={checked}/>
                            </span>
                            <label for="horns">Unsafe/harmful</label>
                        </div>
                        <div>
                            <input type="checkbox" id="horns" name="horns" />
                            <span>
                                <img src={checked}/>
                            </span>
                            <label for="horns">Something else</label>
                        </div>
                    </fieldset>
                    <Input
                        type="text"
                        placeholder="Please explain..."
                    />
                </>
                : null
            }
            <Footer>
                {
                    props.type === 'upvote' ||  props.type === 'downvote' ?
                     <>
                        <Cancel onClick={props.handleCloseModal}>Cancel</Cancel>
                        <Submit onClick={()=>{props.handleModalType('')}}>Submit</Submit>
                    </>
                    :
                   <Dismiss onClick={props.handleCloseModal}>Dismiss</Dismiss>
                }
                
            </Footer>
            </form>
        </Modal>
    )
}

export default CommentModal