import React, {useEffect, useState, useRef} from 'react';
import AWS from 'aws-sdk'
import { useSpring, animated } from "@react-spring/web";
import useWindowResize from '../util/useWindowResize'
import {connect} from "react-redux";
import AudioPlayer from './AudioPlayer';
import BASE_URL from '../BASEURL';

//images
import arrowLeft from '../images/icons/arrow-left.svg'
import arrowRight from '../images/icons/arrow-right.svg'
import arrowDown from '../images/icons/arrow-down.svg'
// import downArrow from "../images/icons/arrow-down.svg"
import bookmarkOutline from '../images/icons/bookmark-outline.svg'
import bookmarkSolid from '../images/icons/bookmark-solid.svg'
import audio from "../images/icons/audio.svg"
import playing from "../images/gifs/audio-small-reverse.gif"

const spacesEndpoint = new AWS.Endpoint('nyc3.digitaloceanspaces.com');
const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: 'DO00G7FHTCCRD4RWUXE4',
    secretAccessKey: 'caC2B2nkRiaxP79k0cgGTMrv6B2wkOGzP30h35NZowQ'
});

//for go to page
let typingTimer;


function Reader(props){

    const [width, height] = useWindowResize()
    // const [chapter, setChapter] = useState(props.currentChapter)
    const [bookmarkActive, setBookmarkActive] = useState(false)
    // const [showAudioPlayer, setShowAudioPlayer] = useState(false)

    const [localPageNum, setLocalPageNum] = useState(props.page)

    const goToPageRef = useRef(null)
    const carouselRef = useRef(null)

    // const [bookmarkActive, setBookmarkActive] = useState(false)

    const styleSheets = ['override.css', 'bronte.css', 'theme.css']

    useEffect(()=>{
        setLocalPageNum(props.page)
    },[props.page])

    // get book stylesheet
    // useEffect(()=>{
    //     // let linkEl = document.getElementById('book-stylesheet')
    //     if(props.testContent){
    //         styleSheets.forEach((sheetName, index)=>{
    //             let link = document.createElement('link');
    //             link.id = 'book-stylesheet';
    //             let head = document.getElementsByTagName("head")[0]
    //             link.rel = 'stylesheet'
    //             link.href = s3.getSignedUrl('getObject', {
    //                 Bucket: 'books-api',
    //                 Key: `${process.env.REACT_APP_BOOK_ID}/OPS/css/${sheetName}`
    //             })
    //             head.insertBefore(link, head.firstChild)
    //         })
    //     }
    // },[props.testContent])

    // //when the page loads or changes
    // //get all the src paths and replace them with paths from digital ocean assets
    // //and scroll to the current slide in the carousel
    // useEffect(()=>{
    //     // if(props.slides && props.slides[props.page]){
    //     if(props.testContent){
    //         let srcFiles = document.querySelectorAll("[src]");
    //         srcFiles.forEach(link => {
    //             let srcPath = link.getAttribute('src');
    //             const condition = srcPath.substring(0,3) === '../';
        
    //             if (condition) {
    //                 srcPath = srcPath.replace('../', 'OPS/');
    //                 console.log(srcPath)
        
    //                 let folder = srcPath.split('/'),
    //                     bucket = 'books-api/' + process.env.REACT_APP_BOOK_ID + '/',
    //                     fileName = srcPath.substring(srcPath.lastIndexOf("/") + 1),
    //                     myKey = fileName.split('#')[0].replace('.xhtml', '.html');
                    
    //                 let url = s3.getSignedUrl('getObject', {
    //                     Bucket: 'books-api',
    //                     Key: `${process.env.REACT_APP_BOOK_ID}/${srcPath}`
    //                 })
    
    //                 link.src = url
    //             }
    //         })
    //         // scrollToPage()
    //     }
    // // },[props.slides, props.page])
    // },[props.testContent])

    // useEffect(()=>{
    //     // goToPageRef.current.value = props.page
    //     getChapter()
    // },[props.page])

    // // //bookmarks
    // useEffect(()=>{
    //     if(props.page){
    //         setBookmarkActive(false)
    //         props.bookmarks.forEach(bookmark=>{
    //             if(props.page === bookmark.page){
    //                 setBookmarkActive(true)
    //                 return
    //             }else{
    //                 return
    //             }
    //         })
            
    //     }
    // },[props.page, props.bookmarks])

    // const getChapter = () => {
    //     if(props.book && props.book.chapters){
    //         props.book.chapters.forEach((chapter, index)=>{
    //             if(props.book.chapters[index + 1]){
    //                 let chapterPage = chapter.page
    //                 let nextChapterPage = props.book.chapters[index + 1].page
    //                 if(props.page >= chapterPage && props.page < nextChapterPage){
    //                     // console.log(chapter.title)
    //                     setChapter(chapter.title)
    //                 }
    //             }
    //         })
    //     }
    // }

    //navigation functions
    const scrollToPage = () => {
        carouselRef.current.scrollTo(props.page * carouselRef.current.getBoundingClientRect().width, 0)
    }

    // const changePageInput = (e) =>{
    //     let newPage = parseInt(goToPageRef.current.value)
    //     if(newPage) {
    //         if(newPage !== 0 && newPage < props.lastPage) {
    //             props.loadPage(newPage)
    //         }
    //     }
    // }

    const getLeft = () => {
        if(props.drawerViewState.showLeftDrawer === true && props.drawerViewState.showRightDrawer === false){
            if(props.miniDrawer === true){
                return '172px'
            }
            return '486px'
        }
        //no drawers open
        else{
            if(!props.cleanMode){
                return '86px'
            }else{
                return '0px'
            }
        }
    }

    const getWidth = () => {
        if(props.drawerViewState.showLeftDrawer === true && props.drawerViewState.showRightDrawer === false){
            if(props.miniDrawer === true){
                return `${window.innerWidth - 172}px`
            }
            return `${window.innerWidth - 486}px`
        }
        //no drawers open
        else{
            if(!props.cleanMode){
                return `${window.innerWidth - 86}px`
            }else{
                return `${window.innerWidth}px`
            }
            
        }
    }

    // const handleToggleAudioPlayer = (e) => {
    //     e.stopPropagation();
    //     setShowAudioPlayer(!showAudioPlayer)
    // } 

    // const handleCloseAudioPlayer = (e) => {
    //     e.stopPropagation();
    //     setShowAudioPlayer(false)
    // }

    const springProps = useSpring({
        left: getLeft(),
        position: "absolute",
        width: getWidth(),
        immediate: true
    });

    return(
        <animated.div style={springProps}>
            <div className={`reader`}>
                {
                    !props.cleanMode ?
                    <header className='reader-header'>
                        <p className='chapter'>{props.currentChapter}</p>
                        <button className={`avatar-button ${props.showAccountMenu ? 'active' : ''}`} 
                                onClick={(e)=>{
                                    // e.stopPropagation()
                                    props.setShowAccountMenu(!props.showAccountMenu)   
                                }}
                            >
                            <span className='avatar'>HH</span>
                            <img className='arrow' src={arrowDown}/>
                        </button>
                    </header>
                    : <></>
                }
                
                <ul className={`carousel ${props.cleanMode ? 'cleanmode' : ''}`} ref={carouselRef} 
                    onMouseUp={(e)=>{props.handlePageClick(e)}}
                    >
                    {/* {
                        props.slides != null ?
                            props.slides.map((slide, index) => {
                                return <li key={index} className="slide" id={`slide-${index}`} 
                                >
                                    <div dangerouslySetInnerHTML={{__html: slide}}></div>
                                </li>
                            })
                        : null
                    } */}
                   
                    <li className="slide" id={`slide-0`} >
                        <iframe 
                            style={{width:'100%', height: '100%', border: 'none', background: 'white'}} 
                            // src={`${BASE_URL}reader?page=${props.page ? props.page : '33203940-7457-11ed-83c9-80d153521896'}`}
                            src={`${BASE_URL}reader?page=${'33203940-7457-11ed-83c9-80d153521896'}`}
                        ></iframe>
                    </li>
                     
                </ul>
                {/* <footer >  
                    <div className='audio-container'>
                        <button 
                            className={`audio-button ${props.showAudioPlayer ? 'active' : ''} ${props.playlist.playing ? 'playing' : ''}`}
                            onClick={props.handleToggleAudioPlayer}
                        >
                            {
                            props.playlist.playing === true ? 
                            <img style={{height:'35px'}} src={playing}/>
                            :
                            <img style={{height:'25px'}} src={audio}/>
                            }
                            
                        </button>
                         
                        <AudioPlayer 
                            // handleCloseAudioPlayer={props.handleCloseAudioPlayer}
                            // cleanMode={props.cleanMode}
                            showAudioPlayer={props.showAudioPlayer}
                        /> 
                    </div>

                    
                    <div className='go-to-page'>
                        <button 
                            className="previous"
                            disabled={props.page === 1 ? true : false}
                            onClick={(e)=>{
                                e.stopPropagation();
                                props.loadPage(props.page - 1)
                            }}>
                            <img src={arrowLeft}/>
                        </button>
                        <span>Page</span>
                        <div className="input-container">
                            <input 
                                type="number"
                                defaultValue={props.page}
                                ref={goToPageRef}
                                onKeyDown={(e)=>{
                                    e.stopPropagation();
                                    clearTimeout(typingTimer);
                                }}
                                onKeyUp={(e)=>{
                                    e.stopPropagation();
                                    clearTimeout(typingTimer);
                                    typingTimer = setTimeout(changePageInput, 1000);
                                }}
                                onClick={(e)=>{e.stopPropagation()}}
                            />
                            <button className={`bookmark-button ${bookmarkActive === true ? 'active' : ''}`}
                                    onClick={(e)=>{
                                        e.stopPropagation();
                                        if(bookmarkActive === true){
                                            props.removeBookmark(props.page)
                                        }else{
                                            props.addBookmark(props.page, props.currentChapter, props.book.toc[props.page].title)
                                        }
                                    }}
                            > 
                                <img src={`${bookmarkActive === true ? bookmarkSolid : bookmarkOutline}`}/>
                            </button>
                        </div>
                        <span>of {props.lastPage}</span>
                        <button 
                            className='next'
                            disabled={props.page === props.lastPage ? true : false}
                            onClick={(e)=>{
                                e.stopPropagation();
                                props.loadPage(props.page + 1)
                            }}>
                            <img src={arrowRight}/>
                        </button>
                    </div>

                    <div className={`zen-toggle ${props.drawerViewState.showLeftDrawer === false ? 'more-left' : ''}`}>
                            <label htmlFor='zen-toggle'>Focus</label>
                            <button
                                id="zen-toggle"
                                className={`toggle ${props.cleanMode ? 'on' : ''}`}
                                // name="readalong-toggle"
                                role="switch"
                                // aria-labelledby="readalong-toggle"
                                aria-checked='true'
                                onClick={props.cleanModeToggle}
                            >
                            </button>
                    </div>
                    
                </footer> */}
            </div>
        </animated.div>
    )

}

const mapStateToProps = ({bookmarks, playlist}) => ({
    bookmarks, playlist
  });
  
  const mapDispatchToProps = dispatch => ({
    addBookmark: (page,chapter,title) => dispatch({type: 'ADD_BOOKMARK', page:page, chapter: chapter, title:title}),
    removeBookmark: (page) => dispatch({type: 'REMOVE_BOOKMARK',page:page}),
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Reader);

