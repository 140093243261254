import { createSlice } from '@reduxjs/toolkit';

const selectedSectionSlice = createSlice({
  name: "selectedSection",
  initialState: "",
  reducers: {
    setSelected: (state, action) => {
      state = action.payload
      return state;
    },
  }
});

export const { setSelected } = selectedSectionSlice.actions;

export default selectedSectionSlice.reducer;