import React, {useState, useEffect}from 'react';
import {connect} from "react-redux";

import close from "../../images/icons/close.svg"

const PlaylistSettings = props => {

   const toggleReadAlong = () => {
    //    if(props.read_along === true){
    //         props.turnOffReadAlong()
    //         // if(props.playlist.playing === true){
    //         //     props.setPlaying(false)
    //         // }
    //    }else{
    //         props.turnOnReadAlong()
    //    }
    }


    return (<>
        <div className='playlist-settings'>
            <div className='playlist-settings-header'>
                <h2>Audiobook settings</h2>
                <button onClick={props.closePlaylistSettings}>
                    <img src={close}/>
                </button>
            </div>
            {/* <div className='container'>
                <p>Volume</p>
                <div className='volume-container open'>
                    <span className='volume'></span>
                    <div className='input-container'>
                        <input type='range'
                                min={0}
                                max={12}
                                value={6}
                        />
                    </div>
                </div>
            </div> */}
             <div className='container'>
                <p>Speed</p>
                <div className='playback-container open'>
                    <button className='minus'> </button>
                    <span>1x</span>
                    <button className='plus'> </button>
                </div>
            </div>
             <div className='container'>
                <p>Read along</p>
                 <button
                     className={`toggle ${props.read_along === true ? 'on' : ''}`}
                     name="toggle-0"
                     role="switch"
                     aria-labelledby="labelId"
                     aria-checked="true"
                     onClick={toggleReadAlong}
                 >
                 </button>
            </div>
             <div className='container'>
                <p>Pause at end of page</p>
                <button
                     className='toggle'
                     name="toggle-0"
                     role="switch"
                     aria-labelledby="labelId"
                     aria-checked="true"
                 >
                 </button>
            </div>
        </div>
        <div className='overlay'></div>
        </>
    );
}


const mapStateToProps = ({display_settings, read_along, playlist}) => ({
    display_settings, read_along, playlist
});

const mapDispatchToProps = dispatch => ({
    turnOnReadAlong: () => dispatch({type: 'READ_ALONG_ON'}),
    turnOffReadAlong: () => dispatch({type: 'READ_ALONG_OFF'}),
    setPlaying: (data) => dispatch({type: 'SET_PLAYING', data:data}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PlaylistSettings);
