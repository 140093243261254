import '../css/styles.css';
import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux"
import BASE_URL from "../BASEURL";
import AccountMenu from "./AccountMenu"
import ContextMenu from './ContextMenu';
import BookmarkMenu from './BookmarkMenu';
import PlaylistSettings from './Drawer/PlaylistSettings';
import Toolbar from './Toolbar';
import CustomizeMenu from './CustomizeMenu';
import Tooltips from './Tooltips'
import { useSearchParams } from "react-router-dom";
import { getBookData } from "../util/getBookData";
import { getOnePage } from "../util/getOnePage";
import Chatbot from "../components/chatbot/Chatbot"
import ChatbotWindow from "../components/chatbot/ChatbotWindow"
import FlashcardsModule from '../components/FlashcardsModule/FlashcardsModule';

//images
import play from "../images/icons/audio/play.svg"
import LeftDrawerNew from './Drawer/LeftDrawerNew';
import ReaderNew from './ReaderNew'
import FooterNew from './FooterNew';


function App(props) {

  const accountMenuRef = useRef(null);
  const contextMenuRef = useRef(null);

  const [searchParams, setSearchParams] = useSearchParams();

  //book data
  const [id, setId] = useState('617b139759b7ff4ae787b524')
  const [book, setBook] = useState(null)
  const [page, setPage] = useState('2f8203e0-7457-11ed-879e-ec8ef940ee9a')
  const [slides, setSlides] = useState([])
  const [lastPage, setLastPage] = useState(null)
  const [currentChapter, setCurrentChapter] = useState('')
  const [prevPage, setPrevPage] = useState('')
  const [nextPage, setNextPage] = useState('')

  //views
  const [showLeftDrawer, setShowLeftDrawer] = useState(false)
  const [tabShowing, setTabShowing] = useState('')
  const [miniDrawer, setMiniDrawer] = useState(false)
  const [showRightDrawer, setShowRightDrawer] = useState(false)
  const [cleanMode, setCleanMode] = useState(false)

  //menus, popups etc. 
  const [showAccountMenu, setShowAccountMenu] = useState(false)
  const [showContextMenu, setShowContextMenu] = useState(false)
  const [popUpLocation, setPopUpLocation] = useState([0,0])
  const [showBookmarkMenu, setShowBookmarkMenu] = useState(false)
  const [showPlaylistSettings, setShowPlaylistSettings] = useState(false)
  const [customizeMenu, setCustomizeMenu] = useState(false)

  const [textSelection, setTextSelection] = useState("")

  const [testContent, setTestContent] = useState("")

  const [showChatWindow, setShowChatWindow] = useState(false)

  //flashcards
  const [showModule, setShowModule] = useState(false)
  const [experienceType, setExperienceType] = useState(null)

  const drawerViewState = {
    showLeftDrawer:showLeftDrawer,
    showRightDrawer:showRightDrawer
  }

  window.addEventListener('message', (e)=>{
    //get chapter from child iframe
    if(e.origin === 'http://localhost:9006' || e.origin === 'https://pearsonct.design'){
      if(e.data.type === 'chapter'){
        setCurrentChapter(e.data.content)
      }else if(e.data.type === 'section'){
        if(book){
            book.toc.forEach(chapter=>{
              if(chapter.url === e.data.content.url){
                props.setSelected(chapter)
                props.setDrawerSelectedSection(chapter)
              }
            })
        }
        // props.setSelected(e.data.content)
        // console.log(e.data.content)
      }else if(e.data.type === 'hover')(
        console.log(e)
      )
    }
  });

  
  useEffect(()=>{
    let urlPageId = searchParams.get("page")
    if(urlPageId){
      setPage(urlPageId)
      setSearchParams('')
    }
  },[])


  useEffect(()=>{
    getBookData(process.env.REACT_APP_BOOK_ID)
    .then(data=>{
      setBook(data)
    })
},[])



  //get the book data
  // useEffect(()=>{
  //   if(book === null){
  //     fetch(BASE_URL + "find/one?id="+id+"", {
  //         method: "GET"
  //     })
  //     .then(response => response.json())
  //     .then(data => {
  //       // console.log(data)
  //       let newTOC = [];
  //       data.toc.forEach((item, i)=>{
  //           if(!item.title.startsWith('Part', 0)) {
  //             newTOC.push(item)
  //           }
  //       }) 
  //       newTOC.forEach((item, i)=>{
  //         if(newTOC[i - 1] && (item.href === newTOC[i - 1].href)){
  //           newTOC.splice(i, 1)
  //         }
  //       })
  //       data.toc = [...newTOC]
  //       // console.log(newTOC)
  //       setBook(data)
  //     })
  //   }
  // },[])

  // //once the book data is available
  // //set up the chapters
  // //get the total number of pages
  // //set up an empty slides array
  // //and set the starting page number
  // useEffect(()=>{

  //   //chapters
  //   if(book != null && !book.chapters){
  //     let chapters = [];
  //     chapters.push({
  //         page: 1,
  //         title:'Frontmatter',
  //     })
  //     book.toc.forEach((item, index)=>{
  //         //if the first character in the title string contains a number
  //         if(item.title.charAt(0).match(/^[1-9]*$/) && item.title.charAt(1) !== '.' && item.title.charAt(2) !== '.'){
  //             item.page = index
  //             chapters.push(item)
  //         }
  //     })
  //     setBook((prevState)=>{
  //         return {
  //           ...prevState,
  //           chapters: chapters
  //         }
  //     })

  //     //get total number of pages
  //     setLastPage(book.toc.length - 1)

  //     //set up slides to hold data
  //     let newSlides = [...slides]
  //     book.toc.forEach(()=>{
  //         newSlides.push([])
  //     })
  //     setSlides(newSlides)

  //     //starting page
      // setPage(1)
  //   }

  // },[book])

  // //every time the pages changes
  // //getPages will get the html needed to render a group of 3 pages
  // useEffect(()=>{
  //   if(page && book.toc){
  //     getPages()
      
  //   }
  //   if(page && book.chapters){
  //     getCurrentChapter()
  //     getPrevNext()
  //   }
  // },[page])

  // const getCurrentChapter = () => {
  //   //get chapter name
  //   let chapterName = 'Frontmatter';
  //   book.chapters.forEach((chapter, index)=>{
  //       if(index !== book.chapters.length - 1) {
  //           if (page >= chapter.page && page < book.chapters[index + 1].page) {
  //               chapterName = chapter.title
  //           }
  //       }else{
  //           if(page >= chapter.page){
  //               chapterName = 'Backmatter'
  //           }
  //       }
  //   })
  //   setCurrentChapter(chapterName)
  // }

  const getPrevNext = () => {
      let previousTitle = book.toc[page - 1].title
      let nextTitle = book.toc[page + 1].title
      setPrevPage(previousTitle)
      setNextPage(nextTitle)
  }

  //clean mode
  useEffect(()=>{
    closeLeft()
    setTabShowing('')
  },[cleanMode])

  //text highlighting and context menu
  const handlePageClick = (e) => {
    let text = "";
    if (window.getSelection && window.getSelection().toString() != textSelection) {
        text = window.getSelection().toString();
        setTextSelection(window.getSelection().toString())
        if(text.length > 0){
              setShowContextMenu(true)
              let x,y;

              if(e.pageX >= (window.innerWidth - 300)){
                  x = window.innerWidth - 500
              }else{
                x = e.pageX
              }

              if(e.pageY >= (window.innerHeight - 500)){
                  y = window.innerHeight - 500
              }else{
                  y = e.pageY
              }

              setPopUpLocation([x,y])
        }
    }else{
      window.getSelection().removeAllRanges();
    }
  }

  //view functions
  const openLeft = () => {
    setShowLeftDrawer(true)
  }

  const closeLeft = () => {
    setShowLeftDrawer(false)
    setTabShowing('')
  }

  const toggleLeft = () => {
    setShowLeftDrawer(!showLeftDrawer)
  }

  const openRight = () => {
    setShowRightDrawer(true)
  }

  const closeRight = () => {
    setShowRightDrawer(false)
  }

  const toggleRight = () => {
    setShowRightDrawer(!showRightDrawer)
  }

  const drawerViewFunctions = {
    openLeft:openLeft,
    closeLeft:closeLeft,
    openRight:openRight,
    closeRight:closeRight
  }

  const cleanModeToggle = () => {
    setCleanMode(!cleanMode)
  }

  const bookmarkMenuToggle = () => {
    setShowBookmarkMenu(!showBookmarkMenu)
  }

  const openPlaylistSettings = () => {
    setShowPlaylistSettings(true)
  }

  const closePlaylistSettings = () => {
    setShowPlaylistSettings(false)
  }

  const openCustomizeMenu = () => {
    setCustomizeMenu(true)
    setShowContextMenu(false)
}

const closeCustomizeMenu = () => {
    setCustomizeMenu(false)
}

    useEffect(()=>{
      setMiniDrawer(false)
      if(tabShowing === 'Scrubber'){
          setMiniDrawer(true)
      }
  },[tabShowing])

  //navigation functions 
  const loadPage = (page) => {
    setPage(page)
    // props.addRecent({
    //   page:pageNum,
    //   title: book.toc[pageNum].title,
    //   // chapter:chapterName,
    //   lastVisited: new Date()
    // })
  }

  //gets data for previous/current/next pages
  const getPages = () => {
    //page data in html
    //stored in slides state
    if(book != null){

      let pagesToGet = [page]
      if(page != lastPage){
          pagesToGet.push(page + 1)
      }
      if(page != 1){
          pagesToGet.unshift(page - 1)
      }
      
      //example: if page is 3 then pagesToGet should be [2, 3, 4]
      //if page is 1 then pagesToget should be [1, 2]
      //TODO if page is the last page pagesToGet should be [nextToLastPage#, lastPage#]

      //get the page data for the page numbers in pagesToGet

      let tempSlides = [...slides]
      let urls = []

      pagesToGet.forEach((pageNumber)=>{
        let pageFile = book.toc[pageNumber].href
        pageFile = pageFile.includes('OPS') ? pageFile : 'OPS/xhtml/'+ pageFile
        urls.push(BASE_URL + "find/one/content?id="+id+'&page='+pageFile)
      })

      Promise.all(
        urls.map(url =>
          fetch(url)
            .then(res => res.json())
            .then(data => data.content)
        )
      )
      .then(all3PagesContent=> {
        all3PagesContent.forEach((pageContent, index)=>{
            tempSlides[pagesToGet[index]] = pageContent
        })
        setSlides(tempSlides)
      });

    }
  }

  //handle click outside menus
  //account menu
  useEffect(() => {
      if (showAccountMenu){
          setTimeout(()=>{
              document.addEventListener("click", handleOutsideAccountMenu, false);
          },300)
          return () => {
              document.removeEventListener("click", handleOutsideAccountMenu, false);
          };
      }
  }, [showAccountMenu]);


  const handleOutsideAccountMenu = event => {
    if(accountMenuRef.current && !accountMenuRef.current.contains(event.target)){
        setShowAccountMenu(false)
    }
  }

  //context menu
  useEffect(() => {
    if (showContextMenu){
        setTimeout(()=>{
            document.addEventListener("click", handleOutsideContextMenu, false);
        },300)
        return () => {
            document.removeEventListener("click", handleOutsideContextMenu, false);
        };
    }
  }, [showContextMenu]);


  const handleOutsideContextMenu = event => {
    if(contextMenuRef.current && !contextMenuRef.current.contains(event.target)){
        setShowContextMenu(false)
        handlePageClick(event)
    }
  }

  const openChatWindow = () => {
    setShowChatWindow(true)
  }

  const closeChatWindow = () => {
    setShowChatWindow(false)
  }

  const handleShowModule = (type) => {
    setExperienceType(type)
    setShowModule(true)
  }

  const handleHideModule = () => {
    setShowModule(false)
  }


  return (
    <>
      <FlashcardsModule
          handleHideModule={handleHideModule}
          showModule={showModule}
          experienceType={experienceType}
      />
      {/* <Chatbot 
        onClick={openChatWindow}
      /> */}
      {
        showChatWindow ? 
        <ChatbotWindow
          onClick={closeChatWindow}
        /> 
        : null
      }
    <div className="app">
       <Tooltips
          prevPage={prevPage}
          nextPage={nextPage}
       />
      {showAccountMenu === true ? <AccountMenu accountMenuRef={accountMenuRef}/> : null}
      {showContextMenu === true ? <ContextMenu popUpLocation={popUpLocation} setCustomizeMenu={setCustomizeMenu} setShowContextMenu={setShowContextMenu} contextMenuRef={contextMenuRef}/> : null}
      {showBookmarkMenu === true ? <BookmarkMenu loadPage={loadPage}/> : null}
      {showPlaylistSettings? <PlaylistSettings closePlaylistSettings={closePlaylistSettings}/> : null}
      {customizeMenu === true ? <CustomizeMenu closeCustomizeMenu={closeCustomizeMenu}/> : null}
      <div className='main'>
          <Toolbar 
            openLeft={openLeft}
            closeLeft={closeLeft}
            showLeftDrawer={showLeftDrawer}
            tabShowing={tabShowing}
            setTabShowing={setTabShowing}
            // toggleToolbarExpanded={toggleToolbarExpanded}
            // toolbarExpanded={toolbarExpanded}
            cleanMode={cleanMode}
          />
          <LeftDrawerNew
            tabShowing={tabShowing}
            miniDrawer={miniDrawer}
            setMiniDrawer={setMiniDrawer}
            setTabShowing={setTabShowing}
            book={book} 
            showLeftDrawer={showLeftDrawer}
            toggleLeft={toggleLeft}
            closeLeft={closeLeft}
            cleanMode={cleanMode}
            page={page} 
            // lastPage={lastPage}
            loadPage={loadPage}
            openPlaylistSettings={openPlaylistSettings}
            currentChapter={currentChapter}
            handleShowModule={handleShowModule}
          />
          <ReaderNew
            testContent={testContent}
            // book={book} 
            // slides={slides} 
            page={page} 
            // lastPage={lastPage}
            currentChapter={currentChapter}
            drawerViewState={drawerViewState}
            // loadPage={loadPage}
            handlePageClick={handlePageClick}
            bookmarkMenuToggle={bookmarkMenuToggle}
            cleanMode={cleanMode}
            cleanModeToggle={cleanModeToggle}
            tabShowing={tabShowing}
            miniDrawer={miniDrawer}
            showAccountMenu={showAccountMenu}
            setShowAccountMenu={setShowAccountMenu}
          />
          <FooterNew
            // book={book} 
            // slides={slides} 
            page={page} 
            // lastPage={lastPage}
            // currentChapter={currentChapter}
            drawerViewState={drawerViewState}
            loadPage={loadPage}
            handlePageClick={handlePageClick}
            bookmarkMenuToggle={bookmarkMenuToggle}
            cleanMode={cleanMode}
            cleanModeToggle={cleanModeToggle}
            tabShowing={tabShowing}
            miniDrawer={miniDrawer}
            showAccountMenu={showAccountMenu}
            setShowAccountMenu={setShowAccountMenu}
          />
      </div>
      
    </div>
    </>
  );
}

const mapStateToProps = ({selectedSection, drawerSelectedSection}) => ({
  selectedSection, drawerSelectedSection
});

const mapDispatchToProps = dispatch => ({
  addRecent: (newRecent) => dispatch({type: 'ADD_RECENT', newRecent:newRecent}),
  setSelected: (data) => dispatch({type: 'SET_SELECTED', data:data}),
  setDrawerSelectedSection: (data) => dispatch({type: 'SET_DRAWER_SELECTED', data:data}),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(App);
