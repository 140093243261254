export const colors = {
    textlightThemeDefault: '#020917',
    textdarkThemeDefault: '#FEFEFE',
    textdarkThemeMuted: '#989BA3',
    swatchLight600: '#BCC1CB',
    swatchSepia900: '#1D1B19',
    SwatchDark700:'#393F4A',
    swatchDark800: '#272D39',
    swatchSecondary700: '#4C406B',
    border: '#DDE3EE',
    backgroundlightthemebglevel0: '#FEFEFE',
    backgroundlightthemebglevel1: '#F7F9FD',
    backgroundlightthemebglevel2: '#E8EEFA',
    backgrounddarkthemebglevel0:'#020917',
    borderlightThemeborder: '#DDE3EE',
    borderdarkThemeborder: '#393F4A',
    primaryTintedWhite: '#F7F9FD'

}

export const DONUT_COLORS = ['#898D93', '#AE90FF', '#01CBB7'];
export const BAR_COLORS = ['#898D93', '#E9DDFF', '#D1BCFF', '#B79BFF', '#9B81E2', '#01CBB7'];