import React, {useState} from 'react';
// import {Slider} from '@material-ui/core'

// import lh1 from "../images/icons/lh1.svg";
// import lh2 from "../images/icons/lh2.svg";
// import lh3 from "../images/icons/lh3.svg";
// import lh1Active from "../images/icons/lh1-active.svg";
// import lh2Active from "../images/icons/lh2-active.svg";
// import lh3Active from "../images/icons/lh3-active.svg";
import {connect} from "react-redux";

const DisplaySettings = props => {

    const toggleReadAlong = () => {
    //    if(props.read_along === true){
    //         props.turnOffReadAlong()
    //         // if(props.playlist.playing === true){
    //         //     props.setPlaying(false)
    //         // }
    //    }else{
    //         props.turnOnReadAlong()
    //    }
    }

    const themes = ['light', 'sepia', 'dark']
    const [textScale, setTextScale] = React.useState(props.display_settings.textScale)

    const handleChangeColor = (color) => {
        // props.changeColorMode(color)
        // themes.forEach(theme=>{
        //     props.drawerRef.current.classList.contains(theme)
        //     props.drawerRef.current.classList.remove(theme)
        // })
        // props.drawerRef.current.classList.add(color)
    }

    const handleSlideTextScale = (event, newValue) => {
        setTextScale(newValue)
    }

    const handleChangeTextScale = (event, newValue) => {
        props.changeTextScale(newValue)
    }

    return (
        <div className={`display-settings drawer-panel ${props.display_settings.colorMode}`}>
            <div className='category color-mode'>
                <p>Color mode</p>
                <div>
                    <div className='input-container'>
                        <button
                            id="light"
                            onClick={()=>{handleChangeColor('light')}}
                            className={`light button ${props.display_settings.colorMode === 'light' ? 'active' : ''}`}
                        >
                            <span>AaBbCcDd</span>
                        </button>
                        <label
                            htmlFor="light"
                            className={`label ${props.display_settings.colorMode === 'light' ? 'active' : ''}`}
                        > Light </label>
                    </div>

                    <div className='input-container'>
                        <button
                            id="sepia"
                            onClick={()=>{handleChangeColor('sepia')}}
                            className={`sepia button ${props.display_settings.colorMode === 'sepia' ? 'active' : ''}`}
                        >
                            <span>AaBbCcDd</span>
                        </button>
                        <label
                            htmlFor="sepia"
                            className={`label ${props.display_settings.colorMode === 'sepia' ? 'active' : ''}`}
                        > Sepia </label>
                    </div>

                    <div className='input-container'>
                        <button
                            id="dark"
                            onClick={()=>{handleChangeColor('dark')}}
                            className={`dark button ${props.display_settings.colorMode === 'dark' ? 'active' : ''}`}
                        >
                            <span>AaBbCcDd</span>
                        </button>
                        <label
                            htmlFor="dark"
                            className={`label ${props.display_settings.colorMode === 'dark' ? 'active' : ''}`}
                        > Dark </label>
                    </div>
                </div>
            </div>
            {/* <div className='category page-progression'>
                <p>Page progression</p>
                <div className='input-container'>
                    <input type="radio" id="scroll" name="page" value="scroll" defaultChecked/>
                    <label htmlFor="scroll">
                        <span>Scroll</span>
                    </label>
                    <input type="radio" id="click" name="page" value="click"/>
                    <label htmlFor="click">
                        <span>Click</span>
                    </label>
                </div>
            </div> */}
            <div className="category text-scale">
                <p>Text size</p>
                <div className='input-container'>
                    <span className="littleA"> </span>
                    {/* <Slider
                        value={textScale}
                        min={0}
                        max={12}
                        onChange={handleSlideTextScale}
                        onChangeCommitted={handleChangeTextScale}
                    /> */}
                    <span className="bigA"> </span>
                </div>
            </div>
            {/* <div className='category font-options'>
                <p>Font options</p>
                <div className='input-container'>
                    <ul>
                        <li className={`default ${props.display_settings.font === 'Default' ? 'active' : ''}`} onClick={()=>{props.changeFont('Default')}}>Default</li>
                        <li className={`verdana ${props.display_settings.font === 'Verdana' ? 'active' : ''}`} onClick={()=>{props.changeFont('Verdana')}}>Verdana</li>
                        <li className={`arial ${props.display_settings.font === 'Arial' ? 'active' : ''}`} onClick={()=>{props.changeFont('Arial')}}>Arial</li>
                        <li className={`times ${props.display_settings.font === 'Times New Roman' ? 'active' : ''}`} onClick={()=>{props.changeFont('Times New Roman')}}>Times New Roman</li>
                        <li className={`sassoon ${props.display_settings.font === 'Sassoon Sans' ? 'active' : ''}`} onClick={()=>{props.changeFont('Sassoon Sans')}}>Sassoon Sans</li>
                    </ul>
                </div>
            </div> */}
            {/* <div className='category character-case'>
                <p>Character case</p>
                <div className='input-container'>
                    <input type="radio" id="sentence-case" name="character case" value="sentence-case" defaultChecked onClick={()=>{props.changeCharacterCase('none')}}/>
                    <label htmlFor="sentence-case">
                        <span>Abcd</span>
                    </label>
                    <input type="radio" id="upper-case" name="character case" value="upper-case" onClick={()=>{props.changeCharacterCase('uppercase')}}/>
                    <label htmlFor="upper-case">
                        <span>ABCD</span>
                    </label>
                </div>
            </div> */}
            {/* <div className='category line-spacing'>
                <p>Line spacing</p>
                <div className='input-container'>
                    <input type="radio" id="line-height-1x" name="line spacing" value="line-height-1x" defaultChecked onClick={()=>{props.changeLineSpacing(2)}}/>
                    <label htmlFor="line-height-1x">
                        <img src={props.display_settings.lineSpacing === 2 ? lh1Active : lh1}/>
                    </label>
                    <input type="radio" id="line-height-2x" name="line spacing" value="line-height-2x" onClick={()=>{props.changeLineSpacing(3)}}/>
                    <label htmlFor="line-height-2x">
                        <img src={props.display_settings.lineSpacing === 3 ? lh2Active : lh2}/>
                    </label>
                    <input type="radio" id="line-height-3x" name="line spacing" value="line-height-3x" onClick={()=>{props.changeLineSpacing(4)}}/>
                    <label htmlFor="line-height-3x">
                        <img src={props.display_settings.lineSpacing === 4 ? lh3Active : lh3}/>
                    </label>
                </div>
            </div> */}
            <div className='category show-highlights'>
                <p>Visibility</p>
                <div className='toggle-container'>
                    <label htmlFor="highlights-toggle" id="highlights-toggle">Show highlights</label>
                    <button
                        id="highlights-toggle"
                        className="toggle on"
                        name="highlights-toggle"
                        role="switch"
                        aria-labelledby="highlights-toggle"
                        aria-checked='true'
                        onClick={(event)=>{
                            if(event.target.getAttribute('aria-checked') === 'true'){
                                event.target.setAttribute('aria-checked', 'false')
                            }else{
                                event.target.setAttribute('aria-checked', 'true')
                            }
                        }}
                    >
                    </button>
                </div>
                {/* <div className='toggle-container'>
                    <label htmlFor="readalong-toggle" id="readalong-toggle">Show read along </label>
                    <button
                        id="readalong-toggle"
                        className={`toggle ${props.read_along === true ? 'on' : ''}`}
                        name="readalong-toggle"
                        role="switch"
                        aria-labelledby="readalong-toggle"
                        aria-checked='true'
                         onClick={toggleReadAlong}
                    >
                    </button>
                </div> */}
            </div>
        </div>
    );
}


const mapStateToProps = ({display_settings, playlist}) => ({
    display_settings, playlist
});

const mapDispatchToProps = dispatch => ({
    changeColorMode: (colorMode) => dispatch({type: 'COLOR_MODE', colorMode:colorMode}),
    changeTextScale: (textScale) => dispatch({type: 'TEXT_SCALE', textScale:textScale}),
    changeFont: (font) => dispatch({type: 'FONT', font:font}),
    changeCharacterCase: (characterCase) => dispatch({type: 'CHAR_CASE', characterCase:characterCase}),
    changeLineSpacing: (lineSpacing) => dispatch({type: 'LINE_SPACE', lineSpacing:lineSpacing}),
    turnOnReadAlong: () => dispatch({type: 'READ_ALONG_ON'}),
    turnOffReadAlong: () => dispatch({type: 'READ_ALONG_OFF'}),
    setPlaying: (data) => dispatch({type: 'SET_PLAYING', data:data}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DisplaySettings);