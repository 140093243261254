import BOOKS_URL from "./BOOKS_URL"

export const getBookData = async (bookId) => {
    const resp = await fetch(`${BOOKS_URL}/book/${bookId}`);
    // console.log(resp)
    if (resp.ok) {
        const json = await resp.json();
        // console.log(json)
        return json
        // return resp
    }
}