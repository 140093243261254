import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import {colors} from '../../global_styles/colors'
import {PrimaryButton, GradientButton, LinkButton} from '../../global_styles/GlobalStyles'
import useWindowResize from '../../util/useWindowResize'
import Header from './Header'
import Flashcard from "./Flashcard"
import SelfScore from './SelfScore';
import StatsDrawer from './StatsDrawer';
import {shuffle} from "../../util/shuffle"
import { useSelector } from 'react-redux';
import {setTermsDeck} from '../../redux/termsDeckSlice'
import { useDispatch } from 'react-redux';
import { updateTermsState } from '../../redux/termsStateSlice';
import mcqStateSlice, { updateMCQState } from '../../redux/mcqStateSlice';
import {setSavedTermsScore, setSavedTermsViewed} from "../../redux/termsDeckSlice"
import {setSavedMCQScore, setSavedMCQViewed} from "../../redux/mcqDeckSlice"
import { useSpring, animated } from "@react-spring/web";
import CommentModal from "../CommentModal"
import MCQFlashcard from "./MCQFlashcard"


const ModuleContainer = styled.div`
    display: flex;
    width:100%;
    height:100vh;
    overflow:hidden;
    font-size:30px;
    z-index:5000;
    position:relative;
    background-color:${colors.backgroundlightthemebglevel0};
`

const ActivityWrapper = styled.div`
    // align-items: center;
    // justify-content:space-between;
    // display: flex;
    flex: 1;
    // flex-direction: column;
    padding:32px 24px;
    width: calc(100vw - 80px);
    @media(max-width:560px){
        padding:32px 8px;
    }
`

const PreviousButton = styled(LinkButton)`
    margin-top:24px;
`

const NextButton = styled(GradientButton)`
    margin: 0 auto;
    width: 136px;
    &[hidden]{
        display:none;
    }
`

const SubmitButton = styled(GradientButton)`

`

const SkipButton = styled(LinkButton)`
    margin-top:24px;
`

function FlashcardsModule(props){

        const selectedSection = useSelector((state) => state.selectedSection);
        const chapters = useSelector((state) => state.chapters);
        const glossary = useSelector((state) => state.glossary);
        const termsDeck = useSelector((state) => state.termsDeck);
        const mcqDeck = useSelector((state) => state.mcqDeck);
        const termsState = useSelector((state) => state.termsState);
        const mcqState = useSelector((state) => state.mcqState);

        const dispatch = useDispatch();

        const [width, height] = useWindowResize()

        const [drawerOpen, setDrawerOpen] = useState(false)
        const [currentCard, setCurrentCard] = useState(0)
        const [showDefinition, setShowDefinition] = useState(false)
        const [firstTimeReveal, setFirstTimeReveal] = useState(false)
        const [score, setScore] = useState(null)
        const [previousScore, setPreviousScore] = useState(null)
        const [animateScoreFast, setAnimateScoreFast] = useState(null)
        const [animateScoreSlower, setAnimateScoreSlower] = useState(null)
        const [showCommentModal, setShowCommentModal] = useState(null)
        const [deck, setDeck] = useState([])
        const [selectedAnswer, setSelectedAnswer] = useState(null)
        const [showAnswer, setShowAnswer] = useState(false)

        const moduleContainerRef = useRef(null)
        const headerTitleRef = useRef(null)
        const headerCloseBtnRef = useRef(null)

        useEffect(()=>{
            if(props.experienceType){
                if(props.experienceType === 'mcq'){
                    setDeck(mcqDeck)
                }else{
                    setDeck(termsDeck)
                }
            }
        },[props.experienceType, termsDeck, mcqDeck])

        //on window resize
        useEffect(()=>{
            let root = moduleContainerRef.current
            let typeTitle = headerTitleRef.current.querySelector('span')
            let h1 = headerTitleRef.current.querySelector('h1')
            let closeBtn = headerCloseBtnRef.current
            if(window.innerWidth <= 1400){
                root.style.fontSize = "30px";
            }
            if(drawerOpen){
                // if(window.innerWidth <= 1050){
                //     setDrawerOpen(false)
                // }
                if(window.innerWidth >= 1340){
                    closeBtn.style.position = 'absolute'
                    closeBtn.style.top = '27px'
                    closeBtn.style.right = '24px'
                }
                if(window.innerWidth <= 1340){
                    closeBtn.style.position = 'relative'
                    closeBtn.style.top = 'unset'
                    closeBtn.style.right = 'unset'
                }
            }else{
                if(window.innerWidth >= 900){
                    h1.style.fontSize = "1em"
                    typeTitle.style.fontSize = "0.8em"
                    // h1.style.textAlign = "center"
                    // h1.style.marginLeft = "unset"
                }
                if(window.innerWidth <= 900 && window.innerWidth >= 630){
                    h1.style.fontSize = "0.8em"
                    typeTitle.style.fontSize = "0.6em"
                    // h1.style.textAlign = "left"
                    // h1.style.marginLeft = "24px"
                }
                if(window.innerWidth >= 920){
                    closeBtn.style.position = 'absolute'
                    closeBtn.style.top = '27px'
                    closeBtn.style.right = '24px'
                }
                if(window.innerWidth <= 920){
                    closeBtn.style.position = 'relative'
                    closeBtn.style.top = 'unset'
                    closeBtn.style.right = 'unset'
                }
                if(window.innerWidth <= 630){
                    // h1.style.textAlign = "left"
                    h1.style.fontSize = "0.6em"
                    typeTitle.style.fontSize = "0.6em"
                }
            }
        },[width, drawerOpen])

        useEffect(()=>{
            if(drawerOpen){
                if(window.innerWidth <= 1050){
                    setDrawerOpen(false)
                }
            }else{
                if(window.innerWidth >= 1050){
                    setDrawerOpen(true)
                }
            }
        },[width])

        useEffect(()=>{
            document.addEventListener('keydown', handleKeyPress);
             // remove the event listener
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        },[showDefinition, firstTimeReveal, score])

        useEffect(()=>{
            if(props.showModule === true && window.innerWidth >= 1050){
                setTimeout(()=>{
                    setDrawerOpen(true)
                },[800])
            }
        },[props.showModule])

        useEffect(()=>{
            // if(deck[currentCard]){
            //     console.log(deck[currentCard].score)
            // }
            if(deck[currentCard] && deck[currentCard].score){
                setPreviousScore(deck[currentCard].score)
                setScore(deck[currentCard].score)
                setFirstTimeReveal(true)
            }
        },[currentCard])

        const handleKeyPress = (e) => {
                //spacebar: reveal/hide Definition
                // console.log(e.key)
                if(e.key == " "){
                    if(showDefinition === false){
                        handleReveal()
                    }else{
                        handleHide()
                    }
                }
                //1 - 5 number keys: set confidence rating if not disabled
                if(['1', '2', '3', '4', '5'].some(value => value === e.key)){
                    if(firstTimeReveal === true){
                        // console.log(Number(e.key))
                        setScore(Number(e.key))
                    }
                }
                //Enter: Next card if not disabled
                if(e.key === "Enter"){
                    if(score != null){
                        handleNext()
                    }
                }
        }

        const handleReveal = () => {
            setShowDefinition(true)
            if(firstTimeReveal !== true){
                setFirstTimeReveal(true)
            }
        }

        const handleHide = () => {
            setShowDefinition(false)
        }

        const toggleDrawer = () => {
            if(window.innerWidth >= 1050){
                setDrawerOpen(!drawerOpen)
            }
        }

        const handleScoring = (e) => {
            let newScore = Number(e.target.innerText)
            setScore(newScore)
            
            setAnimateScoreFast(true)
            setAnimateScoreSlower(true)

            let payload = {...termsState}
            if(previousScore){
                let key = `rating${previousScore}`
                if(previousScore === 5){
                    key = 'memorized'
                }
                payload[key] = termsState[key] - 1
            }

            if(deck[currentCard].viewed === undefined){
                dispatch(setSavedTermsViewed({index: currentCard, viewed: true}))
                payload.notViewed = termsState.notViewed - 1
            }

            setPreviousScore(newScore)

            if(newScore === 1){
                payload.rating1 = payload.rating1 + 1
            }
            if(newScore === 2){
                payload.rating2 = payload.rating2 + 1
            }
            if(newScore === 3){
                payload.rating3 = payload.rating3 + 1
            }
            if(newScore === 4){
                payload.rating4 = payload.rating4 + 1
            }
            if(newScore === 5){
                payload.memorized = payload.memorized + 1
            }
            
            dispatch(updateTermsState(payload))
            dispatch(setSavedTermsScore({index: currentCard, score:newScore}))
            

            setTimeout(()=>{
                setAnimateScoreFast(false)
            },100)

            setTimeout(()=>{
                setAnimateScoreSlower(false)
            },400)
        }

        const handleSubmitMCQ = () => {
            let payload = {...mcqState}
            if(selectedAnswer === deck[currentCard].answer){
                payload.correct = payload.correct + 1
                // dispatch(setSavedMCQScore({index: currentCard, correct: true}))
            }else{
                payload.incorrect = payload.incorrect + 1
                // dispatch(setSavedMCQScore({index: currentCard, correct: false}))
            }
            payload.notViewed = payload.notViewed - 1
            dispatch(updateMCQState(payload))

            // if(deck[currentCard].viewed === undefined){
            //     dispatch(setSavedMCQViewed({index: currentCard, viewed: true}))
            // }
            setShowAnswer(true)
        }

        const handleNext = () => {
            if(props.experienceType === 'terms'){
                setAnimateScoreSlower(false)
                setScore(null)
                setPreviousScore(null)
                setFirstTimeReveal(false)
            }else{
                setShowAnswer(false)
                setSelectedAnswer(null)
            }
            if(currentCard === deck.length - 1){
                props.handleHideModule()
                setCurrentCard(0)
            }else{
                setCurrentCard(currentCard + 1)
            }
        }

        const handlePrevious = () => {
            setScore(null)
            setPreviousScore(null)
            setShowDefinition(false)
            setCurrentCard(currentCard - 1)
            setFirstTimeReveal(false)
        }

        const handleCloseModal = () => {
            setShowCommentModal(null) //no value = false
        }

        const handleModalType = (type) => {
            setShowCommentModal(type) //value = true
        }

        const springProps = useSpring({
            transform: props.showModule ? 'scale(1)' : 'scale(0)',
            transformOrigin: 'center',
            pointerEvents: props.showModule ? 'auto' : 'none',
            transition: 'transform 200ms linear',
            width:'100%',
            height:'100vh',
            zIndex:'5000',
            position:'absolute',
            top:'0px',
            left:'0px'
        });

        return(
            <animated.div style={springProps}>
                <ModuleContainer
                    ref={moduleContainerRef}
                    className='enter'
                >
                    {
                        showCommentModal != null? 
                        <CommentModal 
                            type={showCommentModal}
                            handleCloseModal={handleCloseModal}
                            handleModalType={handleModalType}
                        />
                        : null
                    }
                    
                    <StatsDrawer
                        drawerOpen={drawerOpen}
                        toggleDrawer={toggleDrawer}
                        data={deck}
                        count={deck.length}
                        currentCard={currentCard + 1}
                        animateScoreFast={animateScoreFast}
                        animateScoreSlower={animateScoreSlower}
                        score={score}
                        experienceType={props.experienceType}
                        deck={deck}
                    />
                    <ActivityWrapper>
                        <Header
                            chapter={selectedSection.name}
                            count={deck.length}
                            currentCard={currentCard + 1}
                            headerTitleRef={headerTitleRef}
                            headerCloseBtnRef={headerCloseBtnRef}
                            handleHideModule={()=>{
                                props.handleHideModule()
                                setCurrentCard(0)
                            }}
                            experienceType={props.experienceType}
                        />
                        {/* <Deck> */}
                        {
                            deck.length > 0 ? 
                            deck.map((card, index)=>{
                                        if(props.experienceType === 'mcq'){
                                            return (
                                                <MCQFlashcard
                                                    key={index}
                                                    showAnswer={showAnswer}
                                                    handleReveal={handleReveal}
                                                    handleHide={handleHide}
                                                    currentCard={currentCard}
                                                    index={index}
                                                    data={deck[currentCard]}
                                                    drawerOpen={drawerOpen}
                                                    handleModalType={handleModalType}
                                                    selectedAnswer={selectedAnswer}
                                                    setSelectedAnswer={setSelectedAnswer}
                                                    handleSubmitMCQ={handleSubmitMCQ}
                                                    handleNext={handleNext}
                                                />
                                            )
                                        }else{
                                            return(
                                                <Flashcard
                                                    key={index}
                                                    showDefinition={showDefinition}
                                                    handleReveal={handleReveal}
                                                    handleHide={handleHide}
                                                    currentCard={currentCard}
                                                    index={index}
                                                    data={deck[currentCard]}
                                                    drawerOpen={drawerOpen}
                                                    handleModalType={handleModalType}
                                                    handleNext={handleNext}
                                                    score={score}
                                                />
                                            )
                                        }
                                })
                                : null
                        }
                        {/* </Deck> */}
                        {
                            props.experienceType === 'terms' ? 
                            <SelfScore
                                firstTimeReveal={firstTimeReveal}
                                showDefinition={showDefinition}
                                score={score}
                                handleScoring={handleScoring}
                                handleNext={handleNext}
                                handlePrevious={handlePrevious}
                                currentCard={currentCard}
                                deckLength={deck.length}
                            />
                            : null
                        }
                        
                        <div>
                            {/* { */}
                                {/* firstTimeReveal && props.experienceType === 'terms' ?  */}
                                <NextButton
                                    disabled={
                                        props.experienceType === 'terms' && score  
                                        ||
                                        props.experienceType === 'mcq' && showAnswer ?
                                        false : true
                                    }
                                    onClick={handleNext}
                                    hidden={
                                        props.experienceType === 'terms' && !firstTimeReveal 
                                        ||
                                        props.experienceType === 'mcq' && !showAnswer
                                        ? true : false}
                                >
                                    {currentCard === deck.length - 1 ? 'Finish' : 'Next card'}
                                </NextButton>
                                {/* : null */}
                            {/* } */}
                            
                            {/* {
                                currentCard >= 1 && props.experienceType === 'terms' ? 
                                <PreviousButton
                                    onClick={handlePrevious}
                                >
                                        Previous card
                                </PreviousButton>
                                : null
                            } */}

                            {/* {
                                props.experienceType === 'mcq' ? 
                                <>
                                    <SubmitButton
                                        disabled={selectedAnswer ? false : true}
                                        onClick={showAnswer ? handleNext : handleSubmitMCQ}
                                    >
                                        {showAnswer ? 'Next' : 'Submit'}
                                    </SubmitButton>
                                    <SkipButton
                                        disabled={showAnswer ? true : false}
                                        onClick={handleNext}
                                    >
                                        Skip
                                    </SkipButton>
                                </>
                                : null
                            } */}
                        </div>
                        
                    </ActivityWrapper>
                </ModuleContainer>
            </animated.div>
        )
}   


export default FlashcardsModule;