import './chatbot_window.css'

function ChatbotWindow (props) {

    return (
        <div class="chatbot-container open">
            <div class="chatbot-body">
                <div class="chatbot-header">
                    <p>AI Study Tool <span>Beta</span></p>
                    <div class="chatbot-window-actions">
                    <button>
                        <img src="https://assets.codepen.io/1062952/expand.svg" alt="" />
                    </button>
                    <button class="close-button"
                        onClick={props.onClick}
                    >
                        <img src="https://assets.codepen.io/1062952/close.svg" alt="" />
                    </button>
                </div>
                </div>
                <div class="chatbot-chatarea">
                <p>Welcome back!</p>
                <p>I'm here to help with anything related to <b>Campbell Biology, 12e</b>. Make a request below or choose from these options:</p>
                <ul>
                    <li>
                        <button>Summarize</button>
                    </li>
                    <li>
                        <button>Practice</button>
                    </li>
                    <li>
                        <button>Explain</button>
                    </li>
                </ul>
                <div class="chatbot-message">
                    <p>How to start design system in Figma?</p>
                </div>
                <div class="chatbot-response">
                    <p>How to build a design system in Figma using Material UI components</p>
                    <p>1. Select an open source library.</p>
                    <p>2. Install the UI kit in Figma.</p>
                    <p>3. Customize the components in Figma.</p>
                    <p>4. Share the design system.</p>
                    <p>5. Hand the design system off.</p>
                    <p>6. Use the design system like a magic wand.</p>
                </div>
                </div>
                <div class="chatbot-actionsarea">
                <input type="text" placeholder="Type a message"/>
                <div class="chatbot-actions">
                    {/* <button>
                        <img src="https://assets.codepen.io/1062952/lightbulb.svg" alt="" />
                    </button> */}
                    <button class="secondary" disabled>New topic</button>
                    <button class="primary">Send</button>
                </div>
                <p>Don’t share personal data, and keep in mind that AI can be inaccurate. For more info, see our <a href="#">FAQs.</a></p>
                </div>
            </div>
            </div>
    )
}

export default ChatbotWindow