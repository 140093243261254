import React, { useState, useEffect, useRef } from 'react';
import {connect} from "react-redux";

//images
import playlistPlaying from "../../images/gifs/audio-small.gif"

const Playlist = props => {
    const [currentTrack, setCurrentTrack] = useState(props.book.toc[15].title)
    const [currentTrackIndex, setCurrentTrackIndex] = useState(16)
    const [currentChapterIndex, setCurrentChapterIndex] = useState(1)
    const [timeStamp, setTimeStamp] = useState(props.playlist.time)
    const [remainingTime, setRemainingTime] = useState(props.playlist.remaining)
    const [totalTime, setTotalTime] = useState(212)

    // const [controlsCollapsed, setControlsCollapsed] = useState(false)

    const [volume, setVolume] = useState(5)
    const [volumeOpen, setVolumeOpen] = useState(false)

    const [playbackOpen, setPlaybackOpen] = useState(false)
    const playbackSpeeds = [0.5, 0.75, 1, 1.25, 1.5, 2, 3]
    const [playbackSpeed, setPlaybackSpeed] = useState(playbackSpeeds[2])

    const [iconHover, setIconHover] = useState(false)

    const titleRef = useRef()

    useEffect(()=>{
        let allChapters = document.querySelectorAll('.chapter')
        allChapters.forEach(chapter=>{
            if(chapter.getAttribute('data-chapter') == currentChapterIndex){
                chapter.classList.add('active')
            }
        })
    },[])

    // const slowPan = () => {
    //     if(titleRef.current){
    //         let titleContainer = document.querySelector('.audio-playlist .title-container')
    //         let extraWidth = titleContainer.scrollWidth - titleContainer.offsetWidth
    //         setTimeout(() => {
    //             titleRef.current.style.left = `${0 - (extraWidth + 12)}px`
    //         }, 1000)
    //         setTimeout(() => {
    //             titleRef.current.style.left = 0;
    //         }, 7000)
    //     }
    // }

    const handleSlider = (newTime) => {
        let timeNum = parseInt(newTime)
        setTimeStamp(timeNum)
        const root = document.querySelector(":root");
        root.style.setProperty("--track-width", `${(timeNum * 100) / totalTime}%`);
        if (timeNum < totalTime && timeNum > 1) {
            props.setPosition((timeNum * 100) / totalTime)
            props.setCurrent(currentTrack)
            props.setTime(timeNum)
            setTimeStamp(timeNum)
            setRemainingTime(totalTime - timeNum)
            props.setRemaining(totalTime - timeNum)
        }
    }

    useEffect(() => {
        let sliderInterval;
        if (props.playlist.playing === true) {
            if(timeStamp < totalTime){
                sliderInterval = setInterval(() => {
                    let newTime;
                    setTimeStamp(prevState => {
                        newTime = prevState + 1
                        return prevState + 1
                    })
                    setRemainingTime(totalTime - newTime)
                    props.setRemaining(totalTime - newTime)
                    setTimeStamp(newTime)
                    props.setPosition((newTime * 100) / totalTime)
                    props.setTime(newTime)
                    const root = document.querySelector(":root");
                    root.style.setProperty("--track-width", `${((newTime * 100) / totalTime)}%`);
                }, 1000 / playbackSpeed);
            }else{
                props.setPlaying(false)
            }
            return () => clearInterval(sliderInterval);
        }
    }, [props.playlist.playing, timeStamp])

   useEffect(()=>{
    const root = document.querySelector(":root");
    root.style.setProperty("--volume-width", `${(volume * 100) / 12}%`);
   }, [volume])


    const getSections = (chapter, index) => {
        let newArr = []
        //if (props.chapters[index + 1]) {
        let limit;
        if(props.book.chapters[index + 1]){
            limit = props.book.chapters[index + 1].page
        }else{
            //placeholder to get chapter 28 sections (need to fix later)
            limit = 321;
        }
            let order = 0;
            for (let i = chapter.page + 1; i < limit; i++) {
                if (props.book.toc[i]) {
                    let chapter = index;
                    order += 1;
                    newArr.push(
                        <li key={i} className={`${currentTrackIndex === i ? 'active' : ''}`}>
                            {
                                currentTrackIndex === i ? 
                                <>
                                    {
                                        props.playlist.playing === true ?
                                            //pause icon/and playig gif
                                            <>
                                            {
                                                iconHover ? 
                                                <div className='play-icon-container' 
                                                    onMouseLeave={()=>{
                                                        setIconHover(false)
                                                    }}
                                                    onClick={()=>{props.setPlaying(false)}}
                                                    >
                                                    <svg class="MuiSvgIcon-root" viewBox="0 0 48 48" aria-hidden="true">
                                                        <path d="M26 33V15a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1zM14 15a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1h-6a1 1 0 0 1-1-1V15zm10 33c13.234 0 24-10.766 24-24C48 10.768 37.234 0 24 0S0 10.768 0 24c0 13.234 10.766 24 24 24z"></path>
                                                    </svg>
                                                </div> : 
                                                <img src={playlistPlaying} 
                                                    className='playlist-playing'
                                                    onMouseEnter={()=>{
                                                        setIconHover(true)
                                                    }}
                                                />
                                            }
                                            </>
                                        : 
                                        //play icon
                                        <div className='play-icon-container' onClick={()=>{props.setPlaying(true)}}>
                                            <svg class="MuiSvgIcon-root" viewBox="0 0 48 48" aria-hidden="true">
                                                <path d="M16 15a1.001 1.001 0 0 1 1.448-.894l18 9a1.002 1.002 0 0 1 0 1.788l-18 9a.995.995 0 0 1-.974-.044A1.001 1.001 0 0 1 16 33V15zm8 33c13.234 0 24-10.766 24-24S37.234 0 24 0 0 10.766 0 24s10.766 24 24 24z"></path>
                                            </svg>
                                        </div>
                                    }
                                </> : null
                            }
                            
                            <button data-index={i}
                                    data-chapter={chapter}
                                    data-order={order}
                                    id={props.book.toc[i].href.split('.')[0]}
                                    className={`${currentTrackIndex === i ? 'active' : ''} ${props.playlist.playing === true ? 'playing' : ''}`}
                                    onClick={(e) => {
                                        selectTrack(i, chapter)
                                    }}
                            >{props.book.toc[i].title}</button>
                        </li>
                    )
                }
            }
        //}
        return newArr
    }

    const expandItem = (e) => {
        let index = e.target.getAttribute('data-chapter')
        let menu = document.querySelector(`.menu-${index}`)
        if(menu.classList.contains('hidden')){
            menu.classList.remove('hidden')
            setTimeout(()=>{
                menu.style.transition='max-height .5s ease-in-out .05s, opacity .2s ease-in-out 0s'
            },300)
        }else{
            menu.classList.add('hidden')
            setTimeout(()=>{
                menu.style.transition='max-height .5s ease-in-out 0s, opacity .2s ease-in-out .2s'
            },300)
        }
        e.target.parentElement.classList.contains('expanded') ? e.target.parentElement.classList.remove('expanded') : e.target.parentElement.classList.add('expanded')
    }

    const selectTrack = (index, chapter) => {
        let newTrack = props.book.toc[index].title
        let chapterLi = document.querySelector(`.chapter[data-chapter='${chapter}']`)
        let allChapters = document.querySelectorAll('.chapter')
        if (currentTrack != newTrack) {
            setCurrentTrack(newTrack)
            setCurrentTrackIndex(index)
            setCurrentChapterIndex(parseInt(chapter))
            handleSlider(0)
            allChapters.forEach(chapterLi=>{chapterLi.classList.remove('active')})
            allChapters.forEach(chapterLi=>{chapterLi.classList.remove('playing')})
            chapterLi.classList.add('active')
            props.setPlaying(false)
            props.setPosition(0)
            props.setCurrent(currentTrack)
            props.setTime(0)
            props.setRemaining(212)
            setTimeout(() => {
                props.setPlaying(true)
                chapterLi.classList.add('playing')
            }, 300)
            props.loadPage(index)
            // props.scrollToPage()
            // if (newTrack.length > 45) {
            //     titleRef.current.style.transition = 'left 5s linear'
            //     setTimeout(() => {
            //         slowPan()
            //     }, 2000)
            //     window.interval = setInterval(() => {
            //         if (props.mini === false) {
            //             slowPan()
            //         }
            //     }, 13000);
            //     return () => clearInterval(window.interval);
            // } else {
            //     clearInterval(window.interval);
            //     titleRef.current.style.transition = 'unset'
            //     titleRef.current.style.left = 0;
            // }
        } else {
            setTimeout(() => {
                props.setPlaying(!props.playlist.playing)
                chapterLi.classList.contains('playing') ? chapterLi.classList.remove('playing') : chapterLi.classList.add('playing')
            }, 300)
        }
    }

    const handlePlayPauseBtn = () => {
        // let audioBtn = document.querySelector('.tools-btn.audioBtn')
        // let chapterLi = document.querySelector(`.chapter[data-chapter='${currentChapterIndex}']`)
        // if (props.playlist.playing === false) {
        //     audioBtn.classList.remove('playing-light')
        //     chapterLi.classList.remove('playing')
        // } else {
        //     if (props.display_settings.colorMode === 'light') {
        //         audioBtn.classList.add('playing-dark')
        //     } else {
        //         audioBtn.classList.add('playing-light')
        //     }
        //     chapterLi.classList.add('playing')
        // }
    }

    useEffect(()=>{
        handlePlayPauseBtn()
    },[props.playlist.playing])

    const handleSkip = (e) => {
        let newIndex = currentTrackIndex;
        let newChapter = currentChapterIndex;
        let currentChapterList = document.querySelectorAll(`.menu-${currentChapterIndex} li`)
        let currentTrackLi = document.querySelector(`[data-index="${currentTrackIndex}"]`)
        if (e.target.classList.contains('skip-forward') && currentTrackIndex !== 311) {
            newIndex = currentTrackIndex + 1
            if (currentChapterList.length === parseInt(currentTrackLi.getAttribute('data-order'))) {
                newIndex = currentTrackIndex + 2
                newChapter = currentChapterIndex + 1
            }
            selectTrack(newIndex, newChapter)
        } else if(e.target.classList.contains('skip-backward') && currentTrackIndex !== 15) {
            newIndex = currentTrackIndex - 1
            if (parseInt(currentTrackLi.getAttribute('data-order')) === 1) {
                newIndex = currentTrackIndex - 2
                if (currentChapterIndex !== 0) {
                    newChapter = currentChapterIndex - 1
                }
            }
            selectTrack(newIndex, newChapter)
        }
    }

    const handle10= (e) => {
        let newTime;
        if(e.target.classList.contains('forward') && timeStamp <= 202 ){
            newTime = timeStamp + 10
            handleSlider(newTime)
        }else if(e.target.classList.contains('rewind') && timeStamp >= 10){
            newTime = timeStamp - 10
            handleSlider(newTime)
        }
    }

    const handlePlayback = (e) => {
        let currentIndex, newPlayback;
        playbackSpeeds.forEach((speed, index) => {
            if(speed === playbackSpeed){
                currentIndex = index
            }
        })
        if(e.target.classList.contains('plus')){
            newPlayback = playbackSpeeds[currentIndex + 1]
        }else{
            newPlayback = playbackSpeeds[currentIndex - 1]
        }
        setPlaybackSpeed(newPlayback)
    }


    // const [lastScrollTop, setLastScrollTop] = useState(0)
    //
    // const handleScroll = (e) => {
    //     let pos = e.target.scrollTop;
    //     if (pos > lastScrollTop) {
    //         if (controlsCollapsed === false) {
    //             setControlsCollapsed(true)
    //         }
    //     } else if (pos < lastScrollTop) {
    //         if (controlsCollapsed === true) {
    //             setControlsCollapsed(false)
    //         }
    //     }
    //     setLastScrollTop(pos);
    // }



    return (
        <div className={`audio-playlist drawer-panel`}>
            {
                props.mini === false ?
                   <div className='title-container'>
                       <h2 className='title'
                           ref={titleRef}
                       >{currentTrack}</h2>
                   </div> : null 
            }
            <div className={`controls ${props.mini === true ? 'mini' : ''}`}>
                    {props.mini === false ?
                        <>
                            {/* <button className={`skip skip-backward ${volumeOpen === true ? 'hidden' : ''}`}
                                    onClick={handleSkip}
                            >
                            </button> */}
                            <button className={`rewind ${volumeOpen === true ? 'hidden' : ''}`}
                                    onClick={handle10}
                            >
                            </button>
                        </>
                    : null}
                    <button
                        className={`play-pause ${props.playlist.playing === true ? 'playing' : 'paused'}`}
                        onClick={() => {
                            // handlePlayPauseBtn()
                            props.setPlaying(!props.playlist.playing)
                        }}
                        data-tooltip-content={`${props.playlist.playing === true ? 'Pause audio' : 'Play audio'}`}
                    ></button>
                    {props.mini === false ?
                        <>
                            <button className={`forward ${playbackOpen === true ? 'hidden' : ''}`}
                                    onClick={handle10}
                            >
                            </button>
                            {/* <button className={`skip skip-forward ${playbackOpen === true ? 'hidden' : ''}`}
                                    onClick={handleSkip}
                            >
                            </button> */}
                            {/* <button className='settings' onClick={props.openPlaylistSettings}></button> */}
                        </>
                    : null}
            </div>
            {
            props.mini === false ?
                <button className='settings' onClick={props.openPlaylistSettings}></button>
                : null
            }
            {
                props.mini === false ?
                    <ul className='playlist'>
                        {
                            props.book.chapters.map((chapter, index) => {
                                return <li data-chapter={index}
                                            key={index}
                                            className='chapter'
                                            //className={currentChapterIndex === index ? 'active' : ''}
                                >
                                    <button data-chapter={index}
                                            data-index={chapter.page}
                                            id={chapter.href ? chapter.href.split('.')[0] : ''}
                                            onClick={expandItem}
                                    >
                                            {chapter.title}
                                    </button>
                                    <ul className={`sections hidden menu-${index}`}>
                                        {getSections(chapter, index)}
                                    </ul>
                                </li>

                            })
                        }
                    </ul>
                    : null
            }
            <div
                className={`slider-container ${props.mini === true ? 'mini' : ''}`}
                ref={props.sliderContainerRef}
            >
                    <span className='timestamp top'>
                        {new Date(timeStamp * 1000).toISOString().substr(15, 4)}
                    </span>
                <div className='input-container'>
                    <input
                        type="range"
                        min={0}
                        max={totalTime}
                        value={timeStamp}
                        onChange={(event => {
                            handleSlider(event.target.value)
                            props.showAudioHover()
                        })}
                        onMouseUp={(event) => {
                            props.hideAudioHover()
                        }}
                    />
                </div>
                <span className='timestamp'>
                        -{new Date(remainingTime * 1000).toISOString().substr(15, 4)}
                    </span>
            </div>
        </div>
    )
}


const mapStateToProps = ({playlist}) => ({
    playlist
});

const mapDispatchToProps = dispatch => ({
    setPlaying: (data) => dispatch({type: 'SET_PLAYING', data:data}),
    setPosition: (pos) => dispatch({type: 'SET_POSITION', pos:pos}),
    setCurrent: (current) => dispatch({type: 'SET_CURRENT', current:current}),
    setTime: (time) => dispatch({type: 'SET_TIME', time:time}),
    setRemaining: (remaining) => dispatch({type: 'SET_REMAINING', remaining:remaining}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Playlist);
