import { createSlice, current } from '@reduxjs/toolkit';

const playlistSlice = createSlice({
  name: "playlist",
  initialState: {
    playing:false,
    position:0,
    current: '',
    time:0,
    remaining:212
  },
  reducers: {
    setPlaying: (state, action) => {
      state.playing = action.payload.data
      return state;
    },
    setPosition: (state, action) => {
        state.position = action.payload.pos
        return state;
    },
    setCurrent: (state, action) => {
        state.current = action.payload.current
        return state;
    },
    setTime: (state, action) => {
        state.time = action.payload.time
        return state;
    },
    setRemaining: (state, action) => {
        state.remaining = action.payload.remaining
        return state;
    },
  }
});

export const {setPlaying, setPosition, setCurrent, setTime, setRemaining} = playlistSlice.actions;

export default playlistSlice.reducer;