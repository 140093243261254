import React, {useEffect, useState} from 'react';
import { useSpring, animated } from "@react-spring/web";
import {connect} from "react-redux";
import TOC from './TOC';
// import Scrubber from './Scrubber';
import Search from "./Search";
import Playlist from "./Playlist";
import Notebook from './Notebook';
import Flashcards from '../FlashcardsDrawer/FlashcardsDrawer';
import Channels from './Channels';
import DisplaySettings from './DisplaySettings'
import Posts from './Posts'

function LeftDrawer(props) {

    //the section selector in the drawer can have a different chapter or section
    //selected than the page thats displaying in the reader so it needs its own state
    //so that different drawer panels can share the same selected chapter or section without changing the page
    useEffect(()=>{
        if(props.selectedSection.type && props.selectedSection.type != "chapter"){
            //set to chapter 1
            props.setDrawerSelectedSection(props.book.toc[4])
        }
    },[props.selectedSection])

    const getWidth = () => {
        if(props.tabShowing === 'Scrubber'){
            return '86px'
        }
        else{
            return '416px'
        }
    }

  const springProps = useSpring({
    left: props.showLeftDrawer ? '70px' : '-416px',
    position: "absolute",
    top:'12px',
    height: "calc(100% - 90px)",
    width: getWidth(),
    immediate: true
  });

  return (
    <animated.div style={springProps}>
        <div className={`drawer left-drawer ${props.miniDrawer ? 'mini' : ''}`}>
            <div className='drawer-header'>
                {props.miniDrawer ? '' : <h2>{props.tabShowing}</h2>}

                {
                    props.tabShowing === 'Audiobook' ? 
                    <button 
                        className={`expand-collapse ${props.miniDrawer ? 'expand' : 'collapse'}`}
                        onClick={()=>{
                            props.setMiniDrawer(!props.miniDrawer)
                        }}
                        data-tooltip-content={`${props.miniDrawer === true ? 'View full screen' : 'View split screen'}`}
                    ></button> : null
                }

                {
                    !props.miniDrawer || (props.miniDrawer && props.tabShowing === 'Scrubber') ?
                    <button className='close' onClick={()=>{
                        props.closeLeft()
                        props.setTabShowing('')
                    }}></button> : null
                }
                

            </div>
            
            {
                props.tabShowing === 'Table of Contents' ? <>
                    <TOC
                        page={props.page}
                        currentChapter={props.currentChapter}
                        // lastPage={props.lastPage}
                        loadPage={props.loadPage}
                        book={props.book}
                    />
                </> : null
            } 

            {/* {
                props.tabShowing === 'Scrubber' ? <>
                    <Scrubber
                        page={props.page}
                        lastPage={props.lastPage}
                        loadPage={props.loadPage}
                        book={props.book}
                    />
                </> : null
            }  */}

            {
                props.tabShowing === 'Search' ? <>
                    <Search
                        book={props.book}
                    />
                </> : null
            } 

{
                props.tabShowing === 'Audiobook' ? <>
                    <Playlist
                        page={props.page}
                        lastPage={props.lastPage}
                        loadPage={props.loadPage}
                        book={props.book}
                        mini={props.miniDrawer}
                        openPlaylistSettings={props.openPlaylistSettings}
                    />
                </> : null
            } 

            {
                props.tabShowing === 'Notebook' ? <>
                    <Notebook/>
                </> : null
            } 

            {
                props.tabShowing === 'Flashcards' ? <>
                    <Flashcards
                        book={props.book}
                        handleShowModule={props.handleShowModule}
                        drawerSelectedSection={props.drawerSelectedSection}
                    />
                </> : null
            } 

            {   
                props.tabShowing === 'Channels Miniplayer' ? <>
                    <Channels
                        book={props.book}
                        drawerSelectedSection={props.drawerSelectedSection}
                    />
                </> : null
            }

            {   
                props.tabShowing === 'Community Posts' ? <>
                    <Posts/>
                </> : null
            }

             {   
                props.tabShowing === 'Display settings' ? <>
                    <DisplaySettings/>
                </> : null
            }         
        </div>
    </animated.div>
  );
}

const mapStateToProps = ({bookmarks, selectedSection, drawerSelectedSection}) => ({
  bookmarks, selectedSection, drawerSelectedSection
});

const mapDispatchToProps = dispatch => ({
    removeBookmark: (page) => dispatch({type: 'REMOVE_BOOKMARK',page:page}),
    setSelected: (data) => dispatch({type: 'SET_SELECTED', data:data}),
    setDrawerSelectedSection: (data) => dispatch({type: 'SET_DRAWER_SELECTED', data:data}),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftDrawer);