import { createSlice, current } from '@reduxjs/toolkit';

const mcqDeckSlice = createSlice({
  name: "mcqDeck",
  initialState: [],
  reducers: {
    setMCQDeck: (state, action) => {
      state = action.payload
      return state;
    },
    setSavedMCQScore: (state, action) => {
      state.forEach((question, index)=>{
        if(index === action.payload.index){
            question.correct = action.payload.correct
        }
      })
      return state;
    },
    setSavedMCQViewed: (state, action) => {
      state.forEach((question, index)=>{
        if(index === action.payload.index){
            question.viewed = action.payload.viewed
        }
      })
      return state;
    },
  }
});

export const { setMCQDeck, setSavedMCQScore, setSavedMCQViewed } = mcqDeckSlice.actions;

export default mcqDeckSlice.reducer;