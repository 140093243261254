export const glossary = [
  {
    "front": "5\u2032 cap",
    "back": "A modified form of guanine nucleotide added onto the 5\u2032 end of a pre-mRNA molecule."
  },
  {
    "front": "A site",
    "back": "One of a ribosome\u2019s three binding sites for tRNA during translation. The A site holds the tRNA carrying the next amino acid to be added to the polypeptide chain. (A stands for aminoacyl tRNA.)"
  },
  {
    "front": "ABC hypothesis",
    "back": "A model of flower formation identifying three classes of organ identity genes that direct formation of the four types of floral organs."
  },
  {
    "front": "Abiotic",
    "back": "(\u0101\u2032-b\u012b-ot\u2032-ik) Nonliving; referring to the physical and chemical properties of an environment."
  },
  {
    "front": "Abortion",
    "back": "The deliberate termination of a pregnancy in progress."
  },
  {
    "front": "Abscisic acid",
    "back": "(ABA) (ab-sis\u2032-ik) A plant hormone that slows growth, often antagonizing the actions of growth hormones. Two of its many effects are to promote seed dormancy and facilitate drought tolerance."
  },
  {
    "front": "Absorption",
    "back": "The third stage of food processing in animals: the uptake of small nutrient molecules by an organism\u2019s body."
  },
  {
    "front": "Absorption spectrum",
    "back": "The range of a pigment\u2019s ability to absorb various wavelengths of light; also a graph of such a range."
  },
  {
    "front": "Abyssal zone",
    "back": "(uh-bis\u2032-ul) The part of the ocean\u2019s benthic zone between 2,000 and 6,000 m deep."
  },
  {
    "front": "Acanthodians",
    "back": "(ak\u2032-an-th\u014d\u2032-d\u0113-un) Any of a group of ancient jawed aquatic vertebrates from the Silurian and Devonian periods."
  },
  {
    "front": "Accessory fruits",
    "back": "A fruit, or assemblage of fruits, in which the fleshy parts are derived largely or entirely from tissues other than the ovary."
  },
  {
    "front": "Acclimatization",
    "back": "(uh-kl\u012b\u2032-muh-t\u012b-z\u0101\u2032-shun) Physiological adjustment to a change in an environmental factor."
  },
  {
    "front": "Acetyl CoA",
    "back": "Acetyl coenzyme A; the entry compound for the citric acid cycle in cellular respiration, formed from a two-carbon fragment of pyruvate attached to a coenzyme."
  },
  {
    "front": "Acetylcholine",
    "back": "(as\u2032-uh-til-k\u014d\u2032-l\u0113n) One of the most common neurotransmitters; functions by binding to receptors and altering the permeability of the postsynaptic membrane to specific ions, either depolarizing or hyperpolarizing the membrane."
  },
  {
    "front": "Acid",
    "back": "A substance that increases the hydrogen ion concentration of a solution."
  },
  {
    "front": "Acquired immunodeficiency syndrome",
    "back": "(AIDS) The symptoms and signs present during the late stages of HIV infection, defined by a specified reduction in the number of T cells and the appearance of characteristic secondary infections."
  },
  {
    "front": "Acrosomal reaction",
    "back": "(ak\u2032-ruh-s\u014dm\u2032-ul) The discharge of hydrolytic enzymes from the acrosome, a vesicle in the tip of a sperm, when the sperm approaches or contacts an egg."
  },
  {
    "front": "Acrosome",
    "back": "(ak\u2032-ruh-s\u014dm) A vesicle in the tip of a sperm containing hydrolytic enzymes and other proteins that help the sperm reach the egg."
  },
  {
    "front": "Actin",
    "back": "(ak'-tin) A globular protein that links into chains, two of which twist helically about each other, forming microfilaments (actin filaments) in muscle and other kinds of cells."
  },
  {
    "front": "Action potentials",
    "back": "An electrical signal that propagates (travels) along the membrane of a neuron or other excitable cell as a nongraded (all-or-none) depolarization."
  },
  {
    "front": "Action spectrum",
    "back": "A graph that profiles the relative effectiveness of different wavelengths of radiation in driving a particular process."
  },
  {
    "front": "Activation energy",
    "back": "The amount of energy that reactants must absorb before a chemical reaction will start; also called free energy of activation."
  },
  {
    "front": "Activator",
    "back": "A protein that binds to DNA and stimulates gene transcription. In prokaryotes, activators bind in or near the promoter; in eukaryotes, activators generally bind to control elements in enhancers."
  },
  {
    "front": "Active immunity",
    "back": "Long-lasting immunity conferred by the action of B cells and T cells and the resulting B and T memory cells specific for a pathogen. Active immunity can develop as a result of natural infection or immunization."
  },
  {
    "front": "Active site",
    "back": "The specific region of an enzyme that binds the substrate and that forms the pocket in which catalysis occurs."
  },
  {
    "front": "Active transport",
    "back": "The movement of a substance across a cell membrane against its concentration or electrochemical gradient, mediated by specific transport proteins and requiring an expenditure of energy."
  },
  {
    "front": "Adaptations",
    "back": "Inherited characteristic of an organism that enhances its survival and reproduction in a specific environment."
  },
  {
    "front": "Adaptive evolution",
    "back": "A process in which traits that enhance survival or reproduction tend to increase in frequency over time, resulting in a better match between organisms and their environment."
  },
  {
    "front": "Adaptive immunity",
    "back": "A vertebrate-specific defense that is mediated by B lymphocytes (B cells) and T lymphocytes (T cells) and that exhibits specificity, memory, and self-nonself recognition; also called acquired immunity."
  },
  {
    "front": "Adaptive radiations",
    "back": "Period of evolutionary change in which groups of organisms form many new species whose adaptations allow them to fill different ecological roles in their communities."
  },
  {
    "front": "Addition rule",
    "back": "A rule of probability stating that the probability of any one of two or more mutually exclusive events occurring can be determined by adding their individual probabilities."
  },
  {
    "front": "Adenosine triphosphate",
    "back": "(a-den\u2032-\u014d-s\u0113n tr\u012b-fos\u2032-f\u0101t) An adenine-containing nucleoside triphosphate that releases free energy when its phosphate bonds are hydrolyzed. This energy is used to drive endergonic reactions in cells."
  },
  {
    "front": "Adenylyl cyclase",
    "back": "(uh-den\u2032-uh-lil) An enzyme that converts ATP to cyclic AMP in response to an extracellular signal."
  },
  {
    "front": "Adhesion",
    "back": "The clinging of one substance to another, such as water to plant cell walls, in this case by means of hydrogen bonds."
  },
  {
    "front": "Adipose tissue",
    "back": "A connective tissue that insulates the body and serves as a fuel reserve; contains fat-storing cells called adipose cells."
  },
  {
    "front": "Adrenal gland",
    "back": "(uh-dr\u0113\u2032-nul) One of two endocrine glands located adjacent to the kidneys in mammals. Endocrine cells in the outer portion (cortex) respond to adrenocorticotropic hormone (ACTH) by secreting steroid hormones that help maintain homeostasis during long-term stress. Neurosecretory cells in the central portion (medulla) secrete epinephrine and norepinephrine in response to nerve signals triggered by short-term stress."
  },
  {
    "front": "Aerobic respiration",
    "back": "A catabolic pathway for organic molecules, using oxygen as the final electron acceptor in an electron transport chain and ultimately producing ATP. This is the most efficient catabolic pathway and is carried out in most eukaryotic cells and many prokaryotic organisms."
  },
  {
    "front": "Age structure",
    "back": "The relative number of individuals of each age in a population."
  },
  {
    "front": "Aggregate fruit",
    "back": "A fruit derived from a single flower that has more than one carpel."
  },
  {
    "front": "AIDS",
    "back": "(acquired immunodeficiency syndrome) The symptoms and signs present during the late stages of HIV infection, defined by a specified reduction in the number of T cells and the appearance of characteristic secondary infections."
  },
  {
    "front": "Alcohol fermentation",
    "back": "Glycolysis followed by the reduction of pyruvate to ethyl alcohol, regenerating NAD-plus and releasing carbon dioxide."
  },
  {
    "front": "Algae",
    "back": "(plural,algae) A general term for any species of photosynthetic protist, including both unicellular and multicellular forms. Algal species are included in three eukaryote supergroups (Excavata, SAR, and Archaeplastida)."
  },
  {
    "front": "Alimentary canal",
    "back": "(al\u2032-uh-men\u2032-tuh-r\u0113) A complete digestive tract, consisting of a tube running between a mouth and an anus."
  },
  {
    "front": "Alkaline vents",
    "back": "A deep-sea hydrothermal vent that releases water that is warm (40 to 90 degrees C) rather than hot and that has a high pH (is basic). These vents consist of tiny pores lined with iron and other catalytic minerals that some scientists hypothesize might have been the location of the earliest abiotic synthesis of organic compounds."
  },
  {
    "front": "Alleles",
    "back": "(uh-l\u0113\u2032-ul) Any of the alternative versions of a gene that may produce distinguishable phenotypic effects."
  },
  {
    "front": "Allopatric speciation",
    "back": "(al\u2032-uh-pat\u2032-rik) The formation of new species in populations that are geographically isolated from one another."
  },
  {
    "front": "Allopolyploid",
    "back": "(al\u2032-\u014d-pol\u2032-\u0113-ployd) A fertile individual that has more than two chromosome sets as a result of two different species interbreeding and combining their chromosomes."
  },
  {
    "front": "Allosteric regulation",
    "back": "The binding of a regulatory molecule to a protein at one site that affects the function of the protein at a different site."
  },
  {
    "front": "Alpha helix",
    "back": "(al'-fuh h\u0113'-liks) A coiled region constituting one form of the secondary structure of proteins, arising from a specific pattern of hydrogen bonding between atoms of the polypeptide backbone (not the side chains)."
  },
  {
    "front": "Alternation of generations",
    "back": "A life cycle in which there is both a multicellular diploid form, the sporophyte, and a multicellular haploid form, the gametophyte; characteristic of plants and some algae."
  },
  {
    "front": "Alternative RNA splicing",
    "back": "A type of eukaryotic gene regulation at the RNA-processing level in which different mRNA molecules are produced from the same primary transcript, depending on which RNA segments are treated as exons and which as introns."
  },
  {
    "front": "Altruism",
    "back": "(al\u2032-tr\u016b-iz-um) Selflessness; behavior that reduces an individual\u2019s fitness while increasing the fitness of another individual."
  },
  {
    "front": "Alveolates",
    "back": "(al-v\u0113\u2032-uh-lets) One of the three major subgroups for which the SAR eukaryotic supergroup is named. This clade arose by secondary endosymbiosis, and its members have membrane-enclosed sacs (alveoli) located just under the plasma membrane."
  },
  {
    "front": "Alveoli",
    "back": "(al-v\u0113\u2032-uh-lus) (plural,alveoli) One of the dead-end air sacs where gas exchange occurs in a mammalian lung."
  },
  {
    "front": "Alzheimer\u2019s disease",
    "back": "(alts\u2032-h\u012b-merz) An age-related dementia (mental deterioration) characterized by confusion and memory loss."
  },
  {
    "front": "Amino acid",
    "back": "(uh-m\u0113n\u2032-\u014d) An organic molecule possessing both a carboxyl and an amino group. Amino acids serve as the monomers of polypeptides."
  },
  {
    "front": "Amino group",
    "back": "(uh-m\u0113n\u2219-\u014d) A chemical group consisting of a nitrogen atom bonded to two hydrogen atoms; can act as a base in solution, accepting a hydrogen ion and acquiring a charge of plus one."
  },
  {
    "front": "Aminoacyl-tRNA synthetases",
    "back": "An enzyme that joins each amino acid to the appropriate tRNA."
  },
  {
    "front": "Ammonia",
    "back": "A small, toxic molecule (NH subscript 3) produced by nitrogen fixation or as a metabolic waste product of protein and nucleic acid metabolism."
  },
  {
    "front": "Ammonites",
    "back": "A member of a group of shelled cephalopods that were important marine predators for hundreds of millions of years until their extinction at the end of the Cretaceous period (65.5 million years ago)."
  },
  {
    "front": "Amniocentesis",
    "back": "(am\u2032-n\u0113-\u014d-sen-t\u0113\u2032-sis) A technique associated with prenatal diagnosis in which amniotic fluid is obtained by aspiration from a needle inserted into the uterus. The fluid and the fetal cells it contains are analyzed to detect certain genetic and congenital defects in the fetus."
  },
  {
    "front": "Amniotes",
    "back": "(am\u2032-n\u0113-\u014dt) A member of a clade of tetrapods named for a key derived character, the amniotic egg, which contains specialized membranes, including the fluid-filled amnion, that protect the embryo. Amniotes include mammals as well as birds and other reptiles."
  },
  {
    "front": "Amniotic egg",
    "back": "An egg that contains specialized membranes that function in protection, nourishment, and gas exchange. The amniotic egg was a major evolutionary innovation, allowing embryos to develop on land in a fluid-filled sac, thus reducing the dependence of tetrapods on water for reproduction."
  },
  {
    "front": "Amoebas",
    "back": "(uh-m\u0113\u2032-buh) A protist characterized by the presence of pseudopodia."
  },
  {
    "front": "Amoebocytes",
    "back": "(uh-m\u0113\u2032-buh-s\u012bt\u2032) An amoeba-like cell that moves by pseudopodia and is found in most animals. Depending on the species, it may digest and distribute food, dispose of wastes, form skeletal fibers, fight infections, or change into other cell types."
  },
  {
    "front": "Amoebozoan",
    "back": "(uh-m\u0113\u2032-buh-z\u014d\u2032-an) A protist in a clade that includes many species with lobe- or tube-shaped pseudopodia."
  },
  {
    "front": "Amphibians",
    "back": "A member of the clade of tetrapods that includes salamanders, frogs, and caecilians."
  },
  {
    "front": "Amphipathic",
    "back": "(am\u2032-f\u0113-path\u2032-ik) Having both a hydrophilic region and a hydrophobic region."
  },
  {
    "front": "Amplification",
    "back": "The strengthening of stimulus energy during transduction."
  },
  {
    "front": "Amygdala",
    "back": "(uh-mig\u2032-duh-luh) A structure in the temporal lobe of the vertebrate brain that has a major role in the processing of emotions."
  },
  {
    "front": "Amylase",
    "back": "(am\u2032-uh-l\u0101s\u2032) An enzyme that hydrolyzes starch (a glucose polymer from plants) and glycogen (a glucose polymer from animals) into smaller polysaccharides and the disaccharide maltose."
  },
  {
    "front": "Anabolic pathways",
    "back": "(an\u2032-uh-bol\u2032-ik) A metabolic pathway that consumes energy to synthesize a complex molecule from simpler molecules."
  },
  {
    "front": "Anaerobic respiration",
    "back": "(an-er-\u014d\u2032-bik) A catabolic pathway in which inorganic molecules other than oxygen accept electrons at the \u201cdownhill\u201d end of electron transport chains."
  },
  {
    "front": "Analogous",
    "back": "Having characteristics that are similar because of convergent evolution, not homology."
  },
  {
    "front": "Analogy",
    "back": "(an-al\u2032-uh-j\u0113) Similarity between two species that is due to convergent evolution rather than to descent from a common ancestor with the same trait."
  },
  {
    "front": "Anaphase",
    "back": "The fourth stage of mitosis, in which the chromatids of each chromosome have separated and the daughter chromosomes are moving to the poles of the cell."
  },
  {
    "front": "Anatomy",
    "back": "The structure of an organism."
  },
  {
    "front": "Anchorage dependence",
    "back": "The requirement that a cell must be attached to a substratum in order to initiate cell division."
  },
  {
    "front": "Androgens",
    "back": "(an\u2032-dr\u014d-jen) Any steroid hormone, such as testosterone, that stimulates the development and maintenance of the male reproductive system and secondary sex characteristics."
  },
  {
    "front": "Aneuploidy",
    "back": "(an\u2032-y\u016b-ploy\u2032-d\u0113) A chromosomal aberration in which one or more chromosomes are present in extra copies or are deficient in number."
  },
  {
    "front": "Angiosperms",
    "back": "(an\u2032-j\u0113-\u014d-sperm) A flowering plant, which forms seeds inside a protective chamber called an ovary."
  },
  {
    "front": "Anhydrobiosis",
    "back": "(an-h\u012b\u2032-dr\u014d-b\u012b-\u014d\u2032-sis) A dormant state involving loss of almost all body water."
  },
  {
    "front": "Animal pole",
    "back": "The point at the end of an egg in the hemisphere where the least yolk is concentrated; opposite of vegetal pole."
  },
  {
    "front": "Anion",
    "back": "(an\u2032-\u012b-on) A negatively charged ion.",
    "chapter": 2
  },
  {
    "front": "Anterior pituitary",
    "back": "A portion of the pituitary gland that develops from nonneural tissue; consists of endocrine cells that synthesize and secrete several tropic and nontropic hormones."
  },
  {
    "front": "Anther",
    "back": "In an angiosperm, the terminal pollen sac of a stamen, where pollen grains containing sperm-producing male gametophytes form."
  },
  {
    "front": "Antheridia",
    "back": "(an-thuh-rid\u2032-\u0113-um) (plural,antheridia) In plants, the male gametangium, a moist chamber in which gametes develop."
  },
  {
    "front": "Anthropoids",
    "back": "(an\u2032-thruh-poyd) A member of a primate group made up of the monkeys and the apes (gibbons, orangutans, gorillas, chimpanzees, bonobos, and humans)."
  },
  {
    "front": "Antibody",
    "back": "A protein secreted by plasma cells (differentiated B cells) that binds to a particular antigen; also called immunoglobulin. All antibodies have the same Y-shaped structure and in their monomer form consist of two identical heavy chains and two identical light chains."
  },
  {
    "front": "Anticodon",
    "back": "(an\u2032-t\u012b-k\u014d\u2032-don) A nucleotide triplet at one end of a tRNA molecule that base-pairs with a particular complementary codon on an mRNA molecule."
  },
  {
    "front": "Antidiuretic hormone",
    "back": "(ADH)(an\u2032-t\u012b-d\u012b-y\u016b-ret\u2032-ik) A peptide hormone, also called vasopressin, that promotes water retention by the kidneys. Produced in the hypothalamus and released from the posterior pituitary, ADH also functions in the brain."
  },
  {
    "front": "Antigen",
    "back": "(an\u2032-ti-jen) A substance that elicits an immune response by binding to receptors of B or T cells."
  },
  {
    "front": "Antigen presentation",
    "back": "(an\u2032-ti-jen) The process by which an MHC molecule binds to a fragment of an intracellular protein antigen and carries it to the cell surface, where it is displayed and can be recognized by a T cell."
  },
  {
    "front": "Antigen receptor",
    "back": "(an\u2032-ti-jen) The general term for a surface protein, located on B cells and T cells, that binds to antigens, initiating adaptive immune responses. The antigen receptors on B cells are called B cell receptors, and the antigen receptors on T cells are called T cell receptors."
  },
  {
    "front": "Antigen-presenting cell",
    "back": "(an\u2032-ti-jen) A cell that upon ingesting pathogens or internalizing pathogen proteins generates peptide fragments that are bound by class II MHC molecules and subsequently displayed on the cell surface to T cells. Macrophages, dendritic cells, and B cells are the primary antigen-presenting cells."
  },
  {
    "front": "Antiparallel",
    "back": "Referring to the arrangement of the sugar-phosphate backbones in a DNA double helix (they run in opposite 5' to 3' directions)."
  },
  {
    "front": "Aphotic zone",
    "back": "(\u0101\u2032-f\u014d\u2032-tik) The part of an ocean or lake beneath the photic zone, where light does not penetrate sufficiently for photosynthesis to occur."
  },
  {
    "front": "Apical bud",
    "back": "(\u0101\u2032-pik-ul) A bud at the tip of a plant stem; also called a terminal bud."
  },
  {
    "front": "Apical dominance",
    "back": "(\u0101\u2032-pik-ul) Tendency for growth to be concentrated at the tip of a plant shoot because the apical bud partially inhibits axillary bud growth."
  },
  {
    "front": "Apical ectodermal ridge",
    "back": "(AER) (\u0101\u2032-pik-ul) A thickened area of ectoderm at the tip of a limb bud that promotes outgrowth of the limb bud."
  },
  {
    "front": "Apical meristems",
    "back": "(\u0101\u2032-pik-ul m\u0101r\u2032-uh-stem) A localized region at a growing tip of a plant body where one or more cells divide repeatedly. The dividing cells of an apical meristem enable the plant to grow in length."
  },
  {
    "front": "Apicomplexans",
    "back": "(ap\u2032-\u0113-kom-pleks\u2032-un) A group of alveolate protists, this clade includes many species that parasitize animals. Some apicomplexans cause human disease."
  },
  {
    "front": "Apomixis",
    "back": "(ap\u2032-uh-mik\u2032-sis) The ability of some plant species to reproduce asexually through seeds without fertilization by a male gamete."
  },
  {
    "front": "Apoplast",
    "back": "(ap\u2032-\u014d-plast) Everything external to the plasma membrane of a plant cell, including cell walls, intercellular spaces, and the space within dead structures such as xylem vessels and tracheids."
  },
  {
    "front": "Apoptosis",
    "back": "(\u0101-puh-t\u014d\u2032-sus) A type of programmed cell death, which is brought about by activation of enzymes that break down many chemical components in the cell."
  },
  {
    "front": "Aposematic coloration",
    "back": "(ap\u2032-\u014d-si-mat\u2032-ik) The bright warning coloration of many animals with effective physical or chemical defenses."
  },
  {
    "front": "Appendix",
    "back": "A small, finger-like extension of the vertebrate cecum; contains a mass of white blood cells that contribute to immunity."
  },
  {
    "front": "Aquaporins",
    "back": "A channel protein in a cellular membrane that specifically facilitates osmosis, the diffusion of free water across the membrane."
  },
  {
    "front": "Aqueous solution",
    "back": "(\u0101\u2032-kw\u0113-us) A solution in which water is the solvent."
  },
  {
    "front": "Arachnids",
    "back": "A member of a subgroup of the major arthropod clade Chelicerata. Arachnids have six pairs of appendages, including four pairs of walking legs, and include spiders, scorpions, ticks, and mites."
  },
  {
    "front": "Arbuscular mycorrhizae",
    "back": "(ar-bus\u2032-ky\u016b-lur m\u012b\u2032-k\u014d-r\u012b\u2032-zuh) Association of a fungus with a plant root system in which the fungus causes the invagination of the host (plant) cells\u2019 plasma membranes; also called endomycorrhiza."
  },
  {
    "front": "Arbuscular mycorrhizal fungi",
    "back": "(ar-bus\u2032-ky\u016b-lur) A symbiotic fungus whose hyphae grow through the cell wall of plant roots and extend into the root cell (enclosed in tubes formed by invagination of the root cell plasma membrane)."
  },
  {
    "front": "Arbuscules",
    "back": "Specialized branching hyphae that are found in some mutualistic fungi and exchange nutrients with living plant cells."
  },
  {
    "front": "Archaea",
    "back": "(ar\u2032-k\u0113\u2032-uh) One of two prokaryotic domains, the other being Bacteria.",
    "chapter": 1
  },
  {
    "front": "Archaeplastida",
    "back": "(ar\u2032-k\u0113-plas\u2032-tid-uh) One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This monophyletic group, which includes red algae, green algae, and plants, descended from an ancient protistan ancestor that engulfed a cyanobacterium; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. Excavates have unique cytoskeletal features, and some species have an \u201cexcavated\u201d feeding groove on one side of the cell body; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This supergroup contains a large, extremely diverse collection of protists from three major subgroups: stramenopiles, alveolates, and rhizarians; and One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This clade, which is supported by studies of myosin proteins and DNA, consists of amoebozoans and opisthokonts."
  },
  {
    "front": "Archegonia",
    "back": "(ar-ki-g\u014d\u2032-n\u0113-um) (plural,archegonia) In plants, the female gametangium, a moist chamber in which gametes develop."
  },
  {
    "front": "Archenteron",
    "back": "(ar-ken\u2032-tuh-ron) The endoderm-lined cavity, formed during gastrulation, that develops into the digestive tract of an animal."
  },
  {
    "front": "Archosaurs",
    "back": "(ar\u2032-k\u014d-s\u014dr) A member of the reptilian group that includes crocodiles, alligators and dinosaurs, including birds."
  },
  {
    "front": "Arteries",
    "back": "A vessel that carries blood away from the heart to organs throughout the body."
  },
  {
    "front": "Arterioles",
    "back": "(ar-ter\u2032-\u0113-\u014dl) A vessel that conveys blood between an artery and a capillary bed."
  },
  {
    "front": "Arthropods",
    "back": "A segmented ecdysozoan with a hard exoskeleton and jointed appendages. Familiar examples include insects, spiders, millipedes, and crabs."
  },
  {
    "front": "Artificial selection",
    "back": "The selective breeding of domesticated plants and animals to encourage the occurrence of desirable traits."
  },
  {
    "front": "Asci",
    "back": "(plural,asci) A saclike spore capsule located at the tip of a dikaryotic hypha of a sac fungus."
  },
  {
    "front": "Ascocarps",
    "back": "The fruiting body of a sac fungus (ascomycete)."
  },
  {
    "front": "Ascomycetes",
    "back": "(as\u2032-kuh-m\u012b\u2032-s\u0113t) A member of the fungal phylum Ascomycota, commonly called sac fungus. The name comes from the saclike structure in which the spores develop."
  },
  {
    "front": "Asexual reproduction",
    "back": "The generation of offspring from a single parent that occurs without the fusion of gametes. In most cases, the offspring are genetically identical to the parent."
  },
  {
    "front": "Associative learning",
    "back": "The acquired ability to associate one environmental feature (such as a color) with another (such as danger)."
  },
  {
    "front": "Atherosclerosis",
    "back": "A cardiovascular disease in which fatty deposits called plaques develop in the inner walls of the arteries, obstructing the arteries and causing them to harden."
  },
  {
    "front": "Atom",
    "back": "The smallest unit of matter that retains the properties of an element.",
    "chapter": 2
  },
  {
    "front": "Atomic mass",
    "back": "The total mass of an atom, numerically equivalent to the mass in grams of 1 mole of the atom. (For an element with more than one isotope, the atomic mass is the average mass of the naturally occurring isotopes, weighted by their abundance.)",
    "chapter": 2
  },
  {
    "front": "Atomic nucleus",
    "back": "An atom\u2019s dense central core, containing protons and neutrons.",
    "chapter": 2
  },
  {
    "front": "Atomic number",
    "back": "The number of protons in the nucleus of an atom, unique for each element and designated by a subscript.",
    "chapter": 2
  },
  {
    "front": "ATP",
    "back": "(adenosine triphosphate) (a-den\u2032-\u014d-s\u0113n tr\u012b-fos\u2032-f\u0101t) An adenine-containing nucleoside triphosphate that releases free energy when its phosphate bonds are hydrolyzed. This energy is used to drive endergonic reactions in cells."
  },
  {
    "front": "ATP synthase",
    "back": "A complex of several membrane proteins that functions in chemiosmosis with adjacent electron transport chains, using the energy of a hydrogen ion (proton) concentration gradient to make ATP. ATP synthases are found in the inner mitochondrial membranes of eukaryotic cells and in the plasma membranes of prokaryotes."
  },
  {
    "front": "Atrial natriuretic peptide",
    "back": "(ANP)(\u0101\u2032-tr\u0113-ul na\u2032-tr\u0113-y\u016b-ret\u2032-ik) A peptide hormone secreted by cells of the atria of the heart in response to high blood pressure. ANP\u2019s effects on the kidney alter ion and water movement and reduce blood pressure."
  },
  {
    "front": "Atrioventricular node",
    "back": "(AV) A region of specialized heart muscle tissue between the left and right atria where electrical impulses are delayed for about 0.1 second before spreading to both ventricles and causing them to contract."
  },
  {
    "front": "Atrioventricular valve",
    "back": "(AV) A heart valve located between each atrium and ventricle that prevents a backflow of blood when the ventricle contracts."
  },
  {
    "front": "Atrium",
    "back": "(\u0101\u2219-tr\u0113-um) (plural, atria) A chamber of the vertebrate heart that receives blood from the veins and transfers blood to a ventricle."
  },
  {
    "front": "Autocrine",
    "back": "Referring to a secreted molecule that acts on the cell that secreted it."
  },
  {
    "front": "Autoimmune disease",
    "back": "An immunological disorder in which the immune system turns against self."
  },
  {
    "front": "Autonomic nervous system",
    "back": "(ot\u2032-\u014d-nom\u2032-ik) An efferent branch of the vertebrate peripheral nervous system that regulates the internal environment; consists of the sympathetic and parasympathetic divisions and the enteric nervous system."
  },
  {
    "front": "Autopolyploid",
    "back": "(ot\u2032-\u014d-pol\u2032-\u0113-ployd) An individual that has more than two chromosome sets that are all derived from a single species."
  },
  {
    "front": "Autosomes",
    "back": "(ot\u2032-\u014d-s\u014dm) A chromosome that is not directly involved in determining sex; not a sex chromosome."
  },
  {
    "front": "Autotrophs",
    "back": "(ot\u2032-\u014d-tr\u014df) An organism that obtains organic food molecules without eating other organisms or substances derived from other organisms. Autotrophs use energy from the sun or from oxidation of inorganic substances to make organic molecules from inorganic ones."
  },
  {
    "front": "Auxin",
    "back": "(ok\u2032-sin) A term that primarily refers to indoleacetic acid (IAA), a natural plant hormone that has a variety of effects, including cell elongation, root formation, secondary growth, and fruit growth."
  },
  {
    "front": "Axillary bud",
    "back": "(ak\u2032-sil-\u0101r-\u0113) A structure that has the potential to form a lateral shoot, or branch. The bud appears in the angle formed between a leaf and a stem."
  },
  {
    "front": "Axon",
    "back": "(ak\u2032-son) A typically long extension, or process, of a neuron that carries nerve impulses away from the cell body toward target cells."
  },
  {
    "front": "B cells",
    "back": "The lymphocytes that complete their development in the bone marrow and become effector cells for the humoral immune response."
  },
  {
    "front": "Bacteria",
    "back": "One of two prokaryotic domains, the other being Archaea.",
    "chapter": 1
  },
  {
    "front": "Bacteriophages",
    "back": "(bak-t\u0113r\u2032-\u0113-\u014d-f\u0101j) A virus that infects bacteria; also called a phage."
  },
  {
    "front": "Bacteroids",
    "back": "A form of the bacteriumRhizobiumcontained within the vesicles formed by the root cells of a root nodule."
  },
  {
    "front": "Balancing selection",
    "back": "Natural selection that maintains two or more phenotypic forms in a population."
  },
  {
    "front": "Bar graph",
    "back": "A graph in which the independent variable represents groups or nonnumerical categories and the values of the dependent variable(s) are shown by bars."
  },
  {
    "front": "Bark",
    "back": "All tissues external to the vascular cambium, consisting mainly of the secondary phloem and layers of periderm."
  },
  {
    "front": "Barr body",
    "back": "A dense object lying along the inside of the nuclear envelope in cells of female mammals, representing a highly condensed, inactivated X chromosome."
  },
  {
    "front": "Basal angiosperms",
    "back": "A member of one of three clades of early-diverging lineages of extant flowering plants. Examples areAmborella, water lilies, and star anise and its relatives."
  },
  {
    "front": "Basal body",
    "back": "(b\u0101\u2032-sul) A eukaryotic cell structure consisting of a \u201c9 plus 0\u201d arrangement of microtubule triplets. The basal body may organize the microtubule assembly of a cilium or flagellum and is structurally very similar to a centriole."
  },
  {
    "front": "Basal metabolic rate",
    "back": "(BMR) The metabolic rate of a resting, fasting, and nonstressed endotherm at a comfortable temperature."
  },
  {
    "front": "Basal taxon",
    "back": "In a specified group of organisms, a taxon whose evolutionary lineage diverged early in the history of the group."
  },
  {
    "front": "Base",
    "back": "A substance that reduces the hydrogen ion concentration of a solution."
  },
  {
    "front": "Basidiocarps",
    "back": "Elaborate fruiting body of a dikaryotic mycelium of a club fungus."
  },
  {
    "front": "Basidiomycetes",
    "back": "(buh-sid\u2032-\u0113-\u014d-m\u012b\u2032-s\u0113t) A member of the fungal phylum Basidiomycota, commonly called club fungus. The name comes from the club-like shape of the basidium."
  },
  {
    "front": "Basidium",
    "back": "(plural,basidia) (buh-sid\u2032-\u0113-um, buh-sid\u2032-\u0113-ah) A reproductive appendage that produces sexual spores on the gills of mushrooms (club fungi)."
  },
  {
    "front": "Batesian mimicry",
    "back": "(b\u0101t\u2032-z\u0113-un mim\u2032-uh-kr\u0113) A type of mimicry in which a harmless species resembles an unpalatable or harmful species to which it is not closely related."
  },
  {
    "front": "Behavior",
    "back": "The internally coordinated responses (actions or inactions) of whole organisms (individuals or groups) to internal and/or external stimuli, excluding responses more easily understood as developmental changes."
  },
  {
    "front": "Behavioral ecology",
    "back": "The study of behavioral interactions between individuals within populations and communities, usually in an evolutionary context."
  },
  {
    "front": "Benign tumor",
    "back": "A mass of abnormal cells with specific genetic and cellular changes such that the cells are not capable of surviving at a new site and generally remain at the site of the tumor\u2019s origin."
  },
  {
    "front": "Benthic zone",
    "back": "The bottom surface of an aquatic environment."
  },
  {
    "front": "Benthos",
    "back": "(ben\u2032-th\u014dz) The communities of organisms living in the benthic zone of an aquatic biome."
  },
  {
    "front": "Beta oxidation",
    "back": "A metabolic sequence that breaks fatty acids down to two-carbon fragments that enter the citric acid cycle as acetyl CoA."
  },
  {
    "front": "Beta pleated sheets",
    "back": "One form of the secondary structure of proteins in which the polypeptide chain folds back and forth. Two regions of the chain lie parallel to each other and are held together by hydrogen bonds between atoms of the polypeptide backbone (not the side chains)."
  },
  {
    "front": "Bilateral symmetry",
    "back": "Body symmetry in which a central longitudinal plane divides the body into two equal but opposite halves."
  },
  {
    "front": "Bilaterians",
    "back": "(b\u012b\u2032-luh-ter\u2032-\u0113-uhn) A member of a clade of animals with bilateral symmetry and three germ layers."
  },
  {
    "front": "Bile",
    "back": "A mixture of substances that is produced in the liver and stored in the gallbladder; enables formation of fat droplets in water as an aid in the digestion and absorption of fats."
  },
  {
    "front": "Binary fission",
    "back": "A method of asexual reproduction in single-celled organisms in which the cell grows to roughly double its size and then divides into two cells. In prokaryotes, binary fission does not involve mitosis, but in single-celled eukaryotes that undergo binary fission, mitosis is part of the process."
  },
  {
    "front": "Binomial",
    "back": "A common term for the two-part, latinized format for naming a species, consisting of the genus and specific epithet; also called a binomen."
  },
  {
    "front": "Biodiversity hot spot",
    "back": "A relatively small area with numerous endemic species and a large number of endangered and threatened species."
  },
  {
    "front": "Bioenergetics",
    "back": "(1) The overall flow and transformation of energy in an organism. (2) The study of how energy flows through organisms."
  },
  {
    "front": "Biofilms",
    "back": "A surface-coating colony of one or more species of unicellular organisms that engage in metabolic cooperation; most known biofilms are formed by prokaryotes."
  },
  {
    "front": "Biofuels",
    "back": "A fuel produced from biomass."
  },
  {
    "front": "Biogeochemical cycles",
    "back": "Any of the various chemical cycles, which involve both biotic and abiotic components of ecosystems."
  },
  {
    "front": "Biogeography",
    "back": "The scientific study of the past and present geographic distributions of species."
  },
  {
    "front": "Bioinformatics",
    "back": "The use of computers, software, and mathematical models to process and integrate biological information from large data sets.",
    "chapter": 1
  },
  {
    "front": "Biological augmentation",
    "back": "An approach to restoration ecology that uses organisms to add essential materials to a degraded ecosystem."
  },
  {
    "front": "Biological clock",
    "back": "An internal timekeeper that controls an organism\u2019s biological rhythms. The biological clock marks time with or without environmental cues but often requires signals from the environment to remain tuned to an appropriate period; A physiological cycle of about 24 hours that persists even in the absence of external cues."
  },
  {
    "front": "Biological magnification",
    "back": "A process in which retained substances become more concentrated at each higher trophic level in a food chain."
  },
  {
    "front": "Biological species concept",
    "back": "Definition of a species as a group of populations whose members have the potential to interbreed in nature and produce viable, fertile offspring but do not produce viable, fertile offspring with members of other such groups."
  },
  {
    "front": "Biology",
    "back": "The scientific study of life.",
    "chapter": 1
  },
  {
    "front": "Biomass",
    "back": "The total mass of organic matter comprising a group of organisms in a particular habitat."
  },
  {
    "front": "Biomes",
    "back": "(b\u012b\u2032-\u014dm) Any of the world\u2019s major ecosystem types, often classified according to the predominant vegetation for terrestrial biomes and the physical environment for aquatic biomes and characterized by adaptations of organisms to that particular environment."
  },
  {
    "front": "Bioremediation",
    "back": "The use of organisms to detoxify and restore polluted and degraded ecosystems."
  },
  {
    "front": "Biotechnology",
    "back": "The manipulation of organisms or their components to produce useful products."
  },
  {
    "front": "Biotic",
    "back": "(b\u012b-ot\u2032-ik) Pertaining to the living factors\u2014the organisms\u2014in an environment."
  },
  {
    "front": "Bipolar disorder",
    "back": "A depressive mental illness characterized by swings of mood from high to low; also called manic-depressive disorder."
  },
  {
    "front": "Birth control pills",
    "back": "A hormonal contraceptive that inhibits ovulation, retards follicular development, or alters the cervical mucus to prevent sperm from entering the uterus."
  },
  {
    "front": "Blades",
    "back": "(1) A leaflike structure of a seaweed that provides most of the surface area for photosynthesis. (2) The flattened portion of a typical leaf."
  },
  {
    "front": "Blastocoel",
    "back": "(blas\u2032-tuh-s\u0113l) The fluid-filled cavity that forms in the center of a blastula."
  },
  {
    "front": "Blastocyst",
    "back": "(blas\u2032-tuh-sist) The blastula stage of mammalian embryonic development, consisting of an inner cell mass, a cavity, and an outer layer, the trophoblast. In humans, the blastocyst forms 1 week after fertilization."
  },
  {
    "front": "Blastomeres",
    "back": "An early embryonic cell arising during the cleavage stage of an early embryo."
  },
  {
    "front": "Blastopore",
    "back": "(blas\u2032-t\u014d-p\u014dr) In a gastrula, the opening of the archenteron that typically develops into the anus in deuterostomes and the mouth in protostomes."
  },
  {
    "front": "Blastula",
    "back": "(blas\u2032-ty\u016b-luh) A hollow ball of cells that marks the end of the cleavage stage during early embryonic development in animals."
  },
  {
    "front": "Blood",
    "back": "A connective tissue with a fluid matrix called plasma in which red blood cells, white blood cells, and cell fragments called platelets are suspended."
  },
  {
    "front": "Blue-light photoreceptors",
    "back": "Any of several classes of light-absorbing molecules that have physiological effects when activated by blue light."
  },
  {
    "front": "Body cavity",
    "back": "A fluid- or air-filled space between the digestive tract and the body wall."
  },
  {
    "front": "Body plan",
    "back": "In multicellular eukaryotes, a set of morphological and developmental traits that are integrated into a functional whole\u2014the living organism."
  },
  {
    "front": "Bohr shift",
    "back": "A lowering of the affinity of hemoglobin for oxygen, caused by a drop in pH. It facilitates the release of oxygen from hemoglobin in the vicinity of active tissues."
  },
  {
    "front": "Book lungs",
    "back": "An organ of gas exchange in spiders, consisting of stacked plates contained in an internal chamber."
  },
  {
    "front": "Bottleneck effect",
    "back": "Genetic drift that occurs when the size of a population is reduced, as by a natural disaster or human actions. Typically, the surviving population is no longer genetically representative of the original population."
  },
  {
    "front": "Bottom-up control",
    "back": "A situation in which the abundance of organisms at each trophic level is limited by nutrient supply or the availability of food at lower trophic levels; thus, the supply of nutrients controls plant numbers, which control herbivore numbers, which in turn control predator numbers."
  },
  {
    "front": "Bowman\u2019s capsule",
    "back": "(b\u014d\u2032-munz) A cup-shaped receptacle in the vertebrate kidney that is the initial, expanded segment of the nephron, where filtrate enters from the blood."
  },
  {
    "front": "Brachiopods",
    "back": "(bra\u2032-k\u0113-uh-pod\u2032) A marine lophotrochozoan with a shell divided into dorsal and ventral halves; also called lamp shells."
  },
  {
    "front": "Brain",
    "back": "Organ of the central nervous system where information is processed and integrated."
  },
  {
    "front": "Brainstem",
    "back": "A collection of structures in the vertebrate brain, including the midbrain, the pons, and the medulla oblongata; functions in homeostasis, coordination of movement, and conduction of information to higher brain centers."
  },
  {
    "front": "Branch point",
    "back": "The representation on a phylogenetic tree of the divergence of two or more taxa from a common ancestor. A branch point is usually shown as a dichotomy in which a branch representing the ancestral lineage splits (at the branch point) into two branches, one for each of the two descendant lineages."
  },
  {
    "front": "Brassinosteroids",
    "back": "A steroid hormone in plants that has a variety of effects, including inducing cell elongation, retarding leaf abscission, and promoting xylem differentiation."
  },
  {
    "front": "Breathing",
    "back": "Ventilation of the lungs through alternating inhalation and exhalation."
  },
  {
    "front": "Bronchi",
    "back": "(brong\u2032-kus) (plural,bronchi) One of a pair of breathing tubes that branch from the trachea into the lungs."
  },
  {
    "front": "Bronchioles",
    "back": "(brong\u2032-k\u0113-\u014dl\u2032) A fine branch of the bronchi that transports air to alveoli."
  },
  {
    "front": "Brown algae",
    "back": "A multicellular, photosynthetic protist with a characteristic brown or olive color that results from carotenoids in its plastids. Most brown algae are marine, and some have a plantlike body."
  },
  {
    "front": "Bryophytes",
    "back": "(br\u012b\u2032-uh-f\u012bt) An informal name for a moss, liverwort, or hornwort; a nonvascular plant that lives on land but lacks some of the terrestrial adaptations of vascular plants."
  },
  {
    "front": "Buffer",
    "back": "A solution that contains a weak acid and its corresponding base. A buffer minimizes changes in pH when acids or bases are added to the solution."
  },
  {
    "front": "Bulk feeders",
    "back": "An animal that eats relatively large pieces of food."
  },
  {
    "front": "Bulk flow",
    "back": "The movement of a fluid due to a difference in pressure between two locations."
  },
  {
    "front": "Bundle-sheath cells",
    "back": "In C4 plants, a type of photosynthetic cell arranged into tightly packed sheaths around the veins of a leaf."
  },
  {
    "front": "C3 plants",
    "back": "A plant that uses the Calvin cycle for the initial steps that incorporate carbon dioxide into organic material, forming a three-carbon compound as the first stable intermediate."
  },
  {
    "front": "C4 plants",
    "back": "A plant in which the Calvin cycle is preceded by reactions that incorporate carbon dioxide into a four-carbon compound, the end product of which supplies carbon dioxide for the Calvin cycle."
  },
  {
    "front": "Calcitonin",
    "back": "(kal\u2032-si-t\u014d\u2032-nin) A hormone secreted by the thyroid gland that lowers the blood calcium level by promoting calcium deposition in bone and calcium excretion from the kidneys; nonessential in adult humans."
  },
  {
    "front": "Callus",
    "back": "A mass of dividing, undifferentiated cells growing at the site of a wound or in culture."
  },
  {
    "front": "Calorie",
    "back": "(cal) The amount of heat energy required to raise the temperature of 1 g of water by 1 degree C; also the amount of heat energy that 1 g of water releases when it cools by 1 degree C. The Calorie (with a capital C), usually used to indicate the energy content of food, is a kilocalorie."
  },
  {
    "front": "Calvin cycle",
    "back": "The second of two major stages in photosynthesis (following the light reactions), involving fixation of atmospheric carbon dioxide and reduction of the fixed carbon into carbohydrate."
  },
  {
    "front": "CAM plants",
    "back": "A plant that uses crassulacean acid metabolism, an adaptation for photosynthesis in arid conditions. In this process, carbon dioxide entering open stomata during the night is converted to organic acids, which release carbon dioxide for the Calvin cycle during the day, when stomata are closed."
  },
  {
    "front": "Cambrian explosion",
    "back": "A relatively brief time in geologic history when many present-day phyla of animals first appeared in the fossil record. This burst of evolutionary change occurred about 535\u2013525 million years ago and saw the emergence of the first large, hard-bodied animals."
  },
  {
    "front": "Canopy",
    "back": "The uppermost layer of vegetation in a terrestrial biome."
  },
  {
    "front": "Capillaries",
    "back": "(kap\u2032-il-\u0101r\u2032-\u0113) A microscopic blood vessel that penetrates the tissues and consists of a single layer of endothelial cells that allows exchange between the blood and interstitial fluid."
  },
  {
    "front": "Capillary beds",
    "back": "(kap\u2032-il-\u0101r\u2032-\u0113) A network of capillaries in a tissue or organ."
  },
  {
    "front": "Capsid",
    "back": "The protein shell that encloses a viral genome. It may be rod-shaped, polyhedral, or more complex in shape."
  },
  {
    "front": "Capsule",
    "back": "(1) In many prokaryotes, a dense and well-defined layer of polysaccharide or protein that surrounds the cell wall and is sticky, protecting the cell and enabling it to adhere to substrates or other cells. (2) The sporangium of a bryophyte (moss, liverwort, or hornwort)."
  },
  {
    "front": "Carbohydrates",
    "back": "(kar\u2032-b\u014d-h\u012b\u2032-dr\u0101t) A sugar (monosaccharide) or one of its dimers (disaccharides) or polymers (polysaccharides)."
  },
  {
    "front": "Carbon fixation",
    "back": "The initial incorporation of carbon from carbon dioxide into an organic compound by an autotrophic organism (a plant, another photosynthetic organism, or a chemoautotrophic prokaryote)."
  },
  {
    "front": "Carbonyl group",
    "back": "(kar'-buh-nil) A chemical group present in aldehydes and ketones and consisting of a carbon atom double-bonded to an oxygen atom."
  },
  {
    "front": "Carboxyl group",
    "back": "(kar-bok'-sil) A chemical group present in organic acids and consisting of a single carbon atom double-bonded to an oxygen atom and also bonded to a hydroxyl group."
  },
  {
    "front": "Cardiac cycle",
    "back": "(kar\u2032-d\u0113-ak) The alternating contractions and relaxations of the heart."
  },
  {
    "front": "Cardiac muscle",
    "back": "(kar\u2032-d\u0113-ak) A type of striated muscle that forms the contractile wall of the heart. Its cells are joined by intercalated disks that relay the electrical signals underlying each heartbeat."
  },
  {
    "front": "Cardiac output",
    "back": "(kar\u2032-d\u0113-ak) The volume of blood pumped per minute by each ventricle of the heart."
  },
  {
    "front": "Cardiovascular system",
    "back": "A closed circulatory system with a heart and branching network of arteries, capillaries, and veins. The system is characteristic of vertebrates."
  },
  {
    "front": "Carnivores",
    "back": "An organism that consumes animals for nutrition."
  },
  {
    "front": "Carotenoids",
    "back": "(kuh-rot\u2032-uh-noyd\u2032) An accessory pigment, either yellow or orange, in the chloroplasts of plants and in some prokaryotes. By absorbing wavelengths of light that chlorophyll cannot, carotenoids broaden the spectrum of colors that can drive photosynthesis."
  },
  {
    "front": "Carpels",
    "back": "(kar\u2032-pul) The ovule-producing reproductive organ of a flower, consisting of the stigma, style, and ovary."
  },
  {
    "front": "Carriers",
    "back": "In genetics, an individual who is heterozygous at a given genetic locus for a recessively inherited disorder. The heterozygote is generally phenotypically normal for the disorder but can pass on the recessive allele to offspring."
  },
  {
    "front": "Carrying capacity",
    "back": "The maximum population size that can be supported by the available resources, symbolized asK."
  },
  {
    "front": "Casparian strip",
    "back": "(ka-sp\u0101r\u2032-\u0113-un) A water-impermeable ring of wax in the endodermal cells of plants that blocks the passive flow of water and solutes into the stele by way of cell walls."
  },
  {
    "front": "Catabolic pathways",
    "back": "(kat\u2032-uh-bol\u2032-ik) A metabolic pathway that releases energy by breaking down complex molecules to simpler molecules."
  },
  {
    "front": "Catalysis",
    "back": "(kuh-ta\u2032-luh-sis) A process by which a chemical agent called a catalyst selectively increases the rate of a reaction without being consumed by the reaction."
  },
  {
    "front": "Catalysts",
    "back": "(kat\u2032-uh-list) A chemical agent that selectively increases the rate of a reaction without being consumed by the reaction."
  },
  {
    "front": "Cation",
    "back": "(cat\u2032-\u012b\u2032-on) A positively charged ion.",
    "chapter": 2
  },
  {
    "front": "Cation exchange",
    "back": "(cat\u2032-\u012b\u2032-on) A process in which positively charged minerals are made available to a plant when hydrogen ions in the soil displace mineral ions from the clay particles."
  },
  {
    "front": "Cecum",
    "back": "(s\u0113\u2032-kum) (plural,ceca) The blind pouch forming one branch of the large intestine."
  },
  {
    "front": "Cell",
    "back": "Life\u2019s fundamental unit of structure and function; the smallest unit of organization that can perform all activities required for life."
  },
  {
    "front": "Cell body",
    "back": "The part of a neuron that houses the nucleus and most other organelles."
  },
  {
    "front": "Cell cycle",
    "back": "An ordered sequence of events in the life of a cell, from its origin in the division of a parent cell until its own division into two. The eukaryotic cell cycle is composed of interphase (including G1, S, and G2 phases) and M phase (including mitosis and cytokinesis)."
  },
  {
    "front": "Cell cycle control system",
    "back": "A cyclically operating set of molecules in the eukaryotic cell that both triggers and coordinates key events in the cell cycle."
  },
  {
    "front": "Cell division",
    "back": "The reproduction of cells."
  },
  {
    "front": "Cell fractionation",
    "back": "The disruption of a cell and separation of its parts by centrifugation at successively higher speeds."
  },
  {
    "front": "Cell plate",
    "back": "A membrane-bounded, flattened sac located at the midline of a dividing plant cell, inside which the new cell wall forms during cytokinesis."
  },
  {
    "front": "Cell wall",
    "back": "A protective layer external to the plasma membrane in the cells of plants, prokaryotes, fungi, and some protists. Polysaccharides such as cellulose (in plants and some protists), chitin (in fungi), and peptidoglycan (in bacteria) are important structural components of cell walls."
  },
  {
    "front": "Cell-mediated immune response",
    "back": "The branch of adaptive immunity that involves the activation of cytotoxic T cells, which defend against infected cells."
  },
  {
    "front": "Cellular respiration",
    "back": "The catabolic pathways of aerobic and anaerobic respiration, which break down organic molecules and use an electron transport chain for the production of ATP."
  },
  {
    "front": "Cellulose",
    "back": "(sel\u2032-y\u016b-l\u014ds) A structural polysaccharide of plant cell walls, consisting of glucose monomers joined by beta glycosidic linkages."
  },
  {
    "front": "Central nervous system",
    "back": "(CNS) The portion of the nervous system where signal integration occurs; in vertebrate animals, the brain and spinal cord."
  },
  {
    "front": "Central vacuole",
    "back": "In a mature plant cell, a large membranous sac with diverse roles in growth, storage, and sequestration of toxic substances."
  },
  {
    "front": "Centrioles",
    "back": "(sen'-tr\u0113-\u014dl) A structure in the centrosome of an animal cell composed of a cylinder of microtubule triplets arranged in a \u201c9 plus 0\u201d pattern. A centrosome has a pair of centrioles."
  },
  {
    "front": "Centromere",
    "back": "(sen\u2032-tr\u014d-m\u0113r) In a duplicated chromosome, the region on each sister chromatid where it is most closely attached to its sister chromatid by proteins that bind to the centromeric DNA. Other proteins condense the chromatin in that region, so it appears as a narrow \u201cwaist\u201d on the duplicated chromosome. (An unduplicated chromosome has a single centromere, identified by the proteins bound there.)"
  },
  {
    "front": "Centrosome",
    "back": "(sen'-tr\u014d-s\u014dm) A structure present in the cytoplasm of animal cells that functions as a microtubule-organizing center and is important during cell division. A centrosome has two centrioles."
  },
  {
    "front": "Cercozoans",
    "back": "An amoeboid or flagellated protist that feeds with threadlike pseudopodia."
  },
  {
    "front": "Cerebellum",
    "back": "(s\u0101r\u2032-ruh-bel\u2032-um) Part of the vertebrate hindbrain located dorsally; functions in unconscious coordination of movement and balance."
  },
  {
    "front": "Cerebral cortex",
    "back": "(suh-r\u0113\u2032-brul) The surface of the cerebrum; the largest and most complex part of the mammalian brain, containing nerve cell bodies of the cerebrum; the part of the vertebrate brain most changed through evolution."
  },
  {
    "front": "Cerebrum",
    "back": "(suh-r\u0113\u2032-brum) The dorsal portion of the vertebrate forebrain, composed of right and left hemispheres; the integrating center for memory, learning, emotions, and other\nhighly complex functions of the central nervous system."
  },
  {
    "front": "Cervix",
    "back": "(ser\u2032-viks) The neck of the uterus, which opens into the vagina."
  },
  {
    "front": "Chaparral",
    "back": "A scrubland biome of dense, spiny evergreen shrubs found at midlatitudes along coasts where cold ocean currents circulate offshore; characterized by mild, rainy winters and long, hot, dry summers."
  },
  {
    "front": "Character",
    "back": "An observable heritable feature that may vary among individuals."
  },
  {
    "front": "Character displacement",
    "back": "The tendency for characteristics to be more divergent in sympatric populations of two species than in allopatric populations of the same two species."
  },
  {
    "front": "Checkpoint",
    "back": "A control point in the cell cycle where stop and go-ahead signals can regulate the cycle."
  },
  {
    "front": "Chelicerae",
    "back": "(k\u0113-lih\u2032-suh-ruh) (plural,chelicerae) One of a pair of clawlike feeding appendages characteristic of chelicerates."
  },
  {
    "front": "Chelicerates",
    "back": "(k\u0113-lih-suh\u2032-r\u0101te) An arthropod that has chelicerae and a body divided into a cephalothorax and an abdomen. Living chelicerates include sea spiders, horseshoe crabs, scorpions, ticks, and spiders."
  },
  {
    "front": "Chemical bonds",
    "back": "An attraction between two atoms, resulting from a sharing of outer-shell electrons or the presence of opposite charges on the atoms. The bonded atoms gain complete outer electron shells.",
    "chapter": 2
  },
  {
    "front": "Chemical energy",
    "back": "Energy available in molecules for release in a chemical reaction; a form of potential energy."
  },
  {
    "front": "Chemical equilibrium",
    "back": "In a chemical reaction, the state in which the rate of the forward reaction equals the rate of the reverse reaction, so that the relative concentrations of the reactants and products do not change with time.",
    "chapter": 2
  },
  {
    "front": "Chemical reactions",
    "back": "The making and breaking of chemical bonds, leading to changes in the composition of matter.",
    "chapter": 2
  },
  {
    "front": "Chemiosmosis",
    "back": "(kem\u2032-\u0113-oz-m\u014d\u2032-sis) An energy-coupling mechanism that uses energy stored in the form of a hydrogen ion gradient across a membrane to drive cellular work, such as the synthesis of ATP. Under aerobic conditions, most ATP synthesis in cells occurs by chemiosmosis."
  },
  {
    "front": "Chemoheterotroph",
    "back": "(k\u0113'-m\u014d-het'-er-\u014d-tr\u014df) An organism that requires organic molecules for both energy and carbon."
  },
  {
    "front": "Chemoreceptors",
    "back": "A sensory receptor that responds to a chemical stimulus, such as a solute or an odorant."
  },
  {
    "front": "Chiasmata",
    "back": "(plural, chiasmata) (k\u012b-az'-muh, k\u012b-az'-muh-tuh) The X-shaped, microscopically visible region where crossing over has occurred earlier in prophase I between homologous nonsister chromatids. Chiasmata become visible after synapsis ends, with the two homologs remaining associated due to sister chromatid cohesion."
  },
  {
    "front": "Chitin",
    "back": "(k\u012b\u2032-tin) A structural polysaccharide, consisting of amino sugar monomers, found in many fungal cell walls and in the exoskeletons of all arthropods."
  },
  {
    "front": "Chlorophyll",
    "back": "(kl\u014dr\u2032-\u014d-fil) A green pigment located in membranes within the chloroplasts of plants and algae and in the membranes of certain prokaryotes. Chlorophyllaparticipates directly in the light reactions, which convert solar energy to chemical energy."
  },
  {
    "front": "Chlorophyll a",
    "back": "(kl\u014dr\u2032-\u014d-fil) A photosynthetic pigment that participates directly in the light reactions, which convert solar energy to chemical energy."
  },
  {
    "front": "Chlorophyll b",
    "back": "(kl\u014dr\u2032-\u014d-fil) An accessory photosynthetic pigment that transfers energy to chlorophylla."
  },
  {
    "front": "Chloroplasts",
    "back": "(kl\u014dr'-\u014d-plast) An organelle found in plants and photosynthetic protists that absorbs sunlight and uses it to drive the synthesis of organic compounds from carbon dioxide and water."
  },
  {
    "front": "Choanocytes",
    "back": "(k\u014d-an\u2032-uh-s\u012bt) A flagellated feeding cell found in sponges. Also called a collar cell, it has a collar-like ring that traps food particles around the base of its flagellum."
  },
  {
    "front": "Cholesterol",
    "back": "(k\u014d-les\u2032-tuh-rol) A steroid that forms an essential component of animal cell membranes and acts as a precursor molecule for the synthesis of other biologically important steroids, such as many hormones."
  },
  {
    "front": "Chondrichthyans",
    "back": "(kon-drik\u2032-th\u0113-an) A member of the clade Chondrichthyes, vertebrates with skeletons made mostly of cartilage, such as sharks and rays."
  },
  {
    "front": "Chordates",
    "back": "A member of the phylum Chordata, animals that at some point during their development have a notochord; a dorsal, hollow nerve cord; pharyngeal slits or clefts; and a muscular, post-anal tail."
  },
  {
    "front": "Chorionic villus sampling",
    "back": "(CVS) (k\u014dr\u2032-\u0113-on\u2032-ik vil\u2032-us) A technique associated with prenatal diagnosis in which a small sample of the fetal portion of the placenta is removed for analysis to detect certain genetic and congenital defects in the fetus."
  },
  {
    "front": "Chromatin",
    "back": "(kr\u014d'-muh-tin) The complex of DNA and proteins that makes up eukaryotic chromosomes. When the cell is not dividing, chromatin exists in its dispersed form, as a mass of very long, thin fibers that are not visible with a light microscope."
  },
  {
    "front": "Chromosome theory of inheritance",
    "back": "(kr\u014d\u2032-muh-s\u014dm) A basic principle in biology stating that genes are located at specific positions (loci) on chromosomes and that the behavior of chromosomes during meiosis accounts for inheritance patterns."
  },
  {
    "front": "Chromosomes",
    "back": "(kr\u014d'-muh-s\u014dm) A cellular structure consisting of one DNA molecule and associated protein molecules. A duplicated chromosome has two DNA molecules. (In some contexts, such as genome sequencing, the term may refer to the DNA alone.) A eukaryotic cell typically has multiple, linear chromosomes, which are located in the nucleus. A prokaryotic cell often has a single, circular chromosome, which is found in the nucleoid, a region that is not enclosed by a membrane; The complex of DNA and proteins that makes up eukaryotic chromosomes. When the cell is not dividing, chromatin exists in its dispersed form, as a mass of very long, thin fibers that are not visible with a light microscope."
  },
  {
    "front": "Chylomicrons",
    "back": "(k\u012b\u2032-l\u014d-m\u012b\u2032-kron) A lipid transport globule composed of fats mixed with cholesterol and coated with proteins."
  },
  {
    "front": "Chyme",
    "back": "(k\u012bm) The mixture of partially digested food and digestive juices formed in the stomach."
  },
  {
    "front": "Chytrids",
    "back": "(k\u012b\u2032-trid) A member of the fungal phylum Chytridiomycota, mostly aquatic fungi with flagellated zoospores that represent an early-diverging fungal lineage."
  },
  {
    "front": "Cilia",
    "back": "(sil\u2032-\u0113-um) (plural,cilia) A short appendage containing microtubules in eukaryotic cells. A motile cilium is specialized for locomotion or moving fluid past the cell; it is formed from a core of nine outer doublet microtubules and two inner single microtubules (the \u201c9 plus 2\u201d arrangement) ensheathed in an extension of the plasma membrane. A primary cilium is usually nonmotile and plays a sensory and signaling role; it lacks the two inner microtubules (the \u201c9 plus 0\u201d arrangement)."
  },
  {
    "front": "Ciliates",
    "back": "(sil\u2032-\u0113-it) A type of protist that moves by means of cilia."
  },
  {
    "front": "Circadian rhythms",
    "back": "(ser-k\u0101\u2032-d\u0113-un) A physiological cycle of about 24 hours that persists even in the absence of external cues."
  },
  {
    "front": "Cis-trans isomers",
    "back": "One of several compounds that have the same molecular formula and covalent bonds between atoms but differ in the spatial arrangements of their atoms owing to the inflexibility of double bonds; also called a geometric isomer."
  },
  {
    "front": "Citric acid cycle",
    "back": "A chemical cycle involving eight steps that completes the metabolic breakdown of glucose molecules begun in glycolysis by oxidizing acetyl CoA (derived from pyruvate) to carbon dioxide; occurs within the mitochondrion in eukaryotic cells and in the cytosol of prokaryotes; together with pyruvate oxidation, the second major stage in cellular respiration."
  },
  {
    "front": "Clades",
    "back": "(kl\u0101d) A group of species that includes an ancestral species and all of its descendants. A clade is equivalent to a monophyletic group."
  },
  {
    "front": "Cladistics",
    "back": "(kluh-dis\u2032-tiks) An approach to systematics in which organisms are placed into groups called clades based primarily on common descent."
  },
  {
    "front": "Classes",
    "back": "In Linnaean classification, the taxonomic category above the level of order."
  },
  {
    "front": "Cleavage",
    "back": "(1) The process of cytokinesis in animal cells, characterized by pinching of the plasma membrane. (2) The succession of rapid cell divisions without significant growth during early embryonic development that converts the zygote to a ball of cells."
  },
  {
    "front": "Cleavage furrow",
    "back": "The first sign of cleavage in an animal cell; a shallow groove around the cell in the cell surface near the old metaphase plate."
  },
  {
    "front": "Climate",
    "back": "The long-term prevailing weather conditions at a given place."
  },
  {
    "front": "Climate change",
    "back": "A directional change in temperature, precipitation, or other aspect of the global climate that lasts for three decades or more.",
    "chapter": 1
  },
  {
    "front": "Climograph",
    "back": "A plot of the temperature and precipitation in a particular region."
  },
  {
    "front": "Clitoris",
    "back": "(klit\u2032-uh-ris) An organ at the upper intersection of the labia minora that engorges with blood and becomes erect during sexual arousal."
  },
  {
    "front": "Cloaca",
    "back": "(kl\u014d-\u0101\u2032-kuh) A common opening for the digestive, urinary, and reproductive tracts found in many nonmammalian vertebrates but in few mammals."
  },
  {
    "front": "Clonal selection",
    "back": "The process by which an antigen selectively binds to and activates only those lymphocytes bearing receptors specific for the antigen. The selected lymphocytes proliferate and differentiate into a clone of effector cells and a clone of memory cells specific for the stimulating antigen."
  },
  {
    "front": "Clone",
    "back": "(1) A group of genetically identical individuals or cells. (2) In popular usage, an individual that is genetically identical to another individual. (3) As a verb, to make one or more genetic replicas of an individual or cell; The production of multiple copies of a gene."
  },
  {
    "front": "Cloning vector",
    "back": "In genetic engineering, a DNA molecule that can carry foreign DNA into a host cell and replicate there. Cloning vectors include plasmids and bacterial artificial chromosomes (BACs), which move recombinant DNA from a test tube back into a cell, and viruses that transfer recombinant DNA by infection."
  },
  {
    "front": "Closed circulatory system",
    "back": "A circulatory system in which blood is confined to vessels and is kept separate from the interstitial fluid."
  },
  {
    "front": "Cnidocytes",
    "back": "(n\u012b\u2032-duh-s\u012bt) A specialized cell unique to the phylum Cnidaria; contains a capsule-like organelle housing a coiled thread that, when discharged, explodes outward and functions in prey capture or defense."
  },
  {
    "front": "Cochlea",
    "back": "(kok\u2032-l\u0113-uh) The complex, coiled organ of hearing that contains the organ of Corti."
  },
  {
    "front": "Coding strand",
    "back": "Nontemplate strand of DNA, which has the same sequence as the mRNA except it has thymine (T) instead of uracil (U)."
  },
  {
    "front": "Codominance",
    "back": "The situation in which the phenotypes of both alleles are exhibited in the heterozygote because both alleles affect the phenotype in separate, distinguishable ways."
  },
  {
    "front": "Codons",
    "back": "(k\u014d\u2032-don) A three-nucleotide sequence of DNA or mRNA that specifies a particular amino acid or termination signal; the basic unit of the genetic code."
  },
  {
    "front": "Coefficient of relatedness",
    "back": "The fraction of genes that, on average, are shared by two individuals."
  },
  {
    "front": "Coelom",
    "back": "(s\u0113\u2032-l\u014dm) A body cavity lined by tissue derived only from mesoderm."
  },
  {
    "front": "Coenocytic fungi",
    "back": "(s\u0113\u2032-no-si\u2032-tic) A fungus that lacks septa and hence whose body is made up of a continuous cytoplasmic mass that may contain hundreds or thousands of nuclei."
  },
  {
    "front": "Coenzyme",
    "back": "(k\u014d-en\u2032-z\u012bm) An organic molecule serving as a cofactor. Most vitamins function as coenzymes in metabolic reactions."
  },
  {
    "front": "Coevolution",
    "back": "The joint evolution of two interacting species, each in response to selection imposed by the other."
  },
  {
    "front": "Cofactors",
    "back": "Any nonprotein molecule or ion that is required for the proper functioning of an enzyme. Cofactors can be permanently bound to the active site or may bind loosely and reversibly, along with the substrate, during catalysis."
  },
  {
    "front": "Cognition",
    "back": "The process of knowing that may include awareness, reasoning, recollection, and judgment."
  },
  {
    "front": "Cognitive map",
    "back": "A neural representation of the abstract spatial relationships between objects in an animal\u2019s surroundings."
  },
  {
    "front": "Cohesion",
    "back": "The linking together of like molecules, often by hydrogen bonds."
  },
  {
    "front": "Cohesion-tension hypothesis",
    "back": "The leading explanation of the ascent of xylem sap. It states that transpiration exerts pull on xylem sap, putting the sap under negative pressure, or tension, and that the cohesion of water molecules transmits this pull along the entire length of the xylem from shoots to roots."
  },
  {
    "front": "Cohort",
    "back": "A group of individuals of the same age in a population."
  },
  {
    "front": "Coleoptile",
    "back": "(k\u014d\u2032-l\u0113-op\u2032-tul) The covering of the young shoot of the embryo of a grass seed."
  },
  {
    "front": "Coleorhiza",
    "back": "(k\u014d\u2032-l\u0113-uh-r\u012b\u2032-zuh) The covering of the young root of the embryo of a grass seed."
  },
  {
    "front": "Collagen",
    "back": "A glycoprotein in the extracellular matrix of animal cells that forms strong fibers, found extensively in connective tissue and bone; the most abundant protein in the animal kingdom."
  },
  {
    "front": "Collecting ducts",
    "back": "The location in the kidney where processed filtrate, called urine, is collected from the renal tubules."
  },
  {
    "front": "Collenchyma cells",
    "back": "(k\u014d-len\u2032-kim-uh) A flexible plant cell type that occurs in strands or cylinders that support young parts of the plant without restraining growth."
  },
  {
    "front": "Colon",
    "back": "(k\u014d\u2032-len) The largest section of the vertebrate large intestine; functions in water absorption and formation of feces."
  },
  {
    "front": "Commensalism",
    "back": "(kuh-men\u2032-suh-lizm) A positive-neutral ecological interaction that benefits the individuals of one species but neither harms nor helps the individuals of the other species."
  },
  {
    "front": "Communication",
    "back": "(1) In behavior, a process involving the transmission and reception of signals between organisms. (2) Transfer of information from one cell or molecule to another by means of chemical or physical signals."
  },
  {
    "front": "Community",
    "back": "All the organisms that inhabit a particular area; an assemblage of populations of different species living close enough together for potential interaction."
  },
  {
    "front": "Community structure",
    "back": "The number of species found in an ecological community, the particular species that are present, and the relative abundance of these species."
  },
  {
    "front": "Companion cells",
    "back": "A type of plant cell that is connected to a sieve-tube element by many plasmodesmata and whose nucleus and ribosomes may serve one or more adjacent sieve-tube elements."
  },
  {
    "front": "Competition",
    "back": "A negative-negative interaction that occurs when individuals of different species both use a resource that limits the survival and reproduction of each species."
  },
  {
    "front": "Competitive exclusion",
    "back": "The concept that when populations of two similar species compete for the same limited resources, one population will use the resources more efficiently and have a reproductive advantage that will eventually lead to the elimination of the other population."
  },
  {
    "front": "Competitive inhibitors",
    "back": "A substance that reduces the activity of an enzyme by entering the active site in place of the substrate, whose structure it mimics."
  },
  {
    "front": "Complement system",
    "back": "A group of about 30 blood proteins that may amplify the inflammatory response, enhance phagocytosis, or directly lyse extracellular pathogens."
  },
  {
    "front": "Complementary DNA",
    "back": "(cDNA) A double-stranded DNA molecule madein vitrousing mRNA as a template and the enzymes reverse transcriptase and DNA polymerase. A cDNA molecule corresponds to the exons of a gene."
  },
  {
    "front": "Complete dominance",
    "back": "The situation in which the phenotypes of the heterozygote and dominant homozygote are indistinguishable."
  },
  {
    "front": "Complete flowers",
    "back": "A flower that has all four basic floral organs: sepals, petals, stamens, and carpels."
  },
  {
    "front": "Complete metamorphosis",
    "back": "The transformation of a larva into an adult that looks very different, and often functions very differently in its environment, than the larva."
  },
  {
    "front": "Compound",
    "back": "A substance consisting of two or more different elements combined in a fixed ratio.",
    "chapter": 2
  },
  {
    "front": "Compound eyes",
    "back": "A type of multifaceted eye in insects and crustaceans consisting of up to several thousand light-detecting, focusing ommatidia."
  },
  {
    "front": "Concentration gradient",
    "back": "A region along which the density of a chemical substance increases or decreases."
  },
  {
    "front": "Conception",
    "back": "The fertilization of an egg by a sperm in humans."
  },
  {
    "front": "Cones",
    "back": "A cone-shaped cell in the retina of the vertebrate eye, sensitive to color."
  },
  {
    "front": "Conformer",
    "back": "An animal for which an internal condition conforms to (changes in accordance with) changes in an environmental variable."
  },
  {
    "front": "Conidia",
    "back": "(plural,conidia) A haploid spore produced at the tip of a specialized hypha in ascomycetes during asexual reproduction."
  },
  {
    "front": "Conifers",
    "back": "A member of the largest gymnosperm phylum. Most conifers are cone-bearing trees, such as pines and firs."
  },
  {
    "front": "Conjugation",
    "back": "(kon\u2032-j\u016b-g\u0101\u2032-shun) (1) In prokaryotes, the direct transfer of DNA between two cells that are temporarily joined. When the two cells are members of different species, conjugation results in horizontal gene transfer. (2) In ciliates, a sexual process in which two cells exchange haploid micronuclei but do not reproduce."
  },
  {
    "front": "Connective tissue",
    "back": "Animal tissue that functions mainly to bind and support other tissues, having a sparse population of cells scattered through an extracellular matrix."
  },
  {
    "front": "Conodonts",
    "back": "An early, soft-bodied vertebrate with prominent eyes and dental elements."
  },
  {
    "front": "Conservation biology",
    "back": "The integrated study of ecology, evolutionary biology, physiology, molecular biology, and genetics to sustain biological diversity at all levels."
  },
  {
    "front": "Consumer",
    "back": "An organism that feeds on producers, other consumers, or nonliving organic material.",
    "chapter": 1
  },
  {
    "front": "Contraception",
    "back": "The deliberate prevention of pregnancy."
  },
  {
    "front": "Contractile vacuoles",
    "back": "A membranous sac that helps move excess water out of certain freshwater protists."
  },
  {
    "front": "Control elements",
    "back": "A segment of noncoding DNA that helps regulate transcription of a gene by serving as a binding site for a transcription factor. Multiple control elements are present in a eukaryotic gene\u2019s enhancer."
  },
  {
    "front": "Control group",
    "back": "In a controlled experiment, a set of subjects that lacks (or does not receive) the specific factor being tested. Ideally, the control group is identical to the experimental group in other respects.",
    "chapter": 1
  },
  {
    "front": "Controlled experiment",
    "back": "An experiment designed to compare an experimental group with a control group; ideally, the two groups differ only in the factor being tested.",
    "chapter": 1
  },
  {
    "front": "Convergent evolution",
    "back": "The evolution of similar features in independent evolutionary lineages."
  },
  {
    "front": "Convergent extension",
    "back": "A process in which the cells of a tissue layer rearrange themselves in such a way that the sheet of cells becomes narrower (converges) and longer (extends)."
  },
  {
    "front": "Cooperativity",
    "back": "A kind of allosteric regulation whereby a shape change in one subunit of a protein caused by substrate binding is transmitted to all the other subunits, facilitating binding of additional substrate molecules to those subunits."
  },
  {
    "front": "Corepressor",
    "back": "A small molecule that binds to a bacterial repressor protein and changes the protein\u2019s shape, allowing it to bind to the operator and switch an operon off."
  },
  {
    "front": "Cork cambium",
    "back": "(kam\u2032-b\u0113-um) A cylinder of meristematic tissue in woody plants that replaces the epidermis with thicker, tougher cork cells."
  },
  {
    "front": "Corpus luteum",
    "back": "(kor'-pus l\u016b'-t\u0113-um) A secreting tissue in the ovary that forms from the collapsed follicle after ovulation and produces progesterone."
  },
  {
    "front": "Cortex",
    "back": "(1) The outer region of cytoplasm in a eukaryotic cell, lying just under the plasma membrane, that has a more gel-like consistency than the inner regions due to the presence of multiple microfilaments. (2) In plants, ground tissue that is between the vascular tissue and dermal tissue in a root or eudicot stem."
  },
  {
    "front": "Cortical nephrons",
    "back": "In mammals and birds, a nephron with a loop of Henle located almost entirely in the renal cortex."
  },
  {
    "front": "Cotransport",
    "back": "The coupling of the \u201cdownhill\u201d diffusion of one substance to the \u201cuphill\u201d transport of another against its own concentration gradient."
  },
  {
    "front": "Cotyledons",
    "back": "(kot\u2032-uh-l\u0113\u2032-dun) A seed leaf of an angiosperm embryo. Some species have one cotyledon, others two."
  },
  {
    "front": "Countercurrent exchange",
    "back": "The exchange of a substance or heat between two fluids flowing in opposite directions. For example, blood in a fish gill flows in the opposite direction of water passing over the gill, maximizing diffusion of oxygen into and carbon dioxide out of the blood."
  },
  {
    "front": "Countercurrent multiplier systems",
    "back": "A countercurrent system in which energy is expended in active transport to facilitate exchange of materials and generate concentration gradients."
  },
  {
    "front": "Covalent bond",
    "back": "(k\u014d-v\u0101\u2032-lent) A type of strong chemical bond in which two atoms share one or more pairs of valence electrons.",
    "chapter": 2
  },
  {
    "front": "COVID-19",
    "back": "(COronaVIrusDisease 2019) The disease caused by the coronavirus called SARS-CoV-2; first identified in 2019, the disease became pandemic in 2020 and has caused millions of deaths globally."
  },
  {
    "front": "Crassulacean acid metabolism",
    "back": "(CAM) (crass-y\u016b-l\u0101\u2032-shen) An adaptation for photosynthesis in arid conditions, first discovered in the family Crassulaceae. In this process, a plant takes up carbon dioxide and incorporates it into a variety of organic acids at night; during the day, carbon dioxide is released from organic acids for use in the Calvin cycle."
  },
  {
    "front": "CRISPR-Cas9 system",
    "back": "A technique for editing genes in living cells, involving a bacterial protein called Cas9 associated with a guide RNA complementary to a gene sequence of interest."
  },
  {
    "front": "Cristae",
    "back": "(plural,cristae) (kris'-tuh, kris'-t\u0113) An infolding of the inner membrane of a mitochondrion. The inner membrane houses electron transport chains and molecules of the enzyme catalyzing the synthesis of ATP (ATP synthase)."
  },
  {
    "front": "Critical load",
    "back": "The amount of added nutrient, usually nitrogen or phosphorus, that can be absorbed by plants without damaging ecosystem integrity."
  },
  {
    "front": "Crop rotation",
    "back": "The practice of growing different crops in succession on the same land chiefly to preserve the productive capacity of the soil."
  },
  {
    "front": "Cross-fostering study",
    "back": "A behavioral study in which the young of one species are placed in the care of adults from another species."
  },
  {
    "front": "Cross-pollination",
    "back": "In angiosperms, the transfer of pollen from an anther of a flower on one plant to the stigma of a flower on another plant of the same species."
  },
  {
    "front": "Crossing over",
    "back": "The reciprocal exchange of genetic material between nonsister chromatids during prophase I of meiosis."
  },
  {
    "front": "Cryptic coloration",
    "back": "Camouflage that makes a potential prey difficult to spot against its background."
  },
  {
    "front": "Cryptomycetes",
    "back": "A member of the fungal phylum Cryptomycota, unicellular fungi that have flagellated spores; cryptomycetes and their sister taxon (microsporidians) are a basal fungal lineage."
  },
  {
    "front": "Culture",
    "back": "A system of information transfer through social learning or teaching that influences the behavior of individuals in a population."
  },
  {
    "front": "Cuticle",
    "back": "(ky\u016b\u2032-tuh-kul) Any of a variety of tough but flexible, non-mineral outer coverings of an organism, or parts of an organism, which provide protection."
  },
  {
    "front": "Cyclic AMP",
    "back": "(cAMP) Cyclic adenosine monophosphate, named because of its ring structure, is a common chemical signal that has a diversity of roles, including as a second messenger in many eukaryotic cells, and as a regulator of some bacterial operons."
  },
  {
    "front": "Cyclic electron flow",
    "back": "A route of electron flow during the light reactions of photosynthesis that involves only one photosystem and that produces ATP but not NADPH or oxygen."
  },
  {
    "front": "Cyclin",
    "back": "(s\u012b\u2032-klin) A cellular protein that occurs in a cyclically fluctuating concentration and thwat plays an important role in regulating the cell cycle."
  },
  {
    "front": "Cyclin-dependent kinases",
    "back": "(Cdk) (s\u012b\u2032-klin) A protein kinase that is active only when attached to a particular cyclin."
  },
  {
    "front": "Cyclostomes",
    "back": "(s\u012b\u2032-cluh-st\u014dm) Member of one of the two main clades of vertebrates; cyclostomes lack jaws and include lampreys and hagfishes; Member of one of the two main clades of vertebrates; gnathostomes have jaws and include sharks and rays, ray-finned fishes, coelacanths, lungfishes, amphibians, reptiles, and mammals."
  },
  {
    "front": "Cystic fibrosis",
    "back": "(sis\u2032-tik f\u012b-br\u014d\u2032-sis) A human genetic disease caused by a recessive allele for a chloride channel protein; characterized by an excessive secretion of mucus and consequent vulnerability to infection; fatal if untreated."
  },
  {
    "front": "Cytochromes",
    "back": "(s\u012b\u2032-t\u014d-kr\u014dm) An iron-containing protein that is a component of electron transport chains in the mitochondria and chloroplasts of eukaryotic cells and the plasma membranes of prokaryotic cells."
  },
  {
    "front": "Cytokines",
    "back": "(s\u012b'-t\u014d-k\u012bn') Any of a group of small proteins secreted by a number of cell types, including macrophages and helper T cells, that regulate the function of other cells, particularly immune cells. Examples include interferons and interleukins."
  },
  {
    "front": "Cytokinesis",
    "back": "(s\u012b\u2032-t\u014d-kuh-n\u0113\u2032-sis) The division of the cytoplasm to form two separate daughter cells immediately after mitosis, meiosis I, or meiosis II."
  },
  {
    "front": "Cytokinins",
    "back": "(s\u012b\u2032-t\u014d-k\u012b\u2032-nin) Any of a class of related plant hormones that retard aging and act in concert with auxin to stimulate cell division, influence the pathway of differentiation, and control apical dominance."
  },
  {
    "front": "Cytoplasm",
    "back": "(s\u012b'-t\u014d-plaz-um) The contents of the cell bounded by the plasma membrane; in eukaryotes, the portion exclusive of the nucleus."
  },
  {
    "front": "Cytoplasmic determinants",
    "back": "A maternal substance, such as a protein or RNA, that when placed into an egg influences the course of early development by regulating the expression of genes that affect the developmental fate of cells."
  },
  {
    "front": "Cytoplasmic streaming",
    "back": "A circular flow of cytoplasm, involving interactions of myosin and actin filaments, that speeds the distribution of materials within cells."
  },
  {
    "front": "Cytoskeleton",
    "back": "A network of microtubules, microfilaments, and intermediate filaments that extend throughout the cytoplasm and serve a variety of mechanical, transport, and signaling functions."
  },
  {
    "front": "Cytosol",
    "back": "(s\u012b\u2032-t\u014d-sol) The semifluid portion of the cytoplasm."
  },
  {
    "front": "Cytotoxic T cells",
    "back": "A type of lymphocyte that, when activated, kills infected cells as well as certain cancer cells and transplanted cells."
  },
  {
    "front": "Dalton",
    "back": "A measure of mass for atoms and subatomic particles; the same as the atomic mass unit, or amu.",
    "chapter": 2
  },
  {
    "front": "Data",
    "back": "Recorded observations.",
    "chapter": 1
  },
  {
    "front": "Day-neutral plants",
    "back": "A plant in which flower formation is not controlled by photoperiod or day length."
  },
  {
    "front": "De-etiolation",
    "back": "The changes a plant shoot undergoes in response to sunlight; also known informally as greening."
  },
  {
    "front": "Decomposers",
    "back": "An organism that absorbs nutrients from nonliving organic material such as corpses, fallen plant material, and the wastes of living organisms and converts them to inorganic forms; a detritivore."
  },
  {
    "front": "Deductive reasoning",
    "back": "A type of logic in which specific results are predicted from a general premise.",
    "chapter": 1
  },
  {
    "front": "Dehydration reaction",
    "back": "A chemical reaction in which two molecules become covalently bonded to each other with the removal of a water molecule."
  },
  {
    "front": "Deletion",
    "back": "(1) A deficiency in a chromosome resulting from the loss of a fragment through breakage. (2) A mutational loss of one or more nucleotide pairs from a gene."
  },
  {
    "front": "Demographic transition",
    "back": "In a stable population, a shift from high birth and death rates to low birth and death rates."
  },
  {
    "front": "Demography",
    "back": "The study of changes over time in the vital statistics of populations, especially birth rates and death rates."
  },
  {
    "front": "Denaturation",
    "back": "(d\u0113-n\u0101\u2032-chur-\u0101\u2032-shun) In proteins, a process in which a protein loses its native shape due to the disruption of weak chemical bonds and interactions, thereby becoming biologically inactive; in DNA, the separation of the two strands of the double helix. Denaturation occurs under extreme (noncellular) conditions of pH, salt concentration, or temperature."
  },
  {
    "front": "Dendrites",
    "back": "(den\u2032-dr\u012bt) One of usually numerous, short, highly branched extensions of a neuron that receive signals from other neurons."
  },
  {
    "front": "Dendritic cells",
    "back": "An antigen-presenting cell, located mainly in lymphatic tissues and skin, that is particularly efficient in presenting antigens to helper T cells, thereby initiating a primary immune response."
  },
  {
    "front": "Density",
    "back": "The number of individuals per unit area or volume."
  },
  {
    "front": "Density dependent",
    "back": "Referring to any characteristic that varies with population density."
  },
  {
    "front": "Density independent",
    "back": "Referring to any characteristic that is not affected by population density."
  },
  {
    "front": "Density-dependent inhibition",
    "back": "The phenomenon observed in normal animal cells that causes them to stop dividing when they come into contact with one another."
  },
  {
    "front": "Deoxyribonucleic acid",
    "back": "(DNA) (d\u0113-ok\u2219-s\u0113-r\u012b\u2032- b\u014d-n\u016b-kl\u0101\u2219-ik) A nucleic acid molecule, usually a double-stranded helix, in which each polynucleotide strand consists of nucleotide monomers with a deoxyribose sugar and the nitrogenous bases adenine (A), cytosine (C), guanine (G), and thymine (T); capable of being replicated and determining the inherited structure of a cell\u2019s proteins."
  },
  {
    "front": "Deoxyribose",
    "back": "(d\u0113-ok\u2032-si-r\u012b\u2032-b\u014ds) The sugar component of DNA nucleotides, having one fewer hydroxyl group than ribose, the sugar component of RNA nucleotides."
  },
  {
    "front": "Dependent variable",
    "back": "A factor whose value is measured during an experiment or other test to see whether it is influenced by changes in another factor (the independent variable).",
    "chapter": 1
  },
  {
    "front": "Depolarization",
    "back": "A change in a cell\u2019s membrane potential such that the inside of the membrane is made less negative relative to the outside. For example, a neuron membrane is depolarized if a stimulus decreases its voltage from the resting potential of negative 70 mV in the direction of zero voltage."
  },
  {
    "front": "Dermal tissue",
    "back": "The outer protective covering of plants."
  },
  {
    "front": "Desmosomes",
    "back": "A type of intercellular junction in\nanimal cells that functions as a rivet, fastening cells together."
  },
  {
    "front": "Determinate cleavage",
    "back": "A type of embryonic development in protostomes that rigidly casts the developmental fate of each embryonic cell very early."
  },
  {
    "front": "Determinate growth",
    "back": "A type of growth characteristic of most animals and some plant organs, in which growth stops after a certain size is reached."
  },
  {
    "front": "Determination",
    "back": "The progressive restriction of developmental potential in which the possible fate of each cell becomes more limited as an embryo develops. At the end of determination, a cell is committed to its fate."
  },
  {
    "front": "Detritus",
    "back": "(di-tr\u012b\u2032-tus) Dead organic matter."
  },
  {
    "front": "Deuteromycetes",
    "back": "(d\u016b\u2032-tuh-r\u014d-m\u012b\u2032-s\u0113t) Traditional classification for a fungus with no known sexual stage."
  },
  {
    "front": "Deuterostome development",
    "back": "(d\u016b\u2032-tuh-r\u014d-st\u014dm\u2032) In animals, a developmental mode distinguished by the development of the anus from the blastopore; often also characterized by radial cleavage and by the body cavity forming as outpockets of mesodermal tissue."
  },
  {
    "front": "Deuterostomia",
    "back": "(d\u016b\u2219-tuh-r\u014d-st\u014dm\u2032-\u0113-uh) One of the three main lineages of bilaterian animals."
  },
  {
    "front": "Development",
    "back": "The events involved in an organism\u2019s changing gradually from a simple to a more complex or specialized form."
  },
  {
    "front": "Diabetes mellitus",
    "back": "(d\u012b\u2032-uh-b\u0113\u2032-tis mel\u2032-uh-tus) An endocrine disease marked by an inability to maintain glucose homeostasis. The type 1 form results from autoimmune destruction of insulin-secreting cells; treatment usually requires daily insulin injections. The type 2 form most commonly results from reduced responsiveness of target cells to insulin; obesity and lack of exercise are risk factors."
  },
  {
    "front": "Diacylglycerol",
    "back": "(DAG) (d\u012b-a\u2032-sil-glis\u2032-er-ol) A second messenger produced by the cleavage of the phospholipid PIP2 in the plasma membrane."
  },
  {
    "front": "Diaphragm",
    "back": "(d\u012b\u2032-uh-fram\u2032) (1) A sheet of muscle that forms the bottom wall of the thoracic cavity in mammals. Contraction of the diaphragm pulls air into the lungs. (2) A dome-shaped rubber cup fitted into the upper portion of the vagina before sexual intercourse. It serves as a physical barrier to the passage of sperm into the uterus."
  },
  {
    "front": "Diapsids",
    "back": "(d\u012b-ap\u2032-sid) A member of an amniote clade distinguished by a pair of holes on each side of the skull. Diapsids include the lepidosaurs and archosaurs."
  },
  {
    "front": "Diastole",
    "back": "(d\u012b-as\u2032-t\u014d-l\u0113) The stage of the cardiac cycle in which a heart chamber is relaxed and fills with blood."
  },
  {
    "front": "Diastolic pressure",
    "back": "Blood pressure in the arteries when the ventricles are relaxed."
  },
  {
    "front": "Diatoms",
    "back": "Photosynthetic protist in the stramenopile clade; diatoms have a unique glass-like wall made of silicon dioxide embedded in an organic matrix."
  },
  {
    "front": "Dicots",
    "back": "A term traditionally used to refer to flowering plants that have two embryonic seed leaves, or cotyledons. Recent molecular evidence indicates that dicots do not form a clade; species once classified as dicots are now grouped into eudicots, magnoliids, and several lineages of basal angiosperms."
  },
  {
    "front": "Differential gene expression",
    "back": "The expression of different sets of genes by cells with the same genome."
  },
  {
    "front": "Differentiation",
    "back": "The process by which a cell or group of cells becomes specialized in structure and function."
  },
  {
    "front": "Diffusion",
    "back": "The random thermal motion of particles of liquids, gases, or solids. In the presence of a concentration or electrochemical gradient, diffusion results in the net movement of a substance from a region where it is more concentrated to a region where it is less concentrated."
  },
  {
    "front": "Digestion",
    "back": "The second stage of food processing in animals: the breaking down of food into molecules small enough for the body to absorb."
  },
  {
    "front": "Dihybrid cross",
    "back": "(d\u012b\u2032-h\u012b\u2032-brid) A cross between two organisms that are each heterozygous for both of the characters being followed (or the self-pollination of a plant that is heterozygous for both characters)."
  },
  {
    "front": "Dihybrids",
    "back": "(d\u012b\u2032-h\u012b\u2032-brid) An organism that is heterozygous with respect to two genes of interest. All the offspring from a cross between parents doubly homozygous for different alleles are dihybrids. For example, parents of genotypesAABBandaabbproduce a dihybrid of genotypeAaBb."
  },
  {
    "front": "Dikaryotic",
    "back": "(d\u012b\u2032-k\u0101r-\u0113-ot\u2032-ik) Referring to a fungal mycelium with two haploid nuclei per cell, one from each parent."
  },
  {
    "front": "Dinoflagellates",
    "back": "(d\u012b\u2032-n\u014d-flaj\u2032-uh-let) A member of a group of mostly unicellular photosynthetic algae with two flagella situated in perpendicular grooves in cellulose plates covering the cell."
  },
  {
    "front": "Dinosaurs",
    "back": "A member of an extremely diverse clade of reptiles varying in body shape, size, and habitat. Birds are the only extant dinosaurs."
  },
  {
    "front": "Diploblastic",
    "back": "Having two germ layers."
  },
  {
    "front": "Diploid cell",
    "back": "(dip\u2032-loyd) A cell containing two sets of chromosomes (2n), one set inherited from each parent."
  },
  {
    "front": "Diplomonads",
    "back": "A protist that has modified mitochondria, two equal-sized nuclei, and multiple flagella."
  },
  {
    "front": "Directional selection",
    "back": "Natural selection in which individuals at one end of the phenotypic range survive or reproduce more successfully than do other individuals."
  },
  {
    "front": "Disaccharide",
    "back": "(d\u012b-sak\u2032-uh-r\u012bd) A double sugar, consisting of two monosaccharides joined by a glycosidic linkage formed by a dehydration reaction."
  },
  {
    "front": "Dispersal",
    "back": "The movement of individuals or gametes away from their parent location. This movement sometimes expands the geographic range of a population or species."
  },
  {
    "front": "Dispersion",
    "back": "The pattern of spacing among individuals within the boundaries of a population."
  },
  {
    "front": "Disruptive selection",
    "back": "Natural selection in which individuals on both extremes of a phenotypic range survive or reproduce more successfully than do individuals with intermediate phenotypes."
  },
  {
    "front": "Distal tubule",
    "back": "In the vertebrate kidney, the portion of a nephron that helps refine filtrate and empties it into a collecting duct."
  },
  {
    "front": "Disturbance",
    "back": "A natural or human-caused event that changes a biological community and usually removes organisms from it. Disturbances, such as fires and storms, play a pivotal role in structuring many communities."
  },
  {
    "front": "DNA",
    "back": "(deoxyribonucleic acid) (d\u0113-ok\u2032-s\u0113-r\u012b\u2032-b\u014d-n\u016b-kl\u0101\u2032-ik) A nucleic acid molecule, usually a double-stranded helix, in which each polynucleotide strand consists of nucleotide monomers with a deoxyribose sugar and the nitrogenous bases adenine (A), cytosine (C), guanine (G), and thymine (T); capable of being replicated and determining the inherited structure of a cell\u2019s proteins.",
    "chapter": 1
  },
  {
    "front": "DNA cloning",
    "back": "The production of multiple copies of a specific DNA segment."
  },
  {
    "front": "DNA ligase",
    "back": "(l\u012b\u2032-g\u0101s) A linking enzyme essential for DNA replication; catalyzes the covalent bonding of the 3\u2032 end of one DNA fragment (such as an Okazaki fragment) to the 5\u2032 end of another DNA fragment (such as a growing DNA chain)."
  },
  {
    "front": "DNA methylation",
    "back": "The presence of methyl groups on the DNA bases (usually cytosine) of plants, animals, and fungi. (The term also refers to the process of adding methyl groups to DNA bases.)"
  },
  {
    "front": "DNA microarray assays",
    "back": "A method to detect and measure the expression of thousands of genes at one time. Tiny amounts of a large number of single-stranded DNA fragments representing different genes are fixed to a glass slide and tested for hybridization with samples of labeled cDNA."
  },
  {
    "front": "DNA polymerases",
    "back": "(puh-lim\u2032-er-\u0101s) An enzyme that catalyzes the elongation of new DNA (for example, at a replication fork) by the addition of nucleotides to the 3\u2032 end of an existing chain. There are several different DNA polymerases; DNA polymerase III and DNA polymerase I play major roles in DNA replication inE. coli."
  },
  {
    "front": "DNA replication",
    "back": "The process by which a DNA molecule is copied; also called DNA synthesis."
  },
  {
    "front": "DNA sequencing",
    "back": "Determining the complete nucleotide sequence of a gene or DNA segment."
  },
  {
    "front": "DNA technology",
    "back": "Techniques for sequencing and manipulating DNA."
  },
  {
    "front": "Domains",
    "back": "(1) A taxonomic category above the kingdom level. The three domains are Archaea, Bacteria, and Eukarya. (2) A discrete structural and functional region of a protein."
  },
  {
    "front": "Dominant allele",
    "back": "An allele that is fully expressed in the phenotype of a heterozygote."
  },
  {
    "front": "Dormancy",
    "back": "A condition typified by extremely low metabolic rate and a suspension of growth and development."
  },
  {
    "front": "Dorsal",
    "back": "In an animal with bilateral symmetry, pertaining to the top (in most animals) or back (in animals with upright posture) of the body."
  },
  {
    "front": "Dorsal lip",
    "back": "The region above the blastopore on the dorsal side of the amphibian embryo."
  },
  {
    "front": "Double bond",
    "back": "A double covalent bond; the sharing of two pairs of valence electrons by two atoms.",
    "chapter": 2
  },
  {
    "front": "Double circulation",
    "back": "A circulatory system consisting of separate pulmonary and systemic circuits, in which blood passes through the heart after completing each circuit."
  },
  {
    "front": "Double fertilization",
    "back": "A mechanism of fertilization in angiosperms in which two sperm cells unite with two cells in the female gametophyte (embryo sac) to form the zygote and endosperm."
  },
  {
    "front": "Double helix",
    "back": "The form of native DNA, referring to its two adjacent antiparallel polynucleotide strands wound around an imaginary axis into a spiral shape."
  },
  {
    "front": "Down syndrome",
    "back": "A human genetic disease usually caused by the presence of an extra chromosome 21; characterized by developmental delays and heart and other defects that are generally treatable or non-life-threatening."
  },
  {
    "front": "Duchenne muscular dystrophy",
    "back": "(duh-shen\u2032) A human genetic disease caused by a sex-linked recessive allele; characterized by progressive weakening and a loss of muscle tissue."
  },
  {
    "front": "Duodenum",
    "back": "(d\u016b\u2032-uh-d\u0113n\u2032-um) The first section of the small intestine, where chyme from the stomach mixes with digestive juices from the pancreas, liver, and gallbladder as well as from gland cells of the intestinal wall."
  },
  {
    "front": "Duplication",
    "back": "An aberration in chromosome structure due to fusion with a fragment from a homologous chromosome, such that a portion of a chromosome is duplicated."
  },
  {
    "front": "Dyneins",
    "back": "(d\u012b'-n\u0113-un) In cilia and flagella, a large motor protein extending from one microtubule doublet to the adjacent doublet. ATP hydrolysis drives changes in dynein shape that lead to bending of cilia and flagella."
  },
  {
    "front": "E site",
    "back": "One of a ribosome\u2019s three binding sites for tRNA during translation. The E site is the place where discharged tRNAs leave the ribosome. (E stands for exit.)"
  },
  {
    "front": "Ecdysozoa",
    "back": "(ek\u2032-d\u0113-s\u014d-z\u014d\u2219-uh) One of the three main lineages of bilaterian animals; many ecdysozoans are molting animals."
  },
  {
    "front": "Echinoderms",
    "back": "(i-k\u012b\u2032-n\u014d-derm) A slow-moving or sessile marine deuterostome with a water vascular system and, in larvae, bilateral symmetry. Echinoderms include sea stars, brittle stars, sea urchins, feather stars, and sea cucumbers."
  },
  {
    "front": "Ecological footprint",
    "back": "The aggregate land and water area required by a person, city, or nation to produce all of the resources it consumes and to absorb all of the waste it generates."
  },
  {
    "front": "Ecological niche",
    "back": "(nich) The sum of a species\u2019 use of the biotic and abiotic resources in its environment."
  },
  {
    "front": "Ecological species concept",
    "back": "Definition of a species in terms of ecological niche, the sum of how members of the species interact with the nonliving and living parts of their environment."
  },
  {
    "front": "Ecological succession",
    "back": "Transition in the species composition of a community following a disturbance; establishment of a community in an area virtually barren of life."
  },
  {
    "front": "Ecology",
    "back": "The study of how organisms interact with each other and their environment."
  },
  {
    "front": "Ecosystem",
    "back": "All the organisms in a given area as well as the abiotic factors with which they interact; one or more communities and the physical environment around them."
  },
  {
    "front": "Ecosystem engineers",
    "back": "An organism that influences community structure by causing physical changes in the environment."
  },
  {
    "front": "Ecosystem services",
    "back": "A function performed by an ecosystem that directly or indirectly benefits humans."
  },
  {
    "front": "Ecotone",
    "back": "The transition from one type of habitat or ecosystem to another, such as the transition from a forest to a grassland."
  },
  {
    "front": "Ectoderm",
    "back": "(ek\u2032-t\u014d-durm) The outermost of the three primary germ layers in animal embryos; gives rise to the outer covering and, in some phyla, the nervous system, inner ear, and lens of the eye."
  },
  {
    "front": "Ectomycorrhizae",
    "back": "(plural,ectomycorrhizae) (ek\u2032-t\u014d-m\u012b\u2032-k\u014d-r\u012b\u2032-zuh, ek\u2032-t\u014d-m\u012b\u2032-k\u014d-r\u012b\u2032-z\u0113) Association of a fungus with a plant root system in which the fungus surrounds the roots but does not cause invagination of the host (plant) cell\u2019s plasma membrane."
  },
  {
    "front": "Ectomycorrhizal fungi",
    "back": "A symbiotic fungus that forms sheaths of hyphae over the surface of plant roots and also grows into extracellular spaces of the root cortex."
  },
  {
    "front": "Ectoparasites",
    "back": "A parasite that feeds on the external surface of a host."
  },
  {
    "front": "Ectopic",
    "back": "Occurring in an abnormal location."
  },
  {
    "front": "Ectoprocts",
    "back": "A sessile, colonial lophotrochozoan; also called a bryozoan."
  },
  {
    "front": "Ectothermic",
    "back": "Referring to organisms for which external sources provide most of the heat for temperature regulation."
  },
  {
    "front": "Ediacaran biota",
    "back": "(\u0113\u2032-d\u0113-uh-keh\u2032-run b\u012b-\u014d\u2032-tuh) An early group of macroscopic, mostly soft-bodied, multicellular eukaryotes known from fossils that range in age from 635 million to 541 million years old."
  },
  {
    "front": "Effective population size",
    "back": "An estimate of the size of a population based on the numbers of females and males that successfully breed; generally smaller than the total population."
  },
  {
    "front": "Effector cells",
    "back": "(1) A muscle cell or gland cell that carries out the body\u2019s response to stimuli as directed by signals from the brain or other processing center of the nervous system. (2) A lymphocyte that has undergone clonal selection and is capable of mediating an adaptive immune response."
  },
  {
    "front": "Effectors",
    "back": "A pathogen-encoded protein that cripples the host\u2019s innate immune system."
  },
  {
    "front": "Egg",
    "back": "The female gamete."
  },
  {
    "front": "Ejaculation",
    "back": "The propulsion of sperm from the epididymis through the muscular vas deferens, ejaculatory duct, and urethra."
  },
  {
    "front": "Electrocardiogram",
    "back": "(ECG or EKG) A record of the electrical impulses that travel through heart muscle during the cardiac cycle."
  },
  {
    "front": "Electrochemical gradient",
    "back": "The diffusion gradient of an ion, which is affected by both the concentration difference of an ion across a membrane (a chemical force) and the ion\u2019s tendency to move relative to the membrane potential (an electrical force)."
  },
  {
    "front": "Electrogenic pump",
    "back": "An active transport protein that generates voltage across a membrane while pumping ions."
  },
  {
    "front": "Electromagnetic receptor",
    "back": "A receptor of electromagnetic energy, such as visible light, electricity, or magnetism."
  },
  {
    "front": "Electromagnetic spectrum",
    "back": "The entire spectrum of electromagnetic radiation, ranging in wavelength from less than a nanometer to more than a kilometer."
  },
  {
    "front": "Electron microscope",
    "back": "(EM) A microscope that uses magnets to focus an electron beam on or through a specimen, resulting in a practical resolution that is 100-fold greater than that of a light microscope using standard techniques. A transmission electron microscope (TEM) is used to study the internal structure of thin sections of cells. A scanning electron microscope (SEM) is used to study the fine details of cell surfaces."
  },
  {
    "front": "Electron shells",
    "back": "An energy level of electrons at a characteristic average distance from the nucleus of an atom.",
    "chapter": 2
  },
  {
    "front": "Electron transport chain",
    "back": "A sequence of electron carrier molecules (membrane proteins) that shuttle electrons down a series of redox reactions that release energy used to make ATP."
  },
  {
    "front": "Electronegativity",
    "back": "The attraction of a given atom for the electrons of a covalent bond.",
    "chapter": 2
  },
  {
    "front": "Electrons",
    "back": "A subatomic particle with a single negative electrical charge and a mass about 1 two-thousandths that of a neutron or proton. One or more electrons move around the nucleus of an atom.",
    "chapter": 2
  },
  {
    "front": "Electroporation",
    "back": "A technique to introduce recombinant DNA into cells by applying a brief electrical pulse to a solution containing the cells. The pulse creates temporary holes in the cells\u2019 plasma membranes, through which DNA can enter."
  },
  {
    "front": "Element",
    "back": "Any substance that cannot be broken down to any other substance by chemical reactions.",
    "chapter": 2
  },
  {
    "front": "Elimination",
    "back": "The fourth and final stage of food processing in animals: the passing of undigested material out of the body."
  },
  {
    "front": "Embryo sac",
    "back": "(em\u2032-br\u0113-\u014d) The female gametophyte of angiosperms, formed from the growth and division of the megaspore into a multicellular structure that typically has eight haploid nuclei."
  },
  {
    "front": "Embryonic lethals",
    "back": "A mutation with a phenotype leading to death of an embryo or larva."
  },
  {
    "front": "Emergent properties",
    "back": "New properties that arise with each step upward in the hierarchy of life, owing to the arrangement and interactions of parts as complexity increases.",
    "chapter": 1
  },
  {
    "front": "Emigration",
    "back": "The movement of individuals out of a population."
  },
  {
    "front": "Enantiomers",
    "back": "(en-an\u2032-t\u0113-\u014d-mer) One of two compounds that are mirror images of each other and that differ in shape due to the presence of an asymmetric carbon."
  },
  {
    "front": "Endangered species",
    "back": "A species that is in danger of extinction throughout all or a significant portion of its range."
  },
  {
    "front": "Endergonic reaction",
    "back": "(en\u2032-der-gon\u2032-ik) A nonspontaneous chemical reaction in which free energy is absorbed from the surroundings."
  },
  {
    "front": "Endocrine glands",
    "back": "(en\u2032-d\u014d-krin) A ductless gland that secretes hormones directly into the interstitial fluid, from which they diffuse into the bloodstream."
  },
  {
    "front": "Endocrine system",
    "back": "(en\u2032-d\u014d-krin) In animals, the internal system of communication involving hormones, the ductless glands that secrete hormones, and the molecular receptors on or in target cells that respond to hormones; functions in concert with the nervous system to effect internal regulation and maintain homeostasis."
  },
  {
    "front": "Endocytosis",
    "back": "(en\u2032-d\u014d-s\u012b-t\u014d\u2032-sis) Cellular uptake of biological molecules and particulate matter via formation of vesicles from the plasma membrane."
  },
  {
    "front": "Endoderm",
    "back": "(en\u2032-d\u014d-durm) The innermost of the three primary germ layers in animal embryos; lines the archenteron and gives rise to the liver, pancreas, lungs, and the lining of the digestive tract in species that have these structures."
  },
  {
    "front": "Endodermis",
    "back": "In plant roots, the innermost layer of the cortex that surrounds the vascular cylinder."
  },
  {
    "front": "Endomembrane system",
    "back": "The collection of membranes inside and surrounding a eukaryotic cell, related either through direct physical contact or by the transfer of membranous vesicles; includes the plasma membrane, the nuclear envelope, the smooth and rough endoplasmic reticulum, the Golgi apparatus, lysosomes, vesicles, and vacuoles."
  },
  {
    "front": "Endometriosis",
    "back": "(en\u2032-d\u014d-m\u0113-tr\u0113-\u014d\u2032-sis) The condition resulting from the presence of endometrial tissue outside of the uterus."
  },
  {
    "front": "Endometrium",
    "back": "(en\u2032-d\u014d-m\u0113\u2032-tr\u0113-um) The inner lining of the uterus, which is richly supplied with blood vessels."
  },
  {
    "front": "Endoparasites",
    "back": "A parasite that lives within a host."
  },
  {
    "front": "Endophytes",
    "back": "A harmless fungus, or occasionally another organism, that lives between cells of a plant part or multicellular alga."
  },
  {
    "front": "Endoplasmic reticulum",
    "back": "(ER) (en\u2032-d\u014d-plaz\u2032-mik ruh-tik\u2032-y\u016b-lum) An extensive membranous network in eukaryotic cells, continuous with the outer nuclear membrane and composed of ribosome-studded (rough) and ribosome-free (smooth) regions."
  },
  {
    "front": "Endorphins",
    "back": "(en-d\u014dr\u2032-fin) Any of several hormones produced in the brain and anterior pituitary that inhibit pain perception."
  },
  {
    "front": "Endoskeleton",
    "back": "A hard skeleton buried within the soft tissues of an animal."
  },
  {
    "front": "Endosperm",
    "back": "In angiosperms, a nutrient-rich tissue formed by the union of a sperm with two polar nuclei during double fertilization. The endosperm provides nourishment to the developing embryo in angiosperm seeds."
  },
  {
    "front": "Endospores",
    "back": "A thick-coated, resistant cell produced by some bacterial cells when they are exposed to harsh conditions."
  },
  {
    "front": "Endosymbiont theory",
    "back": "The theory that mitochondria and plastids originated as prokaryotic cells engulfed by a host cell. The engulfed cell and its host cell then evolved into a single organism; A relationship between two species in which one organism lives inside the cell or cells of another organism."
  },
  {
    "front": "Endosymbiosis",
    "back": "A relationship between two species in which one organism lives inside the cell or cells of another organism; The theory that mitochondria and plastids originated as prokaryotic cells engulfed by a host cell. The engulfed cell and its host cell then evolved into a single organism."
  },
  {
    "front": "Endothelium",
    "back": "(en\u2032-d\u014d-th\u0113\u2032-l\u0113-um) The simple squamous layer of cells lining the lumen of blood vessels."
  },
  {
    "front": "Endothermic",
    "back": "Referring to organisms that are warmed by heat generated by their own metabolism. This heat usually maintains a relatively stable body temperature higher than that of the external environment."
  },
  {
    "front": "Endotoxins",
    "back": "A toxic component of the outer membrane of certain gram-negative bacteria that is released only when the bacteria die."
  },
  {
    "front": "Energetic hypothesis",
    "back": "The concept that the length of a food chain is limited by the inefficiency of energy transfer along the chain."
  },
  {
    "front": "Energy",
    "back": "The capacity to cause change, especially to do work (to move matter against an opposing force).",
    "chapter": 2
  },
  {
    "front": "Energy coupling",
    "back": "In cellular metabolism, the use of energy released from an exergonic reaction to drive an endergonic reaction."
  },
  {
    "front": "Enhancers",
    "back": "A segment of eukaryotic DNA containing multiple control elements, usually located far from the gene whose transcription it regulates."
  },
  {
    "front": "Enteric nervous system",
    "back": "Within the autonomic nervous system, a distinct network of neurons that exerts partially independent control over the digestive tract, pancreas, and gallbladder."
  },
  {
    "front": "Entropy",
    "back": "A measure of molecular disorder, or randomness."
  },
  {
    "front": "Enzyme-substrate complex",
    "back": "(en\u2032-z\u012bm) A temporary complex formed when an enzyme binds to its substrate molecule(s)."
  },
  {
    "front": "Enzymes",
    "back": "(en\u2032-z\u012bm) A macromolecule serving as a catalyst, a chemical agent that increases the rate of a reaction without being consumed by the reaction. Most enzymes are proteins."
  },
  {
    "front": "Eosinophils",
    "back": "Immune system cell that secretes destructive enzymes and helps defend against multicellular pathogens."
  },
  {
    "front": "Epicotyl",
    "back": "(ep\u2032-uh-kot\u2032-ul) In an angiosperm embryo, the embryonic axis above the point of attachment of the cotyledon(s) and below the first pair of miniature leaves."
  },
  {
    "front": "Epidemic",
    "back": "A widespread outbreak of a disease."
  },
  {
    "front": "Epidermis",
    "back": "(1) The dermal tissue of nonwoody plants, usually consisting of a single layer of tightly packed cells. (2) The outermost layer of cells in an animal."
  },
  {
    "front": "Epididymis",
    "back": "(ep\u2032-uh-did\u2032-uh-mus) A coiled tubule located adjacent to the mammalian testis where sperm are stored."
  },
  {
    "front": "Epigenetics",
    "back": "The study of the inheritance of traits transmitted by mechanisms that do not involve the nucleotide sequence."
  },
  {
    "front": "Epinephrine",
    "back": "(ep\u2032-i-nef\u2032-rin) A catecholamine that, when secreted as a hormone by the adrenal medulla, mediates \u201cfight-or-flight\u201d responses to short-term stresses; also released by some neurons as a neurotransmitter; also called adrenaline."
  },
  {
    "front": "Epiphytes",
    "back": "(ep\u2032-uh-f\u012bt) A plant that nourishes itself but grows on the surface of another plant for support, usually on the branches or trunks of trees."
  },
  {
    "front": "Epistasis",
    "back": "(ep\u2032-i-st\u0101\u2032-sis) A type of gene interaction in which the phenotypic expression of one gene alters that of another independently inherited gene."
  },
  {
    "front": "Epithelial tissue",
    "back": "(ep\u2032-uh-th\u0113\u2032-l\u0113-ul) Sheets of tightly packed cells that line organs and body cavities as well as external surfaces."
  },
  {
    "front": "Epitope",
    "back": "A small, accessible region of an antigen to which an antigen receptor or antibody binds."
  },
  {
    "front": "Equilibrium potential",
    "back": "(Esubscript ion) The magnitude of a cell\u2019s membrane voltage at equilibrium; calculated using the Nernst equation."
  },
  {
    "front": "Erythrocytes",
    "back": "(eh-rith\u2032-ruh-s\u012bt) A blood cell that contains hemoglobin, which transports oxygen; also called a red blood cell."
  },
  {
    "front": "Erythropoietin",
    "back": "(EPO) (eh-rith\u2032-r\u014d-poy\u2032-uh-tin) A hormone that stimulates the production of erythrocytes. It is secreted by the kidney when body tissues do not receive enough oxygen."
  },
  {
    "front": "Esophagus",
    "back": "(eh-sof\u2032-uh-gus) A muscular tube that conducts food, by peristalsis, from the pharynx to the stomach."
  },
  {
    "front": "Essential amino acids",
    "back": "An amino acid that an animal cannot synthesize itself and must be obtained from food in prefabricated form."
  },
  {
    "front": "Essential elements",
    "back": "A chemical element required for an organism to survive, grow, and reproduce.",
    "chapter": 2
  },
  {
    "front": "Essential fatty acids",
    "back": "An unsaturated fatty acid that an animal needs but cannot make."
  },
  {
    "front": "Essential nutrients",
    "back": "A substance that an organism cannot synthesize from any other material and therefore must absorb in preassembled form."
  },
  {
    "front": "Estradiol",
    "back": "(es\u2032-truh-d\u012b\u2032-ol) A steroid hormone that stimulates the development and maintenance of the female reproductive system and secondary sex characteristics; the major estrogen in mammals."
  },
  {
    "front": "Estrogens",
    "back": "(es\u2032-tr\u014d-jen) Any steroid hormone, such as estradiol, that stimulates the development and maintenance of the female reproductive system and secondary sex characteristics."
  },
  {
    "front": "Estrous cycle",
    "back": "(es\u2032-trus) A reproductive cycle characteristic of female mammals except humans and certain other primates, in which the endometrium is reabsorbed in the absence of pregnancy and sexual response occurs only during a mid-cycle point known as estrus."
  },
  {
    "front": "Ethylene",
    "back": "(eth\u2032-uh-l\u0113n) A gaseous plant hormone involved in responses to mechanical stress, programmed cell death, leaf abscission, and fruit ripening."
  },
  {
    "front": "Etiolation",
    "back": "Plant morphological adaptations for growing in darkness."
  },
  {
    "front": "Euchromatin",
    "back": "(y\u016b-kr\u014d\u2032-muh-tin) The less condensed form of eukaryotic chromatin that is available for transcription."
  },
  {
    "front": "Eudicots",
    "back": "(y\u016b-d\u012b\u2032-kot) A member of a clade that contains the vast majority of flowering plants that have two embryonic seed leaves, or cotyledons."
  },
  {
    "front": "Euglenid",
    "back": "(y\u016b\u2032-glen-id) A protist, such asEuglenaor its relatives, characterized by an anterior pocket from which one or two flagella emerge."
  },
  {
    "front": "Euglenozoans",
    "back": "A member of a diverse clade of flagellated protists that includes predatory heterotrophs, photosynthetic autotrophs, and pathogenic parasites."
  },
  {
    "front": "Eukarya",
    "back": "(y\u016b-k\u0101r\u2032-\u0113-uh) The domain that includes all eukaryotic organisms.",
    "chapter": 1
  },
  {
    "front": "Eukaryotic cell",
    "back": "(y\u016b\u2032-k\u0101r-\u0113-ot\u2032-ik) A type of cell with a membrane-enclosed nucleus and membrane-enclosed organelles. Organisms with eukaryotic cells (protists, plants, fungi, and animals) are called eukaryotes.",
    "chapter": 1
  },
  {
    "front": "Eumetazoans",
    "back": "(y\u016b\u2032-met-uh-z\u014d\u2032-un) A member of a clade of animals with true tissues. All animals except sponges and a few other groups are eumetazoans."
  },
  {
    "front": "Eurypterids",
    "back": "(yur-ip\u2032-tuh-rid) An extinct carnivorous chelicerate; also called a water scorpion."
  },
  {
    "front": "Eutherians",
    "back": "(y\u016b-th\u0113r\u2032-\u0113-un) Placental mammal; mammal whose young complete their embryonic development within the uterus, joined to the female parent by the placenta."
  },
  {
    "front": "Eutrophication",
    "back": "A process by which nutrients, particularly phosphorus and nitrogen, become highly concentrated in a body of water, leading to increased growth of organisms such as algae or cyanobacteria."
  },
  {
    "front": "Evaporative cooling",
    "back": "The process in which the surface of an object becomes cooler during evaporation, a result of the molecules with the greatest kinetic energy changing from the liquid to the gaseous state."
  },
  {
    "front": "Evapotranspiration",
    "back": "The total evaporation of water from an ecosystem, including water transpired by plants and evaporated from a landscape, usually measured in millimeters and estimated for a year."
  },
  {
    "front": "Evo-devo",
    "back": "Evolutionary developmental biology; a field of biology that compares developmental processes of different multicellular organisms to understand how these processes have evolved and how changes can modify existing organismal features or lead to new ones."
  },
  {
    "front": "Evolution",
    "back": "Descent with modification; the process by which species accumulate differences from their ancestors as they adapt to different environments over time; also defined as a change in the genetic composition of a population from generation to generation.",
    "chapter": 1
  },
  {
    "front": "Evolutionary lineage",
    "back": "The sequence of ancestral organisms leading to a particular taxon; represented by a branch (line) in a phylogenetic tree."
  },
  {
    "front": "Evolutionary tree",
    "back": "A branching diagram that reflects a hypothesis about evolutionary relationships among groups of organisms."
  },
  {
    "front": "Excavata",
    "back": "(ex\u2032-kuh-vah\u2032-tuh) One of four super-groups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. Excavates have unique cytoskeletal features, and some species have an \u201cexcavated\u201d feeding groove on one side of the cell body; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This supergroup contains a large, extremely diverse collection of protists from three major subgroups: stramenopiles, alveolates, and rhizarians; and One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This monophyletic group, which includes red algae, green algae, and plants, descended from an ancient protistan ancestor that engulfed a cyanobacterium, One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This clade, which is supported by studies of myosin proteins and DNA, consists of amoebozoans and opisthokonts."
  },
  {
    "front": "Excitatory postsynaptic potential",
    "back": "(EPSP) An electrical change (depolarization) in the membrane of a postsynaptic cell caused by the binding of an excitatory neurotransmitter from a presynaptic cell to a postsynaptic receptor; makes it more likely for a postsynaptic cell to generate an action potential."
  },
  {
    "front": "Excretion",
    "back": "The disposal of nitrogen-containing metabolites and other waste products."
  },
  {
    "front": "Exergonic reaction",
    "back": "(ek\u2032-ser-gon\u2032-ik) A spontaneous chemical reaction in which there is a net release of free energy."
  },
  {
    "front": "Exocytosis",
    "back": "(ek\u2032-s\u014d-s\u012b-t\u014d\u2032-sis) The cellular secretion of biological molecules by the fusion of vesicles containing them with the plasma membrane."
  },
  {
    "front": "Exons",
    "back": "A sequence within a primary transcript that remains in the RNA after RNA processing; also refers to the region of DNA from which this sequence was transcribed."
  },
  {
    "front": "Exoskeleton",
    "back": "A hard encasement on the surface of an animal, such as the shell of a mollusc or the cuticle of an arthropod, that provides protection and points of attachment for muscles."
  },
  {
    "front": "Exotoxins",
    "back": "(ek\u2032-s\u014d-tok\u2032-sin) A toxic protein that is secreted by a prokaryote or other pathogen and that produces specific symptoms, even if the pathogen is no longer present."
  },
  {
    "front": "Expansins",
    "back": "A nonenzyme plant protein that breaks the cross-links (hydrogen bonds) between cellulose microfibrils and other cell wall constituents, loosening the wall\u2019s fabric."
  },
  {
    "front": "Experiment",
    "back": "A scientific test. Often carried out under controlled conditions that involve manipulating one factor in a system in order to see the effects of changing that factor.",
    "chapter": 1
  },
  {
    "front": "Experimental group",
    "back": "A set of subjects that has (or receives) the specific factor being tested in a controlled experiment. Ideally, the experimental group is identical to the control group for all other factors.",
    "chapter": 1
  },
  {
    "front": "Exploitation",
    "back": "A positive-negative ecological interaction in which individuals of one species benefit by feeding on (and thereby harming) individuals of the other species. Exploitative interactions include predation, herbivory, and parasitism."
  },
  {
    "front": "Exponential population growth",
    "back": "Growth of a population in an ideal, unlimited environment, represented by a J-shaped curve when population size is plotted over time."
  },
  {
    "front": "Expression vector",
    "back": "A cloning vector that contains a highly active bacterial promoter just upstream of a restriction site where a eukaryotic gene can be inserted, allowing the gene to be expressed in a bacterial cell. Expression vectors are also available that have been genetically engineered for use in specific types of eukaryotic cells."
  },
  {
    "front": "Extinction vortex",
    "back": "A downward population spiral in which inbreeding and genetic drift combine to cause a small population to shrink and, unless the spiral is reversed, become extinct."
  },
  {
    "front": "Extracellular matrix",
    "back": "(ECM) The meshwork surrounding animal cells, consisting of glycoproteins, polysaccharides, and proteoglycans synthesized and secreted by cells."
  },
  {
    "front": "Extraembryonic membranes",
    "back": "One of four membranes (yolk sac, amnion, chorion, and allantois) located outside the embryo that support the developing embryo in reptiles and mammals."
  },
  {
    "front": "Extreme halophiles",
    "back": "An organism that lives in a highly saline environment, such as the Great Salt Lake or the Dead Sea."
  },
  {
    "front": "Extreme thermophiles",
    "back": "An organism that thrives in hot environments (often 60 to 80 degrees C or hotter)."
  },
  {
    "front": "Extremophiles",
    "back": "An organism that lives in environmental conditions so extreme that few other species can survive there. Extremophiles include extreme halophiles (\u201csalt lovers\u201d) and extreme thermophiles (\u201cheat lovers\u201d)."
  },
  {
    "front": "F factor",
    "back": "In bacteria, the DNA segment that confers the ability to form pili for conjugation and associated functions required for the transfer of DNA from donor to recipient. The F factor may exist as a plasmid or be integrated into the bacterial chromosome."
  },
  {
    "front": "F plasmid",
    "back": "The plasmid form of the F factor."
  },
  {
    "front": "F1 generation",
    "back": "The first filial, hybrid (heterozygous) offspring arising from a parental (P generation) cross."
  },
  {
    "front": "F2 generation",
    "back": "The offspring resulting from interbreeding (or self-pollination) of the hybrid F1 generation."
  },
  {
    "front": "Facilitated diffusion",
    "back": "The passage of molecules or ions down their electrochemical gradient across a biological membrane with the assistance of specific transmembrane transport proteins, requiring no energy expenditure."
  },
  {
    "front": "Facultative anaerobes",
    "back": "(fak\u2032-ul-t\u0101\u2032-tiv an\u2032-uh-r\u014db) An organism that makes ATP by aerobic respiration if oxygen is present but that switches to anaerobic respiration or fermentation if oxygen is not present."
  },
  {
    "front": "Family",
    "back": "In Linnaean classification, the taxonomic category above genus."
  },
  {
    "front": "Fast-twitch fibers",
    "back": "A muscle fiber used for rapid, powerful contractions."
  },
  {
    "front": "Fat",
    "back": "A lipid consisting of three fatty acids linked to one glycerol molecule; also called a triacylglycerol or triglyceride."
  },
  {
    "front": "Fate maps",
    "back": "A territorial diagram of embryonic development that displays the future derivatives of individual cells and tissues."
  },
  {
    "front": "Fatty acid",
    "back": "A carboxylic acid with a long carbon chain. Fatty acids vary in length and in the number and location of double bonds; three fatty acids linked to a glycerol molecule form a fat molecule, also called triacylglycerol or triglyceride."
  },
  {
    "front": "Feces",
    "back": "(f\u0113\u2032-s\u0113z) The wastes of the digestive tract."
  },
  {
    "front": "Feedback inhibition",
    "back": "A method of metabolic control in which the end product of a metabolic pathway acts as an inhibitor of an enzyme within that pathway."
  },
  {
    "front": "Feedback regulation",
    "back": "The regulation of a process by its output or end product.",
    "chapter": 1
  },
  {
    "front": "Fermentation",
    "back": "A catabolic process that makes a limited amount of ATP from glucose (or other organic molecules) without an electron transport chain and that produces a characteristic end product, such as ethyl alcohol or lactic acid."
  },
  {
    "front": "Fertilization",
    "back": "(1) The union of haploid gametes to produce a diploid zygote. (2) The addition of mineral nutrients to the soil"
  },
  {
    "front": "Fetus",
    "back": "(f\u0113\u2032-tus) A developing mammal that has all the major structures of an adult. In humans, the fetal stage lasts from the 9th week of gestation until birth."
  },
  {
    "front": "Fibroblasts",
    "back": "(f\u012b'-br\u014d-blast) A type of cell in loose connective tissue that secretes the protein ingredients of the extracellular fibers."
  },
  {
    "front": "Fibronectin",
    "back": "An extracellular glycoprotein secreted by animal cells that helps them attach to the extracellular matrix."
  },
  {
    "front": "Filament",
    "back": "In an angiosperm, the stalk portion of the stamen, the pollen-producing reproductive organ of a flower."
  },
  {
    "front": "Filter feeders",
    "back": "An animal that feeds by using a filtration mechanism to strain small organisms or food particles from its surroundings."
  },
  {
    "front": "Filtrate",
    "back": "Cell-free fluid extracted from the body fluid by the excretory system."
  },
  {
    "front": "Filtration",
    "back": "In excretory systems, the extraction of water and small solutes, including metabolic wastes, from the body fluid."
  },
  {
    "front": "Fimbriae",
    "back": "(plural,fimbriae) A short, hairlike appendage of a prokaryotic cell that helps it adhere to the substrate or to other cells."
  },
  {
    "front": "First law of thermodynamics",
    "back": "The principle of conservation of energy: Energy can be transferred and transformed, but it cannot be created or destroyed."
  },
  {
    "front": "Fission",
    "back": "The separation of an organism into two or more individuals of approximately equal size."
  },
  {
    "front": "Fixed action pattern",
    "back": "In animal behavior, a sequence of unlearned acts that is essentially unchangeable and, once initiated, usually carried to completion."
  },
  {
    "front": "Flaccid",
    "back": "(flas\u2032-id) Limp. Lacking turgor (stiffness or firmness), as in a plant cell in surroundings where there is a tendency for water to leave the cell. (A walled cell becomes flaccid if it has a higher water potential than its surroundings, resulting in the loss of water.)"
  },
  {
    "front": "Flagella",
    "back": "(fluh-jel'-um) (plural,flagella) A long cellular appendage specialized for locomotion. Like motile cilia, eukaryotic flagella have a core with nine outer doublet microtubules and two inner single microtubules (the \u201c9 plus 2\u201d arrangement) ensheathed in an extension of the plasma membrane. Prokaryotic flagella have a different structure."
  },
  {
    "front": "Florigen",
    "back": "A flowering signal, probably a protein, that is made in leaves under certain conditions and that travels to the shoot apical meristems, inducing them to switch from vegetative to reproductive growth."
  },
  {
    "front": "Flower",
    "back": "In an angiosperm, a specialized shoot with up to four sets of modified leaves, bearing structures that function in sexual reproduction."
  },
  {
    "front": "Fluid feeding",
    "back": "An animal that lives by sucking nutrient-rich fluids from another living organism."
  },
  {
    "front": "Fluid mosaic model",
    "back": "The currently accepted model of cell membrane structure, which envisions the membrane as a mosaic of protein molecules drifting laterally in a fluid bilayer of phospholipids."
  },
  {
    "front": "Follicle-stimulating hormone",
    "back": "(FSH) (fol\u2032-uh-kul) A tropic hormone that is produced and secreted by the anterior pituitary and that stimulates the production of eggs by the ovaries and sperm by the testes."
  },
  {
    "front": "Follicles",
    "back": "(fol\u2032-uh-kul) A microscopic structure in the ovary that contains the developing oocyte and secretes estrogens."
  },
  {
    "front": "Food chain",
    "back": "The pathway along which food energy is transferred from trophic level to trophic level, beginning with producers."
  },
  {
    "front": "Food vacuoles",
    "back": "A membranous sac formed by phagocytosis of microorganisms or particles to be used as food by the cell."
  },
  {
    "front": "Food web",
    "back": "The interconnected feeding relationships in an ecosystem."
  },
  {
    "front": "Foot",
    "back": "(1) The portion of a bryophyte sporophyte that gathers sugars, amino acids, water, and minerals from the parent gametophyte via transfer cells. (2) One of the three main parts of a mollusc; a muscular structure usually used for movement; One of the three main parts of a mollusc; a fold of tissue that drapes over the mollusc\u2019s visceral mass and may secrete a shell; and One of the three main parts of a mollusc; the part containing most of the internal organs."
  },
  {
    "front": "Foraging",
    "back": "The seeking and obtaining of food."
  },
  {
    "front": "Forams",
    "back": "(foraminiferans) An aquatic protist that secretes a hardened shell containing calcium carbonate and extends pseudopodia through pores in the shell."
  },
  {
    "front": "Forebrain",
    "back": "One of three ancestral and embryonic regions of the vertebrate brain; develops into the thalamus, hypothalamus, and cerebrum."
  },
  {
    "front": "Fossils",
    "back": "A preserved remnant or impression of an organism that lived in the past."
  },
  {
    "front": "Foundation species",
    "back": "A species that has strong effects on its community as a result of its large size, high abundance, or pivotal role in community dynamics. Foundation species may provide significant habitat or food for other species; they may also be competitively dominant in exploiting key resources."
  },
  {
    "front": "Founder effect",
    "back": "Genetic drift that occurs when a few individuals become isolated from a larger population and form a new population whose gene pool composition is not reflective of that of the original population."
  },
  {
    "front": "Fovea",
    "back": "(f\u014d\u2032-v\u0113-uh) The place on the retina at the eye\u2019s center of focus, where cones are highly concentrated."
  },
  {
    "front": "Fragmentation",
    "back": "A means of asexual reproduction whereby a single parent breaks into parts that regenerate into whole new individuals."
  },
  {
    "front": "Frameshift mutation",
    "back": "A mutation occurring when nucleotides are inserted in or deleted from a gene and the number inserted or deleted is not a multiple of three, resulting in the improper grouping of the subsequent nucleotides into codons."
  },
  {
    "front": "Free energy",
    "back": "The portion of a biological system\u2019s energy that can perform work when temperature and pressure are uniform throughout the system. The change in free energy of a system (deltaG) is calculated by taking the change in enthalphy (deltaH) and subtracting the product of the temperature (T) and the change in entropy (deltaS). In biological systems, the change in enthalphy is equivalent to total energy, and the change in temperature is the absolute temperature."
  },
  {
    "front": "Frequency-dependent selection",
    "back": "Selection in which the fitness of a phenotype depends on how common the phenotype is in a population."
  },
  {
    "front": "Fruit",
    "back": "A mature ovary of a flower. The fruit protects dormant seeds and often functions in their dispersal."
  },
  {
    "front": "Functional groups",
    "back": "A specific configuration of atoms commonly attached to the carbon skeletons of organic molecules and involved in chemical reactions."
  },
  {
    "front": "G protein-coupled receptors",
    "back": "(GPCRs) A signal receptor protein in the plasma membrane that responds to the binding of a signaling molecule by activating a G protein. Also called a G protein-linked receptor."
  },
  {
    "front": "G proteins",
    "back": "A GTP-binding protein that relays signals from a plasma membrane signal receptor, known as a G protein-coupled receptor, to other signal transduction proteins inside the cell."
  },
  {
    "front": "G0 phase",
    "back": "A nondividing state occupied by cells that have left the cell cycle, sometimes reversibly."
  },
  {
    "front": "G1 phase",
    "back": "The first gap, or growth phase, of the cell cycle, consisting of the portion of interphase before DNA synthesis begins."
  },
  {
    "front": "G2 phase",
    "back": "The second gap, or growth phase, of the cell cycle, consisting of the portion of interphase after DNA synthesis occurs."
  },
  {
    "front": "Gallbladder",
    "back": "An organ that stores bile and releases it as needed into the small intestine."
  },
  {
    "front": "Game theory",
    "back": "An approach to evaluating alternative strategies in situations where the outcome of a particular strategy depends on the strategies used by other individuals."
  },
  {
    "front": "Gametangia",
    "back": "(gam\u2032-uh-tan\u2032-j\u0113-um) (plural,gametangia) Multicellular plant structure in which gametes are formed. Female gametangia are called archegonia, and male gametangia are called antheridia."
  },
  {
    "front": "Gametes",
    "back": "(gam\u2032-\u0113t) A haploid reproductive cell, such as an egg or sperm, that is formed by meiosis or is the descendant of cells formed by meiosis. Gametes unite during sexual reproduction to produce a diploid zygote."
  },
  {
    "front": "Gametogenesis",
    "back": "(guh-m\u0113\u2032-t\u014d-gen\u2032-uh-sis) The process by which gametes are produced."
  },
  {
    "front": "Gametophytes",
    "back": "(guh-m\u0113'-t\u014d-f\u012bt) In organisms (plants and some algae) that have alternation of generations, the multicellular haploid form that produces haploid gametes by mitosis. The haploid gametes unite and develop into sporophytes."
  },
  {
    "front": "Ganglia",
    "back": "(gan\u2032-gl\u0113-uhn) (plural,ganglia) A cluster (functional group) of nerve cell bodies."
  },
  {
    "front": "Gap junctions",
    "back": "A type of intercellular junction in\nanimal cells, consisting of proteins surrounding\na pore that allows the passage of materials\nbetween cells."
  },
  {
    "front": "Gas exchange",
    "back": "The uptake of molecular oxygen from the environment and the discharge of carbon dioxide to the environment."
  },
  {
    "front": "Gastric juice",
    "back": "A digestive fluid secreted by the stomach."
  },
  {
    "front": "Gastrovascular cavity",
    "back": "A central cavity with a single opening in the body of certain animals, including cnidarians and flatworms, that functions in both the digestion and distribution of nutrients."
  },
  {
    "front": "Gastrula",
    "back": "(gas\u2032-tr\u016b-luh) An embryonic stage in animal development encompassing the formation of three layers: ectoderm, mesoderm, and endoderm."
  },
  {
    "front": "Gastrulation",
    "back": "(gas\u2032-tr\u016b-l\u0101\u2032-shun) In animal development, a series of cell and tissue movements in which the blastula-stage embryo folds inward, producing a three-layered embryo, the gastrula."
  },
  {
    "front": "Gated channels",
    "back": "A transmembrane protein channel that opens or closes in response to a particular stimulus."
  },
  {
    "front": "Gated ion channels",
    "back": "A gated channel for a specific ion. The opening or closing of such channels may alter a cell\u2019s membrane potential."
  },
  {
    "front": "Gel electrophoresis",
    "back": "(\u0113-lek\u2032-tr\u014d-f\u014dr-\u0113\u2032-sis) A technique for separating nucleic acids or proteins on the basis of their size and electrical charge, both of which affect their rate of movement through an electric field in a gel made of agarose or another polymer."
  },
  {
    "front": "Gene annotation",
    "back": "Analysis of genomic sequences to identify protein-coding genes and determine the function of their products."
  },
  {
    "front": "Gene cloning",
    "back": "The production of multiple copies of a gene."
  },
  {
    "front": "Gene drive",
    "back": "A process that biases inheritance such that a particular allele is more likely to be inherited than are other alleles, causing the favored allele to spread (be \u201cdriven\u201d) through the population."
  },
  {
    "front": "Gene editing",
    "back": "Altering genes in a specific, predictable way."
  },
  {
    "front": "Gene expression",
    "back": "The process by which information encoded in DNA directs the synthesis of proteins or, in some cases, RNAs that are not translated into proteins and instead function as RNAs.",
    "chapter": 1
  },
  {
    "front": "Gene flow",
    "back": "The transfer of alleles from one population to another, resulting from the movement of fertile individuals or their gametes."
  },
  {
    "front": "Gene pool",
    "back": "The aggregate of all copies of every type of allele at all loci in every individual in a population. The term is also used in a more restricted sense as the aggregate of alleles for just one or a few loci in a population."
  },
  {
    "front": "Gene therapy",
    "back": "The introduction of genes into an afflicted individual for therapeutic purposes."
  },
  {
    "front": "Genes",
    "back": "A discrete unit of hereditary information consisting of a specific nucleotide sequence in DNA (or RNA, in some viruses).",
    "chapter": 1
  },
  {
    "front": "Genetic drift",
    "back": "A process in which chance events cause unpredictable fluctuations in allele frequencies from one generation to the next. Effects of genetic drift are most pronounced in small populations."
  },
  {
    "front": "Genetic engineering",
    "back": "The direct manipulation of genes for practical purposes."
  },
  {
    "front": "Genetic map",
    "back": "An ordered list of genetic loci (genes or other genetic markers) along a chromosome."
  },
  {
    "front": "Genetic profile",
    "back": "An individual\u2019s unique set of genetic markers, detected most often today by PCR or, previously, by electrophoresis and nucleic acid probes."
  },
  {
    "front": "Genetic recombination",
    "back": "General term for the production of offspring with combinations of traits that differ from those found in either parent."
  },
  {
    "front": "Genetic variation",
    "back": "Differences among individuals in the composition of their genes or other DNA sequences."
  },
  {
    "front": "Genetically modified organisms",
    "back": "(GMOs) An organism that has acquired one or more genes by artificial means."
  },
  {
    "front": "Genetics",
    "back": "The scientific study of heredity and hereditary variation."
  },
  {
    "front": "Genome",
    "back": "(j\u0113\u2032-n\u014dm) The genetic material of an organism or virus; the complete complement of an organism\u2019s or virus\u2019s genes along with its noncoding nucleic acid sequences.",
    "chapter": 1
  },
  {
    "front": "Genome-wide association studies",
    "back": "(j\u0113\u2032-n\u014dm) A large-scale analysis of the genomes of many people having a certain phenotype or disease, with the aim of finding genetic markers that correlate with that phenotype or disease."
  },
  {
    "front": "Genomic imprinting",
    "back": "(juh-n\u014d\u2032-mik) A phenomenon in which expression of an allele in offspring depends on whether the allele is inherited from the male or female parent."
  },
  {
    "front": "Genomics",
    "back": "(juh-n\u014d\u2032-miks) The systematic study of whole sets of genes (or other DNA) and their interactions within a species, as well as genome comparisons between species.",
    "chapter": 1
  },
  {
    "front": "Genotype",
    "back": "(j\u0113\u2032-n\u014d-t\u012bp) The genetic makeup, or set of alleles, of an organism."
  },
  {
    "front": "Genus",
    "back": "(j\u0113\u2032-nus) (plural,genera) A taxonomic category above the species level, designated by the first word of a species\u2019 two-part scientific name."
  },
  {
    "front": "Geologic record",
    "back": "A standard time scale dividing Earth\u2019s history into time periods, grouped into four eons\u2014Hadean, Archaean, Proterozoic, and Phanerozoic\u2014and further subdivided into eras, periods, and epochs."
  },
  {
    "front": "Germ layers",
    "back": "One of the three main layers in a gastrula that will form the various tissues and organs of an animal body."
  },
  {
    "front": "Gestation",
    "back": "(jes-t\u0101\u2032-shun) Pregnancy; the condition of carrying one or more embryos in the uterus."
  },
  {
    "front": "Gibberellin",
    "back": "(jib\u2032-uh-rel\u2032-in) Any of a class of related plant hormones that stimulate growth in the stem and leaves, trigger the germination of seeds and breaking of bud dormancy, and (with auxin) stimulate fruit development."
  },
  {
    "front": "Glans",
    "back": "In humans, the rounded head of the penis (in males) or of the clitoris (in females); the glans is highly sensitive to stimulation."
  },
  {
    "front": "Glia",
    "back": "(glial cells) Non-neuronal cells of the nervous system that support, regulate, and nourish neurons; they also insulate neuron axons and remove pathogens and dead cells."
  },
  {
    "front": "Glomeromycetes",
    "back": "(gl\u014d\u2032-mer-\u014d-m\u012b\u2032-s\u0113t) A member of the fungal phylum Glomeromycota, characterized by a distinct branching form of mycorrhizae called arbuscular mycorrhizae."
  },
  {
    "front": "Glomerulus",
    "back": "(gl\u014d-m\u0101r\u2032-y\u016b-lus) A ball of capillaries surrounded by Bowman\u2019s capsule in the nephron and serving as the site of filtration in the vertebrate kidney."
  },
  {
    "front": "Glucagon",
    "back": "(gl\u016b\u2032-kuh-gon) A hormone secreted by the pancreas that raises blood glucose levels. It promotes glycogen breakdown and release of glucose by the liver."
  },
  {
    "front": "Glucocorticoids",
    "back": "A steroid hormone that is secreted by the adrenal cortex and that influences glucose metabolism and immune function."
  },
  {
    "front": "Glyceraldehyde 3-phosphate",
    "back": "(G3P) (glis\u2032-er-al\u2032-de-h\u012bd) A three-carbon carbohydrate that is the direct product of the Calvin cycle; it is also an intermediate in glycolysis."
  },
  {
    "front": "Glycogen",
    "back": "(gl\u012b\u2032-k\u014d-jen) An extensively branched glucose storage polysaccharide found in the liver and muscle of animals; the animal equivalent of starch."
  },
  {
    "front": "Glycolipids",
    "back": "A lipid with one or more covalently attached carbohydrates."
  },
  {
    "front": "Glycolysis",
    "back": "(gl\u012b-kol\u2032-uh-sis) A series of reactions that ultimately splits glucose into pyruvate. Glycolysis occurs in almost all living cells, serving as the starting point for fermentation or cellular respiration."
  },
  {
    "front": "Glycoproteins",
    "back": "A protein with one or more covalently attached carbohydrates."
  },
  {
    "front": "Glycosidic linkage",
    "back": "A covalent bond formed between two monosaccharides by a dehydration reaction."
  },
  {
    "front": "Gnathostomes",
    "back": "(na\u2032-thu-st\u014dm) Member of one of the two main clades of vertebrates; gnathostomes have jaws and include sharks and rays, ray-finned fishes, coelacanths, lungfishes, amphibians, reptiles, and mammals; Member of one of the two main clades of vertebrates; cyclostomes lack jaws and include lampreys and hagfishes."
  },
  {
    "front": "Golgi apparatus",
    "back": "(gol'-j\u0113) An organelle in eukaryotic cells consisting of stacks of flat membranous sacs that modify, store, and route products of the endoplasmic reticulum and synthesize some products, notably noncellulose carbohydrates."
  },
  {
    "front": "Gonads",
    "back": "(g\u014d\u2032-nad) A male or female gamete-producing organ."
  },
  {
    "front": "Graded potential",
    "back": "An electrical response of a cell to a stimulus, consisting of a change in voltage across the membrane proportional to the stimulus strength."
  },
  {
    "front": "Gram stain",
    "back": "A staining method that distinguishes between two different kinds of bacterial cell walls; may be used to help determine medical response to an infection."
  },
  {
    "front": "Gram-negative",
    "back": "Describing the group of bacteria that have a cell wall that is structurally more complex and contains less peptidoglycan than the cell wall of gram-positive bacteria. Gram-negative bacteria are often more toxic than gram-positive bacteria."
  },
  {
    "front": "Gram-positive",
    "back": "Describing the group of bacteria that have a cell wall that is structurally less complex and contains more peptidoglycan than the cell wall of gram-negative bacteria. Gram-positive bacteria are usually less toxic than gram-negative bacteria."
  },
  {
    "front": "Granum",
    "back": "(gran'-um) (plural,grana) A stack of membrane-bounded thylakoids in the chloroplast. Grana function in the light reactions of photosynthesis."
  },
  {
    "front": "Gravitropism",
    "back": "(grav\u2032-uh-tr\u014d\u2032-pizm) A response of a plant or animal to gravity."
  },
  {
    "front": "Gray matter",
    "back": "Regions of clustered neuron cell bodies within the CNS."
  },
  {
    "front": "Green algae",
    "back": "A photosynthetic protist, named for green chloroplasts that are similar in structure and pigment composition to the chloroplasts of plants. Green algae are a paraphyletic group; some members are more closely related to plants than they are to other green algae."
  },
  {
    "front": "Greenhouse effect",
    "back": "The warming of Earth due to the atmospheric accumulation of carbon dioxide and certain other gases, which absorb reflected infrared radiation and reradiate some of it back toward Earth."
  },
  {
    "front": "Gross primary production",
    "back": "(GPP) The total primary production of an ecosystem."
  },
  {
    "front": "Ground tissue",
    "back": "Plant tissue that is neither vascular nor dermal, fulfilling a variety of functions, such as storage, photosynthesis, and support."
  },
  {
    "front": "Growth factor",
    "back": "(1) A protein that must be present in the extracellular environment (culture medium or animal body) for the growth and normal development of certain types of cells. (2) A local regulator that acts on nearby cells to stimulate cell proliferation and differentiation."
  },
  {
    "front": "Growth hormone",
    "back": "(GH) A hormone that is produced and secreted by the anterior pituitary and that has both direct (nontropic) and tropic effects on a wide variety of tissues."
  },
  {
    "front": "Guard cells",
    "back": "The two cells that flank the stomatal pore and regulate the opening and closing of the pore."
  },
  {
    "front": "Gustation",
    "back": "The sense of taste."
  },
  {
    "front": "Guttation",
    "back": "The exudation of water droplets from leaves, caused by root pressure in certain plants."
  },
  {
    "front": "Gymnosperms",
    "back": "(jim\u2032-n\u014d-sperm) A vascular plant that bears naked seeds\u2014seeds not enclosed in protective chambers."
  },
  {
    "front": "Hagfishes",
    "back": "Marine jawless vertebrates that have highly reduced vertebrae and a skull made of cartilage; most hagfishes are bottom-dwelling scavengers."
  },
  {
    "front": "Hair cells",
    "back": "A mechanosensory cell that alters output to the nervous system when hairlike projections on the cell surface are displaced."
  },
  {
    "front": "Half-life",
    "back": "The amount of time it takes for 50 percent of a sample of a radioactive isotope to decay.",
    "chapter": 2
  },
  {
    "front": "Hamilton\u2019s rule",
    "back": "The principle that for natural selection to favor an altruistic act, the benefit to the recipient, devalued by the coefficient of relatedness, must exceed the cost to the altruist."
  },
  {
    "front": "Haploid cells",
    "back": "(hap\u2032-loyd) A cell containing only one set of chromosomes (n)."
  },
  {
    "front": "Hardy-Weinberg equilibrium",
    "back": "The state of a population in which frequencies of alleles and genotypes remain constant from generation to generation, provided that only Mendelian segregation and recombination of alleles are at work."
  },
  {
    "front": "Heart",
    "back": "A muscular pump that uses metabolic energy to elevate the hydrostatic pressure of the circulatory fluid (blood or hemolymph). The fluid then flows down a pressure gradient through the body and eventually returns to the heart."
  },
  {
    "front": "Heart attack",
    "back": "The damage or death of cardiac muscle tissue resulting from prolonged blockage of one or more coronary arteries."
  },
  {
    "front": "Heart murmur",
    "back": "A hissing sound that most often results from blood squirting backward through a leaky valve in the heart."
  },
  {
    "front": "Heart rate",
    "back": "The frequency of heart contraction (in beats per minute)."
  },
  {
    "front": "Heat",
    "back": "Thermal energy in transfer from one body of matter to another."
  },
  {
    "front": "Heat of vaporization",
    "back": "The quantity of heat a liquid must absorb for 1 g of it to be converted from the liquid to the gaseous state."
  },
  {
    "front": "Heat-shock proteins",
    "back": "A protein that helps protect other proteins during heat stress. Heat-shock proteins are found in plants, animals, and microorganisms."
  },
  {
    "front": "Heavy chains",
    "back": "One of the two types of polypeptide chains that make up an antibody molecule and B cell receptor; consists of a variable region, which contributes to the antigen-binding site, and a constant region."
  },
  {
    "front": "Helicases",
    "back": "An enzyme that untwists the double helix of DNA at replication forks, separating the two strands and making them available as template strands."
  },
  {
    "front": "Helper T cell",
    "back": "A type of T cell that, when activated, secretes cytokines that promote the response of B cells (humoral response) and cytotoxic T cells (cell-mediated response) to antigens."
  },
  {
    "front": "Hemocoel",
    "back": "A body cavity lined by tissue derived from mesoderm and by tissue derived from endoderm."
  },
  {
    "front": "Hemoglobin",
    "back": "(h\u0113\u2032-m\u014d-gl\u014d\u2032-bin) An iron-containing protein in red blood cells that reversibly binds oxygen."
  },
  {
    "front": "Hemolymph",
    "back": "(h\u0113\u2032-m\u014d-limf\u2032) In invertebrates with an open circulatory system, the body fluid that bathes tissues."
  },
  {
    "front": "Hemophilia",
    "back": "(h\u0113\u2032-muh-fil\u2032-\u0113-uh) A human genetic disease caused by a sex-linked recessive allele resulting in the absence of one or more blood-clotting proteins; characterized by excessive bleeding following injury."
  },
  {
    "front": "Hepatic portal vein",
    "back": "A large vessel that conveys nutrient-laden blood from the small intestine to the liver, which regulates the blood\u2019s nutrient content."
  },
  {
    "front": "Herbivores",
    "back": "(hur\u2032-bi-v\u014dr\u2032) An animal that mainly eats plants or algae."
  },
  {
    "front": "Herbivory",
    "back": "A positive-negative ecological interaction in which an organism eats part of a plant or alga."
  },
  {
    "front": "Heredity",
    "back": "The transmission of traits from one generation to the next."
  },
  {
    "front": "Hermaphrodites",
    "back": "(hur-maf\u2032-ruh-d\u012bt\u2032) An individual that functions as both male and female in sexual reproduction by producing both sperm and eggs."
  },
  {
    "front": "Hermaphroditism",
    "back": "(hur-maf\u2032-r\u014d-d\u012b-tizm) A condition in which an individual has both female and male gonads and functions as both a male and a female in sexual reproduction by producing both sperm and eggs."
  },
  {
    "front": "Heterochromatin",
    "back": "(het\u2032-er-\u014d-kr\u014d\u2032-muh-tin) Eukaryotic chromatin that remains highly compacted during interphase and is generally not transcribed."
  },
  {
    "front": "Heterochrony",
    "back": "(het\u2032-uh-rok\u2032-ruh-n\u0113) Evolutionary change in the timing or rate of an organism\u2019s development."
  },
  {
    "front": "Heterocysts",
    "back": "(het\u2032-er-\u014d-sist) A specialized cell that engages in nitrogen fixation in some filamentous cyanobacteria; also called a heterocyte."
  },
  {
    "front": "Heterokaryon",
    "back": "(het\u2032-er-\u014d-k\u0101r\u2032-\u0113-un) A fungal mycelium that contains two or more haploid nuclei per cell."
  },
  {
    "front": "Heteromorphic",
    "back": "(het\u2032-er-\u014d-m\u014dr\u2032-fik) Referring to a condition in the life cycle of plants and certain algae in which the sporophyte and gametophyte generations differ in morphology."
  },
  {
    "front": "Heterosporous",
    "back": "(het-er-os\u2032-p\u014dr-us) Referring to a plant species that has two kinds of spores: microspores, which develop into male gametophytes, and megaspores, which develop into female gametophytes."
  },
  {
    "front": "Heterotrophs",
    "back": "(het\u2032-er-\u014d-tr\u014df) An organism that obtains organic food molecules by eating other organisms or substances derived from them."
  },
  {
    "front": "Heterozygote",
    "back": "An organism that has two different alleles for a gene (encoding a character)."
  },
  {
    "front": "Heterozygote advantage",
    "back": "Greater reproductive success of heterozygous individuals compared with homozygotes; tends to preserve variation in a gene pool."
  },
  {
    "front": "Heterozygous",
    "back": "(het\u2032-er-\u014d-z\u012b\u2032-gus) Having two different alleles for a given gene."
  },
  {
    "front": "Hibernation",
    "back": "A long-term physiological state in which metabolism decreases, the heart and respiratory system slow down, and body temperature is maintained at a lower level than normal."
  },
  {
    "front": "High-density lipoprotein",
    "back": "(HDL) A particle in the blood made up of thousands of cholesterol molecules and other lipids bound to a protein. HDL scavenges excess cholesterol."
  },
  {
    "front": "Hindbrain",
    "back": "One of three ancestral and embryonic regions of the vertebrate brain; develops into the medulla oblongata, pons, and cerebellum."
  },
  {
    "front": "Histamine",
    "back": "(his\u2032-tuh-m\u0113n) A substance released by mast cells that causes blood vessels to dilate and become more permeable in inflammatory and allergic responses."
  },
  {
    "front": "Histogram",
    "back": "A variant of a bar graph that is made for numeric data by first grouping, or \u201cbinning,\u201d the variable plotted on thex-axis into intervals of equal width. The \u201cbins\u201d may be integers or ranges of numbers. The height of each bar shows the percent or number of experimental subjects whose characteristics can be described by one of the intervals plotted on thex-axis."
  },
  {
    "front": "Histone acetylation",
    "back": "(his\u2032-t\u014dn) The attachment of acetyl groups to certain amino acids of histone proteins."
  },
  {
    "front": "Histones",
    "back": "(his\u2032-t\u014dn) A small protein with a high proportion of positively charged amino acids that binds to the negatively charged DNA and plays a key role in chromatin structure."
  },
  {
    "front": "HIV",
    "back": "(human immunodeficiency virus) The infectious agent that causes AIDS. HIV is a retrovirus."
  },
  {
    "front": "Holdfast",
    "back": "A rootlike structure that anchors a seaweed."
  },
  {
    "front": "Homeobox",
    "back": "(h\u014d\u2032-m\u0113-\u014d-boks\u2032) A 180-nucleotide sequence within homeotic genes and some other developmental genes that is widely conserved in animals. Related sequences occur in plants and yeasts."
  },
  {
    "front": "Homeostasis",
    "back": "(h\u014d\u2032-m\u0113-\u014d-st\u0101\u2032-sis) The steady-state physiological condition of the body."
  },
  {
    "front": "Homeotic genes",
    "back": "(h\u014d-m\u0113-o\u2032-tik) Any of the master regulatory genes that control placement and spatial organization of body parts in animals, plants, and fungi by controlling the developmental fate of groups of cells."
  },
  {
    "front": "Hominins",
    "back": "(h\u014d\u2032-mi-nin) A group consisting of humans and the extinct species that are more closely related to us than to chimpanzees."
  },
  {
    "front": "Homologous chromosomes",
    "back": "(or homologs) (h\u014d-mol\u2032-uh-gus) A pair of chromosomes of the same length, centromere position, and staining pattern that possess genes for the same characters at corresponding loci. One homologous chromosome is inherited from the organism\u2019s male parent, the other from the female parent. Also called a homologous pair."
  },
  {
    "front": "Homologous structures",
    "back": "(h\u014d-mol\u2032-uh-gus) Structures in different species that are similar because of common ancestry."
  },
  {
    "front": "Homology",
    "back": "(h\u014d-mol\u2032-\u014d-j\u0113) Similarity in characteristics resulting from a shared ancestry."
  },
  {
    "front": "Homosporous",
    "back": "(h\u014d-mos\u2032-puh-rus) Referring to a plant species that has a single kind of spore, which typically develops into a bisexual gametophyte."
  },
  {
    "front": "Homozygote",
    "back": "An organism that has a pair of identical alleles for a gene (encoding a character)."
  },
  {
    "front": "Homozygous",
    "back": "(h\u014d\u2032-m\u014d-z\u012b\u2032-gus) Having two identical alleles for a given gene."
  },
  {
    "front": "Horizontal gene transfer",
    "back": "The transfer of genes from one genome to another through mechanisms such as transposable elements, plasmid exchange, viral activity, and perhaps fusions of different organisms."
  },
  {
    "front": "Hormones",
    "back": "In multicellular organisms, one of many types of secreted chemicals that are formed in specialized cells, travel in body fluids, and act on specific target cells in other parts of the organism, changing the target cells\u2019 functioning."
  },
  {
    "front": "Hornworts",
    "back": "A small, herbaceous, nonvascular plant that is a member of the phylum Anthocerophyta."
  },
  {
    "front": "Host",
    "back": "The larger participant in a symbiotic relationship, often providing a home and food source for the smaller symbiont."
  },
  {
    "front": "Host range",
    "back": "The limited number of species whose cells can be infected by a particular virus."
  },
  {
    "front": "Human Genome Project",
    "back": "An international collaborative effort to map and sequence the DNA of the entire human genome."
  },
  {
    "front": "Humoral immune response",
    "back": "(hy\u016b\u2032-mer-ul) The branch of adaptive immunity that involves the activation of B cells and that leads to the production of antibodies, which defend against bacteria and viruses in body fluids."
  },
  {
    "front": "Humus",
    "back": "(hy\u016b\u2032-mus) Decomposing organic material that is a component of topsoil."
  },
  {
    "front": "Huntington\u2019s disease",
    "back": "A human genetic disease caused by a dominant allele; characterized by uncontrollable body movements and degeneration of the nervous system; usually fatal 10 to 20 years after the onset of symptoms."
  },
  {
    "front": "Hybrid zone",
    "back": "A geographic region in which members of different species meet and mate, producing at least some offspring of mixed ancestry."
  },
  {
    "front": "Hybridization",
    "back": "In genetics, the mating, or crossing, of two true-breeding varieties."
  },
  {
    "front": "Hybrids",
    "back": "Offspring that results from the mating of individuals from two different species or from two true-breeding varieties of the same species."
  },
  {
    "front": "Hydration shell",
    "back": "The sphere of water molecules around a dissolved ion."
  },
  {
    "front": "Hydrocarbons",
    "back": "An organic molecule consisting only of carbon and hydrogen."
  },
  {
    "front": "Hydrogen bond",
    "back": "A type of weak chemical bond that is formed when the slightly positive hydrogen atom of a polar covalent bond in one molecule is attracted to the slightly negative atom of a polar covalent bond in another molecule or in another region of the same molecule.",
    "chapter": 2
  },
  {
    "front": "Hydrogen ion",
    "back": "A single proton with a charge of plus one. The dissociation of a water molecule leads to the generation of a hydroxide ion (with a charge of minus one) and a hydrogen ion (with a charge of plus one); in water, the hydrogen ion is not found alone but associates with a water molecule to form a hydronium ion."
  },
  {
    "front": "Hydrolysis",
    "back": "(h\u012b-drol\u2032-uh-sis) A chemical reaction that breaks bonds between two molecules by the addition of water; functions in disassembly of polymers to monomers."
  },
  {
    "front": "Hydronium ion",
    "back": "A water molecule that has an extra proton bound to it, commonly represented as H superscript plus."
  },
  {
    "front": "Hydrophilic",
    "back": "(h\u012b\u2032-dr\u014d-fil\u2032-ik) Having an affinity for water."
  },
  {
    "front": "Hydrophobic",
    "back": "(h\u012b\u2032-dr\u014d-f\u014d\u2032-bik) Having no affinity for water; tending to coalesce and form droplets in water."
  },
  {
    "front": "Hydrophobic interactions",
    "back": "(h\u012b\u2032-dr\u014d-f\u014d\u2032-bik) A type of weak chemical interaction caused when molecules that do not mix with water coalesce to exclude water."
  },
  {
    "front": "Hydroponic culture",
    "back": "A method in which plants are grown in mineral solutions rather than in soil."
  },
  {
    "front": "Hydrostatic skeleton",
    "back": "A skeletal system composed of fluid held under pressure in a closed body compartment; the main skeleton of most cnidarians, flatworms, nematodes, and annelids."
  },
  {
    "front": "Hydrothermal vents",
    "back": "An area on the seafloor where heated water and minerals from Earth\u2019s interior gush into the seawater, producing a dark, hot, oxygen-deficient environment. The producers in a hydrothermal vent community are chemoautotrophic prokaryotes."
  },
  {
    "front": "Hydroxide ion",
    "back": "A water molecule that has lost a proton, represented as OH superscript minus."
  },
  {
    "front": "Hydroxyl group",
    "back": "(h\u012b-drok'-sil) A chemical group consisting of an oxygen atom joined to a hydrogen atom. Molecules possessing this group are soluble in water and are called alcohols."
  },
  {
    "front": "Hyperpolarization",
    "back": "A change in a cell\u2019s membrane potential such that the inside of the membrane becomes more negative relative to the outside. Hyperpolarization reduces the chance that a neuron will transmit a nerve impulse."
  },
  {
    "front": "Hypersensitive response",
    "back": "A plant\u2019s localized defense response to a pathogen, involving the death of cells around the site of infection."
  },
  {
    "front": "Hypertension",
    "back": "A disease in which blood pressure remains abnormally high."
  },
  {
    "front": "Hypertonic",
    "back": "Referring to a solution that, when surrounding a cell, will cause the cell to lose water."
  },
  {
    "front": "Hyphae",
    "back": "(plural,hyphae) (h\u012b\u2032-fuh, h\u012b\u2032-f\u0113) One of many connected filaments that collectively make up the mycelium of a fungus."
  },
  {
    "front": "Hypocotyl",
    "back": "(h\u012b\u2032-puh-cot\u2032-ul) In an angiosperm embryo, the embryonic axis below the point of attachment of the cotyledon(s) and above the radicle."
  },
  {
    "front": "Hypothalamus",
    "back": "(h\u012b\u2032-p\u014d-thal\u2032-uh-mus) The ventral part of the vertebrate forebrain; functions in maintaining homeostasis, especially in coordinating the endocrine and nervous systems; secretes hormones of the posterior pituitary and releasing factors that regulate the anterior pituitary."
  },
  {
    "front": "Hypothesis",
    "back": "(h\u012b-poth\u2032-uh-sis) A testable explanation for a set of observations based on the available data and guided by inductive reasoning. A hypothesis is narrower in scope than a theory.",
    "chapter": 1
  },
  {
    "front": "Hypotonic",
    "back": "Referring to a solution that, when surrounding a cell, will cause the cell to take up water."
  },
  {
    "front": "Imbibition",
    "back": "The uptake of water by a seed or other structure, resulting in swelling."
  },
  {
    "front": "Immigration",
    "back": "The influx of new individuals into a population from other areas."
  },
  {
    "front": "Immune system",
    "back": "An organism\u2019s system of defenses against agents that cause disease."
  },
  {
    "front": "Immunization",
    "back": "The process of generating a state of immunity by artificial means. In vaccination, an inactive or weakened form of a pathogen is administered, inducing B and T cell responses and immunological memory. In passive immunization, antibodies specific for a particular pathogen are administered, conferring immediate but temporary protection."
  },
  {
    "front": "Immunoglobulin",
    "back": "(Ig) (im\u2032-y\u016b-n\u014d-glob\u2032-y\u016b-lin) A protein secreted by plasma cells (differentiated B cells) that binds to a particular antigen; also called immunoglobulin. All antibodies have the same Y-shaped structure and in their monomer form consist of two identical heavy chains and two identical light chains.."
  },
  {
    "front": "Imprinting",
    "back": "In animal behavior, the formation at a specific stage in life of a long-lasting behavioral response to a specific individual or object; A phenomenon in which expression of an allele in offspring depends on whether the allele is inherited from the male or female parent."
  },
  {
    "front": "In situ hybridization",
    "back": "A technique using nucleic acid hybridization with a labeled probe to detect the location of a specific mRNA in an intact organism."
  },
  {
    "front": "In vitro fertilization",
    "back": "(IVF) (v\u0113\u2032-tr\u014d) Fertilization of oocytes in laboratory containers followed by artificial implantation of the early embryo in the uterus."
  },
  {
    "front": "In vitro mutagenesis",
    "back": "A technique used to discover the function of a gene by cloning it, introducing specific changes into the cloned gene\u2019s sequence, reinserting the mutated gene into a cell, and studying the phenotype of the mutant."
  },
  {
    "front": "Inclusive fitness",
    "back": "The total effect an individual has on proliferating its genes by producing its own offspring and by providing aid that enables other close relatives to increase production of their offspring."
  },
  {
    "front": "Incomplete dominance",
    "back": "The situation in which the phenotype of heterozygotes is intermediate between the phenotypes of individuals homozygous for either allele."
  },
  {
    "front": "Incomplete flowers",
    "back": "A flower in which one or more of the four basic floral organs (sepals, petals, stamens, or carpels) are either absent or nonfunctional."
  },
  {
    "front": "Incomplete metamorphosis",
    "back": "A type of development in certain insects, such as grasshoppers, in which the young (called nymphs) resemble adults but are smaller and have different body proportions. The nymph goes through a series of molts, each time looking more like an adult, until it reaches full size."
  },
  {
    "front": "Independent variable",
    "back": "A factor whose value is manipulated or changed during an experiment to reveal possible effects on another factor (the dependent variable).",
    "chapter": 1
  },
  {
    "front": "Indeterminate cleavage",
    "back": "A type of embryonic development in deuterostomes in which each cell produced by early cleavage divisions retains the capacity to develop into a complete embryo."
  },
  {
    "front": "Indeterminate growth",
    "back": "A type of growth characteristic of plants, in which the organism continues to grow as long as it lives."
  },
  {
    "front": "Induced fit",
    "back": "Caused by entry of the substrate, the change in shape of the active site of an enzyme so that it binds more snugly to the substrate."
  },
  {
    "front": "Inducer",
    "back": "A specific small molecule that binds to a bacterial repressor protein and changes the repressor\u2019s shape so that it cannot bind to an operator, thus switching an operon on."
  },
  {
    "front": "Induction",
    "back": "A process in which a group of cells or tissues influences the development of another group through close-range interactions."
  },
  {
    "front": "Inductive reasoning",
    "back": "A type of logic in which generalizations are based on a large number of specific observations.",
    "chapter": 1
  },
  {
    "front": "Inflammatory response",
    "back": "An innate immune defense triggered by physical injury or infection of tissue involving the release of substances that promote swelling, enhance the infiltration of white blood cells, and aid in tissue repair and destruction of invading pathogens."
  },
  {
    "front": "Inflorescences",
    "back": "A group of flowers tightly clustered together."
  },
  {
    "front": "Ingestion",
    "back": "The first stage of food processing in animals: the act of eating."
  },
  {
    "front": "Ingroup",
    "back": "A species or group of species whose evolutionary relationships are being examined in a given analysis."
  },
  {
    "front": "Inhibitory postsynaptic potential",
    "back": "(IPSP) An electrical change (usually hyperpolarization) in the membrane of a postsynaptic neuron caused by the binding of an inhibitory neurotransmitter from a presynaptic cell to a postsynaptic receptor; makes it more difficult for a postsynaptic neuron to generate an action potential."
  },
  {
    "front": "Innate behavior",
    "back": "Animal behavior that is developmentally fixed and under strong genetic control. Innate behavior is exhibited in virtually the same form by all individuals in a population despite internal and external environmental differences during development and throughout their lifetimes."
  },
  {
    "front": "Innate immunity",
    "back": "A form of defense common to all animals that is active immediately upon exposure to a pathogen and that is the same whether or not the pathogen has been encountered previously."
  },
  {
    "front": "Inner cell mass",
    "back": "An inner cluster of cells at one end of a mammalian blastocyst that subsequently develops into the embryo proper and some of the extraembryonic membranes."
  },
  {
    "front": "Inner ear",
    "back": "One of the three main regions of the vertebrate ear; includes the cochlea (which in turn contains the organ of Corti) and the semicircular canals."
  },
  {
    "front": "Inositol trisphosphate",
    "back": "(IP subscript 3) (in-\u014d\u2032-suh-tol) A second messenger that functions as an intermediate between certain signaling molecules and a subsequent second messenger, a calcium ion (Ca superscript 2-plus), causing a rise in cytoplasmic calcium ion concentration."
  },
  {
    "front": "Inquiry",
    "back": "The search for information and explanation, often focusing on specific questions.",
    "chapter": 1
  },
  {
    "front": "Insertions",
    "back": "A mutation involving the addition of one or more nucleotide pairs to a gene."
  },
  {
    "front": "Insulin",
    "back": "(in\u2032-suh-lin) A hormone secreted by pancreatic beta cells that lowers blood glucose levels. It promotes the uptake of glucose by most body cells and the synthesis and storage of glycogen in the liver and also stimulates protein and fat synthesis."
  },
  {
    "front": "Integral proteins",
    "back": "A transmembrane protein with hydrophobic regions that extend into and often completely span the hydrophobic interior of the membrane and with hydrophilic regions in contact with the aqueous solution on one or both sides of the membrane (or lining the channel in the case of a channel protein)."
  },
  {
    "front": "Integrins",
    "back": "(in'-tuh-grin) In animal cells, a transmembrane receptor protein with two subunits that interconnects the extracellular matrix and the cytoskeleton."
  },
  {
    "front": "Integument",
    "back": "(in-teg\u2032-y\u016b-ment) Layer of sporophyte tissue that contributes to the structure of an ovule of a seed plant."
  },
  {
    "front": "Integumentary system",
    "back": "The outer covering of a mammal\u2019s body, including skin, hair, and nails, claws, or hooves."
  },
  {
    "front": "Interferons",
    "back": "(in\u2032-ter-f\u0113r\u2032-on) A protein that has antiviral or immune regulatory functions. For example, interferons secreted by virus-infected cells help nearby cells resist viral infection."
  },
  {
    "front": "Intermediate disturbance hypothesis",
    "back": "The concept that moderate levels of disturbance can foster greater species diversity than low or high levels of disturbance."
  },
  {
    "front": "Intermediate filaments",
    "back": "A component of the cytoskeleton that includes filaments intermediate in size between microtubules and microfilaments."
  },
  {
    "front": "Interneurons",
    "back": "An association neuron; a nerve cell within the central nervous system that forms synapses with sensory and/or motor neurons and integrates sensory input and motor output."
  },
  {
    "front": "Internodes",
    "back": "A segment of a plant stem between the points where leaves are attached."
  },
  {
    "front": "Interphase",
    "back": "The period in the cell cycle when the cell is not dividing. During interphase, cellular metabolic activity is high, chromosomes and organelles are duplicated, and cell size may increase. Interphase often accounts for about 90 percent of the cell cycle."
  },
  {
    "front": "Intersexual selection",
    "back": "A form of natural selection in which individuals of one sex (usually the females) are choosy in selecting their mates from the other sex; also called mate choice."
  },
  {
    "front": "Interspecific interactions",
    "back": "A relationship between individuals of two or more species in a community."
  },
  {
    "front": "Interstitial fluid",
    "back": "The fluid filling the spaces between cells in most animals."
  },
  {
    "front": "Intertidal zone",
    "back": "The shallow zone of the ocean adjacent to land and between the high- and low-tide lines."
  },
  {
    "front": "Intrasexual selection",
    "back": "A form of natural selection in which there is direct competition among individuals of one sex for mates of the opposite sex."
  },
  {
    "front": "Intrinsic rate of increase",
    "back": "(r) In population models, the per capita rate at which an exponentially growing population increases in size at each instant in time."
  },
  {
    "front": "Introduced species",
    "back": "A species moved by humans, either intentionally or accidentally, from its native location to a new geographic region; sometimes called a non-native species, exotic species, or invasive species."
  },
  {
    "front": "Introns",
    "back": "(in\u2032-tron) A noncoding, intervening sequence within a primary transcript that is removed from the transcript during RNA processing; also refers to the region of DNA from which this sequence was transcribed."
  },
  {
    "front": "Inversion",
    "back": "An aberration in chromosome structure resulting from reattachment of a chromosomal fragment in a reverse orientation to the chromosome from which it originated."
  },
  {
    "front": "Invertebrates",
    "back": "An animal without a backbone. Invertebrates make up 95 percent of animal species."
  },
  {
    "front": "Ion channels",
    "back": "(\u012b\u2032-on) A transmembrane protein channel that allows a specific ion to diffuse across the membrane down its concentration or electrochemical gradient."
  },
  {
    "front": "Ionic bond",
    "back": "(\u012b-on\u2032-ik) A chemical bond resulting from the attraction between oppositely charged ions.",
    "chapter": 2
  },
  {
    "front": "Ionic compounds",
    "back": "(\u012b-on\u2032-ik) A compound resulting from the formation of an ionic bond; also called a salt.",
    "chapter": 2
  },
  {
    "front": "Ions",
    "back": "(\u012b\u2032-on) An atom or group of atoms that has gained or lost one or more electrons, thus acquiring a charge.",
    "chapter": 2
  },
  {
    "front": "Iris",
    "back": "The colored part of the vertebrate eye, formed by the anterior portion of the choroid."
  },
  {
    "front": "Isomers",
    "back": "(\u012b\u2032-s\u014d-mer) One of two or more compounds that have the same numbers of atoms of the same elements but different structures and hence different properties."
  },
  {
    "front": "Isomorphic",
    "back": "Referring to alternating generations in plants and certain algae in which the sporophytes and gametophytes look alike, although they differ in chromosome number."
  },
  {
    "front": "Isotonic",
    "back": "(\u012b\u2032-s\u014d-ton\u2032-ik) Referring to a solution that, when surrounding a cell, causes no net movement of water into or out of the cell."
  },
  {
    "front": "Isotopes",
    "back": "(\u012b\u2032-s\u014d-t\u014dp\u2032) One of several atomic forms of an element, each with the same number of protons but a different number of neutrons, thus differing in atomic mass.",
    "chapter": 2
  },
  {
    "front": "Iteroparity",
    "back": "Reproduction in which adults produce offspring over many years; also called repeated reproduction."
  },
  {
    "front": "Jasmonates",
    "back": "Any of a class of plant hormones that regulate a wide range of developmental processes in plants and play a key role in plant defense against herbivores."
  },
  {
    "front": "Joule",
    "back": "(J) A unit of energy: 1 J equals 0.239 cal; 1 cal equals 4.184 J."
  },
  {
    "front": "Juxtaglomerular apparatus",
    "back": "(JGA)(juks\u2032-tuh-gluh-m\u0101r\u2032-y\u016b-ler) A specialized tissue in nephrons that releases the enzyme renin in response to a drop in blood pressure or volume."
  },
  {
    "front": "Juxtamedullary nephrons",
    "back": "In mammals and birds, a nephron with a loop of Henle that extends far into the renal medulla."
  },
  {
    "front": "K -selection",
    "back": "Selection for life history traits that are sensitive to population density."
  },
  {
    "front": "Karyogamy",
    "back": "(k\u0101r\u2032-\u0113-og\u2032-uh-m\u0113) In fungi, the fusion of haploid nuclei contributed by the two parents; occurs as one stage of sexual reproduction, preceded by plasmogamy."
  },
  {
    "front": "Karyotype",
    "back": "(k\u0101r\u2032-\u0113-\u014d-t\u012bp) A display of the chromosome pairs of a cell arranged by size and shape."
  },
  {
    "front": "Keystone species",
    "back": "A species that is not necessarily abundant in a community yet exerts strong control on community structure by the nature of its ecological role or niche."
  },
  {
    "front": "Kidney",
    "back": "In vertebrates, one of a pair of excretory organs where blood filtrate is formed and processed into urine."
  },
  {
    "front": "Kilocalorie",
    "back": "(kcal) A thousand calories; the amount of heat energy required to raise the temperature of 1 kg of water by 1 degree C."
  },
  {
    "front": "Kin selection",
    "back": "Natural selection that favors altruistic behavior by enhancing the reproductive success of relatives."
  },
  {
    "front": "Kinetic energy",
    "back": "(kuh-net\u2032-ik) The energy associated with the relative motion of objects. Moving matter can perform work by imparting motion to other matter."
  },
  {
    "front": "Kinetochore",
    "back": "(kuh-net\u2032-uh-k\u014dr) A structure of proteins attached to the centromere that links each sister chromatid to the mitotic spindle."
  },
  {
    "front": "Kinetoplastids",
    "back": "A protist, such as a trypanosome, that has a single large mitochondrion that houses an organized mass of DNA."
  },
  {
    "front": "Kingdoms",
    "back": "A taxonomic category, the second broadest after domain."
  },
  {
    "front": "Labia majora",
    "back": "A pair of thick, fatty ridges that enclose and protect the rest of the vulva."
  },
  {
    "front": "Labia minora",
    "back": "A pair of slender skin folds that surround the openings of the vagina and urethra."
  },
  {
    "front": "Lacteal",
    "back": "(lak\u2032-t\u0113-ul) A tiny lymph vessel extending into the core of an intestinal villus and serving as the destination for absorbed chylomicrons."
  },
  {
    "front": "Lactic acid fermentation",
    "back": "Glycolysis followed by the reduction of pyruvate to lactate, regenerating NAD plus with no release of carbon dioxide."
  },
  {
    "front": "Lagging strand",
    "back": "A discontinuously synthesized DNA strand that elongates by means of Okazaki fragments, each synthesized in a 5' to 3' direction away from the replication fork."
  },
  {
    "front": "Lampreys",
    "back": "Any of the jawless vertebrates with highly reduced vertebrae that live in freshwater and marine environments. Almost half of extant lamprey species are parasites that feed by clamping their round, jawless mouth onto the flank of a live fish; nonparasitic lampreys are suspension feeders that feed only as larvae."
  },
  {
    "front": "Lancelets",
    "back": "A member of the clade Cephalochordata, small blade-shaped marine chordates that lack a backbone."
  },
  {
    "front": "Landscape",
    "back": "An area containing several different ecosystems linked by exchanges of energy, materials, and organisms."
  },
  {
    "front": "Large intestine",
    "back": "The portion of the vertebrate alimentary canal between the small intestine and the anus; functions mainly in water absorption and the formation of feces."
  },
  {
    "front": "Larva",
    "back": "(lar\u2032-vuh) (plural,larvae) A free-living, sexually immature form in some animal life cycles that may differ from the adult animal in morphology, nutrition, and habitat."
  },
  {
    "front": "Larynx",
    "back": "(l\u0101r\u2032-inks) The portion of the respiratory tract containing the vocal cords; also called the voice box."
  },
  {
    "front": "Lateral line system",
    "back": "A mechanoreceptor system consisting of a series of pores and receptor units along the sides of the body in fishes and aquatic amphibians; detects water movements made by the animal itself and by other moving objects."
  },
  {
    "front": "Lateral meristems",
    "back": "(m\u0101r\u2032-uh-stem) A meristem that thickens the roots and shoots of woody plants. The vascular cambium and cork cambium are lateral meristems."
  },
  {
    "front": "Lateral roots",
    "back": "A root that arises from the pericycle of an established root."
  },
  {
    "front": "Lateralization",
    "back": "Segregation of functions in the cortex of the left and right cerebral hemispheres."
  },
  {
    "front": "Law of conservation of mass",
    "back": "A physical law stating that matter can change form but cannot be created or destroyed. In a closed system, the mass of the system is constant."
  },
  {
    "front": "Law of independent assortment",
    "back": "Mendel\u2019s second law, stating that each pair of alleles segregates, or assorts, independently of each other pair during gamete formation; applies when genes for two characters are located on different pairs of homologous chromosomes or when they are far enough apart on the same chromosome to behave as though they are on different chromosomes."
  },
  {
    "front": "Law of segregation",
    "back": "Mendel\u2019s first law, stating that the two alleles in a pair segregate (separate from each other) into different gametes during gamete formation."
  },
  {
    "front": "Leading strand",
    "back": "The new complementary DNA strand synthesized continuously along the template strand toward the replication fork in the mandatory 5' to 3' direction."
  },
  {
    "front": "Leaf",
    "back": "The main photosynthetic organ of vascular plants."
  },
  {
    "front": "Leaf primordia",
    "back": "(plural,primordia) A finger-like projection along the flank of a shoot apical meristem, from which a leaf arises."
  },
  {
    "front": "Learning",
    "back": "The modification of behavior as a result of specific experiences."
  },
  {
    "front": "Lens",
    "back": "The structure in an eye that focuses light rays onto the photoreceptors."
  },
  {
    "front": "Lenticels",
    "back": "(len\u2032-ti-sel) A small raised area in the bark of stems and roots that enables gas exchange between living cells and the outside air."
  },
  {
    "front": "Lepidosaurs",
    "back": "(leh-pid\u2032-uh-s\u014dr) A member of the reptilian group that includes lizards, snakes, and two species of New Zealand animals called tuataras."
  },
  {
    "front": "Leukocytes",
    "back": "(l\u016b\u2032-k\u014d-s\u012bt\u2032) A blood cell that functions in fighting infections; also called a white blood cell."
  },
  {
    "front": "Lichen",
    "back": "The mutualistic association between a fungus and a photosynthetic alga or cyanobacterium."
  },
  {
    "front": "Life cycle",
    "back": "The generation-to-generation sequence of stages in the reproductive history of an organism."
  },
  {
    "front": "Life history",
    "back": "The traits that affect an organism\u2019s schedule of reproduction and survival."
  },
  {
    "front": "Life table",
    "back": "A summary of the age-specific survival and reproductive rates of individuals in a population."
  },
  {
    "front": "Ligand",
    "back": "(lig\u2032-und) A molecule that binds specifically to another molecule, usually a larger one."
  },
  {
    "front": "Ligand-gated ion channels",
    "back": "(lig\u2032-und) A transmembrane protein containing a pore that opens or closes as it changes shape in response to a signaling molecule (ligand), allowing or blocking the flow of specific ions; also called an ionotropic receptor."
  },
  {
    "front": "Light chains",
    "back": "One of the two types of polypeptide chains that make up an antibody molecule and B cell receptor; consists of a variable region, which contributes to the antigen-binding site, and a constant region."
  },
  {
    "front": "Light microscope",
    "back": "(LM) An optical instrument with lenses that refract (bend) visible light to magnify images of specimens."
  },
  {
    "front": "Light reactions",
    "back": "The first of two major stages in photosynthesis (preceding the Calvin cycle). These reactions, which occur on the thylakoid membranes of the chloroplast or on membranes of certain prokaryotes, convert solar energy to the chemical energy of ATP and NADPH, releasing oxygen in the process."
  },
  {
    "front": "Light-harvesting complex",
    "back": "A complex of proteins associated with pigment molecules (including chlorophylla, chlorophyllb, and carotenoids) that captures light energy and transfers it to reaction-center pigments in a photosystem."
  },
  {
    "front": "Lignin",
    "back": "(lig\u2032-nin) A strong polymer embedded in the cellulose matrix of the secondary cell walls of vascular plants that provides structural support in terrestrial species."
  },
  {
    "front": "Limiting nutrient",
    "back": "An element that must be added for production to increase in a particular area."
  },
  {
    "front": "Line graph",
    "back": "A graph in which each data point is connected to the next point in the data set with a straight line."
  },
  {
    "front": "Linear electron flow",
    "back": "A route of electron flow during the light reactions of photosynthesis that involves both photosystems (I and II) and produces ATP, NADPH, and oxygen. The net electron flow is from water to NADP plus."
  },
  {
    "front": "Linkage map",
    "back": "A genetic map based on the frequencies of recombination between markers during crossing over of homologous chromosomes."
  },
  {
    "front": "Linked genes",
    "back": "Genes located close enough together on a chromosome that they tend to be inherited together."
  },
  {
    "front": "Lipids",
    "back": "(lip\u2032-id) Any of a group of large biological molecules, including fats, phospholipids, and steroids, that mix poorly, if at all, with water."
  },
  {
    "front": "Liver",
    "back": "A large internal organ in vertebrates that performs diverse functions, such as producing bile, maintaining blood glucose level, and detoxifying poisonous chemicals in the blood."
  },
  {
    "front": "Liverworts",
    "back": "A small, herbaceous, nonvascular plant that is a member of the phylum Hepatophyta."
  },
  {
    "front": "Loams",
    "back": "The most fertile soil type, made up of roughly equal amounts of sand, silt, and clay."
  },
  {
    "front": "Lobe-fins",
    "back": "Member of a clade of osteichthyans having rod-shaped muscular fins. The group includes coelacanths, lungfishes, and tetrapods."
  },
  {
    "front": "Local regulators",
    "back": "A secreted molecule that influences cells near where it is secreted."
  },
  {
    "front": "Locomotion",
    "back": "Active motion from place to place."
  },
  {
    "front": "Locus",
    "back": "(plural,loci), (l\u014d\u2032-kus), (l\u014d\u2032-s\u012b) A specific place along the length of a chromosome where a given gene is located."
  },
  {
    "front": "Logistic population growth",
    "back": "Population growth that levels off as population size approaches carrying capacity."
  },
  {
    "front": "Long noncoding RNAs",
    "back": "(lncRNA) An RNA between 200 and hundreds of thousands of nucleotides in length that does not code for protein but is expressed at significant levels."
  },
  {
    "front": "Long-day plants",
    "back": "A plant that flowers (usually in late spring or early summer) only when the light period is longer than a critical length."
  },
  {
    "front": "Long-term memory",
    "back": "The ability to hold, associate, and recall information over one\u2019s lifetime."
  },
  {
    "front": "Long-term potentiation",
    "back": "(LTP) An enhanced responsiveness to an action potential (nerve signal) by a receiving neuron."
  },
  {
    "front": "Loop of Henle",
    "back": "(hen\u2032-l\u0113) The hairpin turn, with a descending and ascending limb, between the proximal and distal tubules of the vertebrate kidney; functions in water and salt reabsorption."
  },
  {
    "front": "Lophophore",
    "back": "(lof\u2032-uh-f\u014dr) In some lophotrochozoan animals, including brachiopods, a crown of ciliated tentacles that surround the mouth and function in feeding."
  },
  {
    "front": "Lophotrochozoa",
    "back": "(lo-phah\u2219-truh-k\u014d-z\u014d\u2032-uh) One of the three main lineages of bilaterian animals; lophotrochozoans include organisms that have lophophores or trochophore larvae."
  },
  {
    "front": "Low-density lipoprotein",
    "back": "(LDL) A particle in the blood made up of thousands of cholesterol molecules and other lipids bound to a protein. LDL transports cholesterol from the liver for incorporation into cell membranes."
  },
  {
    "front": "Lungs",
    "back": "An infolded respiratory surface of a terrestrial vertebrate, land snail, or spider that connects to the atmosphere by narrow tubes."
  },
  {
    "front": "Luteinizing hormone",
    "back": "(LH) (l\u016b\u2032-t\u0113-uh-n\u012b\u2032-zing) A tropic hormone that is produced and secreted by the anterior pituitary and that stimulates ovulation in females and androgen production in males."
  },
  {
    "front": "Lycophytes",
    "back": "(l\u012b\u2032-kuh-f\u012bt) An informal name for a member of the phylum Lycophyta, which includes club mosses, spike mosses, and quillworts."
  },
  {
    "front": "Lymph",
    "back": "The colorless fluid, derived from interstitial fluid, in the lymphatic system of vertebrates."
  },
  {
    "front": "Lymph nodes",
    "back": "An organ located along a lymph vessel. Lymph nodes filter lymph and contain cells that attack viruses and bacteria."
  },
  {
    "front": "Lymphatic system",
    "back": "A system of vessels and nodes, separate from the circulatory system, that returns fluid, proteins, and cells to the blood."
  },
  {
    "front": "Lymphocytes",
    "back": "A type of white blood cell that mediates immune responses. The two main classes are B cells and T cells."
  },
  {
    "front": "Lysogenic cycle",
    "back": "(l\u012b\u2032-s\u014d-jen\u2032-ik) A type of phage replicative cycle in which the viral genome becomes incorporated into the bacterial host chromosome as a prophage, is replicated along with the chromosome, and does not kill the host."
  },
  {
    "front": "Lysosome",
    "back": "(l\u012b'-suh-s\u014dm) A membrane-enclosed sac of hydrolytic enzymes found in the cytoplasm of animal cells and some protists."
  },
  {
    "front": "Lysozyme",
    "back": "(l\u012b\u2032-s\u014d-z\u012bm) An enzyme that destroys bacterial cell walls; in mammals, it is found in sweat, tears, and saliva."
  },
  {
    "front": "Lytic cycle",
    "back": "(lit\u2032-ik) A type of phage replicative cycle resulting in the release of new phages by lysis (and death) of the host cell."
  },
  {
    "front": "Macroevolution",
    "back": "Evolutionary change above the species level. Examples of macroevolutionary change include the origin of a new group of organisms through a series of speciation events and the impact of mass extinctions on the diversity of life and its subsequent recovery."
  },
  {
    "front": "Macromolecules",
    "back": "A giant molecule formed by the joining of smaller molecules. Polysaccharides, proteins, and nucleic acids are macromolecules."
  },
  {
    "front": "Macronutrients",
    "back": "An essential element that an organism must obtain in relatively large amounts; An essential element that an organism needs in very small amounts."
  },
  {
    "front": "Macrophages",
    "back": "(mak\u2032-r\u014d-f\u0101j) A phagocytic cell present in many tissues that functions in innate immunity by destroying microorganisms and in acquired immunity as an antigen-presenting cell."
  },
  {
    "front": "Magnoliids",
    "back": "A member of the angiosperm clade that is most closely related to the combined eudicot and monocot clades. Extant examples are magnolias, laurels, and black pepper plants."
  },
  {
    "front": "Major depressive disorder",
    "back": "A mood disorder characterized by feelings of sadness, lack of self-worth, emptiness, or loss of interest in nearly all things."
  },
  {
    "front": "Major histocompatibility complex molecule",
    "back": "(MHC) A host protein that functions in antigen presentation. Foreign MHC molecules on transplanted tissue can trigger T cell responses that may lead to rejection of the transplant."
  },
  {
    "front": "Malignant tumor",
    "back": "A cancerous tumor containing cells that have significant genetic and cellular changes and are capable of invading and surviving in new sites. Malignant tumors can impair the functions of one or more organs."
  },
  {
    "front": "Malpighian tubules",
    "back": "(mal-pig\u2032-\u0113-un) A unique excretory organ of insects that empties into the digestive tract, removes nitrogenous wastes from the hemolymph, and functions in osmoregulation."
  },
  {
    "front": "Mammals",
    "back": "A member of the clade Mammalia, amniotes that have hair and mammary glands (glands that produce milk)."
  },
  {
    "front": "Mammary glands",
    "back": "An exocrine gland that secretes milk for nourishing the young. Mammary glands are characteristic of mammals."
  },
  {
    "front": "Mantle",
    "back": "One of the three main parts of a mollusc; a fold of tissue that drapes over the mollusc\u2019s visceral mass and may secrete a shell; (1) The portion of a bryophyte sporophyte that gathers sugars, amino acids, water, and minerals from the parent gametophyte via transfer cells. (2) One of the three main parts of a mollusc; a muscular structure usually used for movement; and One of the three main parts of a mollusc; the part containing most of the internal organs."
  },
  {
    "front": "Mantle cavity",
    "back": "A water-filled chamber that houses the gills, anus, and excretory pores of a mollusc."
  },
  {
    "front": "Map units",
    "back": "A unit of measurement of the distance between genes. One map unit is equivalent to a 1 percent recombination frequency."
  },
  {
    "front": "Mark-recapture method",
    "back": "A sampling technique used to estimate the size of animal populations."
  },
  {
    "front": "Marsupials",
    "back": "(mar-s\u016b\u2032-p\u0113-ul) A mammal, such as a koala, kangaroo, or opossum, whose young complete their embryonic development inside a maternal pouch called the marsupium."
  },
  {
    "front": "Mass extinction",
    "back": "The elimination of a large number of species throughout Earth, the result of global environmental changes."
  },
  {
    "front": "Mass number",
    "back": "The total number of protons and neutrons in an atom\u2019s nucleus.",
    "chapter": 2
  },
  {
    "front": "Mast cells",
    "back": "Immune system cell that secretes histamine; plays role in inflammatory response and allergies."
  },
  {
    "front": "Mate-choice copying",
    "back": "Behavior in which individuals in a population copy the mate choice of others, apparently due to social learning."
  },
  {
    "front": "Maternal effect gene",
    "back": "A gene that, when mutant in the female parent, results in a mutant phenotype in the offspring, regardless of the offspring\u2019s genotype. Maternal effect genes, also called egg-polarity genes, were first identified inDrosophila melanogaster."
  },
  {
    "front": "Matter",
    "back": "Anything that takes up space and has mass.",
    "chapter": 2
  },
  {
    "front": "Maximum likelihood",
    "back": "As applied to DNA sequence data, a principle that states that when considering multiple phylogenetic hypotheses, one should take into account the hypothesis that reflects the most likely sequence of evolutionary events, given certain rules about how DNA changes over time."
  },
  {
    "front": "Maximum parsimony",
    "back": "The principle that when considering multiple explanations for an observation, one should first investigate the simplest explanation that is consistent with the facts."
  },
  {
    "front": "Mean",
    "back": "The sum of all data points in a data set divided by the number of data points."
  },
  {
    "front": "Mechanoreceptors",
    "back": "A sensory receptor that detects physical deformation in the body\u2019s environment associated with pressure, touch, stretch, motion, or sound."
  },
  {
    "front": "Medulla oblongata",
    "back": "(meh-dul\u2032-uh ob\u2032-longgo\u2032-tuh) The lowest part of the vertebrate brain, commonly called the medulla; a swelling of the hindbrain anterior to the spinal cord that controls autonomic, homeostatic functions, including breathing, heart and blood vessel activity, swallowing, digestion, and vomiting."
  },
  {
    "front": "Medusa",
    "back": "(muh-d\u016b\u2032-suh) (plural,medusae) The floating, mouth-down form of the cnidarian body plan. The alternate form is the polyp."
  },
  {
    "front": "Megapascal",
    "back": "(MPa) (meg\u2032-uh-pas-kal\u2032) A unit of pressure equivalent to about 10 atmospheres of pressure."
  },
  {
    "front": "Megaphylls",
    "back": "(meh\u2032-guh-fil) A leaf with a highly branched vascular system, found in almost all vascular plants other than lycophytes; A small, usually spineshaped leaf supported by a single strand of vascular tissue, found only in lycophytes."
  },
  {
    "front": "Megaspores",
    "back": "A spore from a heterosporous plant species that develops into a female gametophyte."
  },
  {
    "front": "Meiosis",
    "back": "(m\u012b-\u014d\u2032-sis) A modified type of cell division in sexually reproducing organisms consisting of two rounds of cell division but only one round of DNA replication. It results in cells with half the number of chromosome sets as the original cell."
  },
  {
    "front": "Meiosis I",
    "back": "(m\u012b-\u014d\u2032-sis) The first division of a two-stage process of cell division in sexually reproducing organisms that results in cells with half the number of chromosome sets as the original cell."
  },
  {
    "front": "Meiosis II",
    "back": "(m\u012b-\u014d\u2032-sis) The second division of a two-stage process of cell division in sexually reproducing organisms that results in cells with half the number of chromosome sets as the original cell."
  },
  {
    "front": "Melanocyte-stimulating hormone",
    "back": "(MSH) A hormone produced and secreted by the anterior pituitary with multiple activities, including regulating the behavior of pigment-containing cells in the skin of some vertebrates."
  },
  {
    "front": "Melatonin",
    "back": "A hormone that is secreted by the pineal gland and that is involved in the regulation of biological rhythms and sleep."
  },
  {
    "front": "Membrane potential",
    "back": "The difference in electrical charge (voltage) across a cell\u2019s plasma membrane due to the differential distribution of ions. Membrane potential affects the activity of excitable cells and the transmembrane movement of all charged substances."
  },
  {
    "front": "Memory cells",
    "back": "One of a clone of long-lived lymphocytes, formed during the primary immune response, that remains in a lymphoid organ until activated by exposure to the same antigen that triggered its formation. Activated memory cells mount the secondary immune response."
  },
  {
    "front": "Menopause",
    "back": "The cessation of ovulation and menstruation marking the end of a human female\u2019s reproductive years."
  },
  {
    "front": "Menstrual cycle",
    "back": "(men\u2032-str\u016b-ul) In humans and certain other primates, the periodic growth and shedding of the uterine lining that occurs in the absence of pregnancy."
  },
  {
    "front": "Menstruation",
    "back": "The shedding of portions of the endometrium during a uterine (menstrual) cycle."
  },
  {
    "front": "Meristems",
    "back": "(m\u0101r\u2032-uh-stem) Plant tissue that remains embryonic as long as the plant lives, allowing for indeterminate growth."
  },
  {
    "front": "Mesoderm",
    "back": "(mez\u2032-\u014d-derm) The middle primary germ layer in a triploblastic animal embryo; develops into the notochord, the lining of the coelom, muscles, skeleton, gonads, kidneys, and most of the circulatory system in species that have these structures."
  },
  {
    "front": "Mesohyl",
    "back": "(mez\u2032-\u014d-h\u012bl) A gelatinous region between the two layers of cells of a sponge."
  },
  {
    "front": "Mesophyll",
    "back": "(mez\u2032-\u014d-fil) Leaf cells specialized for photosynthesis. In C3 and CAM plants, mesophyll cells are located between the upper and lower epidermis; in C4 plants, they are located between the bundle-sheath cells and the epidermis."
  },
  {
    "front": "Messenger RNA",
    "back": "(mRNA) A type of RNA, synthesized using a DNA template, that attaches to ribosomes in the cytoplasm and specifies the primary structure of a protein. (In eukaryotes, the primary RNA transcript must undergo RNA processing to become mRNA.)"
  },
  {
    "front": "Metabolic pathway",
    "back": "A series of chemical reactions that either builds a complex molecule (anabolic pathway) or breaks down a complex molecule to simpler molecules (catabolic pathway)."
  },
  {
    "front": "Metabolic rate",
    "back": "The total amount of energy an animal uses in a unit of time."
  },
  {
    "front": "Metabolism",
    "back": "(muh-tab\u2032-uh-lizm) The totality of an organism\u2019s chemical reactions, consisting of catabolic and anabolic pathways, which manage the material and energy resources of the organism."
  },
  {
    "front": "Metagenomics",
    "back": "The collection and sequencing of DNA from a group of species, usually an environmental sample of microorganisms. Computer software sorts partial sequences and assembles them into genome sequences of individual species making up the sample."
  },
  {
    "front": "Metamorphosis",
    "back": "(met\u2032-uh-m\u014dr\u2032-fuh-sis) A developmental transformation that turns an animal larva into either an adult or an adult-like stage that is not yet sexually mature."
  },
  {
    "front": "Metanephridia",
    "back": "(met\u2032-uh-nuh-frid\u2032-\u0113-um) (plural,metanephridia) An excretory organ found in many invertebrates that typically consists of tubules connecting ciliated internal openings to external openings."
  },
  {
    "front": "Metaphase",
    "back": "The third stage of mitosis, in which the spindle is complete and the chromosomes, attached to microtubules at their kinetochores, are all aligned at the metaphase plate."
  },
  {
    "front": "Metaphase plate",
    "back": "An imaginary structure located at a plane midway between the two poles of a cell in metaphase on which the centromeres of all the duplicated chromosomes are located."
  },
  {
    "front": "Metapopulation",
    "back": "A group of spatially separated populations of one species that interact through immigration and emigration."
  },
  {
    "front": "Metastasis",
    "back": "(muh-tas\u2032-tuh-sis) The spread of cancer cells to locations distant from their original site."
  },
  {
    "front": "Methanogens",
    "back": "(meth-an\u2032-\u014d-jen) An organism that produces methane as a waste product of the way it obtains energy. All known methanogens are in domain Archaea."
  },
  {
    "front": "Methyl group",
    "back": "A chemical group consisting of a carbon bonded to three hydrogen atoms. The methyl group may be attached to a carbon or to a different atom."
  },
  {
    "front": "Microbiome",
    "back": "The collection of microorganisms living in or on an organism\u2019s body, along with their genetic material."
  },
  {
    "front": "Microclimate",
    "back": "Climate patterns on a very fine scale, such as the specific climatic conditions underneath a log."
  },
  {
    "front": "Microevolution",
    "back": "Evolutionary change below the species level; change in the allele frequencies in a population over generations."
  },
  {
    "front": "Microfilaments",
    "back": "A cable composed of actin proteins in the cytoplasm of almost every eukaryotic cell, making up part of the cytoskeleton and acting alone or with myosin to cause cell contraction; also called an actin filament."
  },
  {
    "front": "Micronutrients",
    "back": "An essential element that an organism needs in very small amounts; An essential element that an organism must obtain in relatively large amounts."
  },
  {
    "front": "Microphylls",
    "back": "(m\u012b\u2032-kr\u014d-fil) A small, usually spine-shaped leaf supported by a single strand of vascular tissue, found only in lycophytes."
  },
  {
    "front": "Microplastics",
    "back": "A plastic particle less than 5 mm in size; microplastics have contaminated all of the world\u2019s oceans as well as freshwater and terrestrial ecosystems."
  },
  {
    "front": "Micropyle",
    "back": "A pore in the integuments of an ovule."
  },
  {
    "front": "MicroRNAs",
    "back": "(miRNA) A small, single-stranded RNA molecule, generated from a double-stranded RNA precursor. The miRNA associates with one or more proteins in a complex that can degrade or prevent translation of an mRNA with a complementary sequence."
  },
  {
    "front": "Microspores",
    "back": "A spore from a heterosporous plant species that develops into a male gametophyte."
  },
  {
    "front": "Microsporidians",
    "back": "A member of the fungal phylum Microsporidia, unicellular parasites of protists and animals; microsporidians and their sister taxon (cryptomycetes) are a basal fungal lineage."
  },
  {
    "front": "Microtubules",
    "back": "A hollow rod composed of tubulin proteins that makes up part of the cytoskeleton in all eukaryotic cells and is found in cilia and flagella."
  },
  {
    "front": "Microvilli",
    "back": "(plural,microvilli) One of many fine, finger-like projections of the epithelial cells in the lumen of the small intestine that increase its surface area."
  },
  {
    "front": "Midbrain",
    "back": "One of three ancestral and embryonic regions of the vertebrate brain; in human children and adults, the part of the brainstem that receives and integrates sensory information and then transmits it to the cerebrum."
  },
  {
    "front": "Middle ear",
    "back": "One of three main regions of the vertebrate ear; in mammals, a chamber containing three small bones (the malleus, incus, and stapes) that convey vibrations from the eardrum to the oval window."
  },
  {
    "front": "Middle lamella",
    "back": "(luh-mel'-uh) In plants, a thin layer of adhesive extracellular material, primarily pectins, found between the primary walls of adjacent young cells."
  },
  {
    "front": "Migration",
    "back": "A regular, long-distance change in location."
  },
  {
    "front": "Mineralocorticoids",
    "back": "A steroid hormone secreted by the adrenal cortex that regulates salt and water homeostasis."
  },
  {
    "front": "Minerals",
    "back": "In nutrition, a simple nutrient that is inorganic and therefore cannot be synthesized in the body."
  },
  {
    "front": "Minimum viable population",
    "back": "(MVP) The smallest population size at which a species is able to sustain its numbers and survive."
  },
  {
    "front": "Miscarriage",
    "back": "Early pregnancy loss (occurring in the first 20 weeks of gestation)."
  },
  {
    "front": "Mismatch repair",
    "back": "The cellular process that uses specific enzymes to remove and replace incorrectly paired nucleotides."
  },
  {
    "front": "Missense mutations",
    "back": "A nucleotide-pair substitution that results in a codon that codes for a different amino acid."
  },
  {
    "front": "Mitochondria",
    "back": "(m\u012b\u2032-t\u014d-kon\u2032-dr\u0113-un) (plural,mitochondria) An organelle in eukaryotic cells that serves as the site of cellular respiration; uses oxygen to break down organic molecules and synthesize ATP."
  },
  {
    "front": "Mitochondrial matrix",
    "back": "The compartment of the mitochondrion enclosed by the inner membrane and containing enzymes and substrates for the citric acid cycle, as well as ribosomes and DNA."
  },
  {
    "front": "Mitosis",
    "back": "(m\u012b-t\u014d\u2032-sis) A process of nuclear division in eukaryotic cells conventionally divided into five stages: prophase, prometaphase, metaphase, anaphase, and telophase. Mitosis conserves chromosome number by allocating replicated chromosomes equally to each of the daughter nuclei."
  },
  {
    "front": "Mitotic phase",
    "back": "(M) The phase of the cell cycle that includes mitosis and cytokinesis."
  },
  {
    "front": "Mitotic spindle",
    "back": "An assemblage of microtubules and associated proteins that is involved in the movement of chromosomes during mitosis."
  },
  {
    "front": "Mixotrophs",
    "back": "An organism that is capable of both photosynthesis and heterotrophy."
  },
  {
    "front": "Model organism",
    "back": "A particular species chosen for research into broad biological principles because it is representative of a larger group and usually easy to grow in a lab.",
    "chapter": 1
  },
  {
    "front": "Molarity",
    "back": "A common measure of solute concentration, referring to the number of moles of solute per liter of solution."
  },
  {
    "front": "Molds",
    "back": "Informal term for a fungus that grows as a filamentous fungus, producing haploid spores by mitosis and forming a visible mycelium."
  },
  {
    "front": "Mole",
    "back": "(mol) The number of grams of a substance that equals its molecular or atomic mass in daltons; a mole contains Avogadro\u2019s number of the molecules or atoms in question."
  },
  {
    "front": "Molecular clock",
    "back": "A method for estimating the time required for a given amount of evolutionary change, based on the observation that some regions of genomes evolve at constant rates."
  },
  {
    "front": "Molecular mass",
    "back": "The sum of the masses of all the atoms in a molecule; sometimes called molecular weight."
  },
  {
    "front": "Molecule",
    "back": "Two or more atoms held together by covalent bonds.",
    "chapter": 2
  },
  {
    "front": "Molting",
    "back": "A process in ecdysozoans in which the exoskeleton is shed at intervals, allowing growth by the production of a larger exoskeleton."
  },
  {
    "front": "Monilophytes",
    "back": "An informal name for a member of the phylum Monilophyta, which includes ferns, horsetails, and whisk ferns and their relatives."
  },
  {
    "front": "Monoclonal antibodies",
    "back": "(mon\u2032-\u014d-kl\u014dn\u2032-ul) Any of a preparation of antibodies that have been produced by a single clone of cultured cells and thus are all specific for the same epitope."
  },
  {
    "front": "Monocots",
    "back": "A member of a clade consisting of flowering plants that have one embryonic seed leaf, or cotyledon."
  },
  {
    "front": "Monogamous",
    "back": "(muh-nog\u2032-uh-mus) Referring to a type of relationship in which one male mates with just one female."
  },
  {
    "front": "Monohybrid cross",
    "back": "A cross between two organisms that are heterozygous for the character being followed (or the self-pollination of a heterozygous plant)."
  },
  {
    "front": "Monohybrids",
    "back": "An organism that is heterozygous with respect to a single gene of interest. All the offspring from a cross between parents homozygous for different alleles are monohybrids. For example, parents of genotypesAAandaaproduce a monohybrid of genotypeAa."
  },
  {
    "front": "Monomers",
    "back": "(mon\u2032-uh-mer) The subunit that serves as the building block of a polymer."
  },
  {
    "front": "Monophyletic",
    "back": "(mon\u2032-\u014d-f\u012b-let\u2032-ik) Pertaining to a group of taxa that consists of a common ancestor and all of its descendants. A monophyletic taxon is equivalent to a clade."
  },
  {
    "front": "Monosaccharides",
    "back": "(mon\u2032-\u014d-sak\u2032-uh-r\u012bd) The simplest carbohydrate, active alone or serving as a monomer for disaccharides and polysaccharides. Also called simple sugars, monosaccharides have molecular formulas that are generally some multiple of C-H-subscript-2-O."
  },
  {
    "front": "Monosomic",
    "back": "Referring to a diploid cell that has only one copy of a particular chromosome instead of the normal two."
  },
  {
    "front": "Monotremes",
    "back": "An egg-laying mammal, such as a platypus or echidna. Like all mammals, monotremes have hair and produce milk, but they lack nipples."
  },
  {
    "front": "Morphogenesis",
    "back": "(m\u014dr\u2032-f\u014d-jen\u2032-uh-sis) The development of the form of an organism and its structures."
  },
  {
    "front": "Morphogens",
    "back": "A substance, such as Bicoid protein inDrosophila, that provides positional information in the form of a concentration gradient along an embryonic axis."
  },
  {
    "front": "Morphological species concept",
    "back": "Definition of a species in terms of measurable anatomical criteria."
  },
  {
    "front": "Mosses",
    "back": "A small, herbaceous, nonvascular plant that is a member of the phylum Bryophyta."
  },
  {
    "front": "Motor neurons",
    "back": "A nerve cell that transmits signals from the brain or spinal cord to muscles or glands."
  },
  {
    "front": "Motor proteins",
    "back": "A protein that interacts with cytoskeletal elements and other cell components, producing movement of the whole cell or parts of the cell."
  },
  {
    "front": "Motor system",
    "back": "An efferent branch of the vertebrate peripheral nervous system composed of motor neurons that carry signals to skeletal muscles in response to external stimuli."
  },
  {
    "front": "Motor unit",
    "back": "A single motor neuron and all the muscle fibers it controls."
  },
  {
    "front": "Movement corridor",
    "back": "A series of small clumps or a narrow strip of quality habitat (usable by organisms) that connects otherwise isolated patches of quality habitat."
  },
  {
    "front": "MPF",
    "back": "Maturation-promoting factor (or M-phase-promoting factor); a protein complex required for a cell to progress from late interphase to mitosis. The active form consists of cyclin and a protein kinase."
  },
  {
    "front": "Mucoromycetes",
    "back": "A member of the fungal phylum Mucoromycota, characterized by the formation of a sturdy structure called a zygosporangium during sexual reproduction."
  },
  {
    "front": "Mucus",
    "back": "A viscous and slippery mixture of glycoproteins, cells, salts, and water that moistens and protects the membranes lining body cavities that open to the exterior."
  },
  {
    "front": "M\u00fcllerian mimicry",
    "back": "(my\u016b-l\u0101r\u2032-\u0113-un mim\u2032-uh-kr\u0113) Reciprocal mimicry by two unpalatable species."
  },
  {
    "front": "Multifactorial",
    "back": "Referring to a phenotypic character that is influenced by multiple genes and environmental factors."
  },
  {
    "front": "Multigene families",
    "back": "A collection of genes with similar or identical sequences, presumably of common origin."
  },
  {
    "front": "Multiple fruit",
    "back": "A fruit derived from an entire inflorescence."
  },
  {
    "front": "Multiplication rule",
    "back": "A rule of probability stating that the probability of two or more independent events occurring together can be determined by multiplying their individual probabilities."
  },
  {
    "front": "Muscle tissue",
    "back": "Tissue consisting of long muscle cells that can contract, either on its own or when stimulated by nerve impulses."
  },
  {
    "front": "Mutagens",
    "back": "(my\u016b\u2032-tuh-jen) A chemical or physical agent that interacts with DNA and can cause a mutation."
  },
  {
    "front": "Mutations",
    "back": "(my\u016b-t\u0101\u2032-shun) A change in the nucleotide sequence of an organism\u2019s DNA or in the DNA or RNA of a virus."
  },
  {
    "front": "Mutualism",
    "back": "(my\u016b\u2032-ch\u016b-ul-izm) A positive-positive ecological interaction that benefits individuals of both interacting species."
  },
  {
    "front": "Mycelium",
    "back": "(m\u012b-s\u0113\u2032-l\u0113-um) (plural,mycelia) The densely branched network of hyphae in a fungus."
  },
  {
    "front": "Mycorrhizae",
    "back": "(plural,mycorrhizae) (m\u012b\u2032-k\u014d-r\u012b\u2032-zuh, m\u012b\u2032-k\u014d-r\u012b\u2032-z\u0113) A mutualistic association of plant roots and fungus."
  },
  {
    "front": "Mycosis",
    "back": "(m\u012b-k\u014d\u2032-sis) General term for a fungal infection."
  },
  {
    "front": "Myelin sheath",
    "back": "(m\u012b\u2032-uh-lin) Wrapped around the axon of a neuron, an insulating coat of cell membranes from Schwann cells or oligodendrocytes. It is interrupted by nodes of Ranvier, where action potentials are generated."
  },
  {
    "front": "Myofibrils",
    "back": "(m\u012b\u2032-\u014d-f\u012b\u2032-bril) A longitudinal bundle in a muscle cell (fiber) that contains thin filaments of actin and regulatory proteins and thick filaments of myosin."
  },
  {
    "front": "Myoglobin",
    "back": "(m\u012b\u2032-uh-gl\u014d\u2032-bin) An oxygen-storing, pigmented protein in muscle cells."
  },
  {
    "front": "Myosin",
    "back": "(m\u012b'-uh-sin) A type of motor protein that associates into filaments that interact with actin filaments to cause cell contraction."
  },
  {
    "front": "Myriapods",
    "back": "(mir\u2032-\u0113-uh-pod\u2032) A terrestrial arthropod with many body segments and one or two pairs of legs per segment. Millipedes and centipedes are the two major groups of living myriapods."
  },
  {
    "front": "NAD plus",
    "back": "The oxidized form of nicotinamide adenine dinucleotide, a coenzyme that can accept electrons, becoming NADH. NADH temporarily stores electrons during cellular respiration."
  },
  {
    "front": "NADH",
    "back": "The reduced form of nicotinamide adenine dinucleotide that temporarily stores electrons during cellular respiration. NADH acts as an electron donor to the electron transport chain."
  },
  {
    "front": "NADP plus",
    "back": "The oxidized form of nicotinamide adenine dinucleotide phosphate, an electron carrier that can accept electrons, becoming NADPH. NADPH temporarily stores energized electrons produced during the light reactions."
  },
  {
    "front": "NADPH",
    "back": "The reduced form of nicotinamide adenine dinucleotide phosphate; temporarily stores energized electrons produced during the light reactions. NADPH acts as \u201creducing power\u201d that can be passed along to an electron acceptor, reducing it."
  },
  {
    "front": "Natural killer cells",
    "back": "A type of white blood cell that can kill tumor cells and virus-infected cells as part of innate immunity."
  },
  {
    "front": "Natural selection",
    "back": "A process in which individuals that have certain inherited traits tend to survive and reproduce at higher rates than other individuals because of those traits.",
    "chapter": 1
  },
  {
    "front": "Negative feedback",
    "back": "A form of regulation in which accumulation of an end product of a process slows the process; in physiology, a primary mechanism of homeostasis, whereby a change in a variable triggers a response that counteracts the initial change."
  },
  {
    "front": "Negative pressure breathing",
    "back": "A breathing system in which air is pulled into the lungs."
  },
  {
    "front": "Nematocysts",
    "back": "(nem\u2032-uh-tuh-sist\u2032) In a cnidocyte of a cnidarian, a capsule-like organelle containing a coiled thread that when discharged can penetrate the body wall of the prey."
  },
  {
    "front": "Nephrons",
    "back": "(nef\u2032-ron) The tubular excretory unit of the vertebrate kidney."
  },
  {
    "front": "Nerves",
    "back": "A fiber composed primarily of the bundled axons of neurons."
  },
  {
    "front": "Nervous system",
    "back": "In animals, the fast-acting internal system of communication involving sensory receptors, networks of nerve cells, and connections to muscles and glands that respond to nerve signals; functions in concert with the endocrine system to effect internal regulation and maintain homeostasis."
  },
  {
    "front": "Nervous tissue",
    "back": "Tissue made up of neurons and supportive cells."
  },
  {
    "front": "Net ecosystem production",
    "back": "(NEP) The gross primary production of an ecosystem minus the energy used by all autotrophs and heterotrophs for respiration."
  },
  {
    "front": "Net primary production",
    "back": "(NPP) The gross primary production of an ecosystem minus the energy used by the producers for respiration."
  },
  {
    "front": "Neural crest",
    "back": "In vertebrates, a region located along the sides of the neural tube where it pinches off from the ectoderm. Neural crest cells migrate to various parts of the embryo and form pigment cells in the skin and parts of the skull, teeth, adrenal glands, and peripheral nervous system."
  },
  {
    "front": "Neural tube",
    "back": "A tube of infolded ectodermal cells that runs along the anterior-posterior axis of a vertebrate, just dorsal to the notochord. It will give rise to the central nervous system."
  },
  {
    "front": "Neurohormones",
    "back": "A molecule that is secreted by a neuron, travels in body fluids, and acts on specific target cells, changing their functioning."
  },
  {
    "front": "Neuron",
    "back": "(ny\u016br\u2032-on) A nerve cell; the fundamental unit of the nervous system, having structure and properties that allow it to conduct signals by taking advantage of the electrical charge across its plasma membrane."
  },
  {
    "front": "Neuronal plasticity",
    "back": "The capacity of a nervous system to change with experience."
  },
  {
    "front": "Neuropeptides",
    "back": "A relatively short chain of amino acids that serves as a neurotransmitter."
  },
  {
    "front": "Neurotransmitters",
    "back": "A molecule that is released from the synaptic terminal of a neuron at a chemical synapse, diffuses across the synaptic cleft, and binds to the postsynaptic cell, triggering a response."
  },
  {
    "front": "Neutral variation",
    "back": "Genetic variation that does not provide a selective advantage or disadvantage."
  },
  {
    "front": "Neutrons",
    "back": "A subatomic particle having no electrical charge (electrically neutral), with a mass of about 1.7 times 10 to the negative 24 g, found in the nucleus of an atom.",
    "chapter": 2
  },
  {
    "front": "Neutrophils",
    "back": "The most abundant type of white blood cell. Neutrophils are phagocytic and tend to self-destruct as they destroy foreign invaders, limiting their life span to a few days."
  },
  {
    "front": "Nitric oxide",
    "back": "(NO) A gas produced by many types of cells that functions as a local regulator and as a neurotransmitter."
  },
  {
    "front": "Nitrogen cycle",
    "back": "The natural process by which nitrogen, either from the atmosphere or from decomposed organic material, is converted by soil bacteria to compounds assimilated by plants. This incorporated nitrogen is then taken in by other organisms and subsequently released, acted on by bacteria, and made available again to the nonliving environment."
  },
  {
    "front": "Nitrogen fixation",
    "back": "The conversion of atmospheric nitrogen (N subscript 2) to ammonia (NH subscript 3) Biological nitrogen fixation is carried out by certain prokaryotes, some of which have mutualistic relationships with plants."
  },
  {
    "front": "No-till agriculture",
    "back": "A plowing technique that minimally disturbs the soil, thereby reducing soil loss."
  },
  {
    "front": "Nociceptors",
    "back": "(n\u014d\u2032-si-sep\u2032-tur) A sensory receptor that responds to noxious or painful stimuli; also called a pain receptor."
  },
  {
    "front": "Nodes",
    "back": "A point along the stem of a plant at which leaves are attached."
  },
  {
    "front": "Nodes of Ranvier",
    "back": "(ron\u2032-v\u0113-\u0101\u2032) Gap in the myelin sheath of certain axons where an action potential may be generated. In saltatory conduction, an action potential is regenerated at each node, appearing to \u201cjump\u201d along the axon from node to node."
  },
  {
    "front": "Nodules",
    "back": "A swelling on the root of a legume. Nodules are composed of plant cells that contain nitrogen-fixing bacteria of the genusRhizobium."
  },
  {
    "front": "Noncompetitive inhibitors",
    "back": "A substance that reduces the activity of an enzyme by binding to a location remote from the active site, changing the enzyme\u2019s shape so that the active site no longer effectively catalyzes the conversion of substrate to product."
  },
  {
    "front": "Nondisjunction",
    "back": "An error in meiosis or mitosis in which members of a pair of homologous chromosomes or a pair of sister chromatids fail to separate properly from each other."
  },
  {
    "front": "Nonequilibrium model",
    "back": "A model that maintains that communities change constantly after being buffeted by disturbances."
  },
  {
    "front": "Nonpolar covalent bond",
    "back": "A type of covalent bond in which electrons are shared equally between two atoms of similar electronegativity.",
    "chapter": 2
  },
  {
    "front": "Nonsense mutation",
    "back": "A mutation that changes an amino acid codon to one of the three stop codons, resulting in a shorter and usually nonfunctional protein."
  },
  {
    "front": "Norepinephrine",
    "back": "(nor-ep\u2032-i-nef\u2032-rin) A catecholamine that is chemically and functionally similar to epinephrine and acts as a hormone or neurotransmitter; also called noradrenaline."
  },
  {
    "front": "Northern coniferous forest",
    "back": "A terrestrial biome characterized by long, cold winters and dominated by cone-bearing trees."
  },
  {
    "front": "Notochord",
    "back": "(n\u014d\u2032-tuh-kord\u2032) A longitudinal, flexible rod made of tightly packed mesodermal cells that runs along the anterior-posterior axis of a chordate in the dorsal part of the body."
  },
  {
    "front": "Nuclear envelope",
    "back": "In a eukaryotic cell, the double membrane that surrounds the nucleus, perforated with pores that regulate traffic with the cytoplasm. The outer membrane is continuous with the endoplasmic reticulum."
  },
  {
    "front": "Nuclear lamina",
    "back": "A netlike array of protein filaments that lines the inner surface of the nuclear envelope and helps maintain the shape of the nucleus."
  },
  {
    "front": "Nucleariids",
    "back": "A member of a group of unicellular, amoeboid protists that are more closely related to fungi than they are to other protists."
  },
  {
    "front": "Nuclease",
    "back": "An enzyme that cuts DNA or RNA, either removing one or a few bases or hydrolyzing the DNA or RNA completely into its component nucleotides."
  },
  {
    "front": "Nucleic acid hybridization",
    "back": "(n\u016b-kl\u0101\u2032-ik) The base pairing of one strand of a nucleic acid to the complementary sequence on a strand fromanothernucleic acid molecule."
  },
  {
    "front": "Nucleic acid probe",
    "back": "(n\u016b\u2032-kl\u0101\u2032-ik) In DNA technology, a labeled single-stranded nucleic acid molecule used to locate a specific nucleotide sequence in a nucleic acid sample. Molecules of the probe hydrogen-bond to the complementary sequence wherever it occurs; radioactive, fluorescent, or other labeling of the probe allows its location to be detected."
  },
  {
    "front": "Nucleic acids",
    "back": "(n\u016b-kl\u0101\u2032-ik) A polymer (polynucleotide) consisting of many nucleotide monomers; serves as a blueprint for proteins and, through the actions of proteins, for all cellular activities. The two types are DNA and RNA."
  },
  {
    "front": "Nucleoid",
    "back": "(n\u016b'-kl\u0113-oyd) A non-membrane-enclosed region in a prokaryotic cell where its chromosome is located."
  },
  {
    "front": "Nucleolus",
    "back": "(n\u016b-kl\u0113'-\u014d-lus) (plural,nucleoli) A specialized structure in the nucleus, consisting of chromosomal regions containing ribosomal RNA (rRNA) genes along with ribosomal proteins imported from the cytoplasm; site of rRNA synthesis and ribosomal subunit assembly; A complex of rRNA and protein molecules that functions as a site of protein synthesis in the cytoplasm; consists of a large and a small subunit. In eukaryotic cells, each subunit is assembled in the nucleolus."
  },
  {
    "front": "Nucleosomes",
    "back": "(n\u016b\u2032-kl\u0113-\u014d-s\u014dm\u2032) The basic, bead-like unit of DNA packing in eukaryotes, consisting of a segment of DNA wound around a protein core composed of two copies of each of four types of histone."
  },
  {
    "front": "Nucleotide excision repair",
    "back": "(n\u016b\u2032-kl\u0113-\u014d-t\u012bd\u2032) A repair system that removes and then correctly replaces a damaged segment of DNA using the undamaged strand as a guide."
  },
  {
    "front": "Nucleotide-pair substitution",
    "back": "(n\u016b\u2032-kl\u0113-\u014d-t\u012bd\u2032) A type of point mutation in which one nucleotide in a DNA strand and its partner in the complementary strand are replaced by another pair of nucleotides."
  },
  {
    "front": "Nucleotides",
    "back": "(n\u016b\u2032-kl\u0113-\u014d-t\u012bd\u2032) The building block of a nucleic acid, consisting of a five-carbon sugar covalently bonded to a nitrogenous base and one to three phosphate groups."
  },
  {
    "front": "Nucleus",
    "back": "(1) An atom\u2019s central core, containing protons and neutrons. (2) The organelle of a eukaryotic cell that contains the genetic material in the form of chromosomes, made up of chromatin. (3) A cluster of neurons."
  },
  {
    "front": "Nutrition",
    "back": "The process by which an organism takes in and makes use of food substances."
  },
  {
    "front": "Obligate aerobes",
    "back": "(ob\u2032-lig-et \u0101r\u2032-\u014db) An organism that requires oxygen for cellular respiration and cannot live without it."
  },
  {
    "front": "Obligate anaerobes",
    "back": "(ob\u2032-lig-et an\u2032-uh-r\u014db) An organism that carries out only fermentation or anaerobic respiration. Such organisms cannot use oxygen and in fact may be poisoned by it."
  },
  {
    "front": "Ocean acidification",
    "back": "The process by which the pH of the ocean is lowered (made more acidic) when excess carbon dioxide dissolves in seawater and forms carbonic acid."
  },
  {
    "front": "Odorants",
    "back": "A molecule that can be detected by sensory receptors of the olfactory system."
  },
  {
    "front": "Okazaki fragments",
    "back": "(\u014d\u2032-kah-zah\u2032-k\u0113) A short segment of DNA synthesized away from the replication fork on a template strand during DNA replication. Many such segments are joined together to make up the lagging strand of newly synthesized DNA."
  },
  {
    "front": "Olfaction",
    "back": "The sense of smell."
  },
  {
    "front": "Oligodendrocytes",
    "back": "A type of glial cell that forms insulating myelin sheaths around the axons of neurons in the central nervous system."
  },
  {
    "front": "Ommatidia",
    "back": "(\u014dm\u2032-uh-tid\u2032-\u0113-um) (plural,ommatidia) One of the facets of the compound eye of arthropods and some polychaete worms."
  },
  {
    "front": "Omnivores",
    "back": "An animal that regularly eats animals as well as plants or algae."
  },
  {
    "front": "Oncogenes",
    "back": "(on\u2032-k\u014d-j\u0113n) A gene found in viral or cellular genomes that is involved in triggering molecular events that can lead to cancer."
  },
  {
    "front": "Oocyte",
    "back": "(\u014d\u2032-uh-s\u012bt) A cell in the female reproductive system that differentiates to form an egg."
  },
  {
    "front": "Oogenesis",
    "back": "(\u014d\u2032-uh-jen\u2032-uh-sis) The process in the ovary that results in the production of female gametes."
  },
  {
    "front": "Open circulatory system",
    "back": "A circulatory system in which fluid called hemolymph bathes the tissues and organs directly and there is no distinction between the circulating fluid and the interstitial fluid."
  },
  {
    "front": "Operator",
    "back": "In bacterial and phage DNA, a sequence of nucleotides near the start of an operon to which an active repressor can attach. The binding of the repressor prevents RNA polymerase from attaching to the promoter and transcribing the genes of the operon."
  },
  {
    "front": "Operculum",
    "back": "(\u014d-per\u2032-kyuh-lum) In aquatic osteichthyans, a protective bony flap that covers and protects the gills."
  },
  {
    "front": "Operon",
    "back": "(op\u2032-er-on) A unit of genetic function found in bacteria and phages, consisting of a promoter, an operator, and a coordinately regulated cluster of genes whose products function in a common pathway."
  },
  {
    "front": "Opisthokonts",
    "back": "(uh-pis\u2032-thuh-kont\u2032) A member of an extremely diverse clade of eukaryotes that includes fungi, animals, and several closely related groups of protists."
  },
  {
    "front": "Opposable thumb",
    "back": "A thumb that can touch the ventral surface (fingerprint side) of the fingertip of all four fingers of the same hand with its own ventral surface."
  },
  {
    "front": "Opsin",
    "back": "A membrane protein bound to a light-absorbing pigment molecule."
  },
  {
    "front": "Optimal foraging model",
    "back": "The basis for analyzing behavior as a compromise between feeding costs and feeding benefits."
  },
  {
    "front": "Oral cavity",
    "back": "The mouth of an animal."
  },
  {
    "front": "Orbital",
    "back": "The three-dimensional space where an electron is found 90 percent of the time.",
    "chapter": 2
  },
  {
    "front": "Orders",
    "back": "In Linnaean classification, the taxonomic category above the level of family."
  },
  {
    "front": "Organ",
    "back": "A specialized center of body function composed of several different types of tissues."
  },
  {
    "front": "Organ system",
    "back": "A group of organs that work together in performing vital body functions."
  },
  {
    "front": "Organelles",
    "back": "(\u014dr-guh-nel\u2032) Any of several membrane-enclosed structures with specialized functions, suspended in the cytosol of eukaryotic cells."
  },
  {
    "front": "Organic chemistry",
    "back": "The study of carbon compounds (organic compounds)."
  },
  {
    "front": "Organisms",
    "back": "An individual living thing, consisting of one or more cells.",
    "chapter": 1
  },
  {
    "front": "Organogenesis",
    "back": "(\u014dr-gan\u2032-\u014d-jen\u2032-uh-sis) The process in which organ rudiments develop from the three germ layers after gastrulation."
  },
  {
    "front": "Origin of replication",
    "back": "Site where the replication of a DNA molecule begins, consisting of a specific sequence of nucleotides."
  },
  {
    "front": "Orthologous genes",
    "back": "Homologous genes that are found in different species because of speciation."
  },
  {
    "front": "Osculum",
    "back": "(os\u2032-kyuh-lum) A large opening in a sponge that connects the spongocoel to the environment."
  },
  {
    "front": "Osmoconformer",
    "back": "An animal that is isoosmotic with its environment."
  },
  {
    "front": "Osmolarity",
    "back": "(oz\u2032-m\u014d-l\u0101r\u2032-uh-t\u0113) Solute concentration expressed as molarity."
  },
  {
    "front": "Osmoregulation",
    "back": "Regulation of solute concentrations and water balance by a cell or organism."
  },
  {
    "front": "Osmoregulator",
    "back": "An animal that controls its internal osmolarity independent of the external environment."
  },
  {
    "front": "Osmosis",
    "back": "(oz-m\u014d\u2032-sis) The diffusion of free water across a selectively permeable membrane."
  },
  {
    "front": "Osteichthyans",
    "back": "(os\u2032-t\u0113-ik\u2032-th\u0113-an) A member of a vertebrate clade with jaws and mostly bony skeletons."
  },
  {
    "front": "Outgroup",
    "back": "A species or group of species from an evolutionary lineage that is known to have diverged before the lineage that contains the group of species being studied. An outgroup is selected so that its members are closely related to the group of species being studied, but not as closely related as any study-group members are to each other."
  },
  {
    "front": "Oval window",
    "back": "In the vertebrate ear, a membrane-covered gap in the skull bone, through which sound waves pass from the middle ear to the inner ear."
  },
  {
    "front": "Ovarian cycle",
    "back": "(\u014d-v\u0101r\u2032-\u0113-un) The cyclic recurrence of the follicular phase, ovulation, and the luteal phase in the mammalian ovary, regulated by hormones."
  },
  {
    "front": "Ovary",
    "back": "(\u014d\u2032-vuh-r\u0113) (1) In flowers, the portion of a carpel in which the egg-containing ovules develop. (2) In animals, the structure that produces female gametes and reproductive hormones."
  },
  {
    "front": "Oviduct",
    "back": "(\u014d\u2032-vuh-duct) A tube passing from the ovary to the vagina in invertebrates or to the uterus in vertebrates, where it is also called a fallopian tube."
  },
  {
    "front": "Oviparous",
    "back": "(\u014d-vip\u2032-uh-rus) Referring to a type of development in which young hatch from eggs laid outside the female\u2019s body."
  },
  {
    "front": "Ovoviviparous",
    "back": "(\u014d\u2032-v\u014d-v\u012b-vip\u2032-uh-rus) Referring to a type of development in which young hatch from eggs that are retained in the uterus."
  },
  {
    "front": "Ovulation",
    "back": "The release of an egg from an ovary. In humans, an ovarian follicle releases an egg during each uterine (menstrual) cycle."
  },
  {
    "front": "Ovule",
    "back": "(o\u2032-vy\u016bl) A structure that develops within the ovary of a seed plant and contains the female gametophyte."
  },
  {
    "front": "Oxidation",
    "back": "The complete or partial loss of electrons from a substance involved in a redox reaction."
  },
  {
    "front": "Oxidative phosphorylation",
    "back": "(fos\u2032-f\u014dr-uh-l\u0101\u2032-shun) The production of ATP using energy derived from the redox reactions of an electron transport chain; the third major stage of cellular respiration."
  },
  {
    "front": "Oxidizing agent",
    "back": "The electron acceptor in a redox reaction."
  },
  {
    "front": "Oxytocin",
    "back": "(ok\u2032-si-t\u014d\u2032-sen) A hormone produced by the hypothalamus and released from the posterior pituitary. It induces contractions of the uterine muscles during labor and causes the mammary glands to eject milk during nursing."
  },
  {
    "front": "P generation",
    "back": "The true-breeding (homozygous) parent individuals from which F1 hybrid offspring are derived in studies of inheritance. (P stands for parental.)"
  },
  {
    "front": "P site",
    "back": "One of a ribosome\u2019s three binding sites for tRNA during translation. The P site holds the tRNA carrying the growing polypeptide chain. (P stands for peptidyl tRNA.)"
  },
  {
    "front": "P53 gene",
    "back": "A tumor-suppressor gene that codes for a specific transcription factor that promotes the synthesis of proteins that inhibit the cell cycle."
  },
  {
    "front": "Paedomorphosis",
    "back": "(p\u0113\u2032-duh-m\u014dr\u2032-fuh-sis) The retention in an adult organism of the juvenile features of its evolutionary ancestors."
  },
  {
    "front": "Pain receptors",
    "back": "A sensory receptor that responds to noxious or painful stimuli; also called a nociceptor."
  },
  {
    "front": "Paleoanthropology",
    "back": "The study of human origins and evolution."
  },
  {
    "front": "Paleontology",
    "back": "(p\u0101\u2032-l\u0113-un-tol\u2032-\u014d-j\u0113) The scientific study of fossils."
  },
  {
    "front": "Pancreas",
    "back": "(pan\u2032-kr\u0113-us) A gland with exocrine and endocrine tissues. The exocrine portion functions in digestion, secreting enzymes and an alkaline solution into the small intestine via a duct; the ductless endocrine portion functions in homeostasis, secreting the hormones insulin and glucagon into the blood."
  },
  {
    "front": "Pancrustaceans",
    "back": "A member of a diverse arthropod clade that includes lobsters, crabs, barnacles and other crustaceans, as well as insects and their six-legged terrestrial relatives."
  },
  {
    "front": "Pandemic",
    "back": "A global epidemic."
  },
  {
    "front": "Pangaea",
    "back": "(pan-j\u0113\u2032-uh) The supercontinent that formed near the end of the Paleozoic era, when plate movements brought all the landmasses of Earth together."
  },
  {
    "front": "Parabasalids",
    "back": "A protist, such as a trichomonad, with modified mitochondria."
  },
  {
    "front": "Paracrine",
    "back": "Referring to a secreted molecule that acts on a neighboring cell."
  },
  {
    "front": "Paralogous genes",
    "back": "Homologous genes that are found in the same genome as a result of gene duplication."
  },
  {
    "front": "Paraphyletic",
    "back": "(p\u0101r\u2032-uh-f\u012b-let\u2032-ik) Pertaining to a group of taxa that consists of a common ancestor and some, but not all, of its descendants."
  },
  {
    "front": "Parasite",
    "back": "(p\u0101r\u2032-uh-s\u012bt) An organism that feeds on the cell contents, tissues, or body fluids of another species (the host) while in or on the host organism. Parasites harm but usually do not kill their host."
  },
  {
    "front": "Parasitism",
    "back": "(p\u0101r\u2032-uh-sit-izm) A positive-negative ecological interaction in which one organism, the parasite, benefits by feeding upon another organism, the host, which is harmed; some parasites live within the host (feeding on its tissues), while others feed on the host\u2019s external surface."
  },
  {
    "front": "Parasympathetic division",
    "back": "A division of the autonomic nervous system; generally enhances body activities that gain and conserve energy, such as digestion and reduced heart rate."
  },
  {
    "front": "Parathyroid glands",
    "back": "One of four small endocrine glands, embedded in the surface of the thyroid gland, that secrete parathyroid hormone."
  },
  {
    "front": "Parathyroid hormone",
    "back": "(PTH) A hormone secreted by the parathyroid glands that raises blood calcium level by promoting calcium release from bone and calcium retention by the kidneys."
  },
  {
    "front": "Parenchyma cells",
    "back": "(puh-ren\u2032-ki-muh) A relatively unspecialized plant cell type that carries out most of the metabolism, synthesizes and stores organic products, and develops into a more differentiated cell type."
  },
  {
    "front": "Parental types",
    "back": "An offspring with a phenotype that matches one of the true-breeding parental (P generation) phenotypes; also refers to the phenotype itself."
  },
  {
    "front": "Parkinson\u2019s disease",
    "back": "A progressive brain disease characterized by difficulty in initiating movements, slowness of movement, and rigidity."
  },
  {
    "front": "Parthenogenesis",
    "back": "(par\u2032-thuh-n\u014d\u2032-jen\u2032-uh-sis) A form of asexual reproduction in which females produce offspring from unfertilized eggs."
  },
  {
    "front": "Partial pressure",
    "back": "The pressure exerted by a particular gas in a mixture of gases (for instance, the pressure exerted by oxygen in air)."
  },
  {
    "front": "Passive immunity",
    "back": "Short-term immunity conferred by the transfer of antibodies, as occurs in the transfer of maternal antibodies to a fetus or nursing infant."
  },
  {
    "front": "Passive transport",
    "back": "The diffusion of a substance across a biological membrane with no expenditure of energy."
  },
  {
    "front": "Pathogen-associated molecular patterns",
    "back": "(PAMP) A molecular sequence that is specific to a certain pathogen."
  },
  {
    "front": "Pathogens",
    "back": "An organism or virus that causes disease."
  },
  {
    "front": "Pattern formation",
    "back": "The development of a multicellular organism\u2019s spatial organization, the arrangement of organs and tissues in their characteristic places in three-dimensional space."
  },
  {
    "front": "Peat",
    "back": "Extensive deposits of partially decayed organic material often formed primarily from the wetland mossSphagnum."
  },
  {
    "front": "Pedigree",
    "back": "A diagram of a family tree with conventional symbols, showing the occurrence of heritable characters in parents and offspring over multiple generations."
  },
  {
    "front": "Pelagic zone",
    "back": "The open-water component of aquatic biomes."
  },
  {
    "front": "Penis",
    "back": "The copulatory structure of male mammals."
  },
  {
    "front": "PEP carboxylase",
    "back": "An enzyme that adds carbon dioxide to phosphoenolpyruvate (PEP) to form oxaloacetate in mesophyll cells of C4 plants. It acts prior to photosynthesis."
  },
  {
    "front": "Pepsin",
    "back": "An enzyme present in gastric juice that begins the hydrolysis of proteins."
  },
  {
    "front": "Pepsinogen",
    "back": "The inactive form of pepsin secreted by chief cells located in gastric pits of the stomach."
  },
  {
    "front": "Peptide bond",
    "back": "The covalent bond between the carboxyl group on one amino acid and the amino group on another, formed by a dehydration reaction."
  },
  {
    "front": "Peptidoglycan",
    "back": "(pep\u2032-tid-\u014d-gl\u012b\u2032-kan) A type of polymer in bacterial cell walls consisting of modified sugars cross-linked by short polypeptides."
  },
  {
    "front": "Perception",
    "back": "The interpretation of sensory system input by the brain."
  },
  {
    "front": "Pericycle",
    "back": "The outermost layer in the vascular cylinder, from which lateral roots arise."
  },
  {
    "front": "Periderm",
    "back": "(p\u0101r\u2032-uh-derm\u2032) The protective coat that replaces the epidermis in woody plants during secondary growth, formed of the cork and cork cambium."
  },
  {
    "front": "Peripheral nervous system",
    "back": "(PNS) The sensory and motor neurons that connect to the central nervous system."
  },
  {
    "front": "Peripheral proteins",
    "back": "A protein loosely bound to the surface of a membrane or to part of an integral protein and not embedded in the lipid bilayer."
  },
  {
    "front": "Peristalsis",
    "back": "(p\u0101r\u2032-uh-stal\u2032-sis) (1) Alternating waves of contraction and relaxation in the smooth muscles lining the alimentary canal that push food along the canal. (2) A type of movement on land produced by rhythmic waves of muscle contractions passing from front to back, as in many annelids."
  },
  {
    "front": "Peristome",
    "back": "(p\u0101r\u2032-uh-st\u014dme\u2032) A ring of interlocking, tooth-like structures on the upper part of a moss capsule (sporangium), often specialized for gradual spore discharge."
  },
  {
    "front": "Peroxisome",
    "back": "(puh-rok'-suh-s\u014dm') An organelle containing enzymes that transfer hydrogen atoms from various substrates to oxygen, producing and then degrading hydrogen peroxide."
  },
  {
    "front": "Personalized medicine",
    "back": "A type of medical care in which each person\u2019s specific genetic profile can provide information about diseases or conditions for which the person is especially at risk and help make health-care decisions."
  },
  {
    "front": "Petals",
    "back": "A modified leaf of a flowering plant. Petals are the often colorful parts of a flower that advertise it to insects and other pollinators."
  },
  {
    "front": "Petiole",
    "back": "(pet\u2032-\u0113-\u014dl) The stalk of a leaf, which joins the leaf to a node of the stem."
  },
  {
    "front": "PH",
    "back": "A measure of hydrogen ion concentration equal to the negative log of the concentration of hydrogen ions and ranging in value from 0 to 14."
  },
  {
    "front": "Phages",
    "back": "(f\u0101j) A virus that infects bacteria; also called a bacteriophage."
  },
  {
    "front": "Phagocytosis",
    "back": "(fag\u2032-\u014d-s\u012b-t\u014d\u2032-sis) A type of endocytosis in which large particulate substances or small organisms are taken up by a cell. It is carried out by some protists and by certain immune cells of animals (in mammals, mainly macrophages, neutrophils, and dendritic cells)."
  },
  {
    "front": "Pharyngeal clefts",
    "back": "(fuh-rin\u2032-j\u0113-ul) In chordate embryos, one of the grooves that separate a series of arches along the outer surface of the pharynx and may develop into a pharyngeal slit."
  },
  {
    "front": "Pharyngeal slits",
    "back": "(fuh-rin\u2032-j\u0113-ul) In chordate embryos, one of the slits that form from the pharyngeal clefts and open into the pharynx, later developing into gill slits in many vertebrates."
  },
  {
    "front": "Pharynx",
    "back": "(f\u0101r\u2032-inks) (1) An area in the vertebrate throat where air and food passages cross. (2) In flatworms, the muscular tube that protrudes from the ventral side of the worm and ends in the mouth."
  },
  {
    "front": "Phase changes",
    "back": "(1) A shift from one developmental phase to another. (2) In plants, a morphological change that arises from a transition in shoot apical meristem activity."
  },
  {
    "front": "Phenotype",
    "back": "(f\u0113\u2032-n\u014d-t\u012bp) The observable physical and physiological traits of an organism, which are determined by its genetic makeup."
  },
  {
    "front": "Pheromones",
    "back": "(f\u0101r\u2032-uh-m\u014dn) In animals and fungi, a small molecule released into the environment that functions in communication between members of the same species. In animals, it acts much like a hormone in influencing physiology and behavior."
  },
  {
    "front": "Phloem",
    "back": "(fl\u014d\u2032-em) Vascular plant tissue consisting of living cells arranged into elongated tubes that transport sugar and other organic nutrients throughout the plant."
  },
  {
    "front": "Phloem sap",
    "back": "(fl\u014d\u2032-em) The sugar-rich solution carried through a plant\u2019s sieve tubes."
  },
  {
    "front": "Phosphate group",
    "back": "A chemical group consisting of a phosphorus atom bonded to four oxygen atoms; important in energy transfer."
  },
  {
    "front": "Phospholipid",
    "back": "(fos\u2032-f\u014d-lip\u2032-id) A lipid made up of glycerol joined to two fatty acids and a phosphate group. The hydrocarbon chains of the fatty acids act as nonpolar, hydrophobic tails, while the rest of the molecule acts as a polar, hydrophilic head. Phospholipids form bilayers that function as biological membranes."
  },
  {
    "front": "Phosphorylated intermediate",
    "back": "(fos\u2032-f\u014dr-uh-l\u0101\u2032-ted) A molecule (often a reactant) with a phosphate group covalently bound to it, making it more reactive (less stable) than the unphosphorylated molecule."
  },
  {
    "front": "Phosphorylation cascade",
    "back": "(fos\u2032-f\u014dr-uh-l\u0101\u2032-shun) A series of chemical reactions during cell signaling mediated by enzymes (kinases), in which each kinase in turn phosphorylates and activates another, ultimately leading to phosphorylation of many proteins."
  },
  {
    "front": "Photic zone",
    "back": "(f\u014d\u2032-tic) The narrow top layer of an ocean or lake, where light penetrates sufficiently for photosynthesis to occur."
  },
  {
    "front": "Photoautotroph",
    "back": "(f\u014d'-t\u014d-ot'-\u014d-tr\u014df) An organism that harnesses light energy to drive the synthesis of organic compounds from carbon dioxide."
  },
  {
    "front": "Photoheterotroph",
    "back": "(f\u014d'-t\u014d-het'-er-\u014d-tr\u014df) An organism that uses light to generate ATP but must obtain carbon in organic form."
  },
  {
    "front": "Photomorphogenesis",
    "back": "Effects of light on plant morphology."
  },
  {
    "front": "Photons",
    "back": "(f\u014d\u2032-ton) A quantum, or discrete quantity, of light energy that behaves as if it were a particle."
  },
  {
    "front": "Photoperiodism",
    "back": "(f\u014d\u2032-t\u014d-p\u0113r\u2032-\u0113-\u014d-dizm) A physiological response to photoperiod, the interval in a 24-hour period during which an organism is exposed to light. An example of photoperiodism is flowering."
  },
  {
    "front": "Photophosphorylation",
    "back": "(f\u014d\u2032-t\u014d-fos\u2032-f\u014dr-uh-l\u0101\u2032-shun) The process of generating ATP from ADP and phosphate by means of chemiosmosis, using a proton-motive force generated across the thylakoid membrane of the chloroplast or the membrane of certain prokaryotes during the light reactions of photosynthesis."
  },
  {
    "front": "Photoreceptors",
    "back": "An electromagnetic receptor that detects the radiation known as visible light."
  },
  {
    "front": "Photorespiration",
    "back": "A metabolic pathway that consumes oxygen and ATP, releases carbon dioxide, and decreases photosynthetic output. Photorespiration generally occurs on hot, dry, bright days, when the stomata close and the oxygen to carbon dioxide ratio in the leaf increases, favoring the binding of oxygen rather than carbon dioxide by rubisco."
  },
  {
    "front": "Photosynthesis",
    "back": "(f\u014d\u2032-t\u014d-sin\u2032-thi-sis) The conversion of light energy to chemical energy that is stored in sugars or other organic compounds; occurs in plants, algae, and certain prokaryotes."
  },
  {
    "front": "Photosystem",
    "back": "A light-capturing unit located in the thylakoid membrane of the chloroplast or in the membrane of some prokaryotes, consisting of a reaction-center complex surrounded by numerous light-harvesting complexes. There are two types of photosystems, I and II; they absorb light best at different wavelengths."
  },
  {
    "front": "Photosystem I",
    "back": "(PS I) A light-capturing unit in a chloroplast\u2019s thylakoid membrane or in the membrane of some prokaryotes; it has two molecules of P700 chlorophyllaat its reaction center."
  },
  {
    "front": "Photosystem II",
    "back": "(PS II) One of two light-capturing units in a chloroplast\u2019s thylakoid membrane or in the membrane of some prokaryotes; it has two molecules of P680 chlorophyllaat its reaction center."
  },
  {
    "front": "Phototropism",
    "back": "(f\u014d\u2032-t\u014d-tr\u014d\u2032-pizm) The bending of a plant or other organism in response to light, either toward the source of light (positive phototropism) or away from it (negative phototropism)."
  },
  {
    "front": "Phyla",
    "back": "(f\u012b\u2032-lum) (plural,phyla) In Linnaean classification, the taxonomic category above class."
  },
  {
    "front": "Phylogenetic tree",
    "back": "A branching diagram that represents a hypothesis about the evolutionary history of a group of organisms."
  },
  {
    "front": "Phylogeny",
    "back": "(f\u012b-loj\u2032-uh-n\u0113) The evolutionary history of a species or group of related species."
  },
  {
    "front": "Physiology",
    "back": "The processes and functions of an organism."
  },
  {
    "front": "Phytochromes",
    "back": "(f\u012b\u2032-tuh-kr\u014dm) Plant pigments that absorb mostly red and far-red light and regulate many plant responses, such as seed germination and shade avoidance."
  },
  {
    "front": "Phytoremediation",
    "back": "An emerging technology that seeks to reclaim contaminated areas by taking advantage of some plant species\u2019 ability to extract heavy metals and other pollutants from the soil and to concentrate them in easily harvested portions of the plant."
  },
  {
    "front": "Pili",
    "back": "(plural,pili) (p\u012b\u2032-lus, p\u012b\u2032-l\u012b) In bacteria, a structure that links one cell to another at the start of conjugation; also called a sex pilus or conjugation pilus."
  },
  {
    "front": "Pineal gland",
    "back": "(p\u012b\u2032-n\u0113-ul) A small gland on the dorsal surface of the vertebrate forebrain that secretes the hormone melatonin."
  },
  {
    "front": "Pinocytosis",
    "back": "(p\u012b\u2032-n\u014d-s\u012b-t\u014d\u2032-sis) A type of endocytosis in which the cell ingests extracellular fluid and its dissolved solutes."
  },
  {
    "front": "Pistil",
    "back": "A single carpel (a simple pistil) or a group of fused carpels (a compound pistil)."
  },
  {
    "front": "Pith",
    "back": "Ground tissue that is internal to the vascular tissue in a stem; in many monocot roots, parenchyma cells that form the central core of the vascular cylinder."
  },
  {
    "front": "Pituitary gland",
    "back": "(puh-t\u016b\u2032-uh-t\u0101r\u2032-\u0113) An endocrine gland at the base of the hypothalamus; consists of a posterior lobe, which stores and releases two hormones produced by the hypothalamus, and an anterior lobe, which produces and secretes many hormones that regulate diverse body functions."
  },
  {
    "front": "Placenta",
    "back": "(pluh-sen\u2032-tuh) A structure in the uterus of a pregnant eutherian mammal that nourishes the fetus with the maternal blood supply; formed from the uterine lining and embryonic membranes."
  },
  {
    "front": "Placoderms",
    "back": "A member of an extinct group of fishlike vertebrates that had jaws and were enclosed in a tough outer armor."
  },
  {
    "front": "Planarians",
    "back": "A free-living flatworm found in ponds and streams."
  },
  {
    "front": "Plasma",
    "back": "(plaz\u2032-muh) The liquid matrix of blood in which the blood cells are suspended."
  },
  {
    "front": "Plasma membrane",
    "back": "(plaz'-muh) The membrane at the boundary of every cell that acts as a selective barrier, regulating the cell\u2019s chemical composition."
  },
  {
    "front": "Plasmids",
    "back": "(plaz\u2032-mid) A small, circular, double-stranded DNA molecule that carries accessory genes separate from those of a bacterial chromosome; in DNA cloning, plasmids are used as vectors carrying up to about 10,000 base pairs (10 kb) of DNA. Plasmids are also found in some eukaryotes, such as yeasts."
  },
  {
    "front": "Plasmodesmata",
    "back": "(plaz\u2032-m\u014d-dez\u2032-muh) (plural,plasmodesmata) An open channel through the cell wall that connects the cytoplasm of adjacent plant cells, allowing water, small solutes, and some larger molecules to pass between the cells."
  },
  {
    "front": "Plasmogamy",
    "back": "(plaz-moh\u2032-guh-m\u0113) In fungi, the fusion of the cytoplasm of cells from two individuals; occurs as one stage of sexual reproduction, followed later by karyogamy."
  },
  {
    "front": "Plasmolysis",
    "back": "(plaz-mol\u2032-uh-sis) A phenomenon in walled cells in which the cytoplasm shrivels and the plasma membrane pulls away from the cell wall; occurs when the cell loses water to a hypertonic environment."
  },
  {
    "front": "Plastids",
    "back": "One of a family of closely related organelles that includes chloroplasts, chromoplasts, and amyloplasts. Plastids are found in cells of photosynthetic eukaryotes."
  },
  {
    "front": "Plate tectonics",
    "back": "The theory that the continents are part of great plates of Earth\u2019s crust that float on the hot, underlying portion of the mantle. Movements in the mantle cause the continents to move slowly over time."
  },
  {
    "front": "Platelets",
    "back": "A pinched-off cytoplasmic fragment of a specialized bone marrow cell. Platelets circulate in the blood and are important in blood clotting."
  },
  {
    "front": "Pleiotropy",
    "back": "(pl\u012b\u2032-o-truh-p\u0113) The ability of a single gene to have multiple effects."
  },
  {
    "front": "Pluripotent",
    "back": "Describing a cell that can give rise to many, but not all, parts of an organism."
  },
  {
    "front": "Point mutations",
    "back": "A change in a single nucleotide pair of a gene."
  },
  {
    "front": "Polar covalent bond",
    "back": "A covalent bond between atoms that differ in electronegativity. The shared electrons are pulled closer to the more electronegative atom, making it slightly negative and the other atom slightly positive.",
    "chapter": 2
  },
  {
    "front": "Polar molecule",
    "back": "A molecule (such as water) with an uneven distribution of charges in different regions of the molecule."
  },
  {
    "front": "Pollen grain",
    "back": "In seed plants, a structure consisting of the male gametophyte enclosed within a pollen wall."
  },
  {
    "front": "Pollen tube",
    "back": "A tube that forms after germination of the pollen grain and that functions in the delivery of sperm to the ovule."
  },
  {
    "front": "Pollination",
    "back": "(pol\u2032-uh-n\u0101\u2032-shun) The transfer of pollen to the part of a seed plant containing the ovules, a process required for fertilization."
  },
  {
    "front": "Poly-A tail",
    "back": "A sequence of 50 to 250 adenine nucleotides added onto the 3' end of a pre-mRNA molecule."
  },
  {
    "front": "Polygamous",
    "back": "Referring to a type of relationship in which an individual of one sex mates with several of the other."
  },
  {
    "front": "Polygenic inheritance",
    "back": "(pol\u2032-\u0113-jen\u2032-ik) An additive effect of two or more genes on a single phenotypic character."
  },
  {
    "front": "Polymer",
    "back": "(pol\u2032-uh-mer) A long molecule consisting of many similar or identical monomers linked together by covalent bonds."
  },
  {
    "front": "Polymerase chain reaction",
    "back": "(PCR) (puh-lim\u2032-uh-r\u0101s) A technique for amplifying DNAin vitroby incubating it with specific primers, a heat-resistant DNA polymerase, and nucleotides."
  },
  {
    "front": "Polynucleotides",
    "back": "(pol\u2032-\u0113-n\u016b\u2032-kl\u0113-\u014d-t\u012bd) A polymer consisting of many nucleotide monomers in a chain. The nucleotides can be those of DNA or RNA."
  },
  {
    "front": "Polypeptide",
    "back": "(pol\u2032-\u0113-pep\u2032-t\u012bd) A polymer of many amino acids linked together by peptide bonds."
  },
  {
    "front": "Polyphyletic",
    "back": "(pol\u2032-\u0113-f\u012b-let\u2032-ik) Pertaining to a group of taxa that includes distantly related organisms but does not include their most recent common ancestor."
  },
  {
    "front": "Polyploidy",
    "back": "(pol\u2032-\u0113-ploy\u2032-d\u0113) A chromosomal alteration in which the organism possesses more than two complete chromosome sets. It is the result of an accident of cell division."
  },
  {
    "front": "Polyps",
    "back": "The sessile variant of the cnidarian body plan. The alternate form is the medusa."
  },
  {
    "front": "Polyribosomes",
    "back": "(polysomes) (pol\u2032-\u0113-r\u012b\u2032-buh-s\u014dm\u2032) A group of several ribosomes attached to, and translating, the same messenger RNA molecule."
  },
  {
    "front": "Polysaccharides",
    "back": "(pol\u2032-\u0113-sak\u2032-uh-r\u012bd) A polymer of many monosaccharides, formed by dehydration reactions."
  },
  {
    "front": "Polyspermy",
    "back": "The fertilization of an egg by more than one sperm."
  },
  {
    "front": "Pons",
    "back": "A portion of the brain that participates in certain automatic, homeostatic functions, such as regulating the breathing centers in the medulla."
  },
  {
    "front": "Population",
    "back": "A group of individuals of the same species that live in the same area and interbreed, producing fertile offspring."
  },
  {
    "front": "Population dynamics",
    "back": "The study of how complex interactions between biotic and abiotic factors influence variations in population size."
  },
  {
    "front": "Positional information",
    "back": "Molecular cues that control pattern formation in an animal or plant embryonic structure by indicating a cell\u2019s location relative to the organism\u2019s body axes. These cues elicit a response by genes that regulate development."
  },
  {
    "front": "Positive feedback",
    "back": "A form of regulation in which an end product of a process speeds up that process; in physiology, a control mechanism in which a change in a variable triggers a response that reinforces or amplifies the change."
  },
  {
    "front": "Positive interactions",
    "back": "A postive-positive or positive-neutral ecological interaction between individuals of two species in which at least one individual benefits and neither is harmed; positive interactions include mutualism and commensalism."
  },
  {
    "front": "Positive pressure breathing",
    "back": "A breathing system in which air is forced into the lungs."
  },
  {
    "front": "Posterior pituitary",
    "back": "An extension of the hypothalamus composed of nervous tissue that secretes oxytocin and antidiuretic hormone made in the hypothalamus; a temporary storage site for these hormones."
  },
  {
    "front": "Postzygotic barriers",
    "back": "(p\u014dst\u2032-z\u012b-got\u2032-ik) A reproductive barrier that prevents hybrid zygotes produced by two different species from developing into viable, fertile adults."
  },
  {
    "front": "Potential energy",
    "back": "The energy that matter possesses as a result of its location or spatial arrangement (structure).",
    "chapter": 2
  },
  {
    "front": "Predation",
    "back": "An interaction in which an individual of one species, the predator, kills and eats an individual of the other species, the prey."
  },
  {
    "front": "Prediction",
    "back": "In deductive reasoning, a forecast that follows logically from a hypothesis. By testing predictions, experiments may allow certain hypotheses to be rejected.",
    "chapter": 1
  },
  {
    "front": "Pregnancy",
    "back": "The condition of carrying one or more embryos in the uterus; also called gestation."
  },
  {
    "front": "Pressure potential",
    "back": "(represented by psi subscript P) A component of water potential that consists of the physical pressure on a solution, which can be positive, zero, or negative."
  },
  {
    "front": "Prezygotic barriers",
    "back": "(pr\u0113\u2032-z\u012b-got\u2032-ik) A reproductive barrier that impedes mating between species or hinders fertilization if interspecific mating is attempted."
  },
  {
    "front": "Primary cell wall",
    "back": "In plants, a relatively thin and flexible layer that surrounds the plasma membrane of a young cell."
  },
  {
    "front": "Primary consumers",
    "back": "An herbivore; an organism that eats plants or other autotrophs."
  },
  {
    "front": "Primary electron acceptor",
    "back": "In the thylakoid membrane of a chloroplast or in the membrane of some prokaryotes, a specialized molecule that shares the reaction-center complex with a pair of chlorophyllamolecules and that accepts an electron from them."
  },
  {
    "front": "Primary growth",
    "back": "Growth produced by apical meristems, lengthening stems and roots."
  },
  {
    "front": "Primary immune response",
    "back": "The initial adaptive immune response to an antigen, which appears after a lag of about 10 to17 days."
  },
  {
    "front": "Primary meristems",
    "back": "The three meristematic derivatives (protoderm, procambium, and ground meristem) of an apical meristem."
  },
  {
    "front": "Primary producers",
    "back": "An autotroph, usually a photosynthetic organism. Collectively, autotrophs make up the trophic level of an ecosystem that ultimately supports all other levels."
  },
  {
    "front": "Primary production",
    "back": "The amount of light energy converted to chemical energy (organic compounds) by the autotrophs in an ecosystem during a given time period."
  },
  {
    "front": "Primary structure",
    "back": "The level of protein structure referring to the specific linear sequence of amino acids."
  },
  {
    "front": "Primary succession",
    "back": "A type of ecological succession that occurs in an area where there were originally no organisms present and where soil has not yet formed."
  },
  {
    "front": "Primary transcript",
    "back": "An initial RNA transcript from any gene; also called pre-mRNA when transcribed from a protein-coding gene."
  },
  {
    "front": "Primase",
    "back": "An enzyme that joins RNA nucleotides to make a primer during DNA replication, using the parental DNA strand as a template."
  },
  {
    "front": "Primer",
    "back": "A short polynucleotide with a free 3\u2032 end, bound by complementary base pairing to the template strand and elongated with DNA nucleotides during DNA replication."
  },
  {
    "front": "Prions",
    "back": "An infectious agent that is a misfolded version of a normal cellular protein. Prions appear to increase in number by converting correctly folded versions of the protein to more prions."
  },
  {
    "front": "Problem solving",
    "back": "The cognitive activity of devising a method to proceed from one state to another in the face of real or apparent obstacles."
  },
  {
    "front": "Producers",
    "back": "An organism that produces organic compounds from carbon dioxide by harnessing light energy (in photosynthesis) or by oxidizing inorganic chemicals (in chemosynthetic reactions carried out by some prokaryotes).",
    "chapter": 1
  },
  {
    "front": "Production efficiency",
    "back": "The percentage of energy stored in assimilated food that is not used for respiration or eliminated as waste."
  },
  {
    "front": "Products",
    "back": "A material resulting from a chemical reaction.",
    "chapter": 2
  },
  {
    "front": "Progesterone",
    "back": "A steroid hormone that contributes to the menstrual cycle and prepares the uterus for pregnancy; the major progestin in mammals."
  },
  {
    "front": "Prokaryotes",
    "back": "A single-celled organism of the domain Bacteria or Archaea."
  },
  {
    "front": "Prokaryotic cell",
    "back": "(pr\u014d\u2032-k\u0101r\u2032-\u0113-ot\u2032-ik) A type of cell lacking a membrane-enclosed nucleus and membrane-enclosed organelles. Organisms with prokaryotic cells (bacteria and archaea) are called prokaryotes.",
    "chapter": 1
  },
  {
    "front": "Prolactin",
    "back": "A hormone produced and secreted by the anterior pituitary with a great diversity of effects in different vertebrate species. In mammals, it stimulates growth of and milk production by the mammary glands."
  },
  {
    "front": "Prometaphase",
    "back": "The second stage of mitosis, in which the nuclear envelope fragments and the spindle microtubules attach to the kinetochores of the chromosomes."
  },
  {
    "front": "Promoter",
    "back": "A specific nucleotide sequence in the DNA of a gene that binds RNA polymerase, positioning it to start transcribing RNA at the appropriate place."
  },
  {
    "front": "Prophage",
    "back": "(pr\u014d\u2032-f\u0101j) A phage genome that has been inserted into a specific site on a bacterial chromosome."
  },
  {
    "front": "Prophase",
    "back": "The first stage of mitosis, in which the chromatin condenses into discrete chromosomes visible with a light microscope, the mitotic spindle begins to form, and the nucleolus disappears but the nucleus remains intact."
  },
  {
    "front": "Prostaglandins",
    "back": "(pros\u2032-tuh-glan\u2032-din) One of a group of modified fatty acids that are secreted by virtually all tissues and that perform a wide variety of functions as local regulators."
  },
  {
    "front": "Prostate gland",
    "back": "(pros\u2032-t\u0101t) A gland in human males that secretes an acid-neutralizing component of semen."
  },
  {
    "front": "Protease",
    "back": "(pr\u014d\u2032-t\u0113-\u0101z) An enzyme that digests proteins by hydrolysis."
  },
  {
    "front": "Protein",
    "back": "(pr\u014d\u2032-t\u0113n) A biologically functional molecule consisting of one or more polypeptides folded and coiled into a specific three-dimensional structure."
  },
  {
    "front": "Protein kinase",
    "back": "(pr\u014d\u2032-t\u0113n k\u012b\u2032-n\u0101s) An enzyme that transfers phosphate groups from ATP to a protein, thus phosphorylating the protein."
  },
  {
    "front": "Protein phosphatases",
    "back": "(pr\u014d\u2032-t\u0113n fos\u2032-fuh-t\u0101s) An enzyme that removes phosphate groups from (dephosphorylates) proteins, often functioning to reverse the effect of a protein kinase."
  },
  {
    "front": "Proteoglycans",
    "back": "(pr\u014d'-t\u0113-\u014d-gl\u012b'-kan) A large molecule consisting of a small core protein with many carbohydrate chains attached, found in the extracellular matrix of animal cells. A proteoglycan may consist of up to 95 percent carbohydrate."
  },
  {
    "front": "Proteome",
    "back": "The entire set of proteins expressed by a given cell, tissue, or organism.",
    "chapter": 1
  },
  {
    "front": "Proteomics",
    "back": "(pr\u014d\u2032-t\u0113-\u014d\u2032-miks) The systematic study of sets of proteins and their properties, including their abundance, chemical modifications, and interactions.",
    "chapter": 1
  },
  {
    "front": "Protists",
    "back": "An informal term applied to any eukaryote that is not a plant, animal, or fungus. Most protists are unicellular, though some are colonial or multicellular."
  },
  {
    "front": "Proto-oncogenes",
    "back": "(pr\u014d\u2032-t\u014d-on\u2032-k\u014d-j\u0113n) A normal cellular gene that has the potential to become an oncogene."
  },
  {
    "front": "Protocells",
    "back": "An abiotic precursor of a living cell that had a membrane-like structure and that maintained an internal chemistry different from that of its surroundings."
  },
  {
    "front": "Proton pump",
    "back": "(pr\u014d\u2032-ton) An active transport protein in a cell membrane that uses ATP to transport hydrogen ions out of a cell against their concentration gradient, generating a membrane potential in the process."
  },
  {
    "front": "Proton-motive force",
    "back": "(pr\u014d\u2032-ton) The potential energy stored in the form of a proton electrochemical gradient, generated by the pumping of hydrogen ions across a biological membrane during chemiosmosis."
  },
  {
    "front": "Protonema",
    "back": "(pr\u014d\u2032-tuh-n\u0113\u2032-muh) (plural,protonemata) A mass of green, branched, one-cell-thick filaments produced by germinating moss spores."
  },
  {
    "front": "Protonephridia",
    "back": "(pr\u014d\u2032-t\u014d-nuh-frid\u2032-\u0113-um) (plural,protonephridia) An excretory system, such as the flame bulb system of flatworms, consisting of a network of tubules lacking internal openings."
  },
  {
    "front": "Protons",
    "back": "(pr\u014d'-ton) A subatomic particle with a single positive electrical charge, with a mass of about 1.7 times 10 to the negative 24 g, found in the nucleus of an atom.",
    "chapter": 2
  },
  {
    "front": "Protoplast",
    "back": "The living part of a plant cell, which also includes the plasma membrane."
  },
  {
    "front": "Protostome development",
    "back": "In animals, a developmental mode distinguished by the development of the mouth from the blastopore; often also characterized by spiral cleavage and by the body cavity forming when solid masses of mesoderm split."
  },
  {
    "front": "Provirus",
    "back": "A viral genome that is permanently inserted into a host genome."
  },
  {
    "front": "Proximal tubule",
    "back": "In the vertebrate kidney, the portion of a nephron immediately downstream from Bowman\u2019s capsule that conveys and helps refine filtrate."
  },
  {
    "front": "Pseudogenes",
    "back": "(s\u016b\u2032-d\u014d-j\u0113n) A DNA segment that is very similar to a real gene but does not yield a functional product; a DNA segment that formerly functioned as a gene but has become inactivated in a particular species because of mutation."
  },
  {
    "front": "Pseudopodia",
    "back": "(s\u016b'-d\u014d-p\u014d'-d\u0113-um) (plural,pseudopodia) A cellular extension of amoeboid cells used in moving and feeding."
  },
  {
    "front": "Pterosaurs",
    "back": "Winged reptile that lived during the Mesozoic era."
  },
  {
    "front": "Pulse",
    "back": "The rhythmic bulging of the artery walls with each heartbeat."
  },
  {
    "front": "Punctuated equilibria",
    "back": "In the fossil record, long periods of apparent stasis, in which a species undergoes little or no morphological change, interrupted by relatively brief periods of sudden change."
  },
  {
    "front": "Punnett square",
    "back": "A diagram used in the study of inheritance to show the predicted genotypic results of random fertilization in genetic crosses between individuals of known genotype."
  },
  {
    "front": "Pupil",
    "back": "The opening in the iris, which admits light into the interior of the vertebrate eye. Muscles in the iris regulate its size."
  },
  {
    "front": "Purines",
    "back": "(py\u016b\u2032-r\u0113n) One of two types of nitrogenous bases found in nucleotides, characterized by a six-membered ring fused to a five-membered ring. Adenine (A) and guanine (G) are purines."
  },
  {
    "front": "Pyrimidine",
    "back": "(puh-rim\u2032-uh-d\u0113n) One of two types of nitrogenous bases found in nucleotides, characterized by a six-membered ring. Cytosine (C), thymine (T), and uracil (U) are pyrimidines."
  },
  {
    "front": "Quantitative characters",
    "back": "A heritable feature that varies continuously over a range rather than in an either-or fashion."
  },
  {
    "front": "R -selection",
    "back": "Selection for life history traits that maximize reproductive success in uncrowded environments."
  },
  {
    "front": "R plasmids",
    "back": "A bacterial plasmid carrying genes that confer resistance to certain antibiotics."
  },
  {
    "front": "Radial cleavage",
    "back": "A type of embryonic development in deuterostomes in which the planes of cell division that transform the zygote into a ball of cells are either parallel or perpendicular to the vertical axis of the embryo, thereby aligning tiers of cells one above the other."
  },
  {
    "front": "Radial symmetry",
    "back": "Symmetry in which the body is shaped like a pie or barrel (lacking a left side and a right side) and can be divided into mirror-imaged halves by any plane through its central axis."
  },
  {
    "front": "Radicle",
    "back": "An embryonic root of a plant."
  },
  {
    "front": "Radioactive isotope",
    "back": "An isotope (an atomic form of a chemical element) that is unstable; the nucleus decays spontaneously, giving off detectable particles and energy.",
    "chapter": 2
  },
  {
    "front": "Radiolarians",
    "back": "A protist, usually marine, with a shell generally made of silica and pseudopodia that radiate from the central body."
  },
  {
    "front": "Radiometric dating",
    "back": "A method for determining the absolute age of rocks and fossils, based on the half-life of radioactive isotopes.",
    "chapter": 2
  },
  {
    "front": "Radula",
    "back": "A straplike scraping organ used by many molluscs during feeding."
  },
  {
    "front": "Ras gene",
    "back": "A gene that codes for Ras, a G protein that relays a growth signal from a growth factor receptor on the plasma membrane to a cascade of protein kinases, ultimately resulting in stimulation of the cell cycle."
  },
  {
    "front": "Ratites",
    "back": "(rat\u2032-\u012bt) A member of the group of flightless birds."
  },
  {
    "front": "Ray-finned fishes",
    "back": "A member of the clade Actinopterygii, aquatic osteichthyans with fins supported by long, flexible rays, including tuna, bass, and herring."
  },
  {
    "front": "Reabsorption",
    "back": "In excretory systems, the recovery of solutes and water from filtrate."
  },
  {
    "front": "Reactants",
    "back": "A starting material in a chemical reaction.",
    "chapter": 2
  },
  {
    "front": "Reaction-center complex",
    "back": "A complex of proteins associated with a special pair of chlorophyllamolecules and a primary electron acceptor. Located centrally in a photosystem, this complex triggers the light reactions of photosynthesis. Excited by light energy, the pair of chlorophylls donates an electron to the primary electron acceptor, which passes an electron to an electron transport chain."
  },
  {
    "front": "Reading frame",
    "back": "On an mRNA, the triplet grouping of ribonucleotides used by the translation machinery during polypeptide synthesis."
  },
  {
    "front": "Receptacle",
    "back": "The base of a flower; the part of the stem that is the site of attachment of the floral organs."
  },
  {
    "front": "Receptor potential",
    "back": "A graded potential occurring in a receptor cell."
  },
  {
    "front": "Receptor tyrosine kinases",
    "back": "(RTKs) A receptor protein spanning the plasma membrane, the cytoplasmic (intracellular) part of which can catalyze the transfer of a phosphate group from ATP to a tyrosine on another protein. Receptor tyrosine kinases often respond to the binding of a signaling molecule by dimerizing and then phosphorylating a tyrosine on the cytoplasmic portion of the other receptor in the dimer."
  },
  {
    "front": "Receptor-mediated endocytosis",
    "back": "(en\u2032-d\u014d-s\u012b-t\u014d\u2032-sis) The movement of specific molecules into a cell by the infolding of vesicles containing proteins with receptor sites specific to the molecules being taken in; enables a cell to acquire bulk quantities of specific substances."
  },
  {
    "front": "Recessive allele",
    "back": "An allele whose phenotypic effect is not observed in a heterozygote."
  },
  {
    "front": "Reciprocal altruism",
    "back": "Altruistic behavior between unrelated individuals, whereby the altruistic individual benefits in the future when the beneficiary reciprocates."
  },
  {
    "front": "Recombinant chromosomes",
    "back": "A chromosome created when crossing over combines DNA from two parents into a single chromosome."
  },
  {
    "front": "Recombinant DNA molecule",
    "back": "A DNA molecule madein vitrowith segments from different sources."
  },
  {
    "front": "Recombinant types",
    "back": "An offspring whose phenotype differs from that of the true-breeding P generation parents; also refers to the phenotype itself."
  },
  {
    "front": "Rectum",
    "back": "The terminal portion of the large intestine, where the feces are stored prior to elimination."
  },
  {
    "front": "Red algae",
    "back": "A photosynthetic protist, named for its color, which results from a red pigment that masks the green of chlorophyll. Most red algae are multicellular and marine."
  },
  {
    "front": "Redox reactions",
    "back": "(r\u0113\u2032-doks) A chemical reaction involving the complete or partial transfer of one or more electrons from one reactant to another; short forreduction-oxidation reaction."
  },
  {
    "front": "Reducing agent",
    "back": "The electron donor in a redox reaction."
  },
  {
    "front": "Reduction",
    "back": "The complete or partial addition of electrons to a substance involved in a redox reaction."
  },
  {
    "front": "Reference genome",
    "back": "A complete sequence that researchers agree best represents the genome of a given species, arrived at by sequencing multiple individuals."
  },
  {
    "front": "Reflexes",
    "back": "An automatic reaction to a stimulus, mediated by the spinal cord or lower brain."
  },
  {
    "front": "Refractory period",
    "back": "(r\u0113-frakt\u2032-\u014dr-\u0113) A period, immediately following a response to stimulation, during which a cell or organ is unresponsive to further stimulation."
  },
  {
    "front": "Regulator",
    "back": "An animal for which mechanisms of homeostasis moderate internal changes in a particular variable in the face of external fluctuation of that variable."
  },
  {
    "front": "Regulatory gene",
    "back": "A gene that codes for a protein, such as a repressor, that controls the transcription of another gene or group of genes."
  },
  {
    "front": "Reinforcement",
    "back": "In evolutionary biology, a process in which natural selection strengthens prezygotic barriers to reproduction, thus reducing the chances of hybrid formation. Such a process is likely to occur only if hybrid offspring are less fit than members of the parent species."
  },
  {
    "front": "Relative abundance",
    "back": "The proportional abundance of different species in a community."
  },
  {
    "front": "Relative fitness",
    "back": "The contribution an individual makes to the gene pool of the next generation, relative to the contributions of other individuals in the population."
  },
  {
    "front": "Renal medulla",
    "back": "The inner portion of the vertebrate kidney, beneath the renal cortex."
  },
  {
    "front": "Renal pelvis",
    "back": "The funnel-shaped chamber that receives processed filtrate from the vertebrate kidney\u2019s collecting ducts and is drained by the ureter."
  },
  {
    "front": "Renin-angiotensin-aldosterone system",
    "back": "(RAAS)A hormone cascade pathway that helps regulate blood pressure and blood volume."
  },
  {
    "front": "Repetitive DNA",
    "back": "Nucleotide sequences, usually noncoding, that are present in many copies in a eukaryotic genome. The repeated units may be short and arranged tandemly (in series) or long and dispersed in the genome."
  },
  {
    "front": "Replication fork",
    "back": "A Y-shaped region on a replicating DNA molecule where the parental strands are being unwound and new strands are being synthesized."
  },
  {
    "front": "Repressor",
    "back": "A protein that inhibits gene transcription. In prokaryotes, repressors bind to the DNA in or near the promoter. In eukaryotes, repressors may bind to control elements within enhancers, to activators, or to other proteins in a way that blocks activators from binding to DNA."
  },
  {
    "front": "Reproductive isolation",
    "back": "The existence of biological factors (barriers) that impede members of two species from producing viable, fertile offspring."
  },
  {
    "front": "Reptile",
    "back": "A member of the clade of amniotes that includes tuataras, lizards and snakes, turtles, crocodilians, and birds."
  },
  {
    "front": "Reservoirs",
    "back": "In biogeochemical cycles, location of a chemical element, consisting of either organic or inorganic materials that are either available for direct use by organisms or unavailable as nutrients."
  },
  {
    "front": "Residual volume",
    "back": "The amount of air that remains in the lungs after forceful exhalation."
  },
  {
    "front": "Resource partitioning",
    "back": "The division of environmental resources by coexisting species such that the niche of each species differs by one or more significant factors from the niches of all coexisting species."
  },
  {
    "front": "Respiratory pigments",
    "back": "A protein that transports oxygen in blood or hemolymph."
  },
  {
    "front": "Response",
    "back": "(1) In cellular communication, the change in a specific cellular activity brought about by a transduced signal from outside the cell. (2) In feedback regulation, a physiological activity triggered by a change in a variable."
  },
  {
    "front": "Resting potential",
    "back": "The membrane potential characteristic of a nonconducting excitable cell, with the inside of the cell more negative than the outside."
  },
  {
    "front": "Restriction enzymes",
    "back": "An endonuclease (type of enzyme) that recognizes and cuts DNA molecules foreign to a bacterium (such as phage genomes). The enzyme cuts at specific nucleotide sequences (restriction sites)."
  },
  {
    "front": "Restriction fragments",
    "back": "A DNA segment that results from the cutting of DNA by a restriction enzyme."
  },
  {
    "front": "Restriction site",
    "back": "A specific sequence on a DNA strand that is recognized and cut by a restriction enzyme."
  },
  {
    "front": "Retina",
    "back": "(ret'-i-nuh) The innermost layer of the vertebrate eye, containing photoreceptor cells (rods and cones) and neurons; transmits images formed by the lens to the brain via the optic nerve."
  },
  {
    "front": "Retinal",
    "back": "The light-absorbing pigment in rods and cones of the vertebrate eye."
  },
  {
    "front": "Retrotransposons",
    "back": "(re\u2032-tr\u014d-trans-p\u014d\u2032-zon) A transposable element that moves within a genome by means of an RNA intermediate, a transcript of the retrotransposon DNA."
  },
  {
    "front": "Retroviruses",
    "back": "(re\u2032-tr\u014d-v\u012b\u2032-rus) An RNA virus that replicates by transcribing its RNA into DNA and then inserting the DNA into a cellular chromosome; an important class of cancer-causing viruses."
  },
  {
    "front": "Reverse transcriptase",
    "back": "(tran-skrip\u2032-t\u0101s) An enzyme encoded by certain viruses (retroviruses) that uses RNA as a template for DNA synthesis."
  },
  {
    "front": "Reverse transcriptase polymerase chain reaction",
    "back": "(RT-PCR) A technique for determining expression of a particular gene. It uses reverse transcriptase and DNA polymerase to synthesize cDNA from all the mRNA in a sample and then subjects the cDNA to PCR amplification using primers specific for the gene of interest."
  },
  {
    "front": "Rhizarians",
    "back": "(r\u012b-za\u2032-r\u0113-uhns) One of the three major subgroups for which the SAR eukaryotic supergroup is named. Many species in this clade are amoebas characterized by threadlike pseudopodia."
  },
  {
    "front": "Rhizobacteria",
    "back": "A soil bacterium whose population size is much enhanced in the rhizosphere, the soil region close to a plant\u2019s roots."
  },
  {
    "front": "Rhizoids",
    "back": "(r\u012b\u2032-zoyd) A long, tubular single cell or filament of cells that anchors bryophytes to the ground. Unlike roots, rhizoids are not composed of tissues, lack specialized conducting cells, and do not play a primary role in water and mineral absorption."
  },
  {
    "front": "Rhizosphere",
    "back": "The soil region close to plant roots and characterized by a high level of microbiological activity."
  },
  {
    "front": "Rhodopsin",
    "back": "(r\u014d-dop\u2219-sin) A visual pigment consisting of retinal and opsin. Upon absorbing light, the retinal changes shape and dissociates from the opsin."
  },
  {
    "front": "Ribonucleic acid",
    "back": "(RNA) (r\u012b\u2032-b\u014d-n\u016b-kl\u0101\u2032-ik) A type of nucleic acid consisting of a polynucleotide made up of nucleotide monomers with a ribose sugar and the nitrogenous bases adenine (A), cytosine (C), guanine (G), and uracil (U); usually single-stranded; functions in protein synthesis, in gene regulation, and as the genome of some viruses."
  },
  {
    "front": "Ribose",
    "back": "The sugar component of RNA nucleotides."
  },
  {
    "front": "Ribosomal RNAs",
    "back": "(rRNA) (r\u012b\u2032-buh-s\u014d\u2032-mul) RNA molecules that, together with proteins, make up ribosomes; the most abundant type of RNA."
  },
  {
    "front": "Ribosomes",
    "back": "(r\u012b\u2032-buh-s\u014dm) A complex of rRNA and protein molecules that functions as a site of protein synthesis in the cytoplasm; consists of a large and a small subunit. In eukaryotic cells, each subunit is assembled in the nucleolus; A specialized structure in the nucleus, consisting of chromosomal regions containing ribosomal RNA (rRNA) genes along with ribosomal proteins imported from the cytoplasm; site of rRNA synthesis and ribosomal subunit assembly."
  },
  {
    "front": "Ribozymes",
    "back": "(r\u012b\u2032-buh-z\u012bm) An RNA molecule that functions as an enzyme, such as an intron that catalyzes its own removal during RNA splicing."
  },
  {
    "front": "RNA interference",
    "back": "(RNAi) A mechanism for silencing the expression of specific genes. In RNAi, double-stranded RNA molecules that match the sequence of a particular gene are processed into siRNAs that either block translation or trigger the degradation of the gene\u2019s messenger RNA. This happens naturally in some cells and can be carried out in laboratory experiments as well."
  },
  {
    "front": "RNA polymerase",
    "back": "An enzyme that links ribonucleotides into a growing RNA chain during transcription, based on complementary binding to nucleotides on a DNA template strand."
  },
  {
    "front": "RNA processing",
    "back": "Modification of RNA primary transcripts, including splicing out of introns, joining together of exons, and alteration of the 5' and 3' ends."
  },
  {
    "front": "RNA sequencing",
    "back": "(RNA-seq) (RNA-s\u0113k) A method of analyzing large sets of RNAs that involves making cDNAs and sequencing them."
  },
  {
    "front": "RNA splicing",
    "back": "After synthesis of a eukaryotic primary RNA transcript, the removal of portions of the transcript (introns) that will not be included in the mRNA and the joining together of the remaining portions (exons)."
  },
  {
    "front": "Rods",
    "back": "A rodlike cell in the retina of the vertebrate eye, sensitive to low light intensity."
  },
  {
    "front": "Root",
    "back": "An organ in vascular plants that anchors the plant and enables it to absorb water and minerals from the soil."
  },
  {
    "front": "Root cap",
    "back": "A cone of cells at the tip of a plant root that protects the apical meristem."
  },
  {
    "front": "Root hairs",
    "back": "A tiny extension of a root epidermal cell, growing just behind the root tip and increasing surface area for absorption of water and minerals."
  },
  {
    "front": "Root pressure",
    "back": "Pressure exerted in the roots of plants as the result of osmosis, causing exudation from cut stems and guttation of water from leaves."
  },
  {
    "front": "Root system",
    "back": "All of a plant\u2019s roots, which anchor it in the soil, absorb and transport minerals and water, and store food."
  },
  {
    "front": "Rooted",
    "back": "Describing a phylogenetic tree that contains a branch point (often, the one farthest to the left) representing the most recent common ancestor of all taxa in the tree."
  },
  {
    "front": "Roots",
    "back": "An organ in vascular plants that anchors the plant and enables it to absorb water and minerals from the soil."
  },
  {
    "front": "Rough ER",
    "back": "That portion of the endoplasmic reticulum with ribosomes attached."
  },
  {
    "front": "Round window",
    "back": "In the mammalian ear, the point of contact where vibrations of the stapes create a traveling series of pressure waves in the fluid of the cochlea."
  },
  {
    "front": "Rubisco",
    "back": "(r\u016b-bis\u2032-k\u014d) Ribulose bisphosphate (RuBP) carboxylase-oxygenase, the enzyme that normally catalyzes the first step of the Calvin cycle (the addition of carbon dioxide to RuBP). When excess oxygen is present or carbon dioxide levels are low, rubisco can bind oxygen, resulting in photorespiration."
  },
  {
    "front": "Ruminants",
    "back": "(r\u016bh\u2019-muh-nent) A cud-chewing animal, such as a cow or sheep, with multiple stomach compartments specialized for an herbivorous diet."
  },
  {
    "front": "S phase",
    "back": "The synthesis phase of the cell cycle; the portion of interphase during which DNA is replicated."
  },
  {
    "front": "Salicylic acid",
    "back": "(sal\u2032-i-sil\u2032-ik) A signaling molecule in plants that may be partially responsible for activating systemic acquired resistance to pathogens."
  },
  {
    "front": "Salivary glands",
    "back": "A gland associated with the oral cavity that secretes substances that lubricate food and begin the process of chemical digestion."
  },
  {
    "front": "Saltatory conduction",
    "back": "(sol\u2032-tuh-t\u014dr\u2032-\u0113) Rapid transmission of a nerve impulse along an axon, resulting from the action potential jumping from one node of Ranvier to another, skipping the myelin-sheathed regions of membrane.\ufeff"
  },
  {
    "front": "Salts",
    "back": "A compound resulting from the formation of an ionic bond; also called an ionic compound.",
    "chapter": 2
  },
  {
    "front": "SAR",
    "back": "One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This supergroup contains a large, extremely diverse collection of protists from three major subgroups: stramenopiles, alveolates, and rhizarians; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. Excavates have unique cytoskeletal features, and some species have an \u201cexcavated\u201d feeding groove on one side of the cell body; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This monophyletic group, which includes red algae, green algae, and plants, descended from an ancient protistan ancestor that engulfed a cyanobacterium and One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This clade, which is supported by studies of myosin proteins and DNA, consists of amoebozoans and opisthokonts."
  },
  {
    "front": "Sarcomeres",
    "back": "(sar\u2032-k\u014d-m\u0113r) The fundamental, repeating unit of striated muscle, delimited by the Z lines."
  },
  {
    "front": "Sarcoplasmic reticulum",
    "back": "(SR) (sar\u2032-k\u014d-plaz\u2032-mik ruh-tik\u2032-y\u016b-lum) A specialized endoplasmic reticulum that regulates the calcium concentration in the cytosol of muscle cells."
  },
  {
    "front": "SARS-CoV-2",
    "back": "(SevereAcuteRespiratorySyndrome-CoronaVirus-2) The virus that causes COVID-19."
  },
  {
    "front": "Saturated fatty acid",
    "back": "A fatty acid in which all carbons in the hydrocarbon tail are connected by single bonds, thus maximizing the number of hydrogen atoms that are attached to the carbon skeleton."
  },
  {
    "front": "Savannas",
    "back": "A tropical grassland biome with scattered individual trees and large herbivores and maintained by occasional fires and drought."
  },
  {
    "front": "Scaffolding proteins",
    "back": "A type of large relay protein to which several other relay proteins are simultaneously attached, increasing the efficiency of signal transduction."
  },
  {
    "front": "Scanning electron microscope",
    "back": "(SEM) A microscope that uses an electron beam to scan the surface of a sample, coated with metal atoms, to study details of its topography."
  },
  {
    "front": "Scatter plot",
    "back": "A graph in which each piece of data is represented by a point. A scatter plot is used when the data for all variables are numerical and continuous."
  },
  {
    "front": "Schizophrenia",
    "back": "(skit\u2032-suh-fr\u0113\u2032-n\u0113-uh) A severe mental disturbance characterized by psychotic episodes in which patients have a distorted perception of reality."
  },
  {
    "front": "Schwann cells",
    "back": "A type of glial cell that forms insulating myelin sheaths around the axons of neurons in the peripheral nervous system."
  },
  {
    "front": "Science",
    "back": "An approach to understanding the natural world.",
    "chapter": 1
  },
  {
    "front": "Scion",
    "back": "(s\u012b\u2032-un) The twig grafted onto the stock when making a graft."
  },
  {
    "front": "Sclerenchyma cells",
    "back": "(skluh-ren\u2032-kim-uh) A rigid, supportive plant cell type usually lacking a protoplast and possessing thick secondary walls strengthened by lignin at maturity."
  },
  {
    "front": "Scrotum",
    "back": "A pouch of skin outside the abdomen that houses the testes; functions in maintaining the testes at the lower temperature required for spermatogenesis."
  },
  {
    "front": "Second law of thermodynamics",
    "back": "The principle stating that every energy transfer or transformation increases the entropy of the universe. Usable forms of energy are at least partly converted to heat."
  },
  {
    "front": "Second messengers",
    "back": "A molecule that relays messages in a cell from a receptor to a target where an action within the cell takes place."
  },
  {
    "front": "Secondary cell wall",
    "back": "In plant cells, a strong and durable matrix that is often deposited in several laminated layers around the plasma membrane and provides protection and support."
  },
  {
    "front": "Secondary consumers",
    "back": "A carnivore that eats herbivores."
  },
  {
    "front": "Secondary endosymbiosis",
    "back": "A process in eukaryotic evolution in which a heterotrophic eukaryotic cell engulfed a photosynthetic eukaryotic cell, which survived in a symbiotic relationship inside the heterotrophic cell."
  },
  {
    "front": "Secondary growth",
    "back": "Growth produced by lateral meristems, thickening the roots and shoots of woody plants."
  },
  {
    "front": "Secondary immune response",
    "back": "The adaptive immune response elicited on second or subsequent exposures to a particular antigen. The secondary immune response is more rapid, of greater magnitude, and of longer duration than the primary immune response."
  },
  {
    "front": "Secondary oocyte",
    "back": "(\u014d\u2032-uh-s\u012bt) An oocyte that has completed meiosis I."
  },
  {
    "front": "Secondary production",
    "back": "The amount of chemical energy in consumers\u2019 food that is converted to their own new biomass during a given time period."
  },
  {
    "front": "Secondary structure",
    "back": "Regions of repetitive coiling or folding of the polypeptide backbone of a protein due to hydrogen bonding between constituents of the backbone (not the side chains)."
  },
  {
    "front": "Secondary succession",
    "back": "A type of succession that occurs where an existing community has been cleared by some disturbance that leaves the soil or substrate intact."
  },
  {
    "front": "Secretion",
    "back": "(1) The discharge of molecules synthesized by a cell. (2) The active transport of wastes and certain other solutes from the body fluid into the filtrate in an excretory system."
  },
  {
    "front": "Seed",
    "back": "An adaptation of some terrestrial plants consisting of an embryo packaged along with a store of food within a protective coat."
  },
  {
    "front": "Seed coat",
    "back": "A tough outer covering of a seed, formed from the outer coat of an ovule. In a flowering plant, the seed coat encloses and protects the embryo and endosperm."
  },
  {
    "front": "Seedless vascular plants",
    "back": "An informal name for a plant that has vascular tissue but lacks seeds. Seedless vascular plants form a paraphyletic group that includes the phyla Lycophyta (club mosses and their relatives) and Monilophyta (ferns and their relatives)."
  },
  {
    "front": "Selective permeability",
    "back": "A property of biological membranes that allows them to regulate the passage of substances across them."
  },
  {
    "front": "Self-incompatibility",
    "back": "The ability of a seed plant to reject its own pollen and sometimes the pollen of closely related individuals."
  },
  {
    "front": "Semelparity",
    "back": "(seh\u2032-mel-p\u0101r\u2032-i-t\u0113) Reproduction in which an organism produces all of its offspring in a single event; also called big-bang reproduction."
  },
  {
    "front": "Semen",
    "back": "(s\u0113\u2032-mun) The fluid that is ejaculated by the male during orgasm; contains sperm and secretions from several glands of the male reproductive tract."
  },
  {
    "front": "Semicircular canals",
    "back": "A three-part chamber of the inner ear that functions in maintaining equilibrium."
  },
  {
    "front": "Semiconservative model",
    "back": "Type of DNA replication in which the replicated double helix consists of one old strand, derived from the parental molecule, and one newly made strand."
  },
  {
    "front": "Semilunar valves",
    "back": "A valve located at each exit of the heart, where the aorta leaves the left ventricle and the pulmonary artery leaves the right ventricle."
  },
  {
    "front": "Seminal vesicles",
    "back": "(sem\u2032-i-nul ves\u2032-i-kul) A gland in males that secretes a fluid component of semen that lubricates and nourishes sperm."
  },
  {
    "front": "Seminiferous tubules",
    "back": "(sem\u2032-i-nif\u2032-er-us) A highly coiled tube in the testis in which sperm are produced."
  },
  {
    "front": "Senescence",
    "back": "(se-nes\u2032-ens) The programmed death of certain cells or organs or the entire organism."
  },
  {
    "front": "Sensitive period",
    "back": "A limited phase in an animal\u2019s development when learning of particular behaviors can take place; also called a critical period."
  },
  {
    "front": "Sensor",
    "back": "In homeostasis, a receptor that detects a stimulus."
  },
  {
    "front": "Sensory adaptation",
    "back": "The tendency of sensory neurons to become less sensitive when they are stimulated repeatedly."
  },
  {
    "front": "Sensory neurons",
    "back": "A nerve cell that receives information from the internal or external environment and transmits signals to the central nervous system."
  },
  {
    "front": "Sensory reception",
    "back": "The detection of a stimulus by sensory cells."
  },
  {
    "front": "Sensory receptor",
    "back": "A specialized structure or cell that responds to a stimulus from an animal\u2019s internal or external environment."
  },
  {
    "front": "Sensory transduction",
    "back": "The conversion of stimulus energy to a change in the membrane potential of a sensory receptor cell."
  },
  {
    "front": "Sepals",
    "back": "(s\u0113\u2032-pul) A modified leaf in angiosperms that helps enclose and protect a flower bud before it opens."
  },
  {
    "front": "Septa",
    "back": "(plural,septa) One of the cross-walls that divide a fungal hypha into cells. Septa generally have pores large enough to allow ribosomes, mitochondria, and even nuclei to flow from cell to cell."
  },
  {
    "front": "Serial endosymbiosis",
    "back": "A hypothesis for the origin of eukaryotes consisting of a sequence of endosymbiotic events in which mitochondria, chloroplasts, and perhaps other cellular structures were derived from small prokaryotes that had been engulfed by larger cells."
  },
  {
    "front": "Set point",
    "back": "In homeostasis in animals, a value maintained for a particular variable, such as body temperature or solute concentration."
  },
  {
    "front": "Seta",
    "back": "(s\u0113\u2032-tuh) (plural,setae) The elongated stalk of a bryophyte sporophyte."
  },
  {
    "front": "Sex chromosomes",
    "back": "A chromosome responsible for determining the sex of an individual."
  },
  {
    "front": "Sex-linked gene",
    "back": "A gene located on either sex chromosome. Most sex-linked genes are on the X chromosome and show distinctive patterns of inheritance; there are very few genes on the Y chromosome."
  },
  {
    "front": "Sexual dimorphism",
    "back": "(d\u012b-m\u014dr\u2032-fizm) Differences between the secondary sex characteristics of males and females of the same species."
  },
  {
    "front": "Sexual reproduction",
    "back": "Reproduction arising from fusion of two gametes."
  },
  {
    "front": "Sexual selection",
    "back": "A process in which individuals with certain inherited characteristics are more likely than other individuals of the same sex to obtain mates."
  },
  {
    "front": "Shannon diversity index",
    "back": "An index of community diversity symbolized byHand that quantifies the relative abundance of each species and provides an indication of species richness."
  },
  {
    "front": "Shared ancestral character",
    "back": "A character, shared by members of a particular clade, that originated in an ancestor that is not a member of that clade."
  },
  {
    "front": "Shared derived character",
    "back": "An evolutionary novelty that is unique to a particular clade."
  },
  {
    "front": "Shoot system",
    "back": "The aerial portion of a plant body, consisting of stems, leaves, and (in angiosperms) flowers."
  },
  {
    "front": "Short tandem repeats",
    "back": "(STRs) Simple sequence DNA containing multiple tandemly repeated units of two to five nucleotides. Variations in STRs act as genetic markers in STR analysis, used to prepare genetic profiles."
  },
  {
    "front": "Short-day plant",
    "back": "A plant that flowers (usually in late summer, fall, or winter) only when the light period is shorter than a critical length."
  },
  {
    "front": "Short-term memory",
    "back": "The ability to hold information, anticipations, or goals for a time and then release them if they become irrelevant."
  },
  {
    "front": "Sickle-cell disease",
    "back": "A recessively inherited human blood disease in which a single nucleotide change in the\u03b1-globin<math xmlns=\"http://www.w3.org/1998/Math/MathML\" altimg=\"../images/math/mathml-1b2cdeda-cafc-4cf5-802c-6a7c0eb9fd09.svg\" alttext=\"alpha text -globin end text\" data-uri=\"mathml-1b2cdeda-cafc-4cf5-802c-6a7c0eb9fd09.svg\" display=\"inline\"><mi>\u03b1</mi><mtext>-globin</mtext></math>gene causes hemoglobin to aggregate, changing red blood cell shape and causing multiple symptoms in affected individuals."
  },
  {
    "front": "Sieve plates",
    "back": "An end wall in a sieve-tube element, which facilitates the flow of phloem sap in angiosperm sieve tubes."
  },
  {
    "front": "Sieve-tube elements",
    "back": "A living cell that conducts sugars and other organic nutrients in the phloem of angiosperms; also called a sieve-tube member. Connected end to end, they form sieve tubes."
  },
  {
    "front": "Sign stimulus",
    "back": "An external sensory cue that triggers a fixed action pattern by an animal."
  },
  {
    "front": "Signal",
    "back": "Any kind of information sent from one organism to another, or from one place in an organism to another place."
  },
  {
    "front": "Signal peptide",
    "back": "A sequence of about 20 amino acids at or near the leading (amino) end of a polypeptide that targets it to the endoplasmic reticulum or other organelles in a eukaryotic cell."
  },
  {
    "front": "Signal reception",
    "back": "In cellular communication, the first step of a signaling pathway in which a signaling molecule is detected by a receptor molecule on or in the cell."
  },
  {
    "front": "Signal transduction",
    "back": "The linkage of a mechanical, chemical, or electromagnetic stimulus to a specific cellular response."
  },
  {
    "front": "Signal transduction pathway",
    "back": "A series of steps linking a mechanical, chemical, or electrical stimulus to a specific cellular response."
  },
  {
    "front": "Signal-recognition particle",
    "back": "(SRP) A protein-RNA complex that recognizes a signal peptide as it emerges from a ribosome and helps direct the ribosome to the endoplasmic reticulum (ER) by binding to a receptor protein on the ER."
  },
  {
    "front": "Silent mutation",
    "back": "A nucleotide-pair substitution that has no observable effect on the phenotype; for example, within a gene, a mutation that results in a codon that codes for the same amino acid."
  },
  {
    "front": "Simple fruits",
    "back": "A fruit derived from a single carpel or several fused carpels."
  },
  {
    "front": "Simple sequence DNA",
    "back": "A DNA sequence that contains many copies of tandemly repeated short sequences."
  },
  {
    "front": "Single bond",
    "back": "A single covalent bond; the sharing of a pair of valence electrons by two atoms.",
    "chapter": 2
  },
  {
    "front": "Single circulation",
    "back": "A circulatory system consisting of a single pump and circuit, in which blood passes from the sites of gas exchange to the rest of the body before returning to the heart."
  },
  {
    "front": "Single nucleotide polymorphism",
    "back": "(SNP) (snip) A single base-pair site in a genome where nucleotide variation is found in at least 1 percent of the population."
  },
  {
    "front": "Single-lens eyes",
    "back": "The camera-like eye found in some jellies, polychaete worms, spiders, and many molluscs."
  },
  {
    "front": "Single-strand binding proteins",
    "back": "A protein that binds to the unpaired DNA strands during DNA replication, stabilizing them and holding them apart while they serve as templates for the synthesis of complementary strands of DNA."
  },
  {
    "front": "Sinoatrial node",
    "back": "(SA) (s\u012b\u2032-n\u014d-\u0101\u2032-tr\u0113-uhl) A region in the right atrium of the heart that sets the rate and timing at which all cardiac muscle cells contract; the pacemaker."
  },
  {
    "front": "Sister chromatids",
    "back": "Two copies of a duplicated chromosome attached to each other by proteins at the centromere and, sometimes, along the arms. While joined, two sister chromatids make up one chromosome. Chromatids are eventually separated during mitosis or meiosis II."
  },
  {
    "front": "Sister taxa",
    "back": "Groups of organisms that share an immediate common ancestor and hence are each other\u2019s closest relatives."
  },
  {
    "front": "Skeletal muscle",
    "back": "A type of striated muscle that is generally responsible for the voluntary movements of the body."
  },
  {
    "front": "Sliding-filament model",
    "back": "The idea that muscle contraction is based on the movement of thin (actin) filaments along thick (myosin) filaments, shortening the sarcomere, the basic unit of muscle organization."
  },
  {
    "front": "Slow-twitch fibers",
    "back": "A muscle fiber that can sustain long contractions."
  },
  {
    "front": "Small interfering RNAs",
    "back": "(siRNA) One of multiple small, single-stranded RNA molecules generated by cellular machinery from a long, linear, double-stranded RNA molecule. The siRNA associates with one or more proteins in a complex that can degrade or prevent translation of an mRNA with a complementary sequence."
  },
  {
    "front": "Small intestine",
    "back": "The longest section of the alimentary canal, so named because of its small diameter compared with that of the large intestine; the principal site of the enzymatic hydrolysis of food macromolecules and the absorption of nutrients."
  },
  {
    "front": "Smooth ER",
    "back": "That portion of the endoplasmic reticulum that is free of ribosomes."
  },
  {
    "front": "Smooth muscle",
    "back": "A type of muscle lacking the striations of skeletal and cardiac muscle because of the uniform distribution of myosin filaments in the cells; responsible for involuntary body activities."
  },
  {
    "front": "Social learning",
    "back": "Modification of behavior through the observation of other individuals."
  },
  {
    "front": "Sociobiology",
    "back": "The study of social behavior based on evolutionary theory."
  },
  {
    "front": "Sodium-potassium pump",
    "back": "A transport protein in the plasma membrane of animal cells that actively transports sodium out of the cell and potassium into the cell."
  },
  {
    "front": "Soil horizons",
    "back": "A soil layer with physical characteristics that differ from those of the layers above or beneath."
  },
  {
    "front": "Solute",
    "back": "(sol\u2032-y\u016bt) A substance that is dissolved in a solution."
  },
  {
    "front": "Solute potential",
    "back": "(represented by psi subscript S) A component of water potential that is proportional to the molarity of a solution and that measures the effect of solutes on the direction of water movement; also called osmotic potential, it can be either zero or negative."
  },
  {
    "front": "Solution",
    "back": "A liquid that is a homogeneous mixture of two or more substances."
  },
  {
    "front": "Solvent",
    "back": "The dissolving agent of a solution. Water is the most versatile solvent known."
  },
  {
    "front": "Somatic cells",
    "back": "(s\u014d-mat\u2032-ik) Any cell in a multicellular organism except a sperm or egg or their precursors."
  },
  {
    "front": "Somites",
    "back": "One of a series of blocks of mesoderm that exist in pairs just lateral to the notochord in a vertebrate embryo."
  },
  {
    "front": "Soredia",
    "back": "(suh-r\u0113\u2032-d\u0113-um) (plural,soredia) In lichens, a small cluster of fungal hyphae with embedded algae."
  },
  {
    "front": "Sori",
    "back": "(plural,sori) A cluster of sporangia on a fern sporophyll. Sori may be arranged in various patterns, such as parallel lines or dots, which are useful in fern identification."
  },
  {
    "front": "Spatial learning",
    "back": "The establishment of a memory that reflects the environment\u2019s spatial structure."
  },
  {
    "front": "Speciation",
    "back": "(sp\u0113\u2032-s\u0113-\u0101\u2032-shun) An evolutionary process in which one species splits into two or more species."
  },
  {
    "front": "Species",
    "back": "(sp\u0113\u2032-s\u0113z) A population or group of populations whose members have the potential to interbreed in nature and produce viable, fertile offspring but do not produce viable, fertile offspring with members of other such groups."
  },
  {
    "front": "Species diversity",
    "back": "(sp\u0113\u2032-s\u0113z) The number and relative abundance of species in a biological community."
  },
  {
    "front": "Species richness",
    "back": "(sp\u0113\u2032-s\u0113z) The number of species in a biological community."
  },
  {
    "front": "Species-area curve",
    "back": "(sp\u0113\u2032-s\u0113z) The biodiversity pattern that shows that the larger the geographic area of a community is, the more species it has."
  },
  {
    "front": "Specific heat",
    "back": "The amount of heat that must be absorbed or lost for 1 g of a substance to change its temperature by 1 degree C."
  },
  {
    "front": "Spectrophotometer",
    "back": "An instrument that measures the proportions of light of different wavelengths absorbed and transmitted by a pigment solution."
  },
  {
    "front": "Sperm",
    "back": "The male gamete."
  },
  {
    "front": "Spermathecae",
    "back": "(sper\u2032-muh-th\u0113\u2032-kuh) (plural,spermathecae) In many insects, a sac in the female reproductive system where sperm are stored."
  },
  {
    "front": "Spermatogenesis",
    "back": "(sper-ma\u2032-t\u014d-gen\u2032-uh-sis) The continuous and prolific production of mature sperm in the testis."
  },
  {
    "front": "Spermatogonium",
    "back": "(sper-ma\u2032-t\u014d-g\u014d\u2032-n\u0113-um) (plural,spermatogonia) A cell that divides mitotically to form spermatocytes."
  },
  {
    "front": "Sphincter",
    "back": "(sfink\u2032-ter) A ringlike band of muscle fibers that controls the size of an opening in the body, such as the passage between the esophagus and the stomach."
  },
  {
    "front": "Spiral cleavage",
    "back": "A type of embryonic development in protostomes in which the planes of cell division that transform the zygote into a ball of cells are diagonal to the vertical axis of the embryo. As a result, the cells of each tier sit in the grooves between cells of adjacent tiers."
  },
  {
    "front": "Spliceosome",
    "back": "(spl\u012b\u2032-s\u014d-s\u014dm) A large complex made up of proteins and RNA molecules that splices RNA by interacting with the ends of an RNA intron, releasing the intron and joining the two adjacent exons."
  },
  {
    "front": "Spongocoel",
    "back": "(spon\u2032-j\u014d-s\u0113l) The central cavity of a sponge."
  },
  {
    "front": "Spontaneous process",
    "back": "A process that occurs without an overall input of energy; a process that is energetically favorable."
  },
  {
    "front": "Sporangium",
    "back": "(sp\u014dr-an'-j\u0113-um) (plural,sporangia) A multicellular organ in fungi and plants in which meiosis occurs and haploid cells develop."
  },
  {
    "front": "Spores",
    "back": "(1) In the life cycle of a plant or alga undergoing alternation of generations, a haploid cell produced in the sporophyte by meiosis. A spore can divide by mitosis to develop into a multicellular haploid individual, the gametophyte, without fusing with another cell. (2) In fungi, a haploid cell, produced either sexually or asexually, that produces a mycelium after germination."
  },
  {
    "front": "Sporophylls",
    "back": "(sp\u014d\u2032-ruh-fil) A modified leaf that bears sporangia and hence is specialized for reproduction."
  },
  {
    "front": "Sporophytes",
    "back": "(sp\u014d'-ruh-f\u012bt) In organisms (plants and some algae) that have alternation of generations, the multicellular diploid form that results from the union of gametes. Meiosis in the sporophyte produces haploid spores that develop into gametophytes."
  },
  {
    "front": "Sporopollenin",
    "back": "(sp\u014dr-uh-pol\u2032-eh-nin) A durable polymer that covers exposed zygotes of charophyte algae and forms the walls of plant spores, preventing them from drying out."
  },
  {
    "front": "Stability",
    "back": "In evolutionary biology, a term referring to a hybrid zone in which hybrids continue to be produced; this causes the hybrid zone to be \u201cstable\u201d in the sense of persisting\nover time."
  },
  {
    "front": "Stabilizing selection",
    "back": "Natural selection in which intermediate phenotypes survive or reproduce more successfully than do extreme phenotypes."
  },
  {
    "front": "Stamens",
    "back": "(st\u0101\u2032-men) The pollen-producing reproductive organ of a flower, consisting of an anther and a filament."
  },
  {
    "front": "Standard deviation",
    "back": "A measure of the variation found in a set of data points."
  },
  {
    "front": "Standard metabolic rate",
    "back": "(SMR) Metabolic rate of a resting, fasting, and nonstressed ectotherm at a particular temperature."
  },
  {
    "front": "Starch",
    "back": "A storage polysaccharide in plants, consisting entirely of glucose monomers joined by glycosidic linkages."
  },
  {
    "front": "Start point",
    "back": "In transcription, the nucleotide position on the promoter where RNA polymerase begins synthesis of RNA."
  },
  {
    "front": "Statocysts",
    "back": "(stat\u2032-uh-sist\u2032) A type of mechanoreceptor that functions in equilibrium in invertebrates by use of statoliths, which stimulate hair cells in relation to gravity."
  },
  {
    "front": "Statoliths",
    "back": "(stat\u2032-uh-lith\u2032) (1) In plants, a specialized plastid that contains dense starch grains and may play a role in detecting gravity. (2) In invertebrates, a dense particle that settles in response to gravity and is found in sensory organs that function in equilibrium."
  },
  {
    "front": "Stele",
    "back": "(st\u0113l) The vascular tissue of a stem or root."
  },
  {
    "front": "Stem",
    "back": "A vascular plant organ consisting of an alternating system of nodes and internodes that support the leaves and reproductive structures."
  },
  {
    "front": "Stem cell",
    "back": "Any relatively unspecialized cell that can produce, during a single division, two identical daughter cells or two more specialized daughter cells that can undergo further differentiation, or one cell of each type."
  },
  {
    "front": "Steroids",
    "back": "A type of lipid characterized by a carbon skeleton consisting of four fused rings with various chemical groups attached."
  },
  {
    "front": "Sticky end",
    "back": "A single-stranded end of a double-stranded restriction fragment."
  },
  {
    "front": "Stigma",
    "back": "(plural,stigmata) The sticky part of a flower\u2019s carpel, which receives pollen grains."
  },
  {
    "front": "Stimulus",
    "back": "In feedback regulation, a fluctuation in a variable that triggers a response."
  },
  {
    "front": "Stipe",
    "back": "A stemlike structure of a seaweed."
  },
  {
    "front": "Stock",
    "back": "The plant that provides the root system when making a graft."
  },
  {
    "front": "Stomach",
    "back": "An organ of the digestive system that stores food and performs preliminary steps of digestion."
  },
  {
    "front": "Stomata",
    "back": "(st\u014d\u2032-muh) (plural,stomata) A microscopic pore surrounded by guard cells in the epidermis of leaves and stems that allows gas exchange between the environment and the interior of the plant."
  },
  {
    "front": "Stramenopiles",
    "back": "(strah\u2032-men-\u014d\u2032-p\u0113-l\u0113s) One of the three major subgroups for which the SAR eukaryotic supergroup is named. This clade arose by secondary endosymbiosis and includes diatoms and brown algae."
  },
  {
    "front": "Strata",
    "back": "(strah\u2032-tum) (plural,strata) A rock layer formed when new layers of sediment cover older ones and compress them."
  },
  {
    "front": "Strigolactones",
    "back": "Any of a class of plant hormones that inhibit shoot branching, trigger the germination of parasitic plant seeds, and stimulate the association of plant roots with mycorrhizal fungi."
  },
  {
    "front": "Strobili",
    "back": "(str\u014d-b\u012b\u2032-lus) (plural,strobili) The technical term for a cluster of sporophylls known commonly as a cone, found in most gymnosperms and some seedless vascular plants."
  },
  {
    "front": "Stroke",
    "back": "The death of nervous tissue in the brain, usually resulting from rupture or blockage of arteries in the neck or head."
  },
  {
    "front": "Stroke volume",
    "back": "The volume of blood pumped by a heart ventricle in a single contraction."
  },
  {
    "front": "Stroma",
    "back": "(str\u014d'-muh) The dense fluid within the chloroplast surrounding the thylakoid membrane and containing ribosomes and DNA; involved in the synthesis of organic molecules from carbon dioxide and water."
  },
  {
    "front": "Stromatolites",
    "back": "Layered rock that results from the activities of prokaryotes that bind thin films of sediment together."
  },
  {
    "front": "Structural isomers",
    "back": "One of two or more compounds that have the same molecular formula but differ in the covalent arrangements of their atoms."
  },
  {
    "front": "Style",
    "back": "The stalk of a flower\u2019s carpel, with the ovary at the base and the stigma at the top."
  },
  {
    "front": "Substrate",
    "back": "The reactant on which an enzyme works."
  },
  {
    "front": "Substrate feeding",
    "back": "An animal that lives in or on its food source, eating its way through the food."
  },
  {
    "front": "Substrate-level phosphorylation",
    "back": "(fos\u2032-f\u014dr-uh-l\u0101\u2032-shun) The enzyme-catalyzed formation of ATP by direct transfer of a phosphate group to ADP from an intermediate substrate in catabolism."
  },
  {
    "front": "Sugar sink",
    "back": "A plant organ that is a net consumer or storer of sugar. Growing roots, buds, stems, and fruits are examples of sugar sinks supplied by phloem."
  },
  {
    "front": "Sugar source",
    "back": "A plant organ in which sugar is being produced by either photosynthesis or the breakdown of starch. Mature leaves are the primary sugar sources of plants."
  },
  {
    "front": "Sulfhydryl group",
    "back": "A chemical group consisting of a sulfur atom bonded to a hydrogen atom."
  },
  {
    "front": "Summation",
    "back": "A phenomenon of neural integration in which the membrane potential of the postsynaptic cell is determined by the combined effect of EPSPs or IPSPs produced in rapid succession at one synapse or simultaneously at different synapses."
  },
  {
    "front": "Suprachiasmatic nucleus",
    "back": "(SCN) (s\u016bp\u2032-ruh-k\u0113\u2032-as-ma-tik) A group of neurons in the hypothalamus of mammals that functions as a biological clock."
  },
  {
    "front": "Surface tension",
    "back": "A measure of how difficult it is to stretch or break the surface of a liquid. Water has a high surface tension because of the hydrogen bonding of surface molecules."
  },
  {
    "front": "Surfactant",
    "back": "A substance secreted by alveoli that decreases surface tension in the fluid that coats the alveoli."
  },
  {
    "front": "Survivorship curve",
    "back": "A plot of the number of members of a cohort that are still alive at each age; one way to represent age-specific mortality."
  },
  {
    "front": "Suspension feeder",
    "back": "An animal that feeds by removing suspended food particles from the surrounding medium by a capture, trapping, or filtration mechanism."
  },
  {
    "front": "Sustainable agriculture",
    "back": "Long-term productive farming methods that are environmentally safe."
  },
  {
    "front": "Sustainable development",
    "back": "Development that meets the needs of people today without limiting the ability of future generations to meet their needs."
  },
  {
    "front": "Swim bladder",
    "back": "In aquatic osteichthyans, an air sac that enables the animal to control its buoyancy in the water."
  },
  {
    "front": "Symbiont",
    "back": "(sim\u2032-b\u0113-ont) The smaller participant in a symbiotic relationship, living in or on the host."
  },
  {
    "front": "Symbiosis",
    "back": "An ecological relationship between organisms of two different species that live together in direct and intimate contact."
  },
  {
    "front": "Sympathetic division",
    "back": "A division of the autonomic nervous system; generally increases energy expenditure and prepares the body for action."
  },
  {
    "front": "Sympatric speciation",
    "back": "(sim-pat\u2032-rik) The formation of new species in populations that live in the same geographic area."
  },
  {
    "front": "Symplast",
    "back": "In plants, the continuum of cytosol connected by plasmodesmata between cells."
  },
  {
    "front": "Synapse",
    "back": "(sin\u2032-aps) The junction where a neuron communicates with another cell across a narrow gap via a neurotransmitter or an electrical coupling."
  },
  {
    "front": "Synapsids",
    "back": "(si-nap\u2032-sid) A member of an amniote clade distinguished by a single hole on each side of the skull. Synapsids include the mammals."
  },
  {
    "front": "Synapsis",
    "back": "(si-nap\u2032-sis) The pairing and physical connection of one duplicated chromosome to its homolog during prophase I of meiosis."
  },
  {
    "front": "Synaptonemal complex",
    "back": "(si-nap\u2032-tuh-n\u0113\u2032-muhl) A zipper-like structure composed of proteins, which connects a chromosome to its homolog tightly along their lengths during part of prophase I of meiosis."
  },
  {
    "front": "Systematics",
    "back": "A scientific discipline focused on classifying organisms and determining their evolutionary relationships."
  },
  {
    "front": "Systemic acquired resistance",
    "back": "A defensive response in infected plants that helps protect healthy tissue from pathogenic invasion."
  },
  {
    "front": "Systemic circuit",
    "back": "The branch of the circulatory system that supplies oxygenated blood to and carries deoxygenated blood away from organs and tissues throughout the body."
  },
  {
    "front": "Systems biology",
    "back": "An approach to studying biology that aims to model the dynamic behavior of whole biological systems based on a study of the interactions among the system\u2019s parts.",
    "chapter": 1
  },
  {
    "front": "Systole",
    "back": "(sis\u2032-t\u014d-l\u0113) The stage of the cardiac cycle in which a heart chamber contracts and pumps blood."
  },
  {
    "front": "Systolic pressure",
    "back": "Blood pressure in the arteries during contraction of the ventricles."
  },
  {
    "front": "T cells",
    "back": "The class of lymphocytes that mature in the thymus; they include both effector cells for the cell-mediated immune response and helper cells required for both branches of adaptive immunity."
  },
  {
    "front": "Taproot",
    "back": "A main vertical root that develops from an embryonic root and gives rise to lateral (branch) roots."
  },
  {
    "front": "Tastants",
    "back": "Any chemical that stimulates the sensory receptors in a taste bud."
  },
  {
    "front": "Taste buds",
    "back": "A collection of modified epithelial cells on the tongue or in the mouth that are receptors for taste in mammals."
  },
  {
    "front": "TATA box",
    "back": "A DNA sequence in eukaryotic promoters crucial in forming the transcription initiation complex."
  },
  {
    "front": "Taxis",
    "back": "(tak\u2032-sis) An oriented movement toward or away from a stimulus."
  },
  {
    "front": "Taxon",
    "back": "(plural,taxa) A named taxonomic unit at any given level of classification."
  },
  {
    "front": "Tay-Sachs disease",
    "back": "A human genetic disease caused by a recessive allele for a dysfunctional enzyme, leading to accumulation of certain lipids in the brain. Seizures, blindness, and degeneration of motor and mental performance usually become manifest a few months after birth, followed by death within a few years."
  },
  {
    "front": "Technology",
    "back": "The application of scientific knowledge for a specific purpose, often involving industry or commerce but also including uses in basic research.",
    "chapter": 1
  },
  {
    "front": "Telomeres",
    "back": "(tel\u2032-uh-m\u0113r) The tandemly repetitive DNA at the end of a eukaryotic chromosome\u2019s DNA molecule. Telomeres protect the organism\u2019s genes from being eroded during successive rounds of replication; Nucleotide sequences, usually noncoding, that are present in many copies in a eukaryotic genome. The repeated units may be short and arranged tandemly (in series) or long and dispersed in the genome."
  },
  {
    "front": "Telophase",
    "back": "The fifth and final stage of mitosis, in which daughter nuclei are forming and cytokinesis has typically begun."
  },
  {
    "front": "Temperate broadleaf forest",
    "back": "A biome located throughout midlatitude regions where there is sufficient moisture to support the growth of large, broadleaf deciduous trees."
  },
  {
    "front": "Temperate grassland",
    "back": "A terrestrial biome that exists at midlatitude regions and is dominated by grasses and forbs."
  },
  {
    "front": "Temperate phages",
    "back": "A phage that is capable of replicating by either a lytic or lysogenic cycle."
  },
  {
    "front": "Temperature",
    "back": "A measure in degrees of the average kinetic energy (thermal energy) of the atoms and molecules in a body of matter."
  },
  {
    "front": "Template strand",
    "back": "The DNA strand that provides the pattern, or template, for ordering, by complementary base pairing, the sequence of nucleotides in an RNA transcript."
  },
  {
    "front": "Tendon",
    "back": "A fibrous connective tissue that attaches muscle to bone."
  },
  {
    "front": "Terminator",
    "back": "In bacteria, a sequence of nucleotides in DNA that marks the end of a gene and signals RNA polymerase to release the newly made RNA molecule and detach from the DNA."
  },
  {
    "front": "Territoriality",
    "back": "A behavior in which an animal defends a bounded physical space against encroachment by other individuals, usually of its own species."
  },
  {
    "front": "Tertiary consumers",
    "back": "(ter\u2032-sh\u0113-\u0101r\u2032-\u0113) A carnivore that eats other carnivores."
  },
  {
    "front": "Tertiary structure",
    "back": "(ter\u2032-sh\u0113-\u0101r\u2032-\u0113) The overall shape of a protein molecule due to interactions of amino acid side chains, including hydrophobic interactions, ionic bonds, hydrogen bonds, and disulfide bridges."
  },
  {
    "front": "Testcross",
    "back": "Breeding an organism of unknown genotype with a homozygous recessive individual to determine the unknown genotype. The ratio of phenotypes in the offspring reveals the unknown genotype."
  },
  {
    "front": "Testes",
    "back": "(plural,testes) The male reproductive organ, or gonad, in which sperm and reproductive hormones are produced."
  },
  {
    "front": "Testosterone",
    "back": "A steroid hormone required for development of the male reproductive system, spermatogenesis, and male secondary sex characteristics; the major androgen in mammals."
  },
  {
    "front": "Tests",
    "back": "In foram protists, a porous shell that consists of a single piece of organic material hardened with calcium carbonate."
  },
  {
    "front": "Tetanus",
    "back": "(tet\u2032-uh-nus) The maximal, sustained contraction of a skeletal muscle, caused by a very high frequency of action potentials elicited by continual stimulation."
  },
  {
    "front": "Tetrapods",
    "back": "A vertebrate clade whose members have limbs with digits. Tetrapods include mammals, amphibians, and birds and other reptiles."
  },
  {
    "front": "Thalamus",
    "back": "(thal\u2032-uh-mus) An integrating center of the vertebrate forebrain. Neurons with cell bodies in the thalamus relay neural input to specific areas in the cerebral cortex and regulate what information goes to the cerebral cortex."
  },
  {
    "front": "Theory",
    "back": "An explanation that is broader in scope than a hypothesis, generates new hypotheses, and is supported by a large body of evidence.",
    "chapter": 1
  },
  {
    "front": "Thermal energy",
    "back": "Kinetic energy due to the random motion of atoms and molecules; energy in its most random form; Thermal energy in transfer from one body of matter to another."
  },
  {
    "front": "Thermocline",
    "back": "A narrow stratum of abrupt temperature change in the ocean and in many temperate-zone lakes."
  },
  {
    "front": "Thermodynamics",
    "back": "(ther\u2032-m\u014d-d\u012b-nam\u2032-iks) The study of energy transformations that occur in a collection of matter; The principle of conservation of energy: Energy can be transferred and transformed, but it cannot be created or destroyed and the principle stating that every energy transfer or transformation increases the entropy of the universe. Usable forms of energy are at least partly converted to heat."
  },
  {
    "front": "Thermoreceptors",
    "back": "A receptor stimulated by either heat or cold."
  },
  {
    "front": "Thermoregulation",
    "back": "The maintenance of internal body temperature within a tolerable range."
  },
  {
    "front": "Theropods",
    "back": "A member of a group of dinosaurs that were bipedal carnivores."
  },
  {
    "front": "Thick filaments",
    "back": "A filament composed of staggered arrays of myosin molecules; a component of myofibrils in muscle fibers."
  },
  {
    "front": "Thigmomorphogenesis",
    "back": "(thig\u2032-m\u014d-mor\u2032-ph\u014d-gen\u2032-uh-sis) A response in plants to chronic mechanical stimulation, resulting from increased ethylene production. An example is thickening stems in response to strong winds."
  },
  {
    "front": "Thigmotropism",
    "back": "(thig-m\u014d\u2032-truh-pizm) A directional growth of a plant in response to touch."
  },
  {
    "front": "Thin filaments",
    "back": "A filament consisting of two strands of actin and two strands of regulatory protein coiled around one another; a component of myofibrils in muscle fibers."
  },
  {
    "front": "Threatened species",
    "back": "A species that is considered likely to become endangered in the foreseeable future."
  },
  {
    "front": "Threshold",
    "back": "The potential that an excitable cell membrane must reach for an action potential to be initiated."
  },
  {
    "front": "Thrombus",
    "back": "(plural,thrombi) A fibrin-containing clot that forms in a blood vessel and blocks the flow of blood."
  },
  {
    "front": "Thylakoids",
    "back": "(th\u012b'-luh-koyd) A flattened, membranous sac inside a chloroplast. Thylakoids often exist in stacks called grana that are interconnected; their membranes contain molecular \u201cmachinery\u201d used to convert light energy to chemical energy."
  },
  {
    "front": "Thymus",
    "back": "(th\u012b\u2032-mus) A small organ in the thoracic cavity of vertebrates where maturation of T cells is completed."
  },
  {
    "front": "Thyroid gland",
    "back": "An endocrine gland, located on the ventral surface of the trachea, that secretes two iodine-containing hormones, triiodothyronine  (T subscript 3) and thyroxine  (T subscript 4), as well as calcitonin."
  },
  {
    "front": "Thyroid hormone",
    "back": "Either of two iodine-containing hormones (triiodothyronine and thyroxine) that are secreted by the thyroid gland and that help regulate metabolism, development, and maturation in vertebrates."
  },
  {
    "front": "Thyroxine",
    "back": "(represented by T subscript 4) One of two iodine-containing hormones that are secreted by the thyroid gland and that help regulate metabolism, development, and maturation in vertebrates."
  },
  {
    "front": "Tidal volume",
    "back": "The volume of air a mammal inhales and exhales with each breath."
  },
  {
    "front": "Tight junctions",
    "back": "A type of intercellular junction\nbetween animal cells that prevents the leakage\nof material through the space between cells."
  },
  {
    "front": "Tissue system",
    "back": "One or more tissues organized into a functional unit connecting the organs of a plant."
  },
  {
    "front": "Tissues",
    "back": "An integrated group of cells with a common structure, function, or both."
  },
  {
    "front": "Toll-like receptor",
    "back": "(TLR) A membrane receptor on a phagocytic white blood cell that recognizes fragments of molecules common to a set of pathogens."
  },
  {
    "front": "Tonicity",
    "back": "The ability of a solution surrounding a cell to cause that cell to gain or lose water."
  },
  {
    "front": "Top-down control",
    "back": "A situation in which the abundance of organisms at each trophic level is controlled by the abundance of consumers at higher trophic levels; thus, predators limit herbivores, and herbivores limit plants."
  },
  {
    "front": "Topoisomerase",
    "back": "A protein that breaks, swivels, and rejoins DNA strands. During DNA replication, topoisomerase helps to relieve strain in the double helix ahead of the replication fork."
  },
  {
    "front": "Topsoil",
    "back": "A mixture of particles derived from rock, living organisms, and decaying organic material (humus)."
  },
  {
    "front": "Torpor",
    "back": "A physiological state in which activity is low and metabolism decreases."
  },
  {
    "front": "Totipotent",
    "back": "(t\u014d\u2032-tuh-p\u014dt\u2032-ent) Describing a cell that can give rise to all parts of the embryo and adult, as well as extraembryonic membranes in species that have them."
  },
  {
    "front": "Trace elements",
    "back": "An element indispensable for life but required in extremely minute amounts.",
    "chapter": 2
  },
  {
    "front": "Trachea",
    "back": "(tr\u0101\u2032-k\u0113-uh) The portion of the respiratory tract that passes from the larynx to the bronchi; also called the windpipe."
  },
  {
    "front": "Tracheal system",
    "back": "In insects, a system of branched, air-filled tubes that extends throughout the body and carries oxygen directly to cells."
  },
  {
    "front": "Tracheids",
    "back": "(tr\u0101\u2032-k\u0113-id) A long, tapered water-conducting cell found in the xylem of nearly all vascular plants. Functioning tracheids are no longer living."
  },
  {
    "front": "Trait",
    "back": "One of two or more detectable variants in a genetic character."
  },
  {
    "front": "Trans fats",
    "back": "An unsaturated fat, formed artificially during hydrogenation of oils, containing one or moretransdouble bonds."
  },
  {
    "front": "Transcription",
    "back": "The synthesis of RNA using a DNA template."
  },
  {
    "front": "Transcription factors",
    "back": "A regulatory protein that binds to DNA and affects transcription of specific genes."
  },
  {
    "front": "Transcription initiation complex",
    "back": "The completed assembly of transcription factors and RNA polymerase bound to a promoter."
  },
  {
    "front": "Transcription unit",
    "back": "A region of DNA that is transcribed into an RNA molecule."
  },
  {
    "front": "Transduction",
    "back": "A process in which phages (viruses) carry bacterial DNA from one bacterial cell to another. When these two cells are members of different species, transduction results in horizontal gene transfer; A series of steps linking a mechanical, chemical, or electrical stimulus to a specific cellular response."
  },
  {
    "front": "Transfer RNA",
    "back": "(tRNA) An RNA molecule that functions as a translator between nucleic acid and protein languages by picking up a specific amino acid and carrying it to the ribosome, where the tRNA recognizes the appropriate codon in the mRNA."
  },
  {
    "front": "Transformation",
    "back": "(1) The process by which a cell in culture acquires the ability to divide indefinitely, similar to the division of cancer cells. (2) A change in genotype and phenotype due to the assimilation of external DNA by a cell. When the external DNA is from a member of a different species, transformation results in horizontal gene transfer."
  },
  {
    "front": "Transgene",
    "back": "A gene that has been transferred naturally or by a genetic engineering technique from one organism to another."
  },
  {
    "front": "Transgenic",
    "back": "Pertaining to an organism whose genome contains DNA introduced from another organism of the same or a different species."
  },
  {
    "front": "Translation",
    "back": "The synthesis of a polypeptide using the genetic information encoded in an mRNA molecule. There is a change of \u201clanguage\u201d from nucleotides to amino acids."
  },
  {
    "front": "Translocation",
    "back": "(1) An aberration in chromosome structure resulting from attachment of a chromosomal fragment to a nonhomologous chromosome. (2) During protein synthesis, the third stage in the elongation cycle, when the RNA carrying the growing polypeptide moves from the A site to the P site on the ribosome. (3) The transport of organic nutrients in the phloem of vascular plants."
  },
  {
    "front": "Transmembrane proteins",
    "back": "A type of integral protein that spans the entire membrane."
  },
  {
    "front": "Transmission electron microscope",
    "back": "(TEM) A microscope that passes an electron beam through very thin sections stained with metal atoms and is primarily used to study the internal structure of cells."
  },
  {
    "front": "Transpiration",
    "back": "The evaporative loss of water from a plant."
  },
  {
    "front": "Transport epithelia",
    "back": "One or more layers of specialized epithelial cells that carry out and regulate solute movement."
  },
  {
    "front": "Transport proteins",
    "back": "A transmembrane protein that helps a certain substance or class of closely related substances to cross the membrane."
  },
  {
    "front": "Transport vesicles",
    "back": "A small membranous sac in a eukaryotic cell\u2019s cytoplasm carrying molecules produced by the cell."
  },
  {
    "front": "Transposable elements",
    "back": "A segment of DNA that can move within the genome of a cell by means of a DNA or RNA intermediate; also called a transposable genetic element."
  },
  {
    "front": "Transposons",
    "back": "A transposable element that moves within a genome by means of a DNA intermediate."
  },
  {
    "front": "Transverse tubules",
    "back": "(T) An infolding of the plasma membrane of skeletal muscle cells."
  },
  {
    "front": "Triacylglycerol",
    "back": "(tr\u012b-as\u2032-ul-glis\u2032-uh-rol) A lipid consisting of three fatty acids linked to one glycerol molecule; also called a fat or triglyceride."
  },
  {
    "front": "Trichomes",
    "back": "An epidermal cell that is a highly specialized, often hairlike outgrowth on a plant shoot."
  },
  {
    "front": "Triple response",
    "back": "A plant growth maneuver in response to mechanical stress, involving slowing of stem elongation, thickening of the stem, and a curvature that causes the stem to start growing horizontally."
  },
  {
    "front": "Triplet code",
    "back": "A genetic information system in which a series of three-nucleotide-long words specifies a sequence of amino acids for a polypeptide chain."
  },
  {
    "front": "Triploblastic",
    "back": "Possessing three germ layers: the endoderm, mesoderm, and ectoderm. All bilaterian animals are triploblastic."
  },
  {
    "front": "Trisomic",
    "back": "Referring to a diploid cell that has three copies of a particular chromosome instead of the normal two."
  },
  {
    "front": "Trochophore larva",
    "back": "(tr\u014d\u2032-kuh-f\u014dr) Distinctive larval stage observed in some lophotrochozoan animals, including some annelids and molluscs."
  },
  {
    "front": "Trophic efficiency",
    "back": "The percentage of production transferred from one trophic level to the next higher trophic level."
  },
  {
    "front": "Trophic level",
    "back": "The position an organism occupies in a food chain."
  },
  {
    "front": "Trophic structure",
    "back": "The different feeding relationships in an ecosystem, which determine the route of energy flow and the pattern of chemical cycling."
  },
  {
    "front": "Trophoblast",
    "back": "The outer epithelium of a mammalian blastocyst. It forms the fetal part of the placenta, supporting embryonic development but not forming part of the embryo proper."
  },
  {
    "front": "Tropical rain forest",
    "back": "A terrestrial biome characterized by relatively high precipitation and temperatures year-round."
  },
  {
    "front": "Tropism",
    "back": "A growth response that results in the curvature of whole plant organs toward or away from stimuli due to differential rates of cell elongation."
  },
  {
    "front": "Tropomyosin",
    "back": "The regulatory protein that blocks the myosin-binding sites on actin molecules."
  },
  {
    "front": "Troponin complex",
    "back": "The regulatory proteins that control the position of tropomyosin on the thin filament."
  },
  {
    "front": "True-breeding",
    "back": "Referring to organisms that produce offspring of the same variety over many generations of self-pollination."
  },
  {
    "front": "Tubal ligation",
    "back": "A means of sterilization in which the two oviducts (fallopian tubes) are tied closed and a segment of each is removed to prevent eggs from reaching the uterus."
  },
  {
    "front": "Tube feet",
    "back": "One of numerous extensions of an echinoderm\u2019s water vascular system. Tube feet function in locomotion and feeding."
  },
  {
    "front": "Tumor-suppressor genes",
    "back": "A gene whose protein product inhibits cell division, thereby preventing the uncontrolled cell growth that contributes to cancer."
  },
  {
    "front": "Tundra",
    "back": "A terrestrial biome at the extreme limits of plant growth. At the northernmost limits, it is called arctic tundra, and at high altitudes, where plant forms are limited to low shrubby or matlike vegetation, it is called alpine tundra."
  },
  {
    "front": "Tunicates",
    "back": "A member of the clade Urochordata, sessile marine chordates that lack a backbone."
  },
  {
    "front": "Turgid",
    "back": "(ter\u2032-jid) Swollen or distended, as in plant cells. (A walled cell becomes turgid if it has a lower water potential than its surroundings, resulting in entry of water.)"
  },
  {
    "front": "Turgor pressure",
    "back": "The force directed against a plant cell wall after the influx of water and swelling of the cell due to osmosis."
  },
  {
    "front": "Turnover",
    "back": "The mixing of waters as a result of changing water-temperature profiles in a lake."
  },
  {
    "front": "Tympanic membrane",
    "back": "Another name for the eardrum, the membrane between the outer and middle ear."
  },
  {
    "front": "Unikonta",
    "back": "(y\u016b\u2032-ni-kon\u2032-tuh) One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This clade, which is supported by studies of myosin proteins and DNA, consists of amoebozoans and opisthokonts; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. Excavates have unique cytoskeletal features, and some species have an \u201cexcavated\u201d feeding groove on one side of the cell body; One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This supergroup contains a large, extremely diverse collection of protists from three major subgroups: stramenopiles, alveolates, and rhizarians and One of four supergroups of eukaryotes proposed in a current hypothesis of the evolutionary history of eukaryotes. This monophyletic group, which includes red algae, green algae, and plants, descended from an ancient protistan ancestor that engulfed a cyanobacterium."
  },
  {
    "front": "Unsaturated fatty acid",
    "back": "A fatty acid that has one or more double bonds between carbons in the hydrocarbon tail. Such bonding reduces the number of hydrogen atoms attached to the carbon skeleton."
  },
  {
    "front": "Urban ecology",
    "back": "The study of organisms and their environment in urban and suburban settings."
  },
  {
    "front": "Urea",
    "back": "A soluble nitrogenous waste produced in the liver by a metabolic cycle that combines ammonia with carbon dioxide."
  },
  {
    "front": "Ureter",
    "back": "(y\u016b-r\u0113\u2032-ter) A duct leading from the kidney to the urinary bladder."
  },
  {
    "front": "Urethra",
    "back": "(y\u016b-r\u0113\u2032-thruh) A tube that releases urine from the mammalian body near the vagina in females and through the penis in males; also serves in males as the exit tube for the reproductive system."
  },
  {
    "front": "Uric acid",
    "back": "A product of protein and purine metabolism and the major nitrogenous waste product of insects, land snails, and many reptiles. Uric acid is relatively nontoxic and largely insoluble in water."
  },
  {
    "front": "Urinary bladder",
    "back": "The pouch where urine is stored prior to elimination."
  },
  {
    "front": "Uterine cycle",
    "back": "The cyclic changes in the endometrium (uterine lining) of mammals that occur in the absence of pregnancy. In certain primates, including humans, the uterine cycle is a menstrual cycle."
  },
  {
    "front": "Uterus",
    "back": "A female organ where eggs are fertilized and/or development of the young occurs."
  },
  {
    "front": "Vaccine",
    "back": "A harmless variant or derivative of a pathogen that stimulates a host\u2019s immune system to mount defenses against the pathogen."
  },
  {
    "front": "Vacuoles",
    "back": "(vak'-y\u016b-\u014dl') A membrane-bounded vesicle whose specialized function varies in different kinds of cells."
  },
  {
    "front": "Vagina",
    "back": "Part of the female reproductive system between the uterus and the outside opening; the birth canal in mammals. During copulation, the vagina accommodates the male\u2019s penis and receives sperm."
  },
  {
    "front": "Valence",
    "back": "The bonding capacity of a given atom; the number of covalent bonds that an atom can form, which usually equals the number of unpaired electrons in its outermost (valence) shell.",
    "chapter": 2
  },
  {
    "front": "Valence electrons",
    "back": "An electron in the outermost electron shell.",
    "chapter": 2
  },
  {
    "front": "Valence shell",
    "back": "The outermost energy shell of an atom, containing the valence electrons involved in the chemical reactions of that atom.",
    "chapter": 2
  },
  {
    "front": "Van der Waals interactions",
    "back": "Weak attractions between molecules or parts of molecules that result from transient local partial charges.",
    "chapter": 2
  },
  {
    "front": "Variables",
    "back": "A factor that varies in an experiment.",
    "chapter": 1
  },
  {
    "front": "Variation",
    "back": "Differences between members of the same species."
  },
  {
    "front": "Vas deferens",
    "back": "In mammals, the tube in the male reproductive system in which sperm travel from the epididymis to the urethra."
  },
  {
    "front": "Vasa recta",
    "back": "The capillary system in the kidney that serves the loop of Henle."
  },
  {
    "front": "Vascular cambium",
    "back": "A cylinder of meristematic tissue in woody plants that adds layers of secondary vascular tissue called secondary xylem (wood) and secondary phloem."
  },
  {
    "front": "Vascular plants",
    "back": "A plant with vascular tissue. Vascular plants include all living plant species except liverworts, mosses, and hornworts."
  },
  {
    "front": "Vascular tissue",
    "back": "Plant tissue consisting of cells joined into tubes that transport water and nutrients throughout the plant body."
  },
  {
    "front": "Vasectomy",
    "back": "The cutting and sealing of each vas deferens to prevent sperm from entering the urethra."
  },
  {
    "front": "Vasoconstriction",
    "back": "A decrease in the diameter of blood vessels caused by contraction of smooth muscles in the vessel walls."
  },
  {
    "front": "Vasodilation",
    "back": "An increase in the diameter of blood vessels caused by relaxation of smooth muscles in the vessel walls."
  },
  {
    "front": "Vasopressin",
    "back": "(an\u2032-t\u012b-d\u012b-y\u016b-ret\u2032-ik) A peptide hormone, also called vasopressin, that promotes water retention by the kidneys. Produced in the hypothalamus and released from the posterior pituitary, ADH also functions in the brain."
  },
  {
    "front": "Vector",
    "back": "An organism that transmits pathogens from one host to another."
  },
  {
    "front": "Vegetal pole",
    "back": "The point at the end of an egg in the hemisphere where most yolk is concentrated; opposite of animal pole."
  },
  {
    "front": "Vegetative propagation",
    "back": "Asexual reproduction in plants that is facilitated or induced by humans."
  },
  {
    "front": "Vegetative reproduction",
    "back": "Asexual reproduction in plants."
  },
  {
    "front": "Veins",
    "back": "(1) In animals, a vessel that carries blood toward the heart. (2) In plants, a vascular bundle in a leaf."
  },
  {
    "front": "Ventilation",
    "back": "The flow of air or water over a respiratory surface."
  },
  {
    "front": "Ventral",
    "back": "In an animal with bilateral symmetry, pertaining to the underside (in most animals) or front (in animals with upright posture) of the body."
  },
  {
    "front": "Ventricles",
    "back": "(ven\u2032-tri-kul) (1) A heart chamber that pumps blood out of the heart. (2) A space in the vertebrate brain, filled with cerebrospinal fluid."
  },
  {
    "front": "Venules",
    "back": "(ven\u2032-y\u016bl) A vessel that conveys blood between a capillary bed and a vein."
  },
  {
    "front": "Vernalization",
    "back": "The use of cold treatment to induce a plant to flower."
  },
  {
    "front": "Vertebrates",
    "back": "A chordate animal with vertebrae, the series of bones that make up the backbone."
  },
  {
    "front": "Vesicles",
    "back": "(ves'-i-kul) A membrane-bound sac in or outside a cell."
  },
  {
    "front": "Vessel elements",
    "back": "A short, wide, water-conducting cell found in the xylem of most angiosperms and a few nonflowering vascular plants. Dead at maturity, vessel elements are aligned end to end to form micropipes called vessels."
  },
  {
    "front": "Vestigial structures",
    "back": "A feature of an organism that is a historical remnant of a structure that served a function in the organism\u2019s ancestors."
  },
  {
    "front": "Villi",
    "back": "(plural,villi) (1) A finger-like projection of the inner surface of the small intestine. (2) A finger-like projection of the chorion of the mammalian placenta. Large numbers of villi increase the surface areas of these organs."
  },
  {
    "front": "Viral envelopes",
    "back": "A membrane, derived from membranes of the host cell, that cloaks the capsid, which in turn encloses a viral genome."
  },
  {
    "front": "Virulent phage",
    "back": "A phage that replicates only by a lytic cycle."
  },
  {
    "front": "Virus",
    "back": "An infectious particle incapable of replicating outside of a cell, consisting of an RNA or DNA genome surrounded by a protein coat (capsid) and, for some viruses, a membranous envelope."
  },
  {
    "front": "Visceral mass",
    "back": "One of the three main parts of a mollusc; the part containing most of the internal organs; (1) The portion of a bryophyte sporophyte that gathers sugars, amino acids, water, and minerals from the parent gametophyte via transfer cells. (2) One of the three main parts of a mollusc; a muscular structure usually used for movement and One of the three main parts of a mollusc; a fold of tissue that drapes over the mollusc\u2019s visceral mass and may secrete a shell."
  },
  {
    "front": "Visible light",
    "back": "That portion of the electromagnetic spectrum that can be detected as various colors by the human eye, ranging in wavelength from about 380 nm to about 740 nm."
  },
  {
    "front": "Vital capacity",
    "back": "The maximum volume of air that a mammal can inhale and exhale with each breath."
  },
  {
    "front": "Vitamins",
    "back": "An organic molecule required in the diet in very small amounts. Many vitamins serve as coenzymes or parts of coenzymes."
  },
  {
    "front": "Viviparous",
    "back": "(v\u012b-vip\u2032-uh-rus) Referring to a type of development in which the young are born alive after having been nourished in the uterus by blood from the placenta."
  },
  {
    "front": "Voltage-gated ion channel",
    "back": "A specialized ion channel that opens or closes in response to changes in membrane potential."
  },
  {
    "front": "Vulva",
    "back": "Collective term for the female external genitalia."
  },
  {
    "front": "Water potential",
    "back": "(represented by the Greek letter psi) The physical property predicting the direction in which water will flow, governed by solute concentration and applied pressure."
  },
  {
    "front": "Water vascular system",
    "back": "A network of hydraulic canals unique to echinoderms that branches into extensions called tube feet, which function in locomotion and feeding."
  },
  {
    "front": "Wavelength",
    "back": "The distance between crests of waves, such as those of the electromagnetic spectrum."
  },
  {
    "front": "Wetlands",
    "back": "A habitat that is inundated by water at least some of the time and that supports plants adapted to water-saturated soil."
  },
  {
    "front": "White matter",
    "back": "Tracts of axons within the CNS."
  },
  {
    "front": "Whole-genome shotgun approach",
    "back": "Procedure for genome sequencing in which the genome is randomly cut into many overlapping short segments that are sequenced; computer software then assembles the complete sequence."
  },
  {
    "front": "Wild type",
    "back": "The phenotype most commonly observed in natural populations; also refers to the individual with that phenotype."
  },
  {
    "front": "Wilting",
    "back": "The drooping of leaves and stems as a result of plant cells becoming flaccid."
  },
  {
    "front": "Wobble",
    "back": "Flexibility in the base-pairing rules in which the nucleotide at the 5' end of a tRNA anticodon can form hydrogen bonds with more than one kind of base in the third position (3' end) of a codon."
  },
  {
    "front": "X-linked genes",
    "back": "A gene located on the X chromosome; such genes show a distinctive pattern of inheritance."
  },
  {
    "front": "X-ray crystallography",
    "back": "A technique used to study the three-dimensional structure of molecules. It depends on the diffraction of an X-ray beam by the individual atoms of a crystallized molecule."
  },
  {
    "front": "Xerophytes",
    "back": "(zir\u2032-\u014d-f\u012bt\u2032) A plant adapted to an arid climate."
  },
  {
    "front": "Xylem",
    "back": "(z\u012b\u2032-lum) Vascular plant tissue consisting mainly of tubular dead cells that conduct most of the water and minerals upward from the roots to the rest of the plant."
  },
  {
    "front": "Xylem sap",
    "back": "(z\u012b\u2032-lum) The dilute solution of water and minerals carried through vessels and tracheids."
  },
  {
    "front": "Yeasts",
    "back": "Single-celled fungus. Yeasts reproduce asexually by binary fission or by the pinching of small buds off a parent cell. Many fungal species can grow both as yeasts and as a network of filaments; relatively few species grow only as yeasts."
  },
  {
    "front": "Yolk",
    "back": "Nutrients stored in an egg."
  },
  {
    "front": "Zona pellucida",
    "back": "The extracellular matrix surrounding a mammalian egg."
  },
  {
    "front": "Zone of polarizing activity",
    "back": "(ZPA) A block of mesoderm located just under the ectoderm where the posterior side of a limb bud is attached to the body; required for proper pattern formation along the anterior-posterior axis of the limb."
  },
  {
    "front": "Zoned reserve",
    "back": "An extensive region that includes areas relatively undisturbed by humans surrounded by areas that have been changed by human activity and are used for economic gain."
  },
  {
    "front": "Zoonotic pathogens",
    "back": "A disease-causing agent that is transmitted to humans from other animals."
  },
  {
    "front": "Zoopagomycetes",
    "back": "A member of the fungal phylum Zoopagomycota, multicellular parasites or commensal symbionts of animals; sexual reproduction, where known, involves the formation of a sturdy structure called a zygosporangium."
  },
  {
    "front": "Zoospores",
    "back": "Flagellated spore found in chytrid fungi and some protists."
  },
  {
    "front": "Zygosporangium",
    "back": "(z\u012b\u2032-guh-sp\u014dr-an\u2032-j\u0113-um) (plural,zygosporangia) In zygomycete fungi, a sturdy multinucleate structure in which karyogamy and meiosis occur."
  },
  {
    "front": "Zygote",
    "back": "(z\u012b\u2032-g\u014dt) The diploid cell produced by the union of haploid gametes during fertilization; a fertilized egg."
  }
]