import {useEffect, useState, useRef, createRef} from 'react'
import styled from 'styled-components';
import { colors } from "../../global_styles/colors"
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setSelected } from "../../redux/selectedSectionSlice";

//images
import arrow from "../../images/icons/arrow-filled.svg"


const SectionSelectorContainer = styled.div`
    position:relative;
    margin:16px 0 ;
`

const CustomSelect = styled.div`

`

const CustomSelectLabel = styled.label`
    display:block;
    color:${colors.textdarkThemeMuted};
`

const CustomSelectButton = styled.button`
    border-radius: 4px;
    border: 1px solid ${colors.swatchLight600};
    background: ${colors.backgroundlightthemebglevel0};
    width: 100%;
    cursor: pointer;
    height: 48px;
    display: flex;
    padding: 12px;
    align-items: center;
    border-radius: 8px;
    justify-content: space-between;
    img{
        // width:24px;
        // height:24px;
        margin-left:8px;
    }
`

const CustomSelectValue = styled.span`
    text-align:left;
    font-family: "TT Commons";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: ${colors.textlightThemeDefault};
`

const Accordion = styled.ul`
    border-radius: 4px;
    border: 0px solid ${colors.backgrounddarkthemebglevel1};
    background:#FEFEFE;
    box-shadow: 1px -1px 10px rgba(213, 219, 232, 0.64);
    position:absolute;
    // top:60px;
    z-index:1000;
    width:100%;
    max-height:430px;
    overflow:auto;
`

const AccordionGroup = styled.li`
    margin-bottom: 10px;
    border-radius: 8px;
    margin-top: 24px;
`

const AccordionButton = styled.div`
    width: 100%;
    cursor: pointer;
    display: flex;
    align-items:center;
    position: relative;
    flex-direction: row;
    background-color: transparent!important;
    font-size: 14px;
    button{
        color:black;
        text-align:left;
        p{
            margin:0;
            font-size:16px;
        }
    }
    &[aria-expanded=true] svg{
        transform:rotate(90deg)
    }
    svg{
      width:24px;
      height:24px;
    }
`

const AccordionPanel = styled.div`
    overflow: auto;
    padding: 0 0 0 20px;
    button{
        display:block;
        color:black;
        &.section-button{
            margin: 8px 8px 0 8px;
            padding-left:24px;
            text-align:left;
        }
    }
`

function SectionSelector(props){

    const dispatch = useDispatch();

    const selectedSection = useSelector((state) => state.selectedSection);
    // const chapters = useSelector((state) => state.chapters);

    const [toc, setTOC] = useState([])
    const [showMenu, setShowMenu] = useState(false)

    const accordionButtonsRef = useRef(null)
    const accordionPanelsRef = useRef(null)

    // useEffect(()=>{
    //     dispatch(setSelected(chapters[0]))
    // },[])

    useEffect(()=>{
        if(props.book.toc){
            let chapters = []
            props.book.toc.forEach(item=>{
                if(item.type === "chapter"){
                    chapters.push({...item})
                }
            })
            chapters.forEach((chapter, i)=>{
                chapter.order = i
            })
            setTOC(chapters)
            // props.setSelected(props.currentSection)
            dispatch(setSelected(chapters[0]))
        //   dispatch(setSelected(bookData.toc[0]))
          accordionButtonsRef.current = props.book.toc.map(()=>createRef())
          accordionPanelsRef.current = props.book.toc.map(()=>createRef())
        }
    },[props.book])

    const handleAccordion = (i) => {
        let button = accordionButtonsRef.current[i].current
        let panel = accordionPanelsRef.current[i].current
        if(panel.getAttribute('hidden') === ''){
            button.setAttribute('aria-expanded', 'true')
            panel.removeAttribute('hidden')
        }else{
            button.setAttribute('aria-expanded', 'false')
            panel.setAttribute('hidden', '')
        }
    }

    const handleMenu = () => {
        setShowMenu(!showMenu)
    }

    const handleSelect = (chapterOrPage) => {
        dispatch(setSelected(chapterOrPage))
        setShowMenu(false)
    }

    return(
        <SectionSelectorContainer>
        <CustomSelect>
            {/* <CustomSelectLabel>Where do you want to go?</CustomSelectLabel> */}
            <CustomSelectButton
                onClick={handleMenu}
            >
                <CustomSelectValue>{selectedSection.chapter ? selectedSection.chapter : selectedSection.name}</CustomSelectValue>
                <img src={arrow}/>
            </CustomSelectButton>
        </CustomSelect>
        {
            showMenu ? 
            <Accordion
                role="tree"
            >
                {
                // props.book.toc.length > 0 && props.book.toc ? props.book.toc.map((chapter,i)=>{
                   toc ? toc.map((chapter,i)=>{
                    return(
                    <AccordionGroup 
                        key={i}
                        role="treeitem"
                        // id={`accordion${i}id`}
                        aria-expanded="false"
                    >
                        <AccordionButton 
                            ref={accordionButtonsRef.current[i]}
                        >
                            {
                                props.showSections ? 
                                <button
                                    onClick={()=>{handleAccordion(i)}}
                                    aria-controls={`accordionpanel${i}id`}
                                >
                                    <svg focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" fill="black"></path></svg>
                                </button>
                                : null
                            }
                            
                            <button
                                 onClick={()=>{handleSelect(chapter)}}
                            >
                                <p>{chapter.name}</p>
                            </button>
                        </AccordionButton>
                        {
                            props.showSections ? 
                            <AccordionPanel 
                                    ref={accordionPanelsRef.current[i]}
                                    id={`accordionpanel${i}id`}
                                    role="region"
                                    aria-label={chapter.name}
                                    hidden
                                >
                                {
                                    chapter.pages ? chapter.pages.map((page, i)=>{
                                    return(
                                        <button
                                            key={i}
                                            className='section-button'
                                            onClick={()=>{handleSelect(page)}}
                                        >
                                            {page.name}
                                        </button>
                                    )
                                    }) : null
                                }
                                </AccordionPanel>
                                : null
                        }
                        
                    </AccordionGroup>
                    )
                })
                : null
                }
            </Accordion>
       : null
        }
      </SectionSelectorContainer>
    )


}

export default SectionSelector