import CHANNELS_URL from "./CHANNELS_URL"

export const getChannelTOCItem = async (channelId, sid) => {
    const resp = await fetch(`${CHANNELS_URL}/channel/${channelId}/${sid}`);
    // console.log(resp)
    if (resp.ok) {
        const json = await resp.json();
        return json
        // return resp
    }
}