import {useEffect, useState} from 'react'
import {connect} from "react-redux";

//images
import chevronRight from '../../images/icons/chevron-double-right.svg'
import chevronLeft from '../../images/icons/chevron-double-left.svg'
import bookmarkSolid from '../../images/icons/bookmark-solid.svg'
import recentIcon from "../../images/icons/recent-grey.svg"
import bookmarkIcon from "../../images/icons/bookmark-card.svg"

function TOC(props){

    const [tab, setTab] = useState('contents')
    // const [localPageState, setLocalPageState] = useState(props.page)

    // useEffect(()=>{
    //     setLocalPageState(props.page)
    //     console.log(props.page)
    // },[props.page])

    const handleRemove = () => {
        props.bookmarks.forEach(bookmark=>{
            props.removeBookmark(bookmark.page)
        })
    }

    const handleClearRecent = () => {
        props.clearRecent()
    }

    const timeDifference = (current, previous) => {

        var msPerMinute = 60 * 1000;
        var msPerHour = msPerMinute * 60;
        var msPerDay = msPerHour * 24;
        var msPerMonth = msPerDay * 30;
        var msPerYear = msPerDay * 365;

        var elapsed = current - previous;

        if (elapsed < msPerMinute) {
            return Math.round(elapsed/1000) + ' seconds ago';
        }

        else if (elapsed < msPerHour) {
            return Math.round(elapsed/msPerMinute) + ' minutes ago';
        }

        else if (elapsed < msPerDay ) {
            return Math.round(elapsed/msPerHour ) + ' hours ago';
        }

        else if (elapsed < msPerMonth) {
            return 'approximately ' + Math.round(elapsed/msPerDay) + ' days ago';
        }

        else if (elapsed < msPerYear) {
            return 'approximately ' + Math.round(elapsed/msPerMonth) + ' months ago';
        }

        else {
            return 'approximately ' + Math.round(elapsed/msPerYear ) + ' years ago';
        }
    }
  
    const expandItem = (e) => {
        let index = e.target.getAttribute('data-chapter')
        let menu = document.querySelector(`.menu-${index}`)
        menu.classList.contains('hidden') ? menu.classList.remove('hidden') : menu.classList.add('hidden')
        e.target.classList.contains('expanded') ? e.target.classList.remove('expanded') : e.target.classList.add('expanded')
    }
  
    const selectPage  = (id) => {
        // let number = Number(event.target.getAttribute('data-index'))
        props.loadPage(id)
    }

    const getActiveChapter = (chapter, index) => {
        if(props.book.chapters[index + 1]){
            let chapterPage = chapter.page
            let nextChapterPage = props.book.chapters[index + 1].page
            if(props.page >= chapterPage && props.page < nextChapterPage){
                return 'active'
            }
        }
    }

    // const getSections = (chapter, index) => {
    //     let newArr = []
    //     if(props.book.chapters[index + 1]){
    //         for(let i = chapter.page + 1; i < props.book.chapters[index + 1].page; i++){
    //             if(props.book.toc[i]){
    //                 newArr.push(
    //                     <li key={i} className={i === props.page ? 'active' : ''}>
    //                         <button data-index={i}
    //                                 id={props.book.toc[i].href.split('.')[0]}
    //                                 onClick={(e)=>{
    //                                     selectPage(e)
    //                                 }}
    //                                 >{props.book.toc[i].title}</button>
    //                     </li>
    //                 )
    //             }
    //         }
    //     }
    //     return newArr
    // }

    return (
        <>
        <div className={`toggle-tabs ${tab}`}>
            <button className={`contents ${tab === 'contents' ? 'active' : ''}`} onClick={()=>{setTab('contents')}}>Contents</button>
            <button className={`contents ${tab === 'bookmarks' ? 'active' : ''}`} onClick={()=>{setTab('bookmarks')}}>Bookmarks</button>
            <button  className={`contents ${tab === 'recent' ? 'active' : ''}`} onClick={()=>{setTab('recent')}}>Recent</button>
        </div>

         {
          tab === 'contents' && (props.book != null)? <>
          
          <div className='toc-list'>
            <div className="book-info">
                <img src={props.book.cover}/>
                <div>
                    <p>{props.book.title}</p>
                    <p>{props.book.author}</p>
                </div>
            </div>
            <ul className='chapters'>
                {
                    props.book.toc.map((chapter, index)=>{
                        return <li key={index}>
                                <div className={chapter.name === props.currentChapter ? 'active' : ''}>
                                {/* <div> */}
                                    <button data-chapter={index} onClick={(e)=>{
                                      expandItem(e)
                                      }}> </button>
                                    <button data-index={chapter.page}
                                            id={chapter.url}
                                            onClick={()=>{
                                                selectPage(chapter.url)
                                                // props.closeLeft()
                                            }}
                                    >
                                        <p>{chapter.name}</p>
                                    </button>
                                </div>
                                <ul className={`sections hidden menu-${index}`}>
                                    {
                                        chapter.pages ? chapter.pages.map((page, j)=>{
                                            return(
                                                <li key={j} className={page.url === props.page ? 'active' : ''}>
                                                {/* <li key={j}> */}
                                                <button data-index={j}
                                                        id={page.url}
                                                        onClick={()=>{
                                                            selectPage(page.url)
                                                        }}
                                                        >{page.name}</button>
                                            </li>
                                            )
                                        }) : null
                                    }
                                </ul>
                            </li>

                    })
                }
            </ul>

            </div> 

          </> : null
        }

        {
          tab === 'bookmarks' ? <>
            
            <div className='bookmark-list'>
                {
                    props.bookmarks.length > 0 ? 
                    <>
                        {
                            props.bookmarks.map((bookmark, index)=>{
                                return <div key={index}
                                            className='card bookmark-card'
                                            onClick={()=>{props.loadPage(bookmark.page)}}
                                        >
                                    {/* <div className='title'>
                                        <p>{bookmark.chapter}</p>
                                        <img src={bookmarkSolid}/>
                                    </div> */}
                                    <p>{bookmark.title}</p>
                                    <img src={bookmarkIcon}/>
                                </div>
                            })
                        }
                    </> : 
                    <div className='bookmarks-empty'>
                        <p className='title'>Keep important stuff handy</p>
                        <p>Find your bookmarks here and on the navigation bar.</p>
                    </div>
                }
            </div>
            {props.bookmarks.length > 0 ? <button className='remove-button link' onClick={handleRemove}>Remove all bookmarks</button> : null}

          </> : null
        }

        {
            tab === 'recent' ? <>
            <div className='recent-list'>
                 {
                    props.recent.length > 0 ? 
                    <>
                        {
                            props.recent.map((recent, index) => {
                                return <div key={index}
                                            className='card recent-card'
                                            onClick={()=>{props.loadPage(recent.page)}}
                                >
                                    {/* <p>{recent.chapter}</p> */}
                                    <p>{recent.title}</p>
                                    <div className='last'>
                                        <img src={recentIcon}/>
                                        <p>Last visited: </p>
                                        <p>{timeDifference(new Date(), recent.lastVisited)}</p>
                                    </div>
                                </div>
                            })
                        }
                         <button className='remove-button link' onClick={handleClearRecent}>Clear all recent locations</button>
                    </> : null
                    // <p>No recent pages saved</p>
                }
                </div>
            </> : null
        }
        </>
    )
}

const mapStateToProps = ({bookmarks, recent}) => ({
    bookmarks, recent
});

const mapDispatchToProps = dispatch => ({
    removeBookmark: (page) => dispatch({type: 'REMOVE_BOOKMARK',page:page}),
    clearRecent: () => dispatch({type:'CLEAR_RECENT'})
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(TOC);