import React, {useEffect, useState} from 'react';

// images
import dropdown from "../../images/icons/arrow-down.svg"
import sort from "../../images/icons/sort.svg"
import filter from "../../images/icons/filter.svg"
import more from "../../images/icons/more.svg"
import upvote from "../../images/icons/upvote.svg"
import downvote from "../../images/icons/downvote.svg"

function Posts(props) {

  return (
    <div className="right-drawer-panel posts">
        <h2>Community Posts</h2>
        <div class="tools">
            <button class="fake-select  ">
                <span class="label">All chapters</span>
                <img src={dropdown}/>
            </button>
            <button class="circlebtn ">
                    <img src={sort}/>
                </button>
                <button class="circlebtn ">
                    <img src={filter}/>
                </button>
        </div>

        <div className='card post-card'>
        <div className='header'>
            <div>
                <span className='usericon'>TA</span>
                <div>
                    <p className='username'>TA_132224 |</p>
                    <span className='timestamp'>1 month ago</span>
                </div>
            </div>
            <button>
                <img src={more}/>
            </button>
        </div>

        <div className='content'>
            <p className='highlight'>20: DNA tools and biotechnology</p>
        </div>


        <p className='title'>Practice question</p>

        <p className='post'>where can I find practice questions on this topic?</p>

        <div className='footer'>
           <button className='reply'>Reply</button>
            <div className='votes'>
                <button>
                   <img src={upvote}/>
                </button>
                <span>0</span>
                <button>
                    <img src={downvote}/>
                </button>
            </div>
        </div>
    </div>

    </div>
  );
}

export default Posts;