import React, {useEffect, useState, useRef, createRef} from 'react';
import SectionSelector from './SectionSelector';
import { GradientButton, SecondaryButton } from '../../global_styles/GlobalStyles';
import styled from 'styled-components';
import {colors} from '../../global_styles/colors'
import DonutChart from '../DonutChart';
import {shuffle} from "../../util/shuffle"
import { useSelector } from 'react-redux';
import { setTermsDeck } from '../../redux/termsDeckSlice';
import { setMCQDeck } from '../../redux/mcqDeckSlice';
import { useDispatch } from 'react-redux';
import { updateTermsState } from '../../redux/termsStateSlice';
import { updateMCQState } from '../../redux/mcqStateSlice';

//images
import chevron from '../../images/icons/chevron-gradient.svg'
import plus from "../../images/icons/plus-large.svg"

const DrawerContainer = styled.div`
  width:386px;
  height: calc(100% - 93px);
  overflow:auto;
  margin-left:30px;
  padding-right:16px;
`

const Card = styled.div`
  // height: 152px;
  width:100%;
  border-radius: 8px;
  border: 1px solid ${colors.borderlightThemeborder};
  background: ${colors.backgroundlightthemebglevel0};
  padding: 8px 16px 16px 16px;
  margin-top:8px;
  display:flex;
  flex-direction:column;
  justify-content: space-between;
`

const SectionHeader = styled.p`
  &&&{
    font-family: "TT Commons";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin:0;
  }
`

const CardAccordionButton = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-start;
  gap:8px;
  p{
    margin:0;
  }
  &.card-open img{
    transform: rotate(180deg);
  }
`

const CardAccordionPanel = styled.div`
  &[hidden]{
    display:none;
    height:0px;
  }
  p{
    margin:0;
    &.stats-heading{
      font-family: "TT Commons";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: 0.4px;
      margin-top:16px;
    }
  }
  .table{
    display:flex;
    align-items:center;
    justify-content:space-between;
    p{
      display:inlin-block;
    }
  } 
  .table p:first-of-type{
    text-align:left;
  }
  .table p:last-of-type{
    text-align:right;
  }
`

const CardFooter = styled.div`
  display:flex;
  align-items:center;
  justify-content:flex-end;
`

const CardButton = styled(GradientButton)`
  width:75px;
  min-width:unset;
  font-size: 16px;
`

const DeckContainer =  styled.div`
`

const OldCard = styled.button`
  width:100%;
  padding: 16px 21px 13px 25px;
  background: rgb(247, 249, 253);
  border: 1px solid rgb(232, 238, 250);
  display: flex;
  border-radius: 8px;
  margin-bottom:16px;
  flex-direction: column;
  &:hover{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 10px 2px;
  }
  p{
    font-size: 16px;
    word-break: break-word;
    font-family: Hind;
    font-weight: 600;
    line-height: 120%;
    margin:0;
    color: rgb(51, 51, 51);
    text-align:left;
  }
  span{
    display:block;
    font-size: 14px;
    font-family: Hind;
    font-weight: 400;
    line-height: 24px;
    margin-top: 4px;
    color: rgb(51, 51, 51);
  }
`

const Header = styled.div`
  display:flex;
  justify-content:space-between;
  align-items:center;
  margin-top:8px;
  &.border{
    border-top: 1px solid ${colors.borderlightThemeborder};
  }
`

const SectionAccordionButton = styled.div`
  display:flex;
  align-items:center;
  gap:8px;
  cursor:pointer;
  padding-top:16px;
  padding-bottom:16px;
  &[aria-expanded="true"] img{
    transform: rotate(180deg);
  }
`

const SectionPanel = styled.div`

`

const CreateButton = styled.button`
  border-radius: 24px;
  border: 1px solid #BCC1CB;
  background: #FEFEFE;
  display:flex;
  align-items:center;
  justify-content:center;
  width:40px;
  height:40px;
`

function FlashcardsDrawer(props) {

  const selectedSection = useSelector((state) => state.selectedSection);
  const chapters = useSelector((state) => state.chapters);
  const glossary = useSelector((state) => state.glossary);
  const termsDeck = useSelector((state) => state.termsDeck);
  const mcq_questions = useSelector((state) => state.mcq_questions);
  const mcqDeck = useSelector((state) => state.mcqDeck);

  const dispatch = useDispatch();

  const [showChart, setShowChart] = useState(true)

  // const accordionButtonsRef = useRef(null)
  // const accordionPanelsRef = useRef(null)

  const accordionButtonsRef = useRef([0, 1, 2].map(()=>createRef()))
  const accordionPanelsRef = useRef([0, 1, 2].map(()=>createRef()))

  useEffect(()=>{
      dispatch(setTermsDeck([]))
      dispatch(setMCQDeck([]))
      let newTermsDeck = [...termsDeck]
      let newMCQDeck = [...mcqDeck]
      glossary.forEach((item, i)=>{
          if(item.chapter && item.chapter === selectedSection.order){
              if(newTermsDeck.every(existingItem=>existingItem.front != item.front)){
                newTermsDeck = shuffle([...newTermsDeck, item])
              }
          }
      })
      mcq_questions.forEach((item, i)=>{
          if(item.chapter === selectedSection.order){
              if(newMCQDeck.every(existingItem=>existingItem.question != item.question)){
                // newMCQDeck = shuffle([...newMCQDeck, item])
                newMCQDeck = [...newMCQDeck, item]
              }
          }
      })
      dispatch(setTermsDeck(newTermsDeck))
      dispatch(setMCQDeck(newMCQDeck))
      dispatch(updateTermsState({notViewed: newTermsDeck.length}))
      dispatch(updateMCQState({notViewed: newMCQDeck.length}))
  },[selectedSection])

  const handleToggleChart = () => {
    setShowChart(!showChart)
  }

  const handleAccordion = (i) => {
    let button = accordionButtonsRef.current[i].current
    let panel = accordionPanelsRef.current[i].current
    if(panel.getAttribute('hidden') === ''){
        button.setAttribute('aria-expanded', 'true')
        panel.removeAttribute('hidden')
    }else{
        button.setAttribute('aria-expanded', 'false')
        panel.setAttribute('hidden', '')
    }
}

  return (
    <DrawerContainer>
      <SectionAccordionButton 
        ref={accordionButtonsRef.current[0]}
        onClick={()=>{handleAccordion(0)}}
        aria-expanded={true}
        >
          <img src={chevron}/>
          <SectionHeader>Pearson+ decks</SectionHeader>
      </SectionAccordionButton>
      
      <SectionPanel
        ref={accordionPanelsRef.current[0]}
      >
            <SectionSelector />
             <Card>
                {/* <CardAccordionButton
                    // className='card-open'
                    // onClick={handleToggleChart}
                >
                    <SectionHeader>Practice</SectionHeader>
                </CardAccordionButton> */}
                {/* {
                  showChart ?  */}
                  <CardAccordionPanel>
                    <DonutChart type="mcq" deck={mcqDeck ? mcqDeck : []}/>
                  </CardAccordionPanel>
                  {/* : null
                } */}
                <CardFooter>
                  <CardButton onClick={()=>{props.handleShowModule('mcq')}}>Begin</CardButton>
                </CardFooter>
            </Card>

            <Card>
                {/* <CardAccordionButton
                    className='card-open'
                    // onClick={handleToggleChart}
                >
                    <SectionHeader>Key terms</SectionHeader>
                </CardAccordionButton> */}
                {/* {
                  showChart ?  */}
                  <CardAccordionPanel>
                    <DonutChart type="terms" deck={termsDeck ? termsDeck : []}/>
                  </CardAccordionPanel>
                  {/* : null
                } */}
                <CardFooter>
                  <CardButton onClick={()=>{props.handleShowModule('terms')}}>Begin</CardButton>
                </CardFooter>
            </Card>
      </SectionPanel>

      <DeckContainer>
          <Header>
            <SectionAccordionButton 
              ref={accordionButtonsRef.current[1]}
              onClick={()=>{handleAccordion(1)}}
              aria-expanded={true}
              >
                <img src={chevron}/>
                <SectionHeader>Flashcards from AI Study Tool</SectionHeader>
            </SectionAccordionButton>
          </Header>
          <SectionPanel
            ref={accordionPanelsRef.current[1]}
          >
                add cards here
          </SectionPanel>
      </DeckContainer>

      <DeckContainer>
        <Header className='border'>
            <SectionAccordionButton 
              ref={accordionButtonsRef.current[2]}
              onClick={()=>{handleAccordion(2)}}
              aria-expanded={true}
              >
              <img src={chevron}/>
              <SectionHeader>My decks</SectionHeader>
            </SectionAccordionButton>
            <CreateButton>
                <img src={plus}/>
            </CreateButton>
        </Header>
        <SectionPanel
            ref={accordionPanelsRef.current[2]}
          >
            add cards here
        </SectionPanel>
      </DeckContainer>
    </DrawerContainer>
  );
}

export default FlashcardsDrawer;