import {useEffect, useState} from 'react'
import styled from 'styled-components';
import {colors, BAR_COLORS} from '../global_styles/colors'
import { useSelector } from 'react-redux';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const ChartContainer = styled.div`
    height:400px;
    margin-bottom:20px;
    margin-top:40px;
    p{
      font-family: "TT Commons";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 120% */
      letter-spacing: 0.4px;
    }
`
const TopValuesContainer = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width: 357px;
    margin-left: 10px;
    margin-bottom:4px;
`

const Value = styled.span`
  font-family: "TT Commons";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 120% */
  letter-spacing: 0.4px;
  color:${colors.textlightThemeDefault};
  text-align:center;
  width:23px;
  display:block;
`

const CustomXAxis = styled.div`
    display:flex;
    align-items:center;
    justify-content:space-between;
    width: 381px;
    margin-left: -2px;
    margin-top: 8px;
`

const KeyItemContainer = styled.div`
    display:flex;
    flex-direction:column;
    align-items:center;
    height:63px;
    width:48px;
    overflow:visible;
    position:relative;
    span{
      margin:0;
      font-family: "TT Commons";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      letter-spacing: 0.24px;
      color:${colors.textlightThemeDefault};
      // white-space:nowrap;
      text-align:center;
      margin-top:44px;
    }
`

const KeyItem = styled.div`
    width:32px;
    height:32px;
    flex-shrink:0;
    background-color:red;
    border-radius:32px;
    font-family: "TT Commons";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 16px; /* 114.286% */
    letter-spacing: 0.24px;
    color: ${colors.textlightThemeDefault};
    display:flex;
    align-items:center;
    justify-content:center;
    position:absolute;
    top:0;
    transition:top 100ms ease;
    &.added{
        top:-10px;
    }
`

const CustomBarChart = (props) => {

    const termsDeck = useSelector((state) => state.termsDeck);
    const termsState = useSelector((state) => state.termsState);

    const [data, setData] = useState([])

    useEffect(()=>{
        if(termsDeck && termsState){
          setData(
            [
              { name: '0', value: termsState.notViewed },
              { name: '1', value: termsState.rating1 },
              { name: '2', value: termsState.rating2 },
              { name: '3', value: termsState.rating3 },
              { name: '4', value: termsState.rating4 },
              { name: '5', value: termsState.memorized },
            ]
          )
        }
    },[termsDeck, termsState])

  //   const springProps = useSpring({
  //     transform: props.showModule ? 'scale(1)' : 'scale(0)',
  // });

    return(
        <ChartContainer>
            <p>Card distribution by confidence rating</p>
              <TopValuesContainer>
                  {
                    data.map((item, index)=>{
                      return(
                          <Value 
                            key={index}
                            style={{opacity: item.value === 0 ? '0.32' : '1'}}
                            >
                              {item.value
                          }</Value>
                      )
                    })
                  }
              </TopValuesContainer>
              <BarChart width={410} height={300} data={data} style={{marginLeft:'-16px'}}>
                  <YAxis domain={[0, termsDeck.length]} hide/>
                  <Bar 
                      dataKey="value"
                      fill="black"
                      radius={20}
                      barSize={12}
                      isAnimationActive={true}
                      animationDuration={2000}
                      background={{ fill: colors.backgroundlightthemebglevel2, radius: 20}}
                      >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={index === 5 ? BAR_COLORS[BAR_COLORS.length - 1] : colors.swatchSecondary700} />
                      ))}
                  </Bar>
              </BarChart>
              <CustomXAxis>
                      {
                        data.map((item, index)=>{
                          return (
                              <KeyItemContainer key={index}>
                              <KeyItem 
                                key={index}
                                className={props.animateScoreFast && props.score === Number(item.name) ? 'added' : ''}
                                style={{'backgroundColor': BAR_COLORS[index], opacity: item.value === 0 ? '0.32' : '1'}}
                              >
                                  {item.name}
                              </KeyItem>
                              {
                                    item.name === '0' ?
                                    <span>Not viewed</span>
                                    : null
                                }
                                {
                                    item.name === '1' ?
                                    <span>No clue</span>
                                    : null
                                }
                                {
                                    item.name === '5' ?
                                    <span>Learned</span>
                                    : null
                                }
                              </KeyItemContainer>
                          )
                        })
                      }
              </CustomXAxis>
        </ChartContainer>
    )
}

export default CustomBarChart