import React, {useEffect, useState, useRef} from 'react';
import {connect} from "react-redux";
import { useSpring, animated } from "@react-spring/web";

import back from "../images/icons/audio/rewind.svg"
import forward from "../images/icons/audio/forward.svg"
import pause from "../images/icons/audio/pause.svg"
import play from "../images/icons/audio/play.svg"
import settings from "../images/icons/audio/settings.svg"
import collapse from "../images/icons/collapse.svg"
import { times } from 'underscore';


function AudioPlayer(props) {

  const toMMSS = (time) => {
      var sec_num = parseInt(time, 10); // don't forget the second param
      var hours   = Math.floor(sec_num / 3600);
      var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
      var seconds = sec_num - (hours * 3600) - (minutes * 60);
  
      if (hours   < 10) {hours   = "0"+hours;}
      if (minutes < 10) {minutes = "0"+minutes;}
      if (seconds < 10) {seconds = "0"+seconds;}
      return minutes + ':' + seconds;
  }

  const springProps = useSpring({
    opacity: '1',
    zIndex:'1000',
    pointerEvents: props.showAudioPlayer ? 'unset' : 'none',
    display: props.showAudioPlayer ? 'flex': 'none',
  });

  return (
    <>

      <span className='timestamp' style={{opacity: props.showAudioTimeline ? '1' :'0'}}>
        <span>{toMMSS(props.ellapsedTime)}</span> / <span>{toMMSS(props.totalTime)}</span>
      </span>
      <button 
          className={`audio-button ${props.showAudioPlayer ? 'active' : ''} ${props.playlist.playing ? 'playing' : ''}`}
          // onClick={()=>{props.setShowAudioPlayer(!props.showAudioPlayer)}}
      >  
      </button>
      <animated.div style={springProps}>
      <div className='audio-player'>
            <button className="rewind">
                <img src={back}/>
            </button>
            <button 
              className='play-pause'
              onClick={(e)=>{
                e.stopPropagation()
                props.setPlaying(!props.playlist.playing)
                if(props.audioFirstClick != false){
                  props.setAudioFirstClick(true)
                }
                setTimeout(()=>{
                  if(!props.playlist.playing){
                    props.adjustSlider()
                  }
                },300)
              }}
              data-tooltip-content={`${props.playlist.playing === true ? 'Pause audio' : 'Play audio'}`}
              >
                <img src={props.playlist.playing === true ? pause : play}/>
            </button>
            <button className="forward">
                <img src={forward}/>
            </button>
            {/* <button 
              ref={minimizeButtonRef}
              className='minimize'
              onClick={props.handleCloseAudioPlayer}
              >
                <img src={collapse}/>
            </button> */}
            <button className='audio-settings settings'>
                <img src={settings}/>
            </button>
      </div>
    </animated.div>
    </>
  );
}

const mapStateToProps = ({playlist}) => ({
    playlist
  });
  
  const mapDispatchToProps = dispatch => ({
    setPlaying: (data) => dispatch({type: 'SET_PLAYING', data:data}),
    setPosition: (pos) => dispatch({type: 'SET_POSITION', pos:pos}),
    setCurrent: (current) => dispatch({type: 'SET_CURRENT', current:current}),
    setTime: (time) => dispatch({type: 'SET_TIME', time:time}),
    setRemaining: (remaining) => dispatch({type: 'SET_REMAINING', remaining:remaining}),
  });
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AudioPlayer);