import React, {useEffect, useState, useRef} from 'react';
import AWS from 'aws-sdk'
import { useSpring, animated } from "@react-spring/web";
import useWindowResize from '../util/useWindowResize'
import {connect} from "react-redux";
import AudioPlayer from './AudioPlayer';
import AudioTimeline from './AudioTimeline';
import { useSelector } from 'react-redux';
import {setPlaying, setPosition, setCurrent, setTime, setRemaining} from '../redux/playlistSlice'


//images
import arrowLeft from '../images/icons/chevron-left.svg'
import arrowRight from '../images/icons/chevron-right.svg'
import arrowDown from '../images/icons/arrow-down.svg'
// import downArrow from "../images/icons/arrow-down.svg"
import bookmarkOutline from '../images/icons/bookmark-outline.svg'
import bookmarkSolid from '../images/icons/bookmark-solid.svg'
import audio from "../images/icons/audio.svg"
import playing from "../images/gifs/audio-small-reverse.gif"

//for go to page
let typingTimer;

function Footer(props){

    const playlist = useSelector((state) => state.playlist);

    const [width, height] = useWindowResize()
    const [chapter, setChapter] = useState('test chapter name')
    const [bookmarkActive, setBookmarkActive] = useState(false)
    const [showAudioPlayer, setShowAudioPlayer] = useState(false)
    const [showAudioTimeline, setShowAudioTimeline] = useState(false)
    const [ellapsedTime, setEllapsedTime] = useState(playlist.time)
    const [remainingTime, setRemainingTime] = useState(playlist.remaining)
    const [totalTime, setTotalTime] = useState(600)
    const rangeRef = useRef(null)
    let playbackSpeed = 1;

    const goToPageRef = useRef(null)
    const carouselRef = useRef(null)

    const [audioFirstClick, setAudioFirstClick] = useState(null)

    useEffect(() => {
        let sliderInterval;
        if (playlist.playing === true) {
            if(ellapsedTime < totalTime){
                sliderInterval = setInterval(() => {
                    let newTime;
                    setEllapsedTime(prevState => {
                        newTime = prevState + 1
                        return prevState + 1
                    })
                    setRemainingTime(totalTime - newTime)
                    setRemaining(totalTime - newTime)
                    setEllapsedTime(newTime)
                    // console.log(newTime)
                    setPosition((newTime * 100) / totalTime)
                    setTime(newTime)
                    const root = document.querySelector(":root");
                    root.style.setProperty("--track-width", `${((newTime * 100) / totalTime)}%`);
                    adjustSlider()
                }, 1000 / playbackSpeed);
            }
            else{
                setPlaying(false)
            }
            return () => clearInterval(sliderInterval);
        }
    }, [playlist.playing, ellapsedTime])

    const changePageInput = (e) =>{
        let newPage = parseInt(goToPageRef.current.value)
        // if(newPage) {
        //     if(newPage !== 0 && newPage < props.lastPage) {
                props.loadPage(newPage)
        //     }
        // }
    }

    const handleSlider = (newTime) => {
        let timeNum = parseInt(newTime)
        setEllapsedTime(timeNum)
        const root = document.querySelector(":root");
        root.style.setProperty("--track-width", `${(timeNum * 100) / totalTime}%`);
        if (timeNum < totalTime && timeNum > 1) {
            setPosition((timeNum * 100) / totalTime)
            // props.setCurrent(currentTrack)
            setTime(timeNum)
            setEllapsedTime(timeNum)
            setRemainingTime(totalTime - timeNum)
            setRemaining(totalTime - timeNum)
        }
    }

    const adjustSlider = () => {
        let slider = rangeRef.current
        if(slider){
            const min = slider.min;
            const max = slider.max;
            // Calculate visible width
            const val = ((slider.value - min) * 100) / (max - min);
            
            // Change these variables to the colors you need
            const fillLeft = "#1F897C"
            const fillRight = "#B2B6BF";
            
            slider.style.background = `linear-gradient(to right, ${fillLeft} ${val}%, ${fillRight} ${val}%`;
        }
      };

    useEffect(()=>{
        if(!playlist.playing && ellapsedTime < 1){
            setShowAudioTimeline(false)
        }else if(playlist.playing){
            setShowAudioTimeline(true)
        }
    },[playlist.playing])

    useEffect(()=>{
        if(showAudioPlayer === false && playlist.playing != true){
            setShowAudioTimeline(false)
        }else{
            if(audioFirstClick != null){
            setShowAudioTimeline(true)
            }
        }
    },[showAudioPlayer])

    useEffect(()=>{
        adjustSlider()
    },[showAudioTimeline])

    return(
        <footer >  
            <div className='inner-wrapper'>
                    {/* <div className="white-sliver"></div> */}
                    <div className='audio-container'>
                        <AudioPlayer
                            totalTime={totalTime}
                            ellapsedTime={ellapsedTime}
                            showAudioPlayer={showAudioPlayer}
                            setShowAudioPlayer={setShowAudioPlayer}
                            adjustSlider={adjustSlider}
                            audioFirstClick={audioFirstClick}
                            setAudioFirstClick={setAudioFirstClick}
                            showAudioTimeline={showAudioTimeline}
                        /> 
                    </div>

                    
                    <div className='go-to-page'>
                        <button 
                            className="previous"
                            // disabled={props.page === 1 ? true : false}
                            // onClick={(e)=>{
                            //     e.stopPropagation();
                            //     props.loadPage(props.page - 1)
                            //     props.setPlaying(false)
                            //     props.setTime(0)
                            //     setEllapsedTime(0)
                            //     setRemainingTime(totalTime)
                            //     setShowAudioTimeline(false)
                            //     setAudioFirstClick(null)
                            // }}
                            >
                            <img src={arrowLeft}/>
                        </button>
                        <div className='go-to-page-container'>
                            <span>Page</span>
                            <div className="input-container">
                                <input 
                                    value={3}
                                    type="number"
                                    defaultValue={props.page}
                                    ref={goToPageRef}
                                    // onKeyDown={(e)=>{
                                    //     e.stopPropagation();
                                    //     clearTimeout(typingTimer);
                                    // }}
                                    // onKeyUp={(e)=>{
                                    //     e.stopPropagation();
                                    //     clearTimeout(typingTimer);
                                    //     typingTimer = setTimeout(changePageInput, 1000);
                                    // }}
                                    // onClick={(e)=>{e.stopPropagation()}}
                                />
                            </div>
                            {/* <span>of {props.lastPage}</span> */}
                            <span>of 358</span>
                        </div>
                        <button 
                            className='next'
                            // disabled={props.page === props.lastPage ? true : false}
                            // onClick={(e)=>{
                            //     e.stopPropagation();
                            //     props.loadPage(props.page + 1)
                            //     props.setPlaying(false)
                            //     props.setTime(0)
                            //     setEllapsedTime(0)
                            //     setRemainingTime(totalTime)
                            //     setShowAudioTimeline(false)
                            //     setAudioFirstClick(null)
                            // }}
                            >
                            <img src={arrowRight}/>
                        </button>
                        <div className='bookmark-button-container'>
                            <button className={`bookmark-button ${bookmarkActive === true ? 'active' : ''}`}
                                    // onClick={(e)=>{
                                    //     // e.stopPropagation();
                                    //     if(bookmarkActive === true){
                                    //         props.removeBookmark(props.page)
                                    //     }else{
                                    //         props.addBookmark(props.page, props.currentChapter, props.book.toc[props.page].title)
                                    //     }
                                    // }}
                            > 
                                <img src={`${bookmarkActive === true ? bookmarkSolid : bookmarkOutline}`}/>
                            </button>
                            {/* <button 
                                className="all-bookmarks"
                                onClick={(e)=>{
                                    // e.stopPropagation();
                                    // bookmarkMenuToggle()
                                }}>
                                <img src={arrowDown}/>
                            </button> */}
                        </div>
                    </div>

                    {/* <div className={`zen-toggle ${props.drawerViewState.showLeftDrawer === false ? 'more-left' : ''}`}>
                            <label htmlFor='zen-toggle'>Focus</label>
                            <button
                                id="zen-toggle"
                                className={`toggle ${props.cleanMode ? 'on' : ''}`}
                                role="switch"
                                aria-checked='true'
                                onClick={props.cleanModeToggle}
                            >
                            </button>
                    </div> */}

                    {
                        showAudioTimeline ? 
                        <AudioTimeline
                            totalTime={totalTime}
                            ellapsedTime={ellapsedTime}
                            handleSlider={handleSlider}
                            adjustSlider={adjustSlider}
                            rangeRef={rangeRef}
                        /> 
                        : null
                    }
                    </div>
                </footer>
    )

}

export default Footer