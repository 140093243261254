import { Tooltip } from 'react-tooltip'

//images
import bookmarkIconWhite from "../images/icons/bookmark-outline-white.svg"

function Tooltips(props){
    return (
        <>

            {/* new */}
            <Tooltip anchorSelect="#ai-button" place="top" className='custom-tooltip'>
                AI Study Tool
            </Tooltip>
            <Tooltip anchorSelect=".ai-thumbs-up" place="top" className='custom-tooltip'>
                Report as positive
            </Tooltip>
            <Tooltip anchorSelect=".ai-thumbs-down" place="top" className='custom-tooltip'>
                Report as negative
            </Tooltip>

            {/* <Tooltip anchorSelect="#reader-note-icon-container" place="bottom" className='custom-tooltip'>
                Open note
            </Tooltip> */}

            {/* Toolbar */}
            <Tooltip anchorSelect=".tools-btn.home" place="right" className='custom-tooltip'>
                Home
            </Tooltip>
            <Tooltip anchorSelect=".aiBtn" place="right" className='custom-tooltip'>
                AI Study Tool
            </Tooltip>
            <Tooltip anchorSelect=".tocBtn" place="right" className='custom-tooltip'>
                Table of Contents
            </Tooltip>
            <Tooltip anchorSelect=".scrubber-btn" place="right" className='custom-tooltip'>
                Navigation
            </Tooltip>
            <Tooltip anchorSelect=".searchBtn" place="right" className='custom-tooltip'>
                Search
            </Tooltip>
            <Tooltip anchorSelect=".notebookBtn" place="right" className='custom-tooltip'>
                Notebook
            </Tooltip>
            <Tooltip anchorSelect=".studyBtn" place="right" className='custom-tooltip'>
                Flashcards
            </Tooltip>
            <Tooltip anchorSelect=".channelsBtn" place="right" className='custom-tooltip'>
                Channels - Videos related to this section
            </Tooltip>
            <Tooltip anchorSelect=".postsBtn" place="right" className='custom-tooltip'>
                Community posts
            </Tooltip>
            <Tooltip anchorSelect=".settingsBtn" place="right" className='custom-tooltip'>
                Display settings
            </Tooltip>
            <Tooltip 
                anchorSelect=".show-hide-toolbar"
                place="right"
                className='custom-tooltip'
                render={({ content, activeAnchor }) => (
                    <span>{content}</span>
                  )}
            />

            {/* Reader */}
            <Tooltip anchorSelect=".bookmark-button" place="top" className='custom-tooltip'>
                Add bookmark
            </Tooltip>
            <Tooltip anchorSelect=".previous" place="top" className='custom-tooltip'>
               {props.prevPage}
            </Tooltip>
            <Tooltip anchorSelect=".next" place="top" className='custom-tooltip'>
                {props.nextPage}
            </Tooltip>

            {/* Scrubber */}
            <Tooltip 
                anchorSelect=".MuiSlider-thumb"
                place="right"
                className='custom-tooltip'
                render={({ content, activeAnchor }) => (
                    <span>{content}</span>
                )}
                >
            </Tooltip>
            
            <Tooltip 
                anchorSelect=".bookmark"
                place="right"
                className='custom-tooltip bookmark'
                render={({ content, activeAnchor }) => (
                    <span><img src={bookmarkIconWhite}/>{content}</span>
                )}
            >
            </Tooltip>

            <Tooltip
                anchorSelect=".history"
                place="right"
                className='custom-tooltip'
                render={({ content, activeAnchor }) => (
                    <span>{content}</span>
                )}
                >
            </Tooltip>

            {/* LeftDrawer */}
            <Tooltip anchorSelect=".left-drawer .close" place="bottom" className='custom-tooltip'>
                Close
            </Tooltip>
            {/* <Tooltip 
                anchorSelect=".left-drawer .expand-collapse"
                place="bottom"
                className='custom-tooltip'
                render={({ content, activeAnchor }) => (
                    <span>{content}</span>
                )}
                >
            </Tooltip> */}

            {/* Playlist */}
            <Tooltip anchorSelect=".audio-button" place="top" className='custom-tooltip'>
                Audiobook
            </Tooltip>
            <Tooltip 
                anchorSelect=".play-pause"
                place="bottom"
                className='custom-tooltip'
                render={({ content, activeAnchor }) => (
                    <span>{content}</span>
                )}
                >
            </Tooltip>
            <Tooltip anchorSelect=".rewind" place="top" className='custom-tooltip'>
                Rewind
            </Tooltip>
            <Tooltip anchorSelect=".forward" place="top" className='custom-tooltip'>
                Forward
            </Tooltip>
            <Tooltip anchorSelect=".audio-settings" place="top" className='custom-tooltip'>
                Audiobook settings
            </Tooltip>
            {/* <Tooltip anchorSelect=".audio-playlist .settings" place="bottom" className='custom-tooltip'>
                Audiobook settings
            </Tooltip>
            <Tooltip anchorSelect=".slider-container" place="bottom" className='custom-tooltip'>
                Audio timeline
            </Tooltip> */}

            {/* Notebook */}
            <Tooltip anchorSelect=".note-tools-btn.export" place="bottom" className='custom-tooltip'>
                Export notes
            </Tooltip>
            <Tooltip anchorSelect=".note-tools-btn.new" place="bottom" className='custom-tooltip'>
                Create note
            </Tooltip>
        </>
    )
}

export default Tooltips