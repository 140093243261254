import styled from 'styled-components';

//images
import close from "../../images/icons/close-large.svg"

const HeaderContainer = styled.div`
    margin-bottom:24px;
    span{
        span{
            font-size:0.8em;
            font-weight:400;
        }
        h1{
            text-align:left;
            line-height: 100%;
            font-size: 1em;
            max-width: 660px;
            transition: all 0.5s ease;
        }
    }
    
`

const HeaderTop = styled.div`
    display:flex;
    align-items:flex-start;
    gap:24px;
`

const Count = styled.p`
    font-family: "TT Commons";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    letter-spacing: 0.4px;
    text-align:center;
    margin:0;
`

const CloseButton = styled.button`
    width:40px;
    height:40px;
    position:absolute;
    right:24px;
    top: 27px;
    display:flex;
    align-items:center;
    justify-content:center;
`

function Header(props){

    return(
        <HeaderContainer>
            <HeaderTop>
                <span ref={props.headerTitleRef}>
                    <span>{props.experienceType === 'terms' ? 'Key terms' : 'Concept check'}</span>
                    <h1>
                        {props.chapter}
                    </h1>
                </span>
                <CloseButton
                    ref={props.headerCloseBtnRef}
                    onClick={props.handleHideModule}
                >
                    <img src={close}/>
                </CloseButton>
            </HeaderTop>
            {/* {
                props.experienceType === 'terms' ?
                <Count>{props.currentCard} of {props.count}</Count>
                : null
            } */}
            
        </HeaderContainer>
    )
}

export default Header