import CHANNELS_URL from "./CHANNELS_URL"

export const getAssetDetails = async (assetId) => {
    const resp = await fetch(`${CHANNELS_URL}/asset/${assetId}`);
    // console.log(resp)
    if (resp.ok) {
        const json = await resp.json();
        return json
        // return resp
    }
}