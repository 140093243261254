import {useState} from 'react'

let AUTH_HOME_URL;
if (process.env.NODE_ENV === 'development') {
  AUTH_HOME_URL = 'http://localhost:3000/'
} else {
  AUTH_HOME_URL = 'https://pearsonct.design/'
}

function Toolbar(props){

    // const [expanded, setExpanded] = useState(true)

    // const toggleExpanded = () => {
    //     setExpanded(!expanded)
    // }

    const handleToggleDrawer = (tab) => {
        props.setTabShowing(tab)
        if(props.showLeftDrawer === false){
            props.openLeft()
        }else if(props.showLeftDrawer && props.tabShowing === tab){
            props.closeLeft()
        }
       
    }

    if(!props.cleanMode){
        return(
            <div className={`toolbar expanded`}>
                {/* <a
                    href={AUTH_HOME_URL + 'apps/pplus-home-with-channels-assets'}
                > */}
                    <button className={`tools-btn home`} onClick={props.toggleLeft}></button>
                {/* </a> */}
                <span className='divider'></span>
                {/* {
                    props.toolbarExpanded ?  */}
                    <>
                         <button
                            className={`aiBtn tools-btn`}
                            // onClick={()=>{ handleToggleDrawer('Table of Contents') }}
                            >
                        </button>
                        <button
                            className={`tocBtn tools-btn ${props.tabShowing === 'Table of Contents' ? 'active' : ''}`}
                            // onClick={()=>{ handleToggleDrawer('Table of Contents') }}
                            >
                        </button>
                        <button
                            className={`channelsBtn tools-btn ${props.tabShowing === 'Channels Miniplayer' ? 'active' : ''}`}
                            // onClick={()=>{ handleToggleDrawer('Channels Miniplayer') }}
                            >
                                {
                                    props.tabShowing === 'Channels Miniplayer' ? 
                                    <svg class="MuiSvgIcon-root-14995 jss14878" focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="24" height="24" fill="none" name="Channel"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.2727 0H2.72727C1.22104 0 0 1.04661 0 2.33766V18.2338C0 19.5248 1.22104 20.5714 2.72727 20.5714H21.2727C22.779 20.5714 24 19.5248 24 18.2338V2.33766C24 1.04661 22.779 0 21.2727 0ZM2.05714 2.52429C2.05714 2.26607 2.27018 2.05714 2.57143 2.05714H21.4286C21.7298 2.05714 21.9429 2.26607 21.9429 2.52429V18.0471C21.9429 18.3054 21.7298 18.5143 21.4286 18.5143H2.57143C2.27018 18.5143 2.05714 18.3054 2.05714 18.0471V2.52429Z" fill="white"></path><path d="M10.2857 18.5143H13.6607L13.7143 24H10.2857V18.5143Z" fill="white"></path><path d="M5.14286 22.9714C5.14286 22.4034 5.60336 21.9429 6.17143 21.9429H17.8286C18.3966 21.9429 18.8571 22.4034 18.8571 22.9714C18.8571 23.5395 18.3966 24 17.8286 24H6.17143C5.60336 24 5.14286 23.5395 5.14286 22.9714Z" fill="white"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.57143 6.19981C8.57143 6.08189 8.60528 5.96635 8.66912 5.86641C8.85699 5.5723 9.25254 5.48314 9.55262 5.66727L15.8644 9.5402C15.9795 9.61084 16.0768 9.70617 16.1488 9.819C16.4118 10.2308 16.2845 10.7735 15.8644 11.0313L9.55262 14.9042C9.45065 14.9668 9.33277 15 9.21246 15C8.85843 15 8.57143 14.7187 8.57143 14.3717V6.19981Z" fill="white"></path></svg>
                                    :
                                    <svg class="MuiSvgIcon-root-14995 jss14878" focusable="false" viewBox="0 0 24 24" aria-hidden="true" width="24" height="24" fill="none" name="Channel"><path fill-rule="evenodd" clip-rule="evenodd" d="M21.2727 0H2.72727C1.22104 0 0 1.04661 0 2.33766V18.2338C0 19.5248 1.22104 20.5714 2.72727 20.5714H21.2727C22.779 20.5714 24 19.5248 24 18.2338V2.33766C24 1.04661 22.779 0 21.2727 0ZM2.05714 2.52429C2.05714 2.26607 2.27018 2.05714 2.57143 2.05714H21.4286C21.7298 2.05714 21.9429 2.26607 21.9429 2.52429V18.0471C21.9429 18.3054 21.7298 18.5143 21.4286 18.5143H2.57143C2.27018 18.5143 2.05714 18.3054 2.05714 18.0471V2.52429Z" fill="currentColor"></path><path d="M10.2857 18.5143H13.6607L13.7143 24H10.2857V18.5143Z" fill="currentColor"></path><path d="M5.14286 22.9714C5.14286 22.4034 5.60336 21.9429 6.17143 21.9429H17.8286C18.3966 21.9429 18.8571 22.4034 18.8571 22.9714C18.8571 23.5395 18.3966 24 17.8286 24H6.17143C5.60336 24 5.14286 23.5395 5.14286 22.9714Z" fill="currentColor"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.57143 6.19981C8.57143 6.08189 8.60528 5.96635 8.66912 5.86641C8.85699 5.5723 9.25254 5.48314 9.55262 5.66727L15.8644 9.5402C15.9795 9.61084 16.0768 9.70617 16.1488 9.819C16.4118 10.2308 16.2845 10.7735 15.8644 11.0313L9.55262 14.9042C9.45065 14.9668 9.33277 15 9.21246 15C8.85843 15 8.57143 14.7187 8.57143 14.3717V6.19981Z" fill="currentColor"></path></svg>
                                }
                        </button>
                        {/* <button
                            className={`scrubber-btn tools-btn ${props.tabShowing === 'Scrubber' ? 'active' : ''}`}
                            onClick={()=>{ handleToggleDrawer('Scrubber') }}>
                        </button> */}
                        <button
                            className={`searchBtn tools-btn ${props.tabShowing === 'Search' ? 'active' : ''}`}
                            onClick={()=>{ handleToggleDrawer('Search') }}>
                        </button>
                        <span className='divider'></span>
                        <button
                             className={`notebookBtn tools-btn ${props.tabShowing === 'Notebook' ? 'active' : ''}`}
                            //  onClick={()=>{ handleToggleDrawer('Notebook') }}
                             >
                        </button>
                        <button
                            className={`studyBtn tools-btn ${props.tabShowing === 'Flashcards' ? 'active' : ''}`}
                            // onClick={()=>{ handleToggleDrawer('Flashcards') }}
                            >
                        </button>
                        {/* <button
                            className={`postsBtn tools-btn ${props.tabShowing === 'Community Posts' ? 'active' : ''}`}
                            onClick={()=>{ handleToggleDrawer('Community Posts') }}>
                        </button> */}
                        <span className='divider'></span>
                        <button
                            className={`settingsBtn tools-btn ${props.tabShowing === 'Display settings' ? 'active' : ''}`}
                            // onClick={()=>{ handleToggleDrawer('Display settings') }}
                            >
                        </button>
                    </> 
                    {/* : null
                } */}
                {/* <button 
                    className={`tools-btn show-hide-toolbar ${props.toolbarExpanded === true ? 'close' : 'more'}`}
                    onClick={()=>{
                        props.toggleToolbarExpanded()
                        props.closeLeft()
                        props.setTabShowing('')
                    }}
                    data-tooltip-content={`${props.toolbarExpanded === true ? 'Hide Menu Options' : 'Expand menu Options'}`}
                    >
                </button> */}
            </div>
        )
    }else{
        return <></>
    }
    
}

export default Toolbar