import { createSlice } from '@reduxjs/toolkit';
import {mcq_questions} from "../data/mcq_questions"

const mcqDataSlice = createSlice({
  name: "mcq_questions",
  initialState: mcq_questions,
  reducers: {

  }
});

export default mcqDataSlice.reducer;