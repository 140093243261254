import { createSlice } from '@reduxjs/toolkit';
import {glossary} from "../data/glossary"

const glossarySlice = createSlice({
  name: "glossary",
  initialState: glossary,
  reducers: {

  }
});

export default glossarySlice.reducer;