import {configureStore} from '@reduxjs/toolkit';

import glossaryReducer from "./glossarySlice"
import chaptersReducer from './chaptersSlice'
import selectedSectionReducer from './selectedSectionSlice'
import termsDeckReducer from './termsDeckSlice'
import termsStateReducer from './termsStateSlice';
import mcqDeckReducer from "./mcqDeckSlice"
import mcqDataReducer from './mcqDataSlice';
import mcqStateReducer from './mcqStateSlice';
import playlistReducer from './playlistSlice';


const store = configureStore({
    reducer: {
        glossary: glossaryReducer,
        chapters: chaptersReducer,
        selectedSection: selectedSectionReducer,
        termsDeck: termsDeckReducer,
        termsState: termsStateReducer,
        mcqDeck:mcqDeckReducer,
        mcq_questions: mcqDataReducer,
        mcqState: mcqStateReducer,
        playlist: playlistReducer
    }
});
export default store;