import AWS from 'aws-sdk'

const spacesEndpoint = new AWS.Endpoint('nyc3.digitaloceanspaces.com');
const s3 = new AWS.S3({
    endpoint: spacesEndpoint,
    accessKeyId: 'DO00G7FHTCCRD4RWUXE4',
    secretAccessKey: 'caC2B2nkRiaxP79k0cgGTMrv6B2wkOGzP30h35NZowQ'
});

export const getOnePage = async (bookId, pageUrl) => {
    
    let url = s3.getSignedUrl('getObject', {
        Bucket: 'books-api',
        Key: `${bookId}/OPS/xhtml/${pageUrl}`
    })

    let response = await fetch(url)
    .then(resp=>resp.text())
    .then(data=>{
       return data
    })

    return response
   
}