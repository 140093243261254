import { createSlice, current } from '@reduxjs/toolkit';

const termsStateSlice = createSlice({
  name: "termsState",
  initialState: {
    notViewed: 0,
    viewed: 0,
    memorized: 0,
    rating1: 0,
    rating2: 0,
    rating3: 0,
    rating4: 0
  },
  reducers: {
    updateTermsState: (state, action) => {
      if(action.payload.notViewed){
        state.notViewed = action.payload.notViewed
      }
      // if(action.payload.viewed){
      //   state.viewed = action.payload.viewed
      // }
      if(action.payload.memorized || action.payload.memorized === 0){
        state.memorized = action.payload.memorized
      }
      if(action.payload.rating1 || action.payload.rating1 === 0){
        state.rating1 = action.payload.rating1
      }
      if(action.payload.rating2 || action.payload.rating2 === 0){
        state.rating2 = action.payload.rating2
      }
      if(action.payload.rating3 || action.payload.rating3 === 0){
        state.rating3 = action.payload.rating3
      }
      if(action.payload.rating4 || action.payload.rating4 === 0){
        state.rating4 = action.payload.rating4
      }
      // console.log(current(state));
      return state;
    },
  }
});

export const { updateTermsState } = termsStateSlice.actions;

export default termsStateSlice.reducer;