import {useEffect, useState, useRef} from 'react'
import styled from 'styled-components';
import {colors} from '../../global_styles/colors'
import {OutlineButton, PrimaryButton} from '../../global_styles/GlobalStyles'
import { useSpring, animated } from "@react-spring/web";
import useWindowResize from '../../util/useWindowResize'

//images
import thumbsUp from "../../images/icons/thumb-up.svg"
import thumbsDown from "../../images/icons/thumb-down.svg"

const Card = styled.div`
    max-width: 672px;
    min-height: 368px;
    padding: 16px;
    border-radius: 12px;
    background: ${colors.backgroundlightthemebglevel2};
    transition: width 0.5s ease;
    width: 100%;
    overflow: hidden scroll;
    position:relative;
    display:flex;
    flex-direction:column;
    margin: 0 auto;
    margin-bottom:24px;
    
`

const CardSection = styled.div`
    transition: all 0.5s ease;
`

const CardSectionFront = styled(CardSection)`
    // background:red;
    padding-bottom:16px;
    flex:2;
    display:flex;
    flex-direction:column;
    justify-content:center;
    &.answer-revealed{
        flex:0.5;
    }
`

const CardSectionBack = styled(CardSection)`
    // background:blue;
    flex:0;
    display: flex;
    align-items: center;
    &.answer-revealed{
        flex:1.5;
        padding:16px 0;
        border-top:2px solid ${colors.borderdarkThemeborder};
    }
`

const CardText = styled(animated.p)`
    font-family: "TT Commons";  
    transition: all 0.5s ease;  
    text-align:center;
    width:100%;
    // overflow:hidden;
`

const CardTextFront = styled(CardText)`
    font-size: 1em;
    font-weight: 700
`

const CardTextBack = styled(CardText)`
    font-size: 0.7em;
    font-weight: 400;
    line-height: 150%;
`

const CardFooter = styled.div`
left:0;
width:100%;
display:flex;
align-items:center;
justify-content:center;

`
const CardFooterButtons = styled.div`
    display:flex;
    gap:16px;
    width:100%;
    justify-content:center;
`

const SkipButton = styled(OutlineButton)`
    width:100px;
`

const SubmitButton = styled(PrimaryButton)`
    margin:0 auto;
`

const Credit = styled.div`
    display:flex;
    width:143px;
    position:absolute;
    right:16px;
    bottom:24px;
    gap:8px;
    align-items:center;
    opacity:0;
    &.answer-revealed{
        opacity:1;
    }
    p{
        font-family: "TT Commons";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 114.286% */
        letter-spacing: 0.24px;
        margin:0;
    }
    button{
        width:24px;
        height:24px;
        display:flex;
        align-items:center;
        justify-content:center;
    }
`

function Flashcard(props){

    const [width, height] = useWindowResize()

    const [showDefinition, setShowDefinition] = useState(false)

    const cardFooterRef = useRef(null)

    useEffect(()=>{
        if(cardFooterRef.current){
            if(window.innerWidth >= 638 || window.innerWidth <= 638 && !showDefinition){
                cardFooterRef.current.querySelector('button').style.margin = '0 auto'
                cardFooterRef.current.querySelector('div').style.position = 'absolute'
                cardFooterRef.current.style.justifyContent = 'center'
            }
            if(window.innerWidth <= 638){
                if(showDefinition){
                    cardFooterRef.current.querySelector('button').style.margin = 'unset'
                    cardFooterRef.current.querySelector('div').style.position = 'unset'
                    cardFooterRef.current.style.justifyContent = 'space-between'
                }
            }
        }   
    },[width, props.drawerOpen, showDefinition])


    // useEffect(()=>{
    //     if(cardFooterRef.current){
    //         let container = cardFooterRef.current
    //         let credit = container.querySelector('.credit')
    //         if(window.innerWidth >= 638){
    //             if(!showDefinition){
    //                 container.style.flexDirection = 'row'
    //                 // container.style.justifyContent = 'space-between'
    //                 if(credit){
    //                     credit.style.position = 'relative'
    //                     credit.style.right = 'unset'
    //                 }
    //             }else{
    //                 // container.style.justifyContent = 'center'
    //                 credit.style.position = 'absolute'
    //                 credit.style.right = '16px'
    //             }
    //         }
    //         if(window.innerWidth <= 638){
    //             if(showDefinition){
    //                 container.style.flexDirection = 'column'
    //             }
    //         }
    //     }   
    // },[width, showDefinition])

    useEffect(()=>{
        handleHide()
    },[props.currentCard])

    const handleReveal = () => {
        setShowDefinition(true)
        props.handleReveal()
    }

    const handleHide = () => {
        setShowDefinition(false)
        props.handleHide()
    }

    const springProps = useSpring({
        opacity: showDefinition ? '1' : '0',
        maxHeight: showDefinition ? '500px' : '0px'
    });

    return(
        <>
            {
                props.currentCard === props.index ? 
                <Card>
                    <CardSectionFront 
                        className={showDefinition ? 'answer-revealed' : ''}
                    >
                        <CardTextFront>
                            {props.data.front}
                        </CardTextFront>
                    </CardSectionFront>
                    <CardSectionBack 
                        className={showDefinition? 'answer-revealed' : ''}
                    >
                        {
                            showDefinition === true ? 
                            <CardTextBack 
                                style={springProps}
                            >
                                {props.data.back}
                            </CardTextBack>
                            : null
                        }
                    </CardSectionBack>
                    <CardFooter
                        ref={cardFooterRef}
                        style={{
                            justifyContent: props.showDefinition ? 'space-between' : 'center'
                        }}
                    >   
                        {/* <CardFooterButtons> */}
                            {/* {
                                !showDefinition ?
                                <SkipButton
                                    disabled={props.score ? true : false}
                                    onClick={props.handleNext}
                                    >
                                        Skip
                                </SkipButton>
                                :null
                            } */}
                            <SubmitButton
                                onClick={()=>{showDefinition ? handleHide() : handleReveal()}}
                            >
                                {showDefinition ? 'Hide answer' : 'Reveal answer'}
                            </SubmitButton>
                        {/* </CardFooterButtons> */}
                        
                        {/* {
                            showDefinition ?  */}
                            <Credit
                                // className="credit"
                                className={showDefinition? 'answer-revealed' : ''}
                                >
                                <p>Created by AI</p>
                                <button onClick={()=>{props.handleModalType('upvote')}} >
                                    <img src={thumbsUp}/>
                                </button>
                                <button onClick={()=>{props.handleModalType('downvote')}}>
                                    <img src={thumbsDown}/>
                                </button>
                            </Credit>
                            {/* : null
                        } */}
                       
                    </CardFooter>
                </Card>
                : null   
            }
            
        </>
    )
}

export default Flashcard