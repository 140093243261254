import { createSlice, current } from '@reduxjs/toolkit';

const mcqStateSlice = createSlice({
  name: "mcqState",
  initialState: {
    notViewed: 0,
    correct:0,
    incorrect:0
  },
  reducers: {
    updateMCQState: (state, action) => {
      if(action.payload.notViewed || action.payload.notViewed === 0){
        state.notViewed = action.payload.notViewed
      }
      if(action.payload.correct){
        state.correct = action.payload.correct
      }
      if(action.payload.incorrect){
        state.incorrect = action.payload.incorrect
      }
      // console.log(current(state));
      return state;
    },
  }
});

export const { updateMCQState } = mcqStateSlice.actions;

export default mcqStateSlice.reducer;