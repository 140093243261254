import React, {useState, useEffect, useRef}from 'react';
import {connect} from "react-redux";
import close from "../images/icons/close.svg"
import highlighter from '../images/icons/hightlighter.svg'
import social from "../images/icons/socialqq.svg"
import newNote from '../images/icons/new-note.svg'
import readAlong from "../images/icons/speaker_notes.svg";
import newFlashcard from '../images/icons/new-flashcard.svg'
import citations from "../images/icons/citations.svg"
import newSearch from '../images/icons/new-search.svg'
import copy from "../images/icons/context-menu/copy.svg"
import audio from "../images/toolbar/audio.svg"
import Draggable, {DraggableCore} from "react-draggable";

const CustomizeMenu = props => {

    const [boxSize, setBoxSize] = useState({ w: (window.innerWidth / 2), h: (window.innerHeight / 2)});
    const modalRef = useRef(null)

    useEffect(() => {
        if(modalRef.current){
            setBoxSize(
                { 
                  w: (window.innerWidth / 2) - (modalRef.current.offsetWidth / 2),
                  h: (window.innerHeight / 2) - (modalRef.current.offsetHeight / 2) 
                }
            )
        }
    }, [modalRef]);

    const handleSetActions = (action) => {
        let newActions = [...props.mini_actions]
        if(props.mini_actions.includes(action)){
            newActions.splice(newActions.indexOf(action), 1)
        }else{
            newActions.push(action)
        }
        props.setActions(newActions)
    }


    return (
        <Draggable>
        <div 
            className='modal customize-menu'
            ref={modalRef}
            style={{
                marginLeft: boxSize.w,
                marginTop: boxSize.h
            }}
        >
            <div className='customize-header'>
                <h2>Customize menu</h2>
                <button onClick={props.closeCustomizeMenu}>
                    <img src={close}/>
                </button>
            </div>

            <p>Select the features you would like to have handy in your dropdown menu</p>

            <div className='container'>
                    <div>
                        <img src={highlighter}/>
                        <label htmlFor='highlight-toggle'>Highlight</label>
                     </div>
                     <button
                         className={`toggle ${props.mini_actions.includes('highlight') ? 'on' : ''}`}
                         id='highlight-toggle'
                         name="toggle-0"
                         role="switch"
                         aria-labelledby="labelId"
                         aria-checked="true"
                         onClick={()=>{handleSetActions('highlight')}}
                     >
                     </button>
            </div>

            <div className='container'>
                    <div>
                         <img src={newNote}/>
                         <label htmlFor='note-toggle'>New/edit note</label>
                     </div>
                     <button
                         className={`toggle ${props.mini_actions.includes('note') ? 'on' : ''}`}
                         id='note-toggle'
                         name="toggle-0"
                         role="switch"
                         aria-labelledby="labelId"
                         aria-checked="true"
                         onClick={()=>{handleSetActions('note')}}
                     >
                     </button>
            </div>

            <div className='container'>
                    <div>
                        <img src={newFlashcard}/>
                        <label htmlFor='flashcard-toggle'>New/edit flashcard</label>
                    </div>
                    <button
                         className={`toggle ${props.mini_actions.includes('flashcard') ? 'on' : ''}`}
                         id='flashcard-toggle'
                         name="toggle-0"
                         role="switch"
                         aria-labelledby="labelId"
                         aria-checked="true"
                         onClick={()=>{handleSetActions('flashcard')}}
                     >
                     </button>
            </div>

            <div className='container'>
                    <div>
                        <img src={copy}/>
                        <label htmlFor='copy-toggle'>Copy</label>
                    </div>
                    <button
                         className={`toggle ${props.mini_actions.includes('copy') ? 'on' : ''}`}
                         id='copy-toggle'
                         name="toggle-0"
                         role="switch"
                         aria-labelledby="labelId"
                         aria-checked="true"
                         onClick={()=>{handleSetActions('copy')}}
                     >
                     </button>
            </div>

            <div className='container'>
                    <div>
                        <img src={audio}/>
                        <label htmlFor='read-toggle'>Start audio here</label>
                    </div>
                    <button
                         className={`toggle ${props.mini_actions.includes('read') ? 'on' : ''}`}
                         id='read-toggle'
                         name="toggle-0"
                         role="switch"
                         aria-labelledby="labelId"
                         aria-checked="true"
                         onClick={()=>{handleSetActions('read')}}
                     >
                     </button>
            </div>

            <div className='container'>
                    <div>
                        <img src={newSearch}/>
                        <label htmlFor='search-toggle'>Search selection</label>
                    </div>
                    <button
                         className={`toggle ${props.mini_actions.includes('search') ? 'on' : ''}`}
                         id='search-toggle'
                         name="toggle-0"
                         role="switch"
                         aria-labelledby="labelId"
                         aria-checked="true"
                         onClick={()=>{handleSetActions('search')}}
                     >
                     </button>
            </div>

        </div>
        </Draggable>
    );
}


const mapStateToProps = ({display_settings, mini_actions}) => ({
    display_settings, mini_actions
});

const mapDispatchToProps = dispatch => ({
    setActions: (actions) => dispatch({type: 'SET_ACTIONS', actions:actions}),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomizeMenu);
